import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { alpha, Box, Card, Container, darken, IconButton, styled, Tooltip } from '@mui/material';
import { useContext } from 'react';
import { SidebarContext } from 'src/contexts/SidebarContext';

import NavigationMenu from './NavigationMenu';

const TopBarWrapper = styled(Card)(
    ({ theme }) => `
    color: ${theme.header.textColor};
    background: ${alpha(darken(theme.colors.primary.main, 0.2), 0.2)};
    backdrop-filter: blur(2px);
    padding: ${theme.spacing(4, 0, 7)};
    border-radius: 0;
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 99;
    width: 100%;
    box-shadow: none;
`
);

const IconButtonPrimary = styled(IconButton)(
    ({ theme }) => `
    display: flex;
    width: 48px;
    margin-left: ${theme.spacing(2)};
    height: 48px;
    justify-content: center;
    font-size: ${theme.typography.pxToRem(13)};
    padding: 0;
    position: relative;
    color: ${theme.colors.alpha.trueWhite[50]};
    background-color: ${theme.colors.alpha.white[10]};

    .MuiSvgIcon-root {
      transition: ${theme.transitions.create(['color'])};
      font-size: ${theme.typography.pxToRem(26)};
      color: ${theme.colors.alpha.trueWhite[50]};
    }

    &.active,
    &:hover {
      background-color: ${alpha(theme.colors.alpha.white[30], 0.2)};

      .MuiSvgIcon-root {
        color: ${theme.colors.alpha.trueWhite[100]};
      }
    }
`
);

const TopBar = () => {
    const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

    return (
        <TopBarWrapper>
            <Container
                sx={{
                    zIndex: 6,
                    mb: '-30px',
                }}
                maxWidth="xl"
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex">
                        <Box
                            component="span"
                            sx={{
                                display: { md: 'none', xs: 'inline-flex' },
                            }}
                        >
                            <Tooltip arrow title="Toggle Menu">
                                <IconButtonPrimary color="primary" onClick={toggleSidebar}>
                                    {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
                                </IconButtonPrimary>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        display: { xs: 'none', md: 'flex' },
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <NavigationMenu />
                </Box>
            </Container>
        </TopBarWrapper>
    );
};

export default TopBar;

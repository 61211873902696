import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Scrollbar from 'src/components/Scrollbar';

const Content = styled(Box)(
    () => `
    display: flex;
    flex: 1;
    width: 100%;
`
);

const SidebarWrapper = styled(Box)(
    ({ theme }) => `
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 500px;
  background: ${theme.colors.gradients.purple1};
`
);

const SidebarContent = styled(Box)(
    ({ theme }) => `
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(6)};
`
);
const TypographyPrimary = styled(Typography)(
    ({ theme }) => `
      color: ${theme.colors.alpha.trueWhite[100]};
`
);

const AuthLayout: React.FC = ({ children }) => {
    const { t }: { t: any } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Login - Cover</title>
            </Helmet>
            <Content>
                <SidebarWrapper
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                    }}
                >
                    <Scrollbar>
                        <SidebarContent
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                height: '100%',
                            }}
                        >
                            <Box
                                component="img"
                                sx={{
                                    width: '70%',
                                    maxWidth: '160px',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}
                                alt="Ozlynk Logo"
                                src="https://media.ozlynk.com/logo/logo-384x384.png"
                            />
                            <TypographyPrimary
                                align="center"
                                variant="h3"
                                sx={{
                                    mb: 4,
                                    px: 8,
                                }}
                            >
                                {t('Welcome Admins!')}
                            </TypographyPrimary>
                        </SidebarContent>
                    </Scrollbar>
                </SidebarWrapper>
                {children || <Outlet />}
            </Content>
        </>
    );
};

export default AuthLayout;

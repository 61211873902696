import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Cursor: any;
  Date: any;
  Datetime: any;
  JSON: any;
};

/** A connection to a list of `Activity` values. */
export type ActivitiesConnection = {
  __typename?: 'ActivitiesConnection';
  /** A list of edges which contains the `Activity` and cursor to aid in pagination. */
  edges: Array<ActivitiesEdge>;
  /** A list of `Activity` objects. */
  nodes: Array<Activity>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Activity` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Activity` edge in the connection. */
export type ActivitiesEdge = {
  __typename?: 'ActivitiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Activity` at the end of the edge. */
  node: Activity;
};

/** Methods to use when ordering `Activity`. */
export enum ActivitiesOrderBy {
  AffectedUserIdAsc = 'AFFECTED_USER_ID_ASC',
  AffectedUserIdDesc = 'AFFECTED_USER_ID_DESC',
  ChallengeIdAsc = 'CHALLENGE_ID_ASC',
  ChallengeIdDesc = 'CHALLENGE_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MediaIdAsc = 'MEDIA_ID_ASC',
  MediaIdDesc = 'MEDIA_ID_DESC',
  Natural = 'NATURAL',
  NotificationTypeAsc = 'NOTIFICATION_TYPE_ASC',
  NotificationTypeDesc = 'NOTIFICATION_TYPE_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PostIdAsc = 'POST_ID_ASC',
  PostIdDesc = 'POST_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  ViewedAsc = 'VIEWED_ASC',
  ViewedDesc = 'VIEWED_DESC'
}

export type Activity = Node & {
  __typename?: 'Activity';
  /** Reads a single `User` that is related to this `Activity`. */
  affectedUser?: Maybe<User>;
  affectedUserId: Scalars['Int'];
  /** Reads a single `Challenge` that is related to this `Activity`. */
  challenge?: Maybe<Challenge>;
  challengeId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** Reads a single `Media` that is related to this `Activity`. */
  media?: Maybe<Media>;
  mediaId?: Maybe<Scalars['Int']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  notificationType: NotificationType;
  payload: Scalars['JSON'];
  /** Reads a single `Post` that is related to this `Activity`. */
  post?: Maybe<Post>;
  postId?: Maybe<Scalars['Int']>;
  /** Reads a single `Team` that is related to this `Activity`. */
  team?: Maybe<Team>;
  teamId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `Activity`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
  viewed: Scalars['Boolean'];
};

/**
 * A condition to be used against `Activity` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ActivityCondition = {
  /** Checks for equality with the object’s `affectedUserId` field. */
  affectedUserId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `challengeId` field. */
  challengeId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `mediaId` field. */
  mediaId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `notificationType` field. */
  notificationType?: InputMaybe<NotificationType>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `postId` field. */
  postId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `viewed` field. */
  viewed?: InputMaybe<Scalars['Boolean']>;
};

/** A filter to be used against `Activity` object types. All fields are combined with a logical ‘and.’ */
export type ActivityFilter = {
  /** Filter by the object’s `affectedUserId` field. */
  affectedUserId?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ActivityFilter>>;
  /** Filter by the object’s `challengeId` field. */
  challengeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `mediaId` field. */
  mediaId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ActivityFilter>;
  /** Filter by the object’s `notificationType` field. */
  notificationType?: InputMaybe<NotificationTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ActivityFilter>>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `postId` field. */
  postId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `viewed` field. */
  viewed?: InputMaybe<BooleanFilter>;
};

/** An input for mutations affecting `Activity` */
export type ActivityInput = {
  affectedUserId: Scalars['Int'];
  challengeId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  mediaId?: InputMaybe<Scalars['Int']>;
  notificationType: NotificationType;
  payload: Scalars['JSON'];
  postId?: InputMaybe<Scalars['Int']>;
  teamId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
  viewed?: InputMaybe<Scalars['Boolean']>;
};

/** Represents an update to a `Activity`. Fields that are set will be updated. */
export type ActivityPatch = {
  viewed?: InputMaybe<Scalars['Boolean']>;
};

export type Ad = Node & {
  __typename?: 'Ad';
  /** Reads and enables pagination through a set of `Campaign`. */
  campaigns: CampaignsConnection;
  caption?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  mediaId: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt: Scalars['Datetime'];
  userId: Scalars['Int'];
};


export type AdCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/** A condition to be used against `Ad` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AdCondition = {
  /** Checks for equality with the object’s `caption` field. */
  caption?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `mediaId` field. */
  mediaId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Ad` object types. All fields are combined with a logical ‘and.’ */
export type AdFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AdFilter>>;
  /** Filter by the object’s `caption` field. */
  caption?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `mediaId` field. */
  mediaId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AdFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AdFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Ad` */
export type AdInput = {
  caption?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  mediaId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `Ad`. Fields that are set will be updated. */
export type AdPatch = {
  caption?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  mediaId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Ad` values. */
export type AdsConnection = {
  __typename?: 'AdsConnection';
  /** A list of edges which contains the `Ad` and cursor to aid in pagination. */
  edges: Array<AdsEdge>;
  /** A list of `Ad` objects. */
  nodes: Array<Ad>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Ad` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Ad` edge in the connection. */
export type AdsEdge = {
  __typename?: 'AdsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Ad` at the end of the edge. */
  node: Ad;
};

/** Methods to use when ordering `Ad`. */
export enum AdsOrderBy {
  CaptionAsc = 'CAPTION_ASC',
  CaptionDesc = 'CAPTION_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MediaIdAsc = 'MEDIA_ID_ASC',
  MediaIdDesc = 'MEDIA_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Archive = Node & {
  __typename?: 'Archive';
  /** Reads a single `Cloud` that is related to this `Archive`. */
  cloud?: Maybe<Cloud>;
  cloudId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Post` that is related to this `Archive`. */
  post?: Maybe<Post>;
  postId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Archive`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/** A condition to be used against `Archive` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ArchiveCondition = {
  /** Checks for equality with the object’s `cloudId` field. */
  cloudId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `postId` field. */
  postId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Archive` object types. All fields are combined with a logical ‘and.’ */
export type ArchiveFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ArchiveFilter>>;
  /** Filter by the object’s `cloudId` field. */
  cloudId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ArchiveFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ArchiveFilter>>;
  /** Filter by the object’s `postId` field. */
  postId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Archive` */
export type ArchiveInput = {
  cloudId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  postId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `Archive`. Fields that are set will be updated. */
export type ArchivePatch = {
  cloudId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  postId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Archive` values. */
export type ArchivesConnection = {
  __typename?: 'ArchivesConnection';
  /** A list of edges which contains the `Archive` and cursor to aid in pagination. */
  edges: Array<ArchivesEdge>;
  /** A list of `Archive` objects. */
  nodes: Array<Archive>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Archive` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Archive` edge in the connection. */
export type ArchivesEdge = {
  __typename?: 'ArchivesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Archive` at the end of the edge. */
  node: Archive;
};

/** Methods to use when ordering `Archive`. */
export enum ArchivesOrderBy {
  CloudIdAsc = 'CLOUD_ID_ASC',
  CloudIdDesc = 'CLOUD_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PostIdAsc = 'POST_ID_ASC',
  PostIdDesc = 'POST_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Audience = Node & {
  __typename?: 'Audience';
  /** Reads and enables pagination through a set of `AudienceMeta`. */
  audienceMetas: AudienceMetasConnection;
  /** Reads and enables pagination through a set of `Campaign`. */
  campaigns: CampaignsConnection;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Audience`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};


export type AudienceAudienceMetasArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AudienceMetaCondition>;
  filter?: InputMaybe<AudienceMetaFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AudienceMetasOrderBy>>;
};


export type AudienceCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/**
 * A condition to be used against `Audience` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AudienceCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Audience` object types. All fields are combined with a logical ‘and.’ */
export type AudienceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AudienceFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AudienceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AudienceFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Audience` */
export type AudienceInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

export type AudienceMeta = Node & {
  __typename?: 'AudienceMeta';
  /** Reads a single `Audience` that is related to this `AudienceMeta`. */
  audience?: Maybe<Audience>;
  audienceId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  /** Reads a single `Gender` that is related to this `AudienceMeta`. */
  gender?: Maybe<Gender>;
  genderId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  location: Scalars['String'];
  maxAge: Scalars['Int'];
  minAge: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `AudienceMeta` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AudienceMetaCondition = {
  /** Checks for equality with the object’s `audienceId` field. */
  audienceId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `genderId` field. */
  genderId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `location` field. */
  location?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `maxAge` field. */
  maxAge?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `minAge` field. */
  minAge?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `AudienceMeta` object types. All fields are combined with a logical ‘and.’ */
export type AudienceMetaFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AudienceMetaFilter>>;
  /** Filter by the object’s `audienceId` field. */
  audienceId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `genderId` field. */
  genderId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `maxAge` field. */
  maxAge?: InputMaybe<IntFilter>;
  /** Filter by the object’s `minAge` field. */
  minAge?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AudienceMetaFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AudienceMetaFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `AudienceMeta` */
export type AudienceMetaInput = {
  audienceId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  genderId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  location: Scalars['String'];
  maxAge: Scalars['Int'];
  minAge: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `AudienceMeta`. Fields that are set will be updated. */
export type AudienceMetaPatch = {
  audienceId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  genderId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['String']>;
  maxAge?: InputMaybe<Scalars['Int']>;
  minAge?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `AudienceMeta` values. */
export type AudienceMetasConnection = {
  __typename?: 'AudienceMetasConnection';
  /** A list of edges which contains the `AudienceMeta` and cursor to aid in pagination. */
  edges: Array<AudienceMetasEdge>;
  /** A list of `AudienceMeta` objects. */
  nodes: Array<AudienceMeta>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AudienceMeta` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AudienceMeta` edge in the connection. */
export type AudienceMetasEdge = {
  __typename?: 'AudienceMetasEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AudienceMeta` at the end of the edge. */
  node: AudienceMeta;
};

/** Methods to use when ordering `AudienceMeta`. */
export enum AudienceMetasOrderBy {
  AudienceIdAsc = 'AUDIENCE_ID_ASC',
  AudienceIdDesc = 'AUDIENCE_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  GenderIdAsc = 'GENDER_ID_ASC',
  GenderIdDesc = 'GENDER_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocationAsc = 'LOCATION_ASC',
  LocationDesc = 'LOCATION_DESC',
  MaxAgeAsc = 'MAX_AGE_ASC',
  MaxAgeDesc = 'MAX_AGE_DESC',
  MinAgeAsc = 'MIN_AGE_ASC',
  MinAgeDesc = 'MIN_AGE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** Represents an update to a `Audience`. Fields that are set will be updated. */
export type AudiencePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Audience` values. */
export type AudiencesConnection = {
  __typename?: 'AudiencesConnection';
  /** A list of edges which contains the `Audience` and cursor to aid in pagination. */
  edges: Array<AudiencesEdge>;
  /** A list of `Audience` objects. */
  nodes: Array<Audience>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Audience` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Audience` edge in the connection. */
export type AudiencesEdge = {
  __typename?: 'AudiencesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Audience` at the end of the edge. */
  node: Audience;
};

/** Methods to use when ordering `Audience`. */
export enum AudiencesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Beast = Node & {
  __typename?: 'Beast';
  createdAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Post` that is related to this `Beast`. */
  post?: Maybe<Post>;
  postId: Scalars['Int'];
  /** Reads a single `User` that is related to this `Beast`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/** A condition to be used against `Beast` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type BeastCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `postId` field. */
  postId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Beast` object types. All fields are combined with a logical ‘and.’ */
export type BeastFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BeastFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BeastFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BeastFilter>>;
  /** Filter by the object’s `postId` field. */
  postId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Beast` */
export type BeastInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** Represents an update to a `Beast`. Fields that are set will be updated. */
export type BeastPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  postId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Beast` values. */
export type BeastsConnection = {
  __typename?: 'BeastsConnection';
  /** A list of edges which contains the `Beast` and cursor to aid in pagination. */
  edges: Array<BeastsEdge>;
  /** A list of `Beast` objects. */
  nodes: Array<Beast>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Beast` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Beast` edge in the connection. */
export type BeastsEdge = {
  __typename?: 'BeastsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Beast` at the end of the edge. */
  node: Beast;
};

/** Methods to use when ordering `Beast`. */
export enum BeastsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  Natural = 'NATURAL',
  PostIdAsc = 'POST_ID_ASC',
  PostIdDesc = 'POST_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['BigInt']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['BigInt']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['BigInt']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['BigInt']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['BigInt']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

export type BlockedUser = Node & {
  __typename?: 'BlockedUser';
  blockedUserId: Scalars['Int'];
  /** Reads a single `User` that is related to this `BlockedUser`. */
  blocked_users_buid?: Maybe<User>;
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `BlockedUser`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `BlockedUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type BlockedUserCondition = {
  /** Checks for equality with the object’s `blockedUserId` field. */
  blockedUserId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `BlockedUser` object types. All fields are combined with a logical ‘and.’ */
export type BlockedUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BlockedUserFilter>>;
  /** Filter by the object’s `blockedUserId` field. */
  blockedUserId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BlockedUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BlockedUserFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `BlockedUser` */
export type BlockedUserInput = {
  blockedUserId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `BlockedUser`. Fields that are set will be updated. */
export type BlockedUserPatch = {
  blockedUserId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `BlockedUser` values. */
export type BlockedUsersConnection = {
  __typename?: 'BlockedUsersConnection';
  /** A list of edges which contains the `BlockedUser` and cursor to aid in pagination. */
  edges: Array<BlockedUsersEdge>;
  /** A list of `BlockedUser` objects. */
  nodes: Array<BlockedUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BlockedUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BlockedUser` edge in the connection. */
export type BlockedUsersEdge = {
  __typename?: 'BlockedUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BlockedUser` at the end of the edge. */
  node: BlockedUser;
};

/** Methods to use when ordering `BlockedUser`. */
export enum BlockedUsersOrderBy {
  BlockedUserIdAsc = 'BLOCKED_USER_ID_ASC',
  BlockedUserIdDesc = 'BLOCKED_USER_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type Budget = Node & {
  __typename?: 'Budget';
  /** Reads and enables pagination through a set of `Campaign`. */
  campaigns: CampaignsConnection;
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  maxBudget?: Maybe<Scalars['Int']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  perDay: Scalars['Int'];
  updatedAt: Scalars['Datetime'];
  usedBudget: Scalars['Float'];
  /** Reads a single `User` that is related to this `Budget`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};


export type BudgetCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/** A condition to be used against `Budget` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type BudgetCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maxBudget` field. */
  maxBudget?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `perDay` field. */
  perDay?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `usedBudget` field. */
  usedBudget?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Budget` object types. All fields are combined with a logical ‘and.’ */
export type BudgetFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BudgetFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `maxBudget` field. */
  maxBudget?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BudgetFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BudgetFilter>>;
  /** Filter by the object’s `perDay` field. */
  perDay?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `usedBudget` field. */
  usedBudget?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Budget` */
export type BudgetInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  maxBudget?: InputMaybe<Scalars['Int']>;
  perDay: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usedBudget?: InputMaybe<Scalars['Float']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `Budget`. Fields that are set will be updated. */
export type BudgetPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  maxBudget?: InputMaybe<Scalars['Int']>;
  perDay?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usedBudget?: InputMaybe<Scalars['Float']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Budget` values. */
export type BudgetsConnection = {
  __typename?: 'BudgetsConnection';
  /** A list of edges which contains the `Budget` and cursor to aid in pagination. */
  edges: Array<BudgetsEdge>;
  /** A list of `Budget` objects. */
  nodes: Array<Budget>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Budget` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Budget` edge in the connection. */
export type BudgetsEdge = {
  __typename?: 'BudgetsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Budget` at the end of the edge. */
  node: Budget;
};

/** Methods to use when ordering `Budget`. */
export enum BudgetsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MaxBudgetAsc = 'MAX_BUDGET_ASC',
  MaxBudgetDesc = 'MAX_BUDGET_DESC',
  Natural = 'NATURAL',
  PerDayAsc = 'PER_DAY_ASC',
  PerDayDesc = 'PER_DAY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UsedBudgetAsc = 'USED_BUDGET_ASC',
  UsedBudgetDesc = 'USED_BUDGET_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Campaign = Node & {
  __typename?: 'Campaign';
  /** Reads a single `CampaignsAction` that is related to this `Campaign`. */
  action?: Maybe<CampaignsAction>;
  actionId?: Maybe<Scalars['Int']>;
  /** Reads a single `Ad` that is related to this `Campaign`. */
  ad?: Maybe<Ad>;
  adId: Scalars['Int'];
  approved: Scalars['Boolean'];
  /** Reads a single `User` that is related to this `Campaign`. */
  approvedBy?: Maybe<User>;
  approvedById?: Maybe<Scalars['Int']>;
  /** Reads a single `Audience` that is related to this `Campaign`. */
  audience?: Maybe<Audience>;
  audienceId: Scalars['Int'];
  /** Reads a single `Budget` that is related to this `Campaign`. */
  budget?: Maybe<Budget>;
  budgetId: Scalars['Int'];
  clearedAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  dateFrom?: Maybe<Scalars['Datetime']>;
  dateTo?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  requestDetails: Scalars['JSON'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Campaign`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `Campaign` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CampaignCondition = {
  /** Checks for equality with the object’s `actionId` field. */
  actionId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `adId` field. */
  adId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `approved` field. */
  approved?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `approvedById` field. */
  approvedById?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `audienceId` field. */
  audienceId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `budgetId` field. */
  budgetId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `clearedAt` field. */
  clearedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `dateFrom` field. */
  dateFrom?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `dateTo` field. */
  dateTo?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `requestDetails` field. */
  requestDetails?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Campaign` object types. All fields are combined with a logical ‘and.’ */
export type CampaignFilter = {
  /** Filter by the object’s `actionId` field. */
  actionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `adId` field. */
  adId?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CampaignFilter>>;
  /** Filter by the object’s `approved` field. */
  approved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `approvedById` field. */
  approvedById?: InputMaybe<IntFilter>;
  /** Filter by the object’s `audienceId` field. */
  audienceId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `budgetId` field. */
  budgetId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `clearedAt` field. */
  clearedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateFrom` field. */
  dateFrom?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateTo` field. */
  dateTo?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CampaignFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CampaignFilter>>;
  /** Filter by the object’s `requestDetails` field. */
  requestDetails?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Campaign` */
export type CampaignInput = {
  actionId?: InputMaybe<Scalars['Int']>;
  adId: Scalars['Int'];
  approved?: InputMaybe<Scalars['Boolean']>;
  approvedById?: InputMaybe<Scalars['Int']>;
  audienceId: Scalars['Int'];
  budgetId: Scalars['Int'];
  clearedAt?: InputMaybe<Scalars['Datetime']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  dateFrom?: InputMaybe<Scalars['Datetime']>;
  dateTo?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  requestDetails: Scalars['JSON'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `Campaign`. Fields that are set will be updated. */
export type CampaignPatch = {
  actionId?: InputMaybe<Scalars['Int']>;
  adId?: InputMaybe<Scalars['Int']>;
  approved?: InputMaybe<Scalars['Boolean']>;
  approvedById?: InputMaybe<Scalars['Int']>;
  audienceId?: InputMaybe<Scalars['Int']>;
  budgetId?: InputMaybe<Scalars['Int']>;
  clearedAt?: InputMaybe<Scalars['Datetime']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  dateFrom?: InputMaybe<Scalars['Datetime']>;
  dateTo?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  requestDetails?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type CampaignsAction = Node & {
  __typename?: 'CampaignsAction';
  /** Reads and enables pagination through a set of `Campaign`. */
  campaignsByActionId: CampaignsConnection;
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  url?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `CampaignsAction`. */
  userTo?: Maybe<User>;
  userToId?: Maybe<Scalars['Int']>;
};


export type CampaignsActionCampaignsByActionIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/**
 * A condition to be used against `CampaignsAction` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CampaignsActionCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userToId` field. */
  userToId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `CampaignsAction` object types. All fields are combined with a logical ‘and.’ */
export type CampaignsActionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CampaignsActionFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CampaignsActionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CampaignsActionFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userToId` field. */
  userToId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `CampaignsAction` */
export type CampaignsActionInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  url?: InputMaybe<Scalars['String']>;
  userToId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `CampaignsAction`. Fields that are set will be updated. */
export type CampaignsActionPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  url?: InputMaybe<Scalars['String']>;
  userToId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `CampaignsAction` values. */
export type CampaignsActionsConnection = {
  __typename?: 'CampaignsActionsConnection';
  /** A list of edges which contains the `CampaignsAction` and cursor to aid in pagination. */
  edges: Array<CampaignsActionsEdge>;
  /** A list of `CampaignsAction` objects. */
  nodes: Array<CampaignsAction>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CampaignsAction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CampaignsAction` edge in the connection. */
export type CampaignsActionsEdge = {
  __typename?: 'CampaignsActionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CampaignsAction` at the end of the edge. */
  node: CampaignsAction;
};

/** Methods to use when ordering `CampaignsAction`. */
export enum CampaignsActionsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  UserToIdAsc = 'USER_TO_ID_ASC',
  UserToIdDesc = 'USER_TO_ID_DESC'
}

/** A connection to a list of `Campaign` values. */
export type CampaignsConnection = {
  __typename?: 'CampaignsConnection';
  /** A list of edges which contains the `Campaign` and cursor to aid in pagination. */
  edges: Array<CampaignsEdge>;
  /** A list of `Campaign` objects. */
  nodes: Array<Campaign>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Campaign` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Campaign` edge in the connection. */
export type CampaignsEdge = {
  __typename?: 'CampaignsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Campaign` at the end of the edge. */
  node: Campaign;
};

/** Methods to use when ordering `Campaign`. */
export enum CampaignsOrderBy {
  ActionIdAsc = 'ACTION_ID_ASC',
  ActionIdDesc = 'ACTION_ID_DESC',
  AdIdAsc = 'AD_ID_ASC',
  AdIdDesc = 'AD_ID_DESC',
  ApprovedAsc = 'APPROVED_ASC',
  ApprovedByIdAsc = 'APPROVED_BY_ID_ASC',
  ApprovedByIdDesc = 'APPROVED_BY_ID_DESC',
  ApprovedDesc = 'APPROVED_DESC',
  AudienceIdAsc = 'AUDIENCE_ID_ASC',
  AudienceIdDesc = 'AUDIENCE_ID_DESC',
  BudgetIdAsc = 'BUDGET_ID_ASC',
  BudgetIdDesc = 'BUDGET_ID_DESC',
  ClearedAtAsc = 'CLEARED_AT_ASC',
  ClearedAtDesc = 'CLEARED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DateFromAsc = 'DATE_FROM_ASC',
  DateFromDesc = 'DATE_FROM_DESC',
  DateToAsc = 'DATE_TO_ASC',
  DateToDesc = 'DATE_TO_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RequestDetailsAsc = 'REQUEST_DETAILS_ASC',
  RequestDetailsDesc = 'REQUEST_DETAILS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Challenge = Node & {
  __typename?: 'Challenge';
  /** Reads and enables pagination through a set of `Activity`. */
  activities: ActivitiesConnection;
  challengedAccepted?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Team` that is related to this `Challenge`. */
  challengedTeam?: Maybe<Team>;
  challengedTeamId?: Maybe<Scalars['Int']>;
  /** Reads a single `User` that is related to this `Challenge`. */
  challengedUser?: Maybe<User>;
  challengedUserId?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `ChallengesProof`. */
  challengesProofs: ChallengesProofsConnection;
  /** Reads and enables pagination through a set of `ChallengesWitness`. */
  challengesWitnesses: ChallengesWitnessesConnection;
  completed: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  description: Scalars['String'];
  endsAt: Scalars['Datetime'];
  /** Reads a single `Game` that is related to this `Challenge`. */
  game?: Maybe<Game>;
  gameId: Scalars['Int'];
  id: Scalars['Int'];
  /** Reads a single `Team` that is related to this `Challenge`. */
  initiatorTeam?: Maybe<Team>;
  initiatorTeamId?: Maybe<Scalars['Int']>;
  /** Reads a single `User` that is related to this `Challenge`. */
  initiatorUser?: Maybe<User>;
  initiatorUserId?: Maybe<Scalars['Int']>;
  isPrivate: Scalars['Boolean'];
  isSolo: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Challenge`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
  witnessAccepted: Scalars['Boolean'];
  won?: Maybe<Scalars['Boolean']>;
};


export type ChallengeActivitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ActivityCondition>;
  filter?: InputMaybe<ActivityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};


export type ChallengeChallengesProofsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengesProofCondition>;
  filter?: InputMaybe<ChallengesProofFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesProofsOrderBy>>;
};


export type ChallengeChallengesWitnessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengesWitnessCondition>;
  filter?: InputMaybe<ChallengesWitnessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesWitnessesOrderBy>>;
};

/**
 * A condition to be used against `Challenge` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ChallengeCondition = {
  /** Checks for equality with the object’s `challengedAccepted` field. */
  challengedAccepted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `challengedTeamId` field. */
  challengedTeamId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `challengedUserId` field. */
  challengedUserId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `completed` field. */
  completed?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `endsAt` field. */
  endsAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `gameId` field. */
  gameId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `initiatorTeamId` field. */
  initiatorTeamId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `initiatorUserId` field. */
  initiatorUserId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPrivate` field. */
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isSolo` field. */
  isSolo?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `witnessAccepted` field. */
  witnessAccepted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `won` field. */
  won?: InputMaybe<Scalars['Boolean']>;
};

/** A filter to be used against `Challenge` object types. All fields are combined with a logical ‘and.’ */
export type ChallengeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ChallengeFilter>>;
  /** Filter by the object’s `challengedAccepted` field. */
  challengedAccepted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `challengedTeamId` field. */
  challengedTeamId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `challengedUserId` field. */
  challengedUserId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `completed` field. */
  completed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `endsAt` field. */
  endsAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `gameId` field. */
  gameId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `initiatorTeamId` field. */
  initiatorTeamId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `initiatorUserId` field. */
  initiatorUserId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isPrivate` field. */
  isPrivate?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSolo` field. */
  isSolo?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ChallengeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ChallengeFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `witnessAccepted` field. */
  witnessAccepted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `won` field. */
  won?: InputMaybe<BooleanFilter>;
};

/** An input for mutations affecting `Challenge` */
export type ChallengeInput = {
  challengedAccepted?: InputMaybe<Scalars['Boolean']>;
  challengedTeamId?: InputMaybe<Scalars['Int']>;
  challengedUserId?: InputMaybe<Scalars['Int']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description: Scalars['String'];
  endsAt: Scalars['Datetime'];
  gameId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  initiatorTeamId?: InputMaybe<Scalars['Int']>;
  initiatorUserId?: InputMaybe<Scalars['Int']>;
  isPrivate: Scalars['Boolean'];
  isSolo: Scalars['Boolean'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
  witnessAccepted?: InputMaybe<Scalars['Boolean']>;
  won?: InputMaybe<Scalars['Boolean']>;
};

/** Represents an update to a `Challenge`. Fields that are set will be updated. */
export type ChallengePatch = {
  challengedAccepted?: InputMaybe<Scalars['Boolean']>;
  challengedTeamId?: InputMaybe<Scalars['Int']>;
  challengedUserId?: InputMaybe<Scalars['Int']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description?: InputMaybe<Scalars['String']>;
  endsAt?: InputMaybe<Scalars['Datetime']>;
  gameId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  initiatorTeamId?: InputMaybe<Scalars['Int']>;
  initiatorUserId?: InputMaybe<Scalars['Int']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  isSolo?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  witnessAccepted?: InputMaybe<Scalars['Boolean']>;
  won?: InputMaybe<Scalars['Boolean']>;
};

/** A connection to a list of `Challenge` values. */
export type ChallengesConnection = {
  __typename?: 'ChallengesConnection';
  /** A list of edges which contains the `Challenge` and cursor to aid in pagination. */
  edges: Array<ChallengesEdge>;
  /** A list of `Challenge` objects. */
  nodes: Array<Challenge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Challenge` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Challenge` edge in the connection. */
export type ChallengesEdge = {
  __typename?: 'ChallengesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Challenge` at the end of the edge. */
  node: Challenge;
};

/** Methods to use when ordering `Challenge`. */
export enum ChallengesOrderBy {
  ChallengedAcceptedAsc = 'CHALLENGED_ACCEPTED_ASC',
  ChallengedAcceptedDesc = 'CHALLENGED_ACCEPTED_DESC',
  ChallengedTeamIdAsc = 'CHALLENGED_TEAM_ID_ASC',
  ChallengedTeamIdDesc = 'CHALLENGED_TEAM_ID_DESC',
  ChallengedUserIdAsc = 'CHALLENGED_USER_ID_ASC',
  ChallengedUserIdDesc = 'CHALLENGED_USER_ID_DESC',
  CompletedAsc = 'COMPLETED_ASC',
  CompletedDesc = 'COMPLETED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  EndsAtAsc = 'ENDS_AT_ASC',
  EndsAtDesc = 'ENDS_AT_DESC',
  GameIdAsc = 'GAME_ID_ASC',
  GameIdDesc = 'GAME_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InitiatorTeamIdAsc = 'INITIATOR_TEAM_ID_ASC',
  InitiatorTeamIdDesc = 'INITIATOR_TEAM_ID_DESC',
  InitiatorUserIdAsc = 'INITIATOR_USER_ID_ASC',
  InitiatorUserIdDesc = 'INITIATOR_USER_ID_DESC',
  IsPrivateAsc = 'IS_PRIVATE_ASC',
  IsPrivateDesc = 'IS_PRIVATE_DESC',
  IsSoloAsc = 'IS_SOLO_ASC',
  IsSoloDesc = 'IS_SOLO_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  WitnessAcceptedAsc = 'WITNESS_ACCEPTED_ASC',
  WitnessAcceptedDesc = 'WITNESS_ACCEPTED_DESC',
  WonAsc = 'WON_ASC',
  WonDesc = 'WON_DESC'
}

export type ChallengesProof = Node & {
  __typename?: 'ChallengesProof';
  /** Reads a single `Challenge` that is related to this `ChallengesProof`. */
  challenge?: Maybe<Challenge>;
  challengeId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** Reads a single `Media` that is related to this `ChallengesProof`. */
  media?: Maybe<Media>;
  mediaId: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `User` that is related to this `ChallengesProof`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `ChallengesProof` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ChallengesProofCondition = {
  /** Checks for equality with the object’s `challengeId` field. */
  challengeId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `mediaId` field. */
  mediaId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ChallengesProof` object types. All fields are combined with a logical ‘and.’ */
export type ChallengesProofFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ChallengesProofFilter>>;
  /** Filter by the object’s `challengeId` field. */
  challengeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `mediaId` field. */
  mediaId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ChallengesProofFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ChallengesProofFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ChallengesProof` */
export type ChallengesProofInput = {
  challengeId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  mediaId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** Represents an update to a `ChallengesProof`. Fields that are set will be updated. */
export type ChallengesProofPatch = {
  challengeId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  mediaId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `ChallengesProof` values. */
export type ChallengesProofsConnection = {
  __typename?: 'ChallengesProofsConnection';
  /** A list of edges which contains the `ChallengesProof` and cursor to aid in pagination. */
  edges: Array<ChallengesProofsEdge>;
  /** A list of `ChallengesProof` objects. */
  nodes: Array<ChallengesProof>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ChallengesProof` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ChallengesProof` edge in the connection. */
export type ChallengesProofsEdge = {
  __typename?: 'ChallengesProofsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ChallengesProof` at the end of the edge. */
  node: ChallengesProof;
};

/** Methods to use when ordering `ChallengesProof`. */
export enum ChallengesProofsOrderBy {
  ChallengeIdAsc = 'CHALLENGE_ID_ASC',
  ChallengeIdDesc = 'CHALLENGE_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MediaIdAsc = 'MEDIA_ID_ASC',
  MediaIdDesc = 'MEDIA_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type ChallengesWitness = Node & {
  __typename?: 'ChallengesWitness';
  accepted?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Challenge` that is related to this `ChallengesWitness`. */
  challenge?: Maybe<Challenge>;
  challengeId: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `ChallengesWitness`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `ChallengesWitness` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ChallengesWitnessCondition = {
  /** Checks for equality with the object’s `accepted` field. */
  accepted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `challengeId` field. */
  challengeId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ChallengesWitness` object types. All fields are combined with a logical ‘and.’ */
export type ChallengesWitnessFilter = {
  /** Filter by the object’s `accepted` field. */
  accepted?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ChallengesWitnessFilter>>;
  /** Filter by the object’s `challengeId` field. */
  challengeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ChallengesWitnessFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ChallengesWitnessFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ChallengesWitness` */
export type ChallengesWitnessInput = {
  accepted?: InputMaybe<Scalars['Boolean']>;
  challengeId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `ChallengesWitness`. Fields that are set will be updated. */
export type ChallengesWitnessPatch = {
  accepted?: InputMaybe<Scalars['Boolean']>;
  challengeId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `ChallengesWitness` values. */
export type ChallengesWitnessesConnection = {
  __typename?: 'ChallengesWitnessesConnection';
  /** A list of edges which contains the `ChallengesWitness` and cursor to aid in pagination. */
  edges: Array<ChallengesWitnessesEdge>;
  /** A list of `ChallengesWitness` objects. */
  nodes: Array<ChallengesWitness>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ChallengesWitness` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ChallengesWitness` edge in the connection. */
export type ChallengesWitnessesEdge = {
  __typename?: 'ChallengesWitnessesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ChallengesWitness` at the end of the edge. */
  node: ChallengesWitness;
};

/** Methods to use when ordering `ChallengesWitness`. */
export enum ChallengesWitnessesOrderBy {
  AcceptedAsc = 'ACCEPTED_ASC',
  AcceptedDesc = 'ACCEPTED_DESC',
  ChallengeIdAsc = 'CHALLENGE_ID_ASC',
  ChallengeIdDesc = 'CHALLENGE_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** All input for the `checkPrivateProfile` mutation. */
export type CheckPrivateProfileInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  followingId?: InputMaybe<Scalars['Int']>;
  private?: InputMaybe<Scalars['Boolean']>;
};

/** The output of our `checkPrivateProfile` mutation. */
export type CheckPrivateProfilePayload = {
  __typename?: 'CheckPrivateProfilePayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type Cloud = Node & {
  __typename?: 'Cloud';
  archivedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `Archive`. */
  archives: ArchivesConnection;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** Reads a single `Media` that is related to this `Cloud`. */
  media?: Maybe<Media>;
  mediaId: Scalars['Int'];
  new: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Post` that is related to this `Cloud`. */
  post?: Maybe<Post>;
  postId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Cloud`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};


export type CloudArchivesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArchiveCondition>;
  filter?: InputMaybe<ArchiveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArchivesOrderBy>>;
};

/** A condition to be used against `Cloud` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CloudCondition = {
  /** Checks for equality with the object’s `archivedAt` field. */
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `mediaId` field. */
  mediaId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `new` field. */
  new?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `postId` field. */
  postId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Cloud` object types. All fields are combined with a logical ‘and.’ */
export type CloudFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CloudFilter>>;
  /** Filter by the object’s `archivedAt` field. */
  archivedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `mediaId` field. */
  mediaId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `new` field. */
  new?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CloudFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CloudFilter>>;
  /** Filter by the object’s `postId` field. */
  postId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Cloud` */
export type CloudInput = {
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  mediaId: Scalars['Int'];
  new?: InputMaybe<Scalars['Boolean']>;
  postId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `Cloud`. Fields that are set will be updated. */
export type CloudPatch = {
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  mediaId?: InputMaybe<Scalars['Int']>;
  new?: InputMaybe<Scalars['Boolean']>;
  postId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Cloud` values. */
export type CloudsConnection = {
  __typename?: 'CloudsConnection';
  /** A list of edges which contains the `Cloud` and cursor to aid in pagination. */
  edges: Array<CloudsEdge>;
  /** A list of `Cloud` objects. */
  nodes: Array<Cloud>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Cloud` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Cloud` edge in the connection. */
export type CloudsEdge = {
  __typename?: 'CloudsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Cloud` at the end of the edge. */
  node: Cloud;
};

/** Methods to use when ordering `Cloud`. */
export enum CloudsOrderBy {
  ArchivedAtAsc = 'ARCHIVED_AT_ASC',
  ArchivedAtDesc = 'ARCHIVED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MediaIdAsc = 'MEDIA_ID_ASC',
  MediaIdDesc = 'MEDIA_ID_DESC',
  Natural = 'NATURAL',
  NewAsc = 'NEW_ASC',
  NewDesc = 'NEW_DESC',
  PostIdAsc = 'POST_ID_ASC',
  PostIdDesc = 'POST_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** All input for the create `Activity` mutation. */
export type CreateActivityInput = {
  /** The `Activity` to be created by this mutation. */
  activity: ActivityInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Activity` mutation. */
export type CreateActivityPayload = {
  __typename?: 'CreateActivityPayload';
  /** The `Activity` that was created by this mutation. */
  activity?: Maybe<Activity>;
  /** An edge for our `Activity`. May be used by Relay 1. */
  activityEdge?: Maybe<ActivitiesEdge>;
  /** Reads a single `User` that is related to this `Activity`. */
  affectedUser?: Maybe<User>;
  /** Reads a single `Challenge` that is related to this `Activity`. */
  challenge?: Maybe<Challenge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Media` that is related to this `Activity`. */
  media?: Maybe<Media>;
  /** Reads a single `Post` that is related to this `Activity`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `Activity`. */
  team?: Maybe<Team>;
  /** Reads a single `User` that is related to this `Activity`. */
  user?: Maybe<User>;
};


/** The output of our create `Activity` mutation. */
export type CreateActivityPayloadActivityEdgeArgs = {
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};

/** All input for the create `Ad` mutation. */
export type CreateAdInput = {
  /** The `Ad` to be created by this mutation. */
  ad: AdInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Ad` mutation. */
export type CreateAdPayload = {
  __typename?: 'CreateAdPayload';
  /** The `Ad` that was created by this mutation. */
  ad?: Maybe<Ad>;
  /** An edge for our `Ad`. May be used by Relay 1. */
  adEdge?: Maybe<AdsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Ad` mutation. */
export type CreateAdPayloadAdEdgeArgs = {
  orderBy?: InputMaybe<Array<AdsOrderBy>>;
};

/** All input for the create `Archive` mutation. */
export type CreateArchiveInput = {
  /** The `Archive` to be created by this mutation. */
  archive: ArchiveInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Archive` mutation. */
export type CreateArchivePayload = {
  __typename?: 'CreateArchivePayload';
  /** The `Archive` that was created by this mutation. */
  archive?: Maybe<Archive>;
  /** An edge for our `Archive`. May be used by Relay 1. */
  archiveEdge?: Maybe<ArchivesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Cloud` that is related to this `Archive`. */
  cloud?: Maybe<Cloud>;
  /** Reads a single `Post` that is related to this `Archive`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Archive`. */
  user?: Maybe<User>;
};


/** The output of our create `Archive` mutation. */
export type CreateArchivePayloadArchiveEdgeArgs = {
  orderBy?: InputMaybe<Array<ArchivesOrderBy>>;
};

/** All input for the create `Audience` mutation. */
export type CreateAudienceInput = {
  /** The `Audience` to be created by this mutation. */
  audience: AudienceInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the create `AudienceMeta` mutation. */
export type CreateAudienceMetaInput = {
  /** The `AudienceMeta` to be created by this mutation. */
  audienceMeta: AudienceMetaInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `AudienceMeta` mutation. */
export type CreateAudienceMetaPayload = {
  __typename?: 'CreateAudienceMetaPayload';
  /** Reads a single `Audience` that is related to this `AudienceMeta`. */
  audience?: Maybe<Audience>;
  /** The `AudienceMeta` that was created by this mutation. */
  audienceMeta?: Maybe<AudienceMeta>;
  /** An edge for our `AudienceMeta`. May be used by Relay 1. */
  audienceMetaEdge?: Maybe<AudienceMetasEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Gender` that is related to this `AudienceMeta`. */
  gender?: Maybe<Gender>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `AudienceMeta` mutation. */
export type CreateAudienceMetaPayloadAudienceMetaEdgeArgs = {
  orderBy?: InputMaybe<Array<AudienceMetasOrderBy>>;
};

/** The output of our create `Audience` mutation. */
export type CreateAudiencePayload = {
  __typename?: 'CreateAudiencePayload';
  /** The `Audience` that was created by this mutation. */
  audience?: Maybe<Audience>;
  /** An edge for our `Audience`. May be used by Relay 1. */
  audienceEdge?: Maybe<AudiencesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Audience`. */
  user?: Maybe<User>;
};


/** The output of our create `Audience` mutation. */
export type CreateAudiencePayloadAudienceEdgeArgs = {
  orderBy?: InputMaybe<Array<AudiencesOrderBy>>;
};

/** All input for the create `Beast` mutation. */
export type CreateBeastInput = {
  /** The `Beast` to be created by this mutation. */
  beast: BeastInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Beast` mutation. */
export type CreateBeastPayload = {
  __typename?: 'CreateBeastPayload';
  /** The `Beast` that was created by this mutation. */
  beast?: Maybe<Beast>;
  /** An edge for our `Beast`. May be used by Relay 1. */
  beastEdge?: Maybe<BeastsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Post` that is related to this `Beast`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Beast`. */
  user?: Maybe<User>;
};


/** The output of our create `Beast` mutation. */
export type CreateBeastPayloadBeastEdgeArgs = {
  orderBy?: InputMaybe<Array<BeastsOrderBy>>;
};

/** All input for the create `BlockedUser` mutation. */
export type CreateBlockedUserInput = {
  /** The `BlockedUser` to be created by this mutation. */
  blockedUser: BlockedUserInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BlockedUser` mutation. */
export type CreateBlockedUserPayload = {
  __typename?: 'CreateBlockedUserPayload';
  /** The `BlockedUser` that was created by this mutation. */
  blockedUser?: Maybe<BlockedUser>;
  /** An edge for our `BlockedUser`. May be used by Relay 1. */
  blockedUserEdge?: Maybe<BlockedUsersEdge>;
  /** Reads a single `User` that is related to this `BlockedUser`. */
  blocked_users_buid?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `BlockedUser`. */
  user?: Maybe<User>;
};


/** The output of our create `BlockedUser` mutation. */
export type CreateBlockedUserPayloadBlockedUserEdgeArgs = {
  orderBy?: InputMaybe<Array<BlockedUsersOrderBy>>;
};

/** All input for the create `Budget` mutation. */
export type CreateBudgetInput = {
  /** The `Budget` to be created by this mutation. */
  budget: BudgetInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Budget` mutation. */
export type CreateBudgetPayload = {
  __typename?: 'CreateBudgetPayload';
  /** The `Budget` that was created by this mutation. */
  budget?: Maybe<Budget>;
  /** An edge for our `Budget`. May be used by Relay 1. */
  budgetEdge?: Maybe<BudgetsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Budget`. */
  user?: Maybe<User>;
};


/** The output of our create `Budget` mutation. */
export type CreateBudgetPayloadBudgetEdgeArgs = {
  orderBy?: InputMaybe<Array<BudgetsOrderBy>>;
};

/** All input for the create `Campaign` mutation. */
export type CreateCampaignInput = {
  /** The `Campaign` to be created by this mutation. */
  campaign: CampaignInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Campaign` mutation. */
export type CreateCampaignPayload = {
  __typename?: 'CreateCampaignPayload';
  /** Reads a single `CampaignsAction` that is related to this `Campaign`. */
  action?: Maybe<CampaignsAction>;
  /** Reads a single `Ad` that is related to this `Campaign`. */
  ad?: Maybe<Ad>;
  /** Reads a single `User` that is related to this `Campaign`. */
  approvedBy?: Maybe<User>;
  /** Reads a single `Audience` that is related to this `Campaign`. */
  audience?: Maybe<Audience>;
  /** Reads a single `Budget` that is related to this `Campaign`. */
  budget?: Maybe<Budget>;
  /** The `Campaign` that was created by this mutation. */
  campaign?: Maybe<Campaign>;
  /** An edge for our `Campaign`. May be used by Relay 1. */
  campaignEdge?: Maybe<CampaignsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Campaign`. */
  user?: Maybe<User>;
};


/** The output of our create `Campaign` mutation. */
export type CreateCampaignPayloadCampaignEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/** All input for the create `CampaignsAction` mutation. */
export type CreateCampaignsActionInput = {
  /** The `CampaignsAction` to be created by this mutation. */
  campaignsAction: CampaignsActionInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `CampaignsAction` mutation. */
export type CreateCampaignsActionPayload = {
  __typename?: 'CreateCampaignsActionPayload';
  /** The `CampaignsAction` that was created by this mutation. */
  campaignsAction?: Maybe<CampaignsAction>;
  /** An edge for our `CampaignsAction`. May be used by Relay 1. */
  campaignsActionEdge?: Maybe<CampaignsActionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `CampaignsAction`. */
  userTo?: Maybe<User>;
};


/** The output of our create `CampaignsAction` mutation. */
export type CreateCampaignsActionPayloadCampaignsActionEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignsActionsOrderBy>>;
};

/** All input for the create `Challenge` mutation. */
export type CreateChallengeInput = {
  /** The `Challenge` to be created by this mutation. */
  challenge: ChallengeInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Challenge` mutation. */
export type CreateChallengePayload = {
  __typename?: 'CreateChallengePayload';
  /** The `Challenge` that was created by this mutation. */
  challenge?: Maybe<Challenge>;
  /** An edge for our `Challenge`. May be used by Relay 1. */
  challengeEdge?: Maybe<ChallengesEdge>;
  /** Reads a single `Team` that is related to this `Challenge`. */
  challengedTeam?: Maybe<Team>;
  /** Reads a single `User` that is related to this `Challenge`. */
  challengedUser?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Game` that is related to this `Challenge`. */
  game?: Maybe<Game>;
  /** Reads a single `Team` that is related to this `Challenge`. */
  initiatorTeam?: Maybe<Team>;
  /** Reads a single `User` that is related to this `Challenge`. */
  initiatorUser?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Challenge`. */
  user?: Maybe<User>;
};


/** The output of our create `Challenge` mutation. */
export type CreateChallengePayloadChallengeEdgeArgs = {
  orderBy?: InputMaybe<Array<ChallengesOrderBy>>;
};

/** All input for the create `ChallengesProof` mutation. */
export type CreateChallengesProofInput = {
  /** The `ChallengesProof` to be created by this mutation. */
  challengesProof: ChallengesProofInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ChallengesProof` mutation. */
export type CreateChallengesProofPayload = {
  __typename?: 'CreateChallengesProofPayload';
  /** Reads a single `Challenge` that is related to this `ChallengesProof`. */
  challenge?: Maybe<Challenge>;
  /** The `ChallengesProof` that was created by this mutation. */
  challengesProof?: Maybe<ChallengesProof>;
  /** An edge for our `ChallengesProof`. May be used by Relay 1. */
  challengesProofEdge?: Maybe<ChallengesProofsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Media` that is related to this `ChallengesProof`. */
  media?: Maybe<Media>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ChallengesProof`. */
  user?: Maybe<User>;
};


/** The output of our create `ChallengesProof` mutation. */
export type CreateChallengesProofPayloadChallengesProofEdgeArgs = {
  orderBy?: InputMaybe<Array<ChallengesProofsOrderBy>>;
};

/** All input for the create `ChallengesWitness` mutation. */
export type CreateChallengesWitnessInput = {
  /** The `ChallengesWitness` to be created by this mutation. */
  challengesWitness: ChallengesWitnessInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ChallengesWitness` mutation. */
export type CreateChallengesWitnessPayload = {
  __typename?: 'CreateChallengesWitnessPayload';
  /** Reads a single `Challenge` that is related to this `ChallengesWitness`. */
  challenge?: Maybe<Challenge>;
  /** The `ChallengesWitness` that was created by this mutation. */
  challengesWitness?: Maybe<ChallengesWitness>;
  /** An edge for our `ChallengesWitness`. May be used by Relay 1. */
  challengesWitnessEdge?: Maybe<ChallengesWitnessesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ChallengesWitness`. */
  user?: Maybe<User>;
};


/** The output of our create `ChallengesWitness` mutation. */
export type CreateChallengesWitnessPayloadChallengesWitnessEdgeArgs = {
  orderBy?: InputMaybe<Array<ChallengesWitnessesOrderBy>>;
};

/** All input for the create `Cloud` mutation. */
export type CreateCloudInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Cloud` to be created by this mutation. */
  cloud: CloudInput;
};

/** The output of our create `Cloud` mutation. */
export type CreateCloudPayload = {
  __typename?: 'CreateCloudPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Cloud` that was created by this mutation. */
  cloud?: Maybe<Cloud>;
  /** An edge for our `Cloud`. May be used by Relay 1. */
  cloudEdge?: Maybe<CloudsEdge>;
  /** Reads a single `Media` that is related to this `Cloud`. */
  media?: Maybe<Media>;
  /** Reads a single `Post` that is related to this `Cloud`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Cloud`. */
  user?: Maybe<User>;
};


/** The output of our create `Cloud` mutation. */
export type CreateCloudPayloadCloudEdgeArgs = {
  orderBy?: InputMaybe<Array<CloudsOrderBy>>;
};

/** All input for the create `FavoriteGame` mutation. */
export type CreateFavoriteGameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `FavoriteGame` to be created by this mutation. */
  favoriteGame: FavoriteGameInput;
};

/** The output of our create `FavoriteGame` mutation. */
export type CreateFavoriteGamePayload = {
  __typename?: 'CreateFavoriteGamePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FavoriteGame` that was created by this mutation. */
  favoriteGame?: Maybe<FavoriteGame>;
  /** An edge for our `FavoriteGame`. May be used by Relay 1. */
  favoriteGameEdge?: Maybe<FavoriteGamesEdge>;
  /** Reads a single `Game` that is related to this `FavoriteGame`. */
  game?: Maybe<Game>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `FavoriteGame`. */
  user?: Maybe<User>;
};


/** The output of our create `FavoriteGame` mutation. */
export type CreateFavoriteGamePayloadFavoriteGameEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoriteGamesOrderBy>>;
};

/** All input for the create `Fee` mutation. */
export type CreateFeeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Fee` to be created by this mutation. */
  fee: FeeInput;
};

/** The output of our create `Fee` mutation. */
export type CreateFeePayload = {
  __typename?: 'CreateFeePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Fee` that was created by this mutation. */
  fee?: Maybe<Fee>;
  /** An edge for our `Fee`. May be used by Relay 1. */
  feeEdge?: Maybe<FeesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Fee` mutation. */
export type CreateFeePayloadFeeEdgeArgs = {
  orderBy?: InputMaybe<Array<FeesOrderBy>>;
};

/** All input for the create `Feedback` mutation. */
export type CreateFeedbackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Feedback` to be created by this mutation. */
  feedback: FeedbackInput;
};

/** The output of our create `Feedback` mutation. */
export type CreateFeedbackPayload = {
  __typename?: 'CreateFeedbackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Feedback` that was created by this mutation. */
  feedback?: Maybe<Feedback>;
  /** An edge for our `Feedback`. May be used by Relay 1. */
  feedbackEdge?: Maybe<FeedbacksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Feedback`. */
  user?: Maybe<User>;
};


/** The output of our create `Feedback` mutation. */
export type CreateFeedbackPayloadFeedbackEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedbacksOrderBy>>;
};

/** All input for the create `Following` mutation. */
export type CreateFollowingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Following` to be created by this mutation. */
  following: FollowingInput;
};

/** The output of our create `Following` mutation. */
export type CreateFollowingPayload = {
  __typename?: 'CreateFollowingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Following` that was created by this mutation. */
  following?: Maybe<Following>;
  /** An edge for our `Following`. May be used by Relay 1. */
  followingEdge?: Maybe<FollowingsEdge>;
  /** Reads a single `User` that is related to this `Following`. */
  followings_fid?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Following`. */
  user?: Maybe<User>;
};


/** The output of our create `Following` mutation. */
export type CreateFollowingPayloadFollowingEdgeArgs = {
  orderBy?: InputMaybe<Array<FollowingsOrderBy>>;
};

/** All input for the create `Funny` mutation. */
export type CreateFunnyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Funny` to be created by this mutation. */
  funny: FunnyInput;
};

/** The output of our create `Funny` mutation. */
export type CreateFunnyPayload = {
  __typename?: 'CreateFunnyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Funny` that was created by this mutation. */
  funny?: Maybe<Funny>;
  /** An edge for our `Funny`. May be used by Relay 1. */
  funnyEdge?: Maybe<FunniesEdge>;
  /** Reads a single `Post` that is related to this `Funny`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Funny`. */
  user?: Maybe<User>;
};


/** The output of our create `Funny` mutation. */
export type CreateFunnyPayloadFunnyEdgeArgs = {
  orderBy?: InputMaybe<Array<FunniesOrderBy>>;
};

/** All input for the create `Game` mutation. */
export type CreateGameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Game` to be created by this mutation. */
  game: GameInput;
};

/** The output of our create `Game` mutation. */
export type CreateGamePayload = {
  __typename?: 'CreateGamePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Game` that was created by this mutation. */
  game?: Maybe<Game>;
  /** An edge for our `Game`. May be used by Relay 1. */
  gameEdge?: Maybe<GamesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Game` mutation. */
export type CreateGamePayloadGameEdgeArgs = {
  orderBy?: InputMaybe<Array<GamesOrderBy>>;
};

/** All input for the create `Gender` mutation. */
export type CreateGenderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Gender` to be created by this mutation. */
  gender: GenderInput;
};

/** The output of our create `Gender` mutation. */
export type CreateGenderPayload = {
  __typename?: 'CreateGenderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Gender`. */
  createdBy?: Maybe<User>;
  /** The `Gender` that was created by this mutation. */
  gender?: Maybe<Gender>;
  /** An edge for our `Gender`. May be used by Relay 1. */
  genderEdge?: Maybe<GendersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Gender` mutation. */
export type CreateGenderPayloadGenderEdgeArgs = {
  orderBy?: InputMaybe<Array<GendersOrderBy>>;
};

/** All input for the create `Genre` mutation. */
export type CreateGenreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Genre` to be created by this mutation. */
  genre: GenreInput;
};

/** The output of our create `Genre` mutation. */
export type CreateGenrePayload = {
  __typename?: 'CreateGenrePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Genre` that was created by this mutation. */
  genre?: Maybe<Genre>;
  /** An edge for our `Genre`. May be used by Relay 1. */
  genreEdge?: Maybe<GenresEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Genre` mutation. */
export type CreateGenrePayloadGenreEdgeArgs = {
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};

/** All input for the create `Like` mutation. */
export type CreateLikeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Like` to be created by this mutation. */
  like: LikeInput;
};

/** The output of our create `Like` mutation. */
export type CreateLikePayload = {
  __typename?: 'CreateLikePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Like` that was created by this mutation. */
  like?: Maybe<Like>;
  /** An edge for our `Like`. May be used by Relay 1. */
  likeEdge?: Maybe<LikesEdge>;
  /** Reads a single `Post` that is related to this `Like`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Like`. */
  user?: Maybe<User>;
};


/** The output of our create `Like` mutation. */
export type CreateLikePayloadLikeEdgeArgs = {
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** All input for the create `Media` mutation. */
export type CreateMediaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Media` to be created by this mutation. */
  media: MediaInput;
};

/** The output of our create `Media` mutation. */
export type CreateMediaPayload = {
  __typename?: 'CreateMediaPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Media` that was created by this mutation. */
  media?: Maybe<Media>;
  /** An edge for our `Media`. May be used by Relay 1. */
  mediaEdge?: Maybe<MediaEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Media`. */
  user?: Maybe<User>;
};


/** The output of our create `Media` mutation. */
export type CreateMediaPayloadMediaEdgeArgs = {
  orderBy?: InputMaybe<Array<MediaOrderBy>>;
};

/** All input for the create `Message` mutation. */
export type CreateMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Message` to be created by this mutation. */
  message: MessageInput;
};

/** The output of our create `Message` mutation. */
export type CreateMessagePayload = {
  __typename?: 'CreateMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Message` that was created by this mutation. */
  message?: Maybe<Message>;
  /** An edge for our `Message`. May be used by Relay 1. */
  messageEdge?: Maybe<MessagesEdge>;
  /** Reads a single `Post` that is related to this `Message`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Room` that is related to this `Message`. */
  room?: Maybe<Room>;
  /** Reads a single `User` that is related to this `Message`. */
  user?: Maybe<User>;
};


/** The output of our create `Message` mutation. */
export type CreateMessagePayloadMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/** All input for the create `MusicGenre` mutation. */
export type CreateMusicGenreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MusicGenre` to be created by this mutation. */
  musicGenre: MusicGenreInput;
};

/** The output of our create `MusicGenre` mutation. */
export type CreateMusicGenrePayload = {
  __typename?: 'CreateMusicGenrePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Genre` that is related to this `MusicGenre`. */
  genre?: Maybe<Genre>;
  /** Reads a single `Music` that is related to this `MusicGenre`. */
  music?: Maybe<Music>;
  /** The `MusicGenre` that was created by this mutation. */
  musicGenre?: Maybe<MusicGenre>;
  /** An edge for our `MusicGenre`. May be used by Relay 1. */
  musicGenreEdge?: Maybe<MusicGenresEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `MusicGenre` mutation. */
export type CreateMusicGenrePayloadMusicGenreEdgeArgs = {
  orderBy?: InputMaybe<Array<MusicGenresOrderBy>>;
};

/** All input for the create `Music` mutation. */
export type CreateMusicInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Music` to be created by this mutation. */
  music: MusicInput;
};

/** The output of our create `Music` mutation. */
export type CreateMusicPayload = {
  __typename?: 'CreateMusicPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Music` that was created by this mutation. */
  music?: Maybe<Music>;
  /** An edge for our `Music`. May be used by Relay 1. */
  musicEdge?: Maybe<MusicEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Music` mutation. */
export type CreateMusicPayloadMusicEdgeArgs = {
  orderBy?: InputMaybe<Array<MusicOrderBy>>;
};

/** All input for the create `Noob` mutation. */
export type CreateNoobInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Noob` to be created by this mutation. */
  noob: NoobInput;
};

/** The output of our create `Noob` mutation. */
export type CreateNoobPayload = {
  __typename?: 'CreateNoobPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Noob` that was created by this mutation. */
  noob?: Maybe<Noob>;
  /** An edge for our `Noob`. May be used by Relay 1. */
  noobEdge?: Maybe<NoobsEdge>;
  /** Reads a single `Post` that is related to this `Noob`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Noob`. */
  user?: Maybe<User>;
};


/** The output of our create `Noob` mutation. */
export type CreateNoobPayloadNoobEdgeArgs = {
  orderBy?: InputMaybe<Array<NoobsOrderBy>>;
};

/** All input for the create `NotificationLog` mutation. */
export type CreateNotificationLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `NotificationLog` to be created by this mutation. */
  notificationLog: NotificationLogInput;
};

/** The output of our create `NotificationLog` mutation. */
export type CreateNotificationLogPayload = {
  __typename?: 'CreateNotificationLogPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `NotificationLog` that was created by this mutation. */
  notificationLog?: Maybe<NotificationLog>;
  /** An edge for our `NotificationLog`. May be used by Relay 1. */
  notificationLogEdge?: Maybe<NotificationLogsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `NotificationLog` mutation. */
export type CreateNotificationLogPayloadNotificationLogEdgeArgs = {
  orderBy?: InputMaybe<Array<NotificationLogsOrderBy>>;
};

/** All input for the create `OzSubscription` mutation. */
export type CreateOzSubscriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `OzSubscription` to be created by this mutation. */
  ozSubscription: OzSubscriptionInput;
};

/** The output of our create `OzSubscription` mutation. */
export type CreateOzSubscriptionPayload = {
  __typename?: 'CreateOzSubscriptionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Fee` that is related to this `OzSubscription`. */
  fee?: Maybe<Fee>;
  /** The `OzSubscription` that was created by this mutation. */
  ozSubscription?: Maybe<OzSubscription>;
  /** An edge for our `OzSubscription`. May be used by Relay 1. */
  ozSubscriptionEdge?: Maybe<OzSubscriptionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Transaction` that is related to this `OzSubscription`. */
  transaction?: Maybe<Transaction>;
  /** Reads a single `User` that is related to this `OzSubscription`. */
  user?: Maybe<User>;
};


/** The output of our create `OzSubscription` mutation. */
export type CreateOzSubscriptionPayloadOzSubscriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<OzSubscriptionsOrderBy>>;
};

/** All input for the create `PaymentMethod` mutation. */
export type CreatePaymentMethodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `PaymentMethod` to be created by this mutation. */
  paymentMethod: PaymentMethodInput;
};

/** The output of our create `PaymentMethod` mutation. */
export type CreatePaymentMethodPayload = {
  __typename?: 'CreatePaymentMethodPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PaymentMethod` that was created by this mutation. */
  paymentMethod?: Maybe<PaymentMethod>;
  /** An edge for our `PaymentMethod`. May be used by Relay 1. */
  paymentMethodEdge?: Maybe<PaymentMethodsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PaymentMethod`. */
  user?: Maybe<User>;
};


/** The output of our create `PaymentMethod` mutation. */
export type CreatePaymentMethodPayloadPaymentMethodEdgeArgs = {
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
};

/** All input for the create `Post` mutation. */
export type CreatePostInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Post` to be created by this mutation. */
  post: PostInput;
};

/** The output of our create `Post` mutation. */
export type CreatePostPayload = {
  __typename?: 'CreatePostPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Game` that is related to this `Post`. */
  game?: Maybe<Game>;
  /** Reads a single `Media` that is related to this `Post`. */
  media?: Maybe<Media>;
  /** The `Post` that was created by this mutation. */
  post?: Maybe<Post>;
  /** An edge for our `Post`. May be used by Relay 1. */
  postEdge?: Maybe<PostsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Post`. */
  user?: Maybe<User>;
};


/** The output of our create `Post` mutation. */
export type CreatePostPayloadPostEdgeArgs = {
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};

/** All input for the create `PostsReport` mutation. */
export type CreatePostsReportInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `PostsReport` to be created by this mutation. */
  postsReport: PostsReportInput;
};

/** The output of our create `PostsReport` mutation. */
export type CreatePostsReportPayload = {
  __typename?: 'CreatePostsReportPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Post` that is related to this `PostsReport`. */
  post?: Maybe<Post>;
  /** The `PostsReport` that was created by this mutation. */
  postsReport?: Maybe<PostsReport>;
  /** An edge for our `PostsReport`. May be used by Relay 1. */
  postsReportEdge?: Maybe<PostsReportsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ReportMessage` that is related to this `PostsReport`. */
  reportMessage?: Maybe<ReportMessage>;
  /** Reads a single `User` that is related to this `PostsReport`. */
  user?: Maybe<User>;
  /** Reads a single `User` that is related to this `PostsReport`. */
  validatingUser?: Maybe<User>;
};


/** The output of our create `PostsReport` mutation. */
export type CreatePostsReportPayloadPostsReportEdgeArgs = {
  orderBy?: InputMaybe<Array<PostsReportsOrderBy>>;
};

/** All input for the create `PostsView` mutation. */
export type CreatePostsViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `PostsView` to be created by this mutation. */
  postsView: PostsViewInput;
};

/** The output of our create `PostsView` mutation. */
export type CreatePostsViewPayload = {
  __typename?: 'CreatePostsViewPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Post` that is related to this `PostsView`. */
  post?: Maybe<Post>;
  /** The `PostsView` that was created by this mutation. */
  postsView?: Maybe<PostsView>;
  /** An edge for our `PostsView`. May be used by Relay 1. */
  postsViewEdge?: Maybe<PostsViewsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PostsView`. */
  user?: Maybe<User>;
};


/** The output of our create `PostsView` mutation. */
export type CreatePostsViewPayloadPostsViewEdgeArgs = {
  orderBy?: InputMaybe<Array<PostsViewsOrderBy>>;
};

/** All input for the create `PrivateUser` mutation. */
export type CreatePrivateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `PrivateUser` to be created by this mutation. */
  privateUser: PrivateUserInput;
};

/** The output of our create `PrivateUser` mutation. */
export type CreatePrivateUserPayload = {
  __typename?: 'CreatePrivateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PrivateUser` that was created by this mutation. */
  privateUser?: Maybe<PrivateUser>;
  /** An edge for our `PrivateUser`. May be used by Relay 1. */
  privateUserEdge?: Maybe<PrivateUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PrivateUser`. */
  user?: Maybe<User>;
};


/** The output of our create `PrivateUser` mutation. */
export type CreatePrivateUserPayloadPrivateUserEdgeArgs = {
  orderBy?: InputMaybe<Array<PrivateUsersOrderBy>>;
};

/** All input for the create `ReportMessage` mutation. */
export type CreateReportMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ReportMessage` to be created by this mutation. */
  reportMessage: ReportMessageInput;
};

/** The output of our create `ReportMessage` mutation. */
export type CreateReportMessagePayload = {
  __typename?: 'CreateReportMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ReportMessage` that was created by this mutation. */
  reportMessage?: Maybe<ReportMessage>;
  /** An edge for our `ReportMessage`. May be used by Relay 1. */
  reportMessageEdge?: Maybe<ReportMessagesEdge>;
};


/** The output of our create `ReportMessage` mutation. */
export type CreateReportMessagePayloadReportMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<ReportMessagesOrderBy>>;
};

/** All input for the create `Role` mutation. */
export type CreateRoleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Role` to be created by this mutation. */
  role: RoleInput;
};

/** The output of our create `Role` mutation. */
export type CreateRolePayload = {
  __typename?: 'CreateRolePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Role` that was created by this mutation. */
  role?: Maybe<Role>;
  /** An edge for our `Role`. May be used by Relay 1. */
  roleEdge?: Maybe<RolesEdge>;
};


/** The output of our create `Role` mutation. */
export type CreateRolePayloadRoleEdgeArgs = {
  orderBy?: InputMaybe<Array<RolesOrderBy>>;
};

/** All input for the create `Room` mutation. */
export type CreateRoomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Room` to be created by this mutation. */
  room: RoomInput;
};

/** The output of our create `Room` mutation. */
export type CreateRoomPayload = {
  __typename?: 'CreateRoomPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Room` that was created by this mutation. */
  room?: Maybe<Room>;
  /** An edge for our `Room`. May be used by Relay 1. */
  roomEdge?: Maybe<RoomsEdge>;
  /** Reads a single `User` that is related to this `Room`. */
  user?: Maybe<User>;
};


/** The output of our create `Room` mutation. */
export type CreateRoomPayloadRoomEdgeArgs = {
  orderBy?: InputMaybe<Array<RoomsOrderBy>>;
};

/** All input for the create `Sticker` mutation. */
export type CreateStickerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Sticker` to be created by this mutation. */
  sticker: StickerInput;
};

/** The output of our create `Sticker` mutation. */
export type CreateStickerPayload = {
  __typename?: 'CreateStickerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Sticker` that was created by this mutation. */
  sticker?: Maybe<Sticker>;
  /** An edge for our `Sticker`. May be used by Relay 1. */
  stickerEdge?: Maybe<StickersEdge>;
};


/** The output of our create `Sticker` mutation. */
export type CreateStickerPayloadStickerEdgeArgs = {
  orderBy?: InputMaybe<Array<StickersOrderBy>>;
};

/** All input for the create `StickersTag` mutation. */
export type CreateStickersTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `StickersTag` to be created by this mutation. */
  stickersTag: StickersTagInput;
};

/** The output of our create `StickersTag` mutation. */
export type CreateStickersTagPayload = {
  __typename?: 'CreateStickersTagPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Sticker` that is related to this `StickersTag`. */
  sticker?: Maybe<Sticker>;
  /** The `StickersTag` that was created by this mutation. */
  stickersTag?: Maybe<StickersTag>;
  /** An edge for our `StickersTag`. May be used by Relay 1. */
  stickersTagEdge?: Maybe<StickersTagsEdge>;
  /** Reads a single `Tag` that is related to this `StickersTag`. */
  tag?: Maybe<Tag>;
};


/** The output of our create `StickersTag` mutation. */
export type CreateStickersTagPayloadStickersTagEdgeArgs = {
  orderBy?: InputMaybe<Array<StickersTagsOrderBy>>;
};

/** All input for the create `Tag` mutation. */
export type CreateTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Tag` to be created by this mutation. */
  tag: TagInput;
};

/** The output of our create `Tag` mutation. */
export type CreateTagPayload = {
  __typename?: 'CreateTagPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Tag` that was created by this mutation. */
  tag?: Maybe<Tag>;
  /** An edge for our `Tag`. May be used by Relay 1. */
  tagEdge?: Maybe<TagsEdge>;
};


/** The output of our create `Tag` mutation. */
export type CreateTagPayloadTagEdgeArgs = {
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

/** All input for the create `Team` mutation. */
export type CreateTeamInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Team` to be created by this mutation. */
  team: TeamInput;
};

/** The output of our create `Team` mutation. */
export type CreateTeamPayload = {
  __typename?: 'CreateTeamPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Team`. */
  createdBy?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Team` that was created by this mutation. */
  team?: Maybe<Team>;
  /** An edge for our `Team`. May be used by Relay 1. */
  teamEdge?: Maybe<TeamsEdge>;
};


/** The output of our create `Team` mutation. */
export type CreateTeamPayloadTeamEdgeArgs = {
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};

/** All input for the create `TeamsMember` mutation. */
export type CreateTeamsMemberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `TeamsMember` to be created by this mutation. */
  teamsMember: TeamsMemberInput;
};

/** The output of our create `TeamsMember` mutation. */
export type CreateTeamsMemberPayload = {
  __typename?: 'CreateTeamsMemberPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TeamsMember`. */
  team?: Maybe<Team>;
  /** The `TeamsMember` that was created by this mutation. */
  teamsMember?: Maybe<TeamsMember>;
  /** An edge for our `TeamsMember`. May be used by Relay 1. */
  teamsMemberEdge?: Maybe<TeamsMembersEdge>;
  /** Reads a single `User` that is related to this `TeamsMember`. */
  user?: Maybe<User>;
  /** Reads a single `User` that is related to this `TeamsMember`. */
  userByBannedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `TeamsMember`. */
  userByRemovedBy?: Maybe<User>;
};


/** The output of our create `TeamsMember` mutation. */
export type CreateTeamsMemberPayloadTeamsMemberEdgeArgs = {
  orderBy?: InputMaybe<Array<TeamsMembersOrderBy>>;
};

/** All input for the create `Transaction` mutation. */
export type CreateTransactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Transaction` to be created by this mutation. */
  transaction: TransactionInput;
};

/** The output of our create `Transaction` mutation. */
export type CreateTransactionPayload = {
  __typename?: 'CreateTransactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Fee` that is related to this `Transaction`. */
  fee?: Maybe<Fee>;
  /** Reads a single `PaymentMethod` that is related to this `Transaction`. */
  paymentMethod?: Maybe<PaymentMethod>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Transaction` that was created by this mutation. */
  transaction?: Maybe<Transaction>;
  /** An edge for our `Transaction`. May be used by Relay 1. */
  transactionEdge?: Maybe<TransactionsEdge>;
  /** Reads a single `User` that is related to this `Transaction`. */
  user?: Maybe<User>;
};


/** The output of our create `Transaction` mutation. */
export type CreateTransactionPayloadTransactionEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/** All input for the create `UserAccess` mutation. */
export type CreateUserAccessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UserAccess` to be created by this mutation. */
  userAccess: UserAccessInput;
};

/** The output of our create `UserAccess` mutation. */
export type CreateUserAccessPayload = {
  __typename?: 'CreateUserAccessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserAccess`. */
  user?: Maybe<User>;
  /** The `UserAccess` that was created by this mutation. */
  userAccess?: Maybe<UserAccess>;
  /** An edge for our `UserAccess`. May be used by Relay 1. */
  userAccessEdge?: Maybe<UserAccessesEdge>;
};


/** The output of our create `UserAccess` mutation. */
export type CreateUserAccessPayloadUserAccessEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAccessesOrderBy>>;
};

/** All input for the create `UserEmail` mutation. */
export type CreateUserEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UserEmail` to be created by this mutation. */
  userEmail: UserEmailInput;
};

/** The output of our create `UserEmail` mutation. */
export type CreateUserEmailPayload = {
  __typename?: 'CreateUserEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserEmail`. */
  user?: Maybe<User>;
  /** The `UserEmail` that was created by this mutation. */
  userEmail?: Maybe<UserEmail>;
  /** An edge for our `UserEmail`. May be used by Relay 1. */
  userEmailEdge?: Maybe<UserEmailsEdge>;
};


/** The output of our create `UserEmail` mutation. */
export type CreateUserEmailPayloadUserEmailEdgeArgs = {
  orderBy?: InputMaybe<Array<UserEmailsOrderBy>>;
};

/** All input for the create `User` mutation. */
export type CreateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `User` to be created by this mutation. */
  user: UserInput;
};

/** The output of our create `User` mutation. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Gender` that is related to this `User`. */
  gender?: Maybe<Gender>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was created by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our create `User` mutation. */
export type CreateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the create `UserRole` mutation. */
export type CreateUserRoleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UserRole` to be created by this mutation. */
  userRole: UserRoleInput;
};

/** The output of our create `UserRole` mutation. */
export type CreateUserRolePayload = {
  __typename?: 'CreateUserRolePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Role` that is related to this `UserRole`. */
  role?: Maybe<Role>;
  /** Reads a single `User` that is related to this `UserRole`. */
  user?: Maybe<User>;
  /** The `UserRole` that was created by this mutation. */
  userRole?: Maybe<UserRole>;
  /** An edge for our `UserRole`. May be used by Relay 1. */
  userRoleEdge?: Maybe<UserRolesEdge>;
};


/** The output of our create `UserRole` mutation. */
export type CreateUserRolePayloadUserRoleEdgeArgs = {
  orderBy?: InputMaybe<Array<UserRolesOrderBy>>;
};

/** All input for the create `UsernamesCheck` mutation. */
export type CreateUsernamesCheckInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UsernamesCheck` to be created by this mutation. */
  usernamesCheck: UsernamesCheckInput;
};

/** The output of our create `UsernamesCheck` mutation. */
export type CreateUsernamesCheckPayload = {
  __typename?: 'CreateUsernamesCheckPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UsernamesCheck` that was created by this mutation. */
  usernamesCheck?: Maybe<UsernamesCheck>;
  /** An edge for our `UsernamesCheck`. May be used by Relay 1. */
  usernamesCheckEdge?: Maybe<UsernamesChecksEdge>;
};


/** The output of our create `UsernamesCheck` mutation. */
export type CreateUsernamesCheckPayloadUsernamesCheckEdgeArgs = {
  orderBy?: InputMaybe<Array<UsernamesChecksOrderBy>>;
};

/** All input for the create `UsersAvailableRoom` mutation. */
export type CreateUsersAvailableRoomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UsersAvailableRoom` to be created by this mutation. */
  usersAvailableRoom: UsersAvailableRoomInput;
};

/** The output of our create `UsersAvailableRoom` mutation. */
export type CreateUsersAvailableRoomPayload = {
  __typename?: 'CreateUsersAvailableRoomPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Room` that is related to this `UsersAvailableRoom`. */
  room?: Maybe<Room>;
  /** Reads a single `User` that is related to this `UsersAvailableRoom`. */
  user?: Maybe<User>;
  /** The `UsersAvailableRoom` that was created by this mutation. */
  usersAvailableRoom?: Maybe<UsersAvailableRoom>;
  /** An edge for our `UsersAvailableRoom`. May be used by Relay 1. */
  usersAvailableRoomEdge?: Maybe<UsersAvailableRoomsEdge>;
};


/** The output of our create `UsersAvailableRoom` mutation. */
export type CreateUsersAvailableRoomPayloadUsersAvailableRoomEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersAvailableRoomsOrderBy>>;
};

/** All input for the create `UsersReceivedMessage` mutation. */
export type CreateUsersReceivedMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UsersReceivedMessage` to be created by this mutation. */
  usersReceivedMessage: UsersReceivedMessageInput;
};

/** The output of our create `UsersReceivedMessage` mutation. */
export type CreateUsersReceivedMessagePayload = {
  __typename?: 'CreateUsersReceivedMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Message` that is related to this `UsersReceivedMessage`. */
  message?: Maybe<Message>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UsersReceivedMessage`. */
  user?: Maybe<User>;
  /** The `UsersReceivedMessage` that was created by this mutation. */
  usersReceivedMessage?: Maybe<UsersReceivedMessage>;
  /** An edge for our `UsersReceivedMessage`. May be used by Relay 1. */
  usersReceivedMessageEdge?: Maybe<UsersReceivedMessagesEdge>;
};


/** The output of our create `UsersReceivedMessage` mutation. */
export type CreateUsersReceivedMessagePayloadUsersReceivedMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersReceivedMessagesOrderBy>>;
};

/** All input for the create `Witness` mutation. */
export type CreateWitnessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Witness` to be created by this mutation. */
  witness: WitnessInput;
};

/** The output of our create `Witness` mutation. */
export type CreateWitnessPayload = {
  __typename?: 'CreateWitnessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Witness`. */
  user?: Maybe<User>;
  /** The `Witness` that was created by this mutation. */
  witness?: Maybe<Witness>;
  /** An edge for our `Witness`. May be used by Relay 1. */
  witnessEdge?: Maybe<WitnessesEdge>;
};


/** The output of our create `Witness` mutation. */
export type CreateWitnessPayloadWitnessEdgeArgs = {
  orderBy?: InputMaybe<Array<WitnessesOrderBy>>;
};

export enum Currencies {
  Cad = 'CAD',
  Eur = 'EUR',
  Usd = 'USD'
}

/** A filter to be used against Currencies fields. All fields are combined with a logical ‘and.’ */
export type CurrenciesFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Currencies>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Currencies>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Currencies>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Currencies>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Currencies>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Currencies>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Currencies>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Currencies>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Currencies>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Currencies>>;
};

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Date']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Date']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Date']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Date']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Date']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Date']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Date']>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']>>;
};

/** All input for the `deleteActivityByNodeId` mutation. */
export type DeleteActivityByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Activity` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteActivity` mutation. */
export type DeleteActivityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Activity` mutation. */
export type DeleteActivityPayload = {
  __typename?: 'DeleteActivityPayload';
  /** The `Activity` that was deleted by this mutation. */
  activity?: Maybe<Activity>;
  /** An edge for our `Activity`. May be used by Relay 1. */
  activityEdge?: Maybe<ActivitiesEdge>;
  /** Reads a single `User` that is related to this `Activity`. */
  affectedUser?: Maybe<User>;
  /** Reads a single `Challenge` that is related to this `Activity`. */
  challenge?: Maybe<Challenge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedActivityNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Media` that is related to this `Activity`. */
  media?: Maybe<Media>;
  /** Reads a single `Post` that is related to this `Activity`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `Activity`. */
  team?: Maybe<Team>;
  /** Reads a single `User` that is related to this `Activity`. */
  user?: Maybe<User>;
};


/** The output of our delete `Activity` mutation. */
export type DeleteActivityPayloadActivityEdgeArgs = {
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};

/** All input for the `deleteAdByNodeId` mutation. */
export type DeleteAdByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Ad` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAd` mutation. */
export type DeleteAdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Ad` mutation. */
export type DeleteAdPayload = {
  __typename?: 'DeleteAdPayload';
  /** The `Ad` that was deleted by this mutation. */
  ad?: Maybe<Ad>;
  /** An edge for our `Ad`. May be used by Relay 1. */
  adEdge?: Maybe<AdsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAdNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Ad` mutation. */
export type DeleteAdPayloadAdEdgeArgs = {
  orderBy?: InputMaybe<Array<AdsOrderBy>>;
};

/** All input for the `deleteArchiveByNodeId` mutation. */
export type DeleteArchiveByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Archive` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteArchive` mutation. */
export type DeleteArchiveInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Archive` mutation. */
export type DeleteArchivePayload = {
  __typename?: 'DeleteArchivePayload';
  /** The `Archive` that was deleted by this mutation. */
  archive?: Maybe<Archive>;
  /** An edge for our `Archive`. May be used by Relay 1. */
  archiveEdge?: Maybe<ArchivesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Cloud` that is related to this `Archive`. */
  cloud?: Maybe<Cloud>;
  deletedArchiveNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Post` that is related to this `Archive`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Archive`. */
  user?: Maybe<User>;
};


/** The output of our delete `Archive` mutation. */
export type DeleteArchivePayloadArchiveEdgeArgs = {
  orderBy?: InputMaybe<Array<ArchivesOrderBy>>;
};

/** All input for the `deleteAudienceByNodeId` mutation. */
export type DeleteAudienceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Audience` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAudience` mutation. */
export type DeleteAudienceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** All input for the `deleteAudienceMetaByNodeId` mutation. */
export type DeleteAudienceMetaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AudienceMeta` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAudienceMeta` mutation. */
export type DeleteAudienceMetaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `AudienceMeta` mutation. */
export type DeleteAudienceMetaPayload = {
  __typename?: 'DeleteAudienceMetaPayload';
  /** Reads a single `Audience` that is related to this `AudienceMeta`. */
  audience?: Maybe<Audience>;
  /** The `AudienceMeta` that was deleted by this mutation. */
  audienceMeta?: Maybe<AudienceMeta>;
  /** An edge for our `AudienceMeta`. May be used by Relay 1. */
  audienceMetaEdge?: Maybe<AudienceMetasEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAudienceMetaNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Gender` that is related to this `AudienceMeta`. */
  gender?: Maybe<Gender>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `AudienceMeta` mutation. */
export type DeleteAudienceMetaPayloadAudienceMetaEdgeArgs = {
  orderBy?: InputMaybe<Array<AudienceMetasOrderBy>>;
};

/** The output of our delete `Audience` mutation. */
export type DeleteAudiencePayload = {
  __typename?: 'DeleteAudiencePayload';
  /** The `Audience` that was deleted by this mutation. */
  audience?: Maybe<Audience>;
  /** An edge for our `Audience`. May be used by Relay 1. */
  audienceEdge?: Maybe<AudiencesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedAudienceNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Audience`. */
  user?: Maybe<User>;
};


/** The output of our delete `Audience` mutation. */
export type DeleteAudiencePayloadAudienceEdgeArgs = {
  orderBy?: InputMaybe<Array<AudiencesOrderBy>>;
};

/** All input for the `deleteBeastByNodeId` mutation. */
export type DeleteBeastByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Beast` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBeast` mutation. */
export type DeleteBeastInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** The output of our delete `Beast` mutation. */
export type DeleteBeastPayload = {
  __typename?: 'DeleteBeastPayload';
  /** The `Beast` that was deleted by this mutation. */
  beast?: Maybe<Beast>;
  /** An edge for our `Beast`. May be used by Relay 1. */
  beastEdge?: Maybe<BeastsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedBeastNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Post` that is related to this `Beast`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Beast`. */
  user?: Maybe<User>;
};


/** The output of our delete `Beast` mutation. */
export type DeleteBeastPayloadBeastEdgeArgs = {
  orderBy?: InputMaybe<Array<BeastsOrderBy>>;
};

/** All input for the `deleteBlockedUserByNodeId` mutation. */
export type DeleteBlockedUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BlockedUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBlockedUser` mutation. */
export type DeleteBlockedUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `BlockedUser` mutation. */
export type DeleteBlockedUserPayload = {
  __typename?: 'DeleteBlockedUserPayload';
  /** The `BlockedUser` that was deleted by this mutation. */
  blockedUser?: Maybe<BlockedUser>;
  /** An edge for our `BlockedUser`. May be used by Relay 1. */
  blockedUserEdge?: Maybe<BlockedUsersEdge>;
  /** Reads a single `User` that is related to this `BlockedUser`. */
  blocked_users_buid?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedBlockedUserNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `BlockedUser`. */
  user?: Maybe<User>;
};


/** The output of our delete `BlockedUser` mutation. */
export type DeleteBlockedUserPayloadBlockedUserEdgeArgs = {
  orderBy?: InputMaybe<Array<BlockedUsersOrderBy>>;
};

/** All input for the `deleteBudgetByNodeId` mutation. */
export type DeleteBudgetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Budget` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBudget` mutation. */
export type DeleteBudgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Budget` mutation. */
export type DeleteBudgetPayload = {
  __typename?: 'DeleteBudgetPayload';
  /** The `Budget` that was deleted by this mutation. */
  budget?: Maybe<Budget>;
  /** An edge for our `Budget`. May be used by Relay 1. */
  budgetEdge?: Maybe<BudgetsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedBudgetNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Budget`. */
  user?: Maybe<User>;
};


/** The output of our delete `Budget` mutation. */
export type DeleteBudgetPayloadBudgetEdgeArgs = {
  orderBy?: InputMaybe<Array<BudgetsOrderBy>>;
};

/** All input for the `deleteCampaignByNodeId` mutation. */
export type DeleteCampaignByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Campaign` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCampaign` mutation. */
export type DeleteCampaignInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Campaign` mutation. */
export type DeleteCampaignPayload = {
  __typename?: 'DeleteCampaignPayload';
  /** Reads a single `CampaignsAction` that is related to this `Campaign`. */
  action?: Maybe<CampaignsAction>;
  /** Reads a single `Ad` that is related to this `Campaign`. */
  ad?: Maybe<Ad>;
  /** Reads a single `User` that is related to this `Campaign`. */
  approvedBy?: Maybe<User>;
  /** Reads a single `Audience` that is related to this `Campaign`. */
  audience?: Maybe<Audience>;
  /** Reads a single `Budget` that is related to this `Campaign`. */
  budget?: Maybe<Budget>;
  /** The `Campaign` that was deleted by this mutation. */
  campaign?: Maybe<Campaign>;
  /** An edge for our `Campaign`. May be used by Relay 1. */
  campaignEdge?: Maybe<CampaignsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedCampaignNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Campaign`. */
  user?: Maybe<User>;
};


/** The output of our delete `Campaign` mutation. */
export type DeleteCampaignPayloadCampaignEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/** All input for the `deleteCampaignsActionByNodeId` mutation. */
export type DeleteCampaignsActionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CampaignsAction` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCampaignsAction` mutation. */
export type DeleteCampaignsActionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `CampaignsAction` mutation. */
export type DeleteCampaignsActionPayload = {
  __typename?: 'DeleteCampaignsActionPayload';
  /** The `CampaignsAction` that was deleted by this mutation. */
  campaignsAction?: Maybe<CampaignsAction>;
  /** An edge for our `CampaignsAction`. May be used by Relay 1. */
  campaignsActionEdge?: Maybe<CampaignsActionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedCampaignsActionNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `CampaignsAction`. */
  userTo?: Maybe<User>;
};


/** The output of our delete `CampaignsAction` mutation. */
export type DeleteCampaignsActionPayloadCampaignsActionEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignsActionsOrderBy>>;
};

/** All input for the `deleteChallengeByNodeId` mutation. */
export type DeleteChallengeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Challenge` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteChallenge` mutation. */
export type DeleteChallengeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Challenge` mutation. */
export type DeleteChallengePayload = {
  __typename?: 'DeleteChallengePayload';
  /** The `Challenge` that was deleted by this mutation. */
  challenge?: Maybe<Challenge>;
  /** An edge for our `Challenge`. May be used by Relay 1. */
  challengeEdge?: Maybe<ChallengesEdge>;
  /** Reads a single `Team` that is related to this `Challenge`. */
  challengedTeam?: Maybe<Team>;
  /** Reads a single `User` that is related to this `Challenge`. */
  challengedUser?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedChallengeNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Game` that is related to this `Challenge`. */
  game?: Maybe<Game>;
  /** Reads a single `Team` that is related to this `Challenge`. */
  initiatorTeam?: Maybe<Team>;
  /** Reads a single `User` that is related to this `Challenge`. */
  initiatorUser?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Challenge`. */
  user?: Maybe<User>;
};


/** The output of our delete `Challenge` mutation. */
export type DeleteChallengePayloadChallengeEdgeArgs = {
  orderBy?: InputMaybe<Array<ChallengesOrderBy>>;
};

/** All input for the `deleteChallengesProofByNodeId` mutation. */
export type DeleteChallengesProofByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ChallengesProof` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteChallengesProof` mutation. */
export type DeleteChallengesProofInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ChallengesProof` mutation. */
export type DeleteChallengesProofPayload = {
  __typename?: 'DeleteChallengesProofPayload';
  /** Reads a single `Challenge` that is related to this `ChallengesProof`. */
  challenge?: Maybe<Challenge>;
  /** The `ChallengesProof` that was deleted by this mutation. */
  challengesProof?: Maybe<ChallengesProof>;
  /** An edge for our `ChallengesProof`. May be used by Relay 1. */
  challengesProofEdge?: Maybe<ChallengesProofsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedChallengesProofNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Media` that is related to this `ChallengesProof`. */
  media?: Maybe<Media>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ChallengesProof`. */
  user?: Maybe<User>;
};


/** The output of our delete `ChallengesProof` mutation. */
export type DeleteChallengesProofPayloadChallengesProofEdgeArgs = {
  orderBy?: InputMaybe<Array<ChallengesProofsOrderBy>>;
};

/** All input for the `deleteChallengesWitnessByNodeId` mutation. */
export type DeleteChallengesWitnessByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ChallengesWitness` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteChallengesWitness` mutation. */
export type DeleteChallengesWitnessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ChallengesWitness` mutation. */
export type DeleteChallengesWitnessPayload = {
  __typename?: 'DeleteChallengesWitnessPayload';
  /** Reads a single `Challenge` that is related to this `ChallengesWitness`. */
  challenge?: Maybe<Challenge>;
  /** The `ChallengesWitness` that was deleted by this mutation. */
  challengesWitness?: Maybe<ChallengesWitness>;
  /** An edge for our `ChallengesWitness`. May be used by Relay 1. */
  challengesWitnessEdge?: Maybe<ChallengesWitnessesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedChallengesWitnessNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ChallengesWitness`. */
  user?: Maybe<User>;
};


/** The output of our delete `ChallengesWitness` mutation. */
export type DeleteChallengesWitnessPayloadChallengesWitnessEdgeArgs = {
  orderBy?: InputMaybe<Array<ChallengesWitnessesOrderBy>>;
};

/** All input for the `deleteCloudByNodeId` mutation. */
export type DeleteCloudByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Cloud` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCloud` mutation. */
export type DeleteCloudInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Cloud` mutation. */
export type DeleteCloudPayload = {
  __typename?: 'DeleteCloudPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Cloud` that was deleted by this mutation. */
  cloud?: Maybe<Cloud>;
  /** An edge for our `Cloud`. May be used by Relay 1. */
  cloudEdge?: Maybe<CloudsEdge>;
  deletedCloudNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Media` that is related to this `Cloud`. */
  media?: Maybe<Media>;
  /** Reads a single `Post` that is related to this `Cloud`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Cloud`. */
  user?: Maybe<User>;
};


/** The output of our delete `Cloud` mutation. */
export type DeleteCloudPayloadCloudEdgeArgs = {
  orderBy?: InputMaybe<Array<CloudsOrderBy>>;
};

/** All input for the `deleteFavoriteGameByNodeId` mutation. */
export type DeleteFavoriteGameByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FavoriteGame` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFavoriteGame` mutation. */
export type DeleteFavoriteGameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `FavoriteGame` mutation. */
export type DeleteFavoriteGamePayload = {
  __typename?: 'DeleteFavoriteGamePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFavoriteGameNodeId?: Maybe<Scalars['ID']>;
  /** The `FavoriteGame` that was deleted by this mutation. */
  favoriteGame?: Maybe<FavoriteGame>;
  /** An edge for our `FavoriteGame`. May be used by Relay 1. */
  favoriteGameEdge?: Maybe<FavoriteGamesEdge>;
  /** Reads a single `Game` that is related to this `FavoriteGame`. */
  game?: Maybe<Game>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `FavoriteGame`. */
  user?: Maybe<User>;
};


/** The output of our delete `FavoriteGame` mutation. */
export type DeleteFavoriteGamePayloadFavoriteGameEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoriteGamesOrderBy>>;
};

/** All input for the `deleteFeeByNodeId` mutation. */
export type DeleteFeeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Fee` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFee` mutation. */
export type DeleteFeeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Fee` mutation. */
export type DeleteFeePayload = {
  __typename?: 'DeleteFeePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFeeNodeId?: Maybe<Scalars['ID']>;
  /** The `Fee` that was deleted by this mutation. */
  fee?: Maybe<Fee>;
  /** An edge for our `Fee`. May be used by Relay 1. */
  feeEdge?: Maybe<FeesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Fee` mutation. */
export type DeleteFeePayloadFeeEdgeArgs = {
  orderBy?: InputMaybe<Array<FeesOrderBy>>;
};

/** All input for the `deleteFeedbackByNodeId` mutation. */
export type DeleteFeedbackByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Feedback` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFeedback` mutation. */
export type DeleteFeedbackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Feedback` mutation. */
export type DeleteFeedbackPayload = {
  __typename?: 'DeleteFeedbackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFeedbackNodeId?: Maybe<Scalars['ID']>;
  /** The `Feedback` that was deleted by this mutation. */
  feedback?: Maybe<Feedback>;
  /** An edge for our `Feedback`. May be used by Relay 1. */
  feedbackEdge?: Maybe<FeedbacksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Feedback`. */
  user?: Maybe<User>;
};


/** The output of our delete `Feedback` mutation. */
export type DeleteFeedbackPayloadFeedbackEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedbacksOrderBy>>;
};

/** All input for the `deleteFollowingByNodeId` mutation. */
export type DeleteFollowingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Following` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFollowing` mutation. */
export type DeleteFollowingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Following` mutation. */
export type DeleteFollowingPayload = {
  __typename?: 'DeleteFollowingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFollowingNodeId?: Maybe<Scalars['ID']>;
  /** The `Following` that was deleted by this mutation. */
  following?: Maybe<Following>;
  /** An edge for our `Following`. May be used by Relay 1. */
  followingEdge?: Maybe<FollowingsEdge>;
  /** Reads a single `User` that is related to this `Following`. */
  followings_fid?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Following`. */
  user?: Maybe<User>;
};


/** The output of our delete `Following` mutation. */
export type DeleteFollowingPayloadFollowingEdgeArgs = {
  orderBy?: InputMaybe<Array<FollowingsOrderBy>>;
};

/** All input for the `deleteFunnyByNodeId` mutation. */
export type DeleteFunnyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Funny` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFunny` mutation. */
export type DeleteFunnyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** The output of our delete `Funny` mutation. */
export type DeleteFunnyPayload = {
  __typename?: 'DeleteFunnyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFunnyNodeId?: Maybe<Scalars['ID']>;
  /** The `Funny` that was deleted by this mutation. */
  funny?: Maybe<Funny>;
  /** An edge for our `Funny`. May be used by Relay 1. */
  funnyEdge?: Maybe<FunniesEdge>;
  /** Reads a single `Post` that is related to this `Funny`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Funny`. */
  user?: Maybe<User>;
};


/** The output of our delete `Funny` mutation. */
export type DeleteFunnyPayloadFunnyEdgeArgs = {
  orderBy?: InputMaybe<Array<FunniesOrderBy>>;
};

/** All input for the `deleteGameByNodeId` mutation. */
export type DeleteGameByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Game` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGame` mutation. */
export type DeleteGameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Game` mutation. */
export type DeleteGamePayload = {
  __typename?: 'DeleteGamePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedGameNodeId?: Maybe<Scalars['ID']>;
  /** The `Game` that was deleted by this mutation. */
  game?: Maybe<Game>;
  /** An edge for our `Game`. May be used by Relay 1. */
  gameEdge?: Maybe<GamesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Game` mutation. */
export type DeleteGamePayloadGameEdgeArgs = {
  orderBy?: InputMaybe<Array<GamesOrderBy>>;
};

/** All input for the `deleteGenderByNodeId` mutation. */
export type DeleteGenderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Gender` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGender` mutation. */
export type DeleteGenderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Gender` mutation. */
export type DeleteGenderPayload = {
  __typename?: 'DeleteGenderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Gender`. */
  createdBy?: Maybe<User>;
  deletedGenderNodeId?: Maybe<Scalars['ID']>;
  /** The `Gender` that was deleted by this mutation. */
  gender?: Maybe<Gender>;
  /** An edge for our `Gender`. May be used by Relay 1. */
  genderEdge?: Maybe<GendersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Gender` mutation. */
export type DeleteGenderPayloadGenderEdgeArgs = {
  orderBy?: InputMaybe<Array<GendersOrderBy>>;
};

/** All input for the `deleteGenreByNodeId` mutation. */
export type DeleteGenreByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Genre` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGenre` mutation. */
export type DeleteGenreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Genre` mutation. */
export type DeleteGenrePayload = {
  __typename?: 'DeleteGenrePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedGenreNodeId?: Maybe<Scalars['ID']>;
  /** The `Genre` that was deleted by this mutation. */
  genre?: Maybe<Genre>;
  /** An edge for our `Genre`. May be used by Relay 1. */
  genreEdge?: Maybe<GenresEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Genre` mutation. */
export type DeleteGenrePayloadGenreEdgeArgs = {
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};

/** All input for the `deleteLikeByNodeId` mutation. */
export type DeleteLikeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Like` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLike` mutation. */
export type DeleteLikeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** The output of our delete `Like` mutation. */
export type DeleteLikePayload = {
  __typename?: 'DeleteLikePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedLikeNodeId?: Maybe<Scalars['ID']>;
  /** The `Like` that was deleted by this mutation. */
  like?: Maybe<Like>;
  /** An edge for our `Like`. May be used by Relay 1. */
  likeEdge?: Maybe<LikesEdge>;
  /** Reads a single `Post` that is related to this `Like`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Like`. */
  user?: Maybe<User>;
};


/** The output of our delete `Like` mutation. */
export type DeleteLikePayloadLikeEdgeArgs = {
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** All input for the `deleteMediaByNodeId` mutation. */
export type DeleteMediaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Media` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMedia` mutation. */
export type DeleteMediaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Media` mutation. */
export type DeleteMediaPayload = {
  __typename?: 'DeleteMediaPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedMediaNodeId?: Maybe<Scalars['ID']>;
  /** The `Media` that was deleted by this mutation. */
  media?: Maybe<Media>;
  /** An edge for our `Media`. May be used by Relay 1. */
  mediaEdge?: Maybe<MediaEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Media`. */
  user?: Maybe<User>;
};


/** The output of our delete `Media` mutation. */
export type DeleteMediaPayloadMediaEdgeArgs = {
  orderBy?: InputMaybe<Array<MediaOrderBy>>;
};

/** All input for the `deleteMessageByNodeId` mutation. */
export type DeleteMessageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Message` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMessage` mutation. */
export type DeleteMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Message` mutation. */
export type DeleteMessagePayload = {
  __typename?: 'DeleteMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedMessageNodeId?: Maybe<Scalars['ID']>;
  /** The `Message` that was deleted by this mutation. */
  message?: Maybe<Message>;
  /** An edge for our `Message`. May be used by Relay 1. */
  messageEdge?: Maybe<MessagesEdge>;
  /** Reads a single `Post` that is related to this `Message`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Room` that is related to this `Message`. */
  room?: Maybe<Room>;
  /** Reads a single `User` that is related to this `Message`. */
  user?: Maybe<User>;
};


/** The output of our delete `Message` mutation. */
export type DeleteMessagePayloadMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/** All input for the `deleteMusicByNodeId` mutation. */
export type DeleteMusicByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Music` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMusicGenreByNodeId` mutation. */
export type DeleteMusicGenreByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MusicGenre` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMusicGenre` mutation. */
export type DeleteMusicGenreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `MusicGenre` mutation. */
export type DeleteMusicGenrePayload = {
  __typename?: 'DeleteMusicGenrePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedMusicGenreNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Genre` that is related to this `MusicGenre`. */
  genre?: Maybe<Genre>;
  /** Reads a single `Music` that is related to this `MusicGenre`. */
  music?: Maybe<Music>;
  /** The `MusicGenre` that was deleted by this mutation. */
  musicGenre?: Maybe<MusicGenre>;
  /** An edge for our `MusicGenre`. May be used by Relay 1. */
  musicGenreEdge?: Maybe<MusicGenresEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `MusicGenre` mutation. */
export type DeleteMusicGenrePayloadMusicGenreEdgeArgs = {
  orderBy?: InputMaybe<Array<MusicGenresOrderBy>>;
};

/** All input for the `deleteMusic` mutation. */
export type DeleteMusicInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Music` mutation. */
export type DeleteMusicPayload = {
  __typename?: 'DeleteMusicPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedMusicNodeId?: Maybe<Scalars['ID']>;
  /** The `Music` that was deleted by this mutation. */
  music?: Maybe<Music>;
  /** An edge for our `Music`. May be used by Relay 1. */
  musicEdge?: Maybe<MusicEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Music` mutation. */
export type DeleteMusicPayloadMusicEdgeArgs = {
  orderBy?: InputMaybe<Array<MusicOrderBy>>;
};

/** All input for the `deleteNoobByNodeId` mutation. */
export type DeleteNoobByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Noob` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteNoob` mutation. */
export type DeleteNoobInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** The output of our delete `Noob` mutation. */
export type DeleteNoobPayload = {
  __typename?: 'DeleteNoobPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedNoobNodeId?: Maybe<Scalars['ID']>;
  /** The `Noob` that was deleted by this mutation. */
  noob?: Maybe<Noob>;
  /** An edge for our `Noob`. May be used by Relay 1. */
  noobEdge?: Maybe<NoobsEdge>;
  /** Reads a single `Post` that is related to this `Noob`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Noob`. */
  user?: Maybe<User>;
};


/** The output of our delete `Noob` mutation. */
export type DeleteNoobPayloadNoobEdgeArgs = {
  orderBy?: InputMaybe<Array<NoobsOrderBy>>;
};

/** All input for the `deleteNotificationLogByNodeId` mutation. */
export type DeleteNotificationLogByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `NotificationLog` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteNotificationLog` mutation. */
export type DeleteNotificationLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `NotificationLog` mutation. */
export type DeleteNotificationLogPayload = {
  __typename?: 'DeleteNotificationLogPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedNotificationLogNodeId?: Maybe<Scalars['ID']>;
  /** The `NotificationLog` that was deleted by this mutation. */
  notificationLog?: Maybe<NotificationLog>;
  /** An edge for our `NotificationLog`. May be used by Relay 1. */
  notificationLogEdge?: Maybe<NotificationLogsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `NotificationLog` mutation. */
export type DeleteNotificationLogPayloadNotificationLogEdgeArgs = {
  orderBy?: InputMaybe<Array<NotificationLogsOrderBy>>;
};

/** All input for the `deleteOzSubscriptionByNodeId` mutation. */
export type DeleteOzSubscriptionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OzSubscription` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteOzSubscription` mutation. */
export type DeleteOzSubscriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `OzSubscription` mutation. */
export type DeleteOzSubscriptionPayload = {
  __typename?: 'DeleteOzSubscriptionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSubscriptionNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Fee` that is related to this `OzSubscription`. */
  fee?: Maybe<Fee>;
  /** The `OzSubscription` that was deleted by this mutation. */
  ozSubscription?: Maybe<OzSubscription>;
  /** An edge for our `OzSubscription`. May be used by Relay 1. */
  ozSubscriptionEdge?: Maybe<OzSubscriptionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Transaction` that is related to this `OzSubscription`. */
  transaction?: Maybe<Transaction>;
  /** Reads a single `User` that is related to this `OzSubscription`. */
  user?: Maybe<User>;
};


/** The output of our delete `OzSubscription` mutation. */
export type DeleteOzSubscriptionPayloadOzSubscriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<OzSubscriptionsOrderBy>>;
};

/** All input for the `deletePaymentMethodByNodeId` mutation. */
export type DeletePaymentMethodByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PaymentMethod` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deletePaymentMethod` mutation. */
export type DeletePaymentMethodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `PaymentMethod` mutation. */
export type DeletePaymentMethodPayload = {
  __typename?: 'DeletePaymentMethodPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPaymentMethodNodeId?: Maybe<Scalars['ID']>;
  /** The `PaymentMethod` that was deleted by this mutation. */
  paymentMethod?: Maybe<PaymentMethod>;
  /** An edge for our `PaymentMethod`. May be used by Relay 1. */
  paymentMethodEdge?: Maybe<PaymentMethodsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PaymentMethod`. */
  user?: Maybe<User>;
};


/** The output of our delete `PaymentMethod` mutation. */
export type DeletePaymentMethodPayloadPaymentMethodEdgeArgs = {
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
};

/** All input for the `deletePostByNodeId` mutation. */
export type DeletePostByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Post` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deletePost` mutation. */
export type DeletePostInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Post` mutation. */
export type DeletePostPayload = {
  __typename?: 'DeletePostPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPostNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Game` that is related to this `Post`. */
  game?: Maybe<Game>;
  /** Reads a single `Media` that is related to this `Post`. */
  media?: Maybe<Media>;
  /** The `Post` that was deleted by this mutation. */
  post?: Maybe<Post>;
  /** An edge for our `Post`. May be used by Relay 1. */
  postEdge?: Maybe<PostsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Post`. */
  user?: Maybe<User>;
};


/** The output of our delete `Post` mutation. */
export type DeletePostPayloadPostEdgeArgs = {
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};

/** All input for the `deletePostsReportByNodeId` mutation. */
export type DeletePostsReportByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PostsReport` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deletePostsReport` mutation. */
export type DeletePostsReportInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `PostsReport` mutation. */
export type DeletePostsReportPayload = {
  __typename?: 'DeletePostsReportPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPostsReportNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Post` that is related to this `PostsReport`. */
  post?: Maybe<Post>;
  /** The `PostsReport` that was deleted by this mutation. */
  postsReport?: Maybe<PostsReport>;
  /** An edge for our `PostsReport`. May be used by Relay 1. */
  postsReportEdge?: Maybe<PostsReportsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ReportMessage` that is related to this `PostsReport`. */
  reportMessage?: Maybe<ReportMessage>;
  /** Reads a single `User` that is related to this `PostsReport`. */
  user?: Maybe<User>;
  /** Reads a single `User` that is related to this `PostsReport`. */
  validatingUser?: Maybe<User>;
};


/** The output of our delete `PostsReport` mutation. */
export type DeletePostsReportPayloadPostsReportEdgeArgs = {
  orderBy?: InputMaybe<Array<PostsReportsOrderBy>>;
};

/** All input for the `deletePostsViewByNodeId` mutation. */
export type DeletePostsViewByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PostsView` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deletePostsViewByPostIdAndUserId` mutation. */
export type DeletePostsViewByPostIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** All input for the `deletePostsView` mutation. */
export type DeletePostsViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `PostsView` mutation. */
export type DeletePostsViewPayload = {
  __typename?: 'DeletePostsViewPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPostsViewNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Post` that is related to this `PostsView`. */
  post?: Maybe<Post>;
  /** The `PostsView` that was deleted by this mutation. */
  postsView?: Maybe<PostsView>;
  /** An edge for our `PostsView`. May be used by Relay 1. */
  postsViewEdge?: Maybe<PostsViewsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PostsView`. */
  user?: Maybe<User>;
};


/** The output of our delete `PostsView` mutation. */
export type DeletePostsViewPayloadPostsViewEdgeArgs = {
  orderBy?: InputMaybe<Array<PostsViewsOrderBy>>;
};

/** All input for the `deletePrivateUserByNodeId` mutation. */
export type DeletePrivateUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PrivateUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deletePrivateUser` mutation. */
export type DeletePrivateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['Int'];
};

/** The output of our delete `PrivateUser` mutation. */
export type DeletePrivateUserPayload = {
  __typename?: 'DeletePrivateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPrivateUserNodeId?: Maybe<Scalars['ID']>;
  /** The `PrivateUser` that was deleted by this mutation. */
  privateUser?: Maybe<PrivateUser>;
  /** An edge for our `PrivateUser`. May be used by Relay 1. */
  privateUserEdge?: Maybe<PrivateUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PrivateUser`. */
  user?: Maybe<User>;
};


/** The output of our delete `PrivateUser` mutation. */
export type DeletePrivateUserPayloadPrivateUserEdgeArgs = {
  orderBy?: InputMaybe<Array<PrivateUsersOrderBy>>;
};

/** All input for the `deleteReportMessageByNodeId` mutation. */
export type DeleteReportMessageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ReportMessage` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteReportMessage` mutation. */
export type DeleteReportMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `ReportMessage` mutation. */
export type DeleteReportMessagePayload = {
  __typename?: 'DeleteReportMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedReportMessageNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ReportMessage` that was deleted by this mutation. */
  reportMessage?: Maybe<ReportMessage>;
  /** An edge for our `ReportMessage`. May be used by Relay 1. */
  reportMessageEdge?: Maybe<ReportMessagesEdge>;
};


/** The output of our delete `ReportMessage` mutation. */
export type DeleteReportMessagePayloadReportMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<ReportMessagesOrderBy>>;
};

/** All input for the `deleteRoleByNodeId` mutation. */
export type DeleteRoleByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Role` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteRole` mutation. */
export type DeleteRoleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Role` mutation. */
export type DeleteRolePayload = {
  __typename?: 'DeleteRolePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedRoleNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Role` that was deleted by this mutation. */
  role?: Maybe<Role>;
  /** An edge for our `Role`. May be used by Relay 1. */
  roleEdge?: Maybe<RolesEdge>;
};


/** The output of our delete `Role` mutation. */
export type DeleteRolePayloadRoleEdgeArgs = {
  orderBy?: InputMaybe<Array<RolesOrderBy>>;
};

/** All input for the `deleteRoomByNodeId` mutation. */
export type DeleteRoomByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Room` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteRoom` mutation. */
export type DeleteRoomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Room` mutation. */
export type DeleteRoomPayload = {
  __typename?: 'DeleteRoomPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedRoomNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Room` that was deleted by this mutation. */
  room?: Maybe<Room>;
  /** An edge for our `Room`. May be used by Relay 1. */
  roomEdge?: Maybe<RoomsEdge>;
  /** Reads a single `User` that is related to this `Room`. */
  user?: Maybe<User>;
};


/** The output of our delete `Room` mutation. */
export type DeleteRoomPayloadRoomEdgeArgs = {
  orderBy?: InputMaybe<Array<RoomsOrderBy>>;
};

/** All input for the `deleteStickerByNodeId` mutation. */
export type DeleteStickerByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Sticker` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSticker` mutation. */
export type DeleteStickerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Sticker` mutation. */
export type DeleteStickerPayload = {
  __typename?: 'DeleteStickerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedStickerNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Sticker` that was deleted by this mutation. */
  sticker?: Maybe<Sticker>;
  /** An edge for our `Sticker`. May be used by Relay 1. */
  stickerEdge?: Maybe<StickersEdge>;
};


/** The output of our delete `Sticker` mutation. */
export type DeleteStickerPayloadStickerEdgeArgs = {
  orderBy?: InputMaybe<Array<StickersOrderBy>>;
};

/** All input for the `deleteStickersTagByNodeId` mutation. */
export type DeleteStickersTagByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `StickersTag` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteStickersTag` mutation. */
export type DeleteStickersTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `StickersTag` mutation. */
export type DeleteStickersTagPayload = {
  __typename?: 'DeleteStickersTagPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedStickersTagNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Sticker` that is related to this `StickersTag`. */
  sticker?: Maybe<Sticker>;
  /** The `StickersTag` that was deleted by this mutation. */
  stickersTag?: Maybe<StickersTag>;
  /** An edge for our `StickersTag`. May be used by Relay 1. */
  stickersTagEdge?: Maybe<StickersTagsEdge>;
  /** Reads a single `Tag` that is related to this `StickersTag`. */
  tag?: Maybe<Tag>;
};


/** The output of our delete `StickersTag` mutation. */
export type DeleteStickersTagPayloadStickersTagEdgeArgs = {
  orderBy?: InputMaybe<Array<StickersTagsOrderBy>>;
};

/** All input for the `deleteTagByNodeId` mutation. */
export type DeleteTagByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Tag` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTag` mutation. */
export type DeleteTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Tag` mutation. */
export type DeleteTagPayload = {
  __typename?: 'DeleteTagPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTagNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Tag` that was deleted by this mutation. */
  tag?: Maybe<Tag>;
  /** An edge for our `Tag`. May be used by Relay 1. */
  tagEdge?: Maybe<TagsEdge>;
};


/** The output of our delete `Tag` mutation. */
export type DeleteTagPayloadTagEdgeArgs = {
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

/** All input for the `deleteTeamByName` mutation. */
export type DeleteTeamByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** All input for the `deleteTeamByNodeId` mutation. */
export type DeleteTeamByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Team` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTeam` mutation. */
export type DeleteTeamInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Team` mutation. */
export type DeleteTeamPayload = {
  __typename?: 'DeleteTeamPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Team`. */
  createdBy?: Maybe<User>;
  deletedTeamNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Team` that was deleted by this mutation. */
  team?: Maybe<Team>;
  /** An edge for our `Team`. May be used by Relay 1. */
  teamEdge?: Maybe<TeamsEdge>;
};


/** The output of our delete `Team` mutation. */
export type DeleteTeamPayloadTeamEdgeArgs = {
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};

/** All input for the `deleteTeamsMemberByNodeId` mutation. */
export type DeleteTeamsMemberByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TeamsMember` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTeamsMember` mutation. */
export type DeleteTeamsMemberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `TeamsMember` mutation. */
export type DeleteTeamsMemberPayload = {
  __typename?: 'DeleteTeamsMemberPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTeamsMemberNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TeamsMember`. */
  team?: Maybe<Team>;
  /** The `TeamsMember` that was deleted by this mutation. */
  teamsMember?: Maybe<TeamsMember>;
  /** An edge for our `TeamsMember`. May be used by Relay 1. */
  teamsMemberEdge?: Maybe<TeamsMembersEdge>;
  /** Reads a single `User` that is related to this `TeamsMember`. */
  user?: Maybe<User>;
  /** Reads a single `User` that is related to this `TeamsMember`. */
  userByBannedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `TeamsMember`. */
  userByRemovedBy?: Maybe<User>;
};


/** The output of our delete `TeamsMember` mutation. */
export type DeleteTeamsMemberPayloadTeamsMemberEdgeArgs = {
  orderBy?: InputMaybe<Array<TeamsMembersOrderBy>>;
};

/** All input for the `deleteTransactionByNodeId` mutation. */
export type DeleteTransactionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Transaction` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTransaction` mutation. */
export type DeleteTransactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Transaction` mutation. */
export type DeleteTransactionPayload = {
  __typename?: 'DeleteTransactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTransactionNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Fee` that is related to this `Transaction`. */
  fee?: Maybe<Fee>;
  /** Reads a single `PaymentMethod` that is related to this `Transaction`. */
  paymentMethod?: Maybe<PaymentMethod>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Transaction` that was deleted by this mutation. */
  transaction?: Maybe<Transaction>;
  /** An edge for our `Transaction`. May be used by Relay 1. */
  transactionEdge?: Maybe<TransactionsEdge>;
  /** Reads a single `User` that is related to this `Transaction`. */
  user?: Maybe<User>;
};


/** The output of our delete `Transaction` mutation. */
export type DeleteTransactionPayloadTransactionEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/** All input for the `deleteUserAccessByNodeId` mutation. */
export type DeleteUserAccessByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserAccess` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserAccess` mutation. */
export type DeleteUserAccessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `UserAccess` mutation. */
export type DeleteUserAccessPayload = {
  __typename?: 'DeleteUserAccessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserAccessNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserAccess`. */
  user?: Maybe<User>;
  /** The `UserAccess` that was deleted by this mutation. */
  userAccess?: Maybe<UserAccess>;
  /** An edge for our `UserAccess`. May be used by Relay 1. */
  userAccessEdge?: Maybe<UserAccessesEdge>;
};


/** The output of our delete `UserAccess` mutation. */
export type DeleteUserAccessPayloadUserAccessEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAccessesOrderBy>>;
};

/** All input for the `deleteUserByNodeId` mutation. */
export type DeleteUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserEmailByNodeId` mutation. */
export type DeleteUserEmailByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserEmail` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserEmailByUserId` mutation. */
export type DeleteUserEmailByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['Int'];
};

/** All input for the `deleteUserEmail` mutation. */
export type DeleteUserEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `UserEmail` mutation. */
export type DeleteUserEmailPayload = {
  __typename?: 'DeleteUserEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserEmailNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserEmail`. */
  user?: Maybe<User>;
  /** The `UserEmail` that was deleted by this mutation. */
  userEmail?: Maybe<UserEmail>;
  /** An edge for our `UserEmail`. May be used by Relay 1. */
  userEmailEdge?: Maybe<UserEmailsEdge>;
};


/** The output of our delete `UserEmail` mutation. */
export type DeleteUserEmailPayloadUserEmailEdgeArgs = {
  orderBy?: InputMaybe<Array<UserEmailsOrderBy>>;
};

/** All input for the `deleteUser` mutation. */
export type DeleteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `User` mutation. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Gender` that is related to this `User`. */
  gender?: Maybe<Gender>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was deleted by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our delete `User` mutation. */
export type DeleteUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `deleteUserRoleByNodeId` mutation. */
export type DeleteUserRoleByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserRole` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserRole` mutation. */
export type DeleteUserRoleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `UserRole` mutation. */
export type DeleteUserRolePayload = {
  __typename?: 'DeleteUserRolePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserRoleNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Role` that is related to this `UserRole`. */
  role?: Maybe<Role>;
  /** Reads a single `User` that is related to this `UserRole`. */
  user?: Maybe<User>;
  /** The `UserRole` that was deleted by this mutation. */
  userRole?: Maybe<UserRole>;
  /** An edge for our `UserRole`. May be used by Relay 1. */
  userRoleEdge?: Maybe<UserRolesEdge>;
};


/** The output of our delete `UserRole` mutation. */
export type DeleteUserRolePayloadUserRoleEdgeArgs = {
  orderBy?: InputMaybe<Array<UserRolesOrderBy>>;
};

/** All input for the `deleteUsernamesCheckByNodeId` mutation. */
export type DeleteUsernamesCheckByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UsernamesCheck` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUsernamesCheck` mutation. */
export type DeleteUsernamesCheckInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `UsernamesCheck` mutation. */
export type DeleteUsernamesCheckPayload = {
  __typename?: 'DeleteUsernamesCheckPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUsernamesCheckNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UsernamesCheck` that was deleted by this mutation. */
  usernamesCheck?: Maybe<UsernamesCheck>;
  /** An edge for our `UsernamesCheck`. May be used by Relay 1. */
  usernamesCheckEdge?: Maybe<UsernamesChecksEdge>;
};


/** The output of our delete `UsernamesCheck` mutation. */
export type DeleteUsernamesCheckPayloadUsernamesCheckEdgeArgs = {
  orderBy?: InputMaybe<Array<UsernamesChecksOrderBy>>;
};

/** All input for the `deleteUsersAvailableRoomByNodeId` mutation. */
export type DeleteUsersAvailableRoomByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UsersAvailableRoom` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUsersAvailableRoom` mutation. */
export type DeleteUsersAvailableRoomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `UsersAvailableRoom` mutation. */
export type DeleteUsersAvailableRoomPayload = {
  __typename?: 'DeleteUsersAvailableRoomPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUsersAvailableRoomNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Room` that is related to this `UsersAvailableRoom`. */
  room?: Maybe<Room>;
  /** Reads a single `User` that is related to this `UsersAvailableRoom`. */
  user?: Maybe<User>;
  /** The `UsersAvailableRoom` that was deleted by this mutation. */
  usersAvailableRoom?: Maybe<UsersAvailableRoom>;
  /** An edge for our `UsersAvailableRoom`. May be used by Relay 1. */
  usersAvailableRoomEdge?: Maybe<UsersAvailableRoomsEdge>;
};


/** The output of our delete `UsersAvailableRoom` mutation. */
export type DeleteUsersAvailableRoomPayloadUsersAvailableRoomEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersAvailableRoomsOrderBy>>;
};

/** All input for the `deleteUsersReceivedMessageByNodeId` mutation. */
export type DeleteUsersReceivedMessageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UsersReceivedMessage` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUsersReceivedMessage` mutation. */
export type DeleteUsersReceivedMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `UsersReceivedMessage` mutation. */
export type DeleteUsersReceivedMessagePayload = {
  __typename?: 'DeleteUsersReceivedMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUsersReceivedMessageNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Message` that is related to this `UsersReceivedMessage`. */
  message?: Maybe<Message>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UsersReceivedMessage`. */
  user?: Maybe<User>;
  /** The `UsersReceivedMessage` that was deleted by this mutation. */
  usersReceivedMessage?: Maybe<UsersReceivedMessage>;
  /** An edge for our `UsersReceivedMessage`. May be used by Relay 1. */
  usersReceivedMessageEdge?: Maybe<UsersReceivedMessagesEdge>;
};


/** The output of our delete `UsersReceivedMessage` mutation. */
export type DeleteUsersReceivedMessagePayloadUsersReceivedMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersReceivedMessagesOrderBy>>;
};

/** All input for the `deleteWitnessByNodeId` mutation. */
export type DeleteWitnessByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Witness` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteWitness` mutation. */
export type DeleteWitnessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Witness` mutation. */
export type DeleteWitnessPayload = {
  __typename?: 'DeleteWitnessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedWitnessNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Witness`. */
  user?: Maybe<User>;
  /** The `Witness` that was deleted by this mutation. */
  witness?: Maybe<Witness>;
  /** An edge for our `Witness`. May be used by Relay 1. */
  witnessEdge?: Maybe<WitnessesEdge>;
};


/** The output of our delete `Witness` mutation. */
export type DeleteWitnessPayloadWitnessEdgeArgs = {
  orderBy?: InputMaybe<Array<WitnessesOrderBy>>;
};

export enum Duration {
  '1Day' = '_1_DAY',
  '2Days' = '_2_DAYS',
  '7Days' = '_7_DAYS'
}

/** A filter to be used against Duration fields. All fields are combined with a logical ‘and.’ */
export type DurationFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Duration>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Duration>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Duration>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Duration>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Duration>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Duration>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Duration>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Duration>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Duration>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Duration>>;
};

export type FavoriteGame = Node & {
  __typename?: 'FavoriteGame';
  createdAt: Scalars['Datetime'];
  /** Reads a single `Game` that is related to this `FavoriteGame`. */
  game?: Maybe<Game>;
  gameId: Scalars['Int'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `FavoriteGame`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `FavoriteGame` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FavoriteGameCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `gameId` field. */
  gameId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `FavoriteGame` object types. All fields are combined with a logical ‘and.’ */
export type FavoriteGameFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FavoriteGameFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `gameId` field. */
  gameId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FavoriteGameFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FavoriteGameFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `FavoriteGame` */
export type FavoriteGameInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  gameId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `FavoriteGame`. Fields that are set will be updated. */
export type FavoriteGamePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  gameId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `FavoriteGame` values. */
export type FavoriteGamesConnection = {
  __typename?: 'FavoriteGamesConnection';
  /** A list of edges which contains the `FavoriteGame` and cursor to aid in pagination. */
  edges: Array<FavoriteGamesEdge>;
  /** A list of `FavoriteGame` objects. */
  nodes: Array<FavoriteGame>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FavoriteGame` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FavoriteGame` edge in the connection. */
export type FavoriteGamesEdge = {
  __typename?: 'FavoriteGamesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FavoriteGame` at the end of the edge. */
  node: FavoriteGame;
};

/** Methods to use when ordering `FavoriteGame`. */
export enum FavoriteGamesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  GameIdAsc = 'GAME_ID_ASC',
  GameIdDesc = 'GAME_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Fee = Node & {
  __typename?: 'Fee';
  amount: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  currency: Currencies;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `OzSubscription`. */
  ozSubscriptions: OzSubscriptionsConnection;
  recurring?: Maybe<RecurringPayments>;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions: TransactionsConnection;
  updatedAt: Scalars['Datetime'];
};


export type FeeOzSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OzSubscriptionCondition>;
  filter?: InputMaybe<OzSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OzSubscriptionsOrderBy>>;
};


export type FeeTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/** A condition to be used against `Fee` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FeeCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: InputMaybe<Currencies>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `recurring` field. */
  recurring?: InputMaybe<RecurringPayments>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Fee` object types. All fields are combined with a logical ‘and.’ */
export type FeeFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeeFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: InputMaybe<CurrenciesFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeeFilter>>;
  /** Filter by the object’s `recurring` field. */
  recurring?: InputMaybe<RecurringPaymentsFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Fee` */
export type FeeInput = {
  amount: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currency: Currencies;
  name: Scalars['String'];
  recurring?: InputMaybe<RecurringPayments>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `Fee`. Fields that are set will be updated. */
export type FeePatch = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currency?: InputMaybe<Currencies>;
  name?: InputMaybe<Scalars['String']>;
  recurring?: InputMaybe<RecurringPayments>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type Feedback = Node & {
  __typename?: 'Feedback';
  createdAt: Scalars['Datetime'];
  description: Scalars['String'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  resolved: Scalars['Boolean'];
  updatedAt: Scalars['Datetime'];
  url?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Feedback`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `Feedback` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FeedbackCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `resolved` field. */
  resolved?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Feedback` object types. All fields are combined with a logical ‘and.’ */
export type FeedbackFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedbackFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedbackFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedbackFilter>>;
  /** Filter by the object’s `resolved` field. */
  resolved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Feedback` */
export type FeedbackInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  resolved?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `Feedback`. Fields that are set will be updated. */
export type FeedbackPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  resolved?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Feedback` values. */
export type FeedbacksConnection = {
  __typename?: 'FeedbacksConnection';
  /** A list of edges which contains the `Feedback` and cursor to aid in pagination. */
  edges: Array<FeedbacksEdge>;
  /** A list of `Feedback` objects. */
  nodes: Array<Feedback>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feedback` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Feedback` edge in the connection. */
export type FeedbacksEdge = {
  __typename?: 'FeedbacksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Feedback` at the end of the edge. */
  node: Feedback;
};

/** Methods to use when ordering `Feedback`. */
export enum FeedbacksOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ResolvedAsc = 'RESOLVED_ASC',
  ResolvedDesc = 'RESOLVED_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A connection to a list of `Fee` values. */
export type FeesConnection = {
  __typename?: 'FeesConnection';
  /** A list of edges which contains the `Fee` and cursor to aid in pagination. */
  edges: Array<FeesEdge>;
  /** A list of `Fee` objects. */
  nodes: Array<Fee>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Fee` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Fee` edge in the connection. */
export type FeesEdge = {
  __typename?: 'FeesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Fee` at the end of the edge. */
  node: Fee;
};

/** Methods to use when ordering `Fee`. */
export enum FeesOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RecurringAsc = 'RECURRING_ASC',
  RecurringDesc = 'RECURRING_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Float']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Float']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Float']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Float']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Float']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Float']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Float']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Float']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Float']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type Following = Node & {
  __typename?: 'Following';
  approved: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  followingId: Scalars['Int'];
  /** Reads a single `User` that is related to this `Following`. */
  followings_fid?: Maybe<User>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Following`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `Following` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FollowingCondition = {
  /** Checks for equality with the object’s `approved` field. */
  approved?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `followingId` field. */
  followingId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Following` object types. All fields are combined with a logical ‘and.’ */
export type FollowingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FollowingFilter>>;
  /** Filter by the object’s `approved` field. */
  approved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `followingId` field. */
  followingId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FollowingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FollowingFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Following` */
export type FollowingInput = {
  approved?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  followingId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `Following`. Fields that are set will be updated. */
export type FollowingPatch = {
  approved?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  followingId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Following` values. */
export type FollowingsConnection = {
  __typename?: 'FollowingsConnection';
  /** A list of edges which contains the `Following` and cursor to aid in pagination. */
  edges: Array<FollowingsEdge>;
  /** A list of `Following` objects. */
  nodes: Array<Following>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Following` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Following` edge in the connection. */
export type FollowingsEdge = {
  __typename?: 'FollowingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Following` at the end of the edge. */
  node: Following;
};

/** Methods to use when ordering `Following`. */
export enum FollowingsOrderBy {
  ApprovedAsc = 'APPROVED_ASC',
  ApprovedDesc = 'APPROVED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FollowingIdAsc = 'FOLLOWING_ID_ASC',
  FollowingIdDesc = 'FOLLOWING_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A connection to a list of `Funny` values. */
export type FunniesConnection = {
  __typename?: 'FunniesConnection';
  /** A list of edges which contains the `Funny` and cursor to aid in pagination. */
  edges: Array<FunniesEdge>;
  /** A list of `Funny` objects. */
  nodes: Array<Funny>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Funny` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Funny` edge in the connection. */
export type FunniesEdge = {
  __typename?: 'FunniesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Funny` at the end of the edge. */
  node: Funny;
};

/** Methods to use when ordering `Funny`. */
export enum FunniesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  Natural = 'NATURAL',
  PostIdAsc = 'POST_ID_ASC',
  PostIdDesc = 'POST_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Funny = Node & {
  __typename?: 'Funny';
  createdAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Post` that is related to this `Funny`. */
  post?: Maybe<Post>;
  postId: Scalars['Int'];
  /** Reads a single `User` that is related to this `Funny`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/** A condition to be used against `Funny` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FunnyCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `postId` field. */
  postId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Funny` object types. All fields are combined with a logical ‘and.’ */
export type FunnyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FunnyFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FunnyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FunnyFilter>>;
  /** Filter by the object’s `postId` field. */
  postId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Funny` */
export type FunnyInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** Represents an update to a `Funny`. Fields that are set will be updated. */
export type FunnyPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  postId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type Game = Node & {
  __typename?: 'Game';
  /** Reads and enables pagination through a set of `Challenge`. */
  challenges: ChallengesConnection;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `FavoriteGame`. */
  favoriteGames: FavoriteGamesConnection;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectId: Scalars['String'];
  photo: Scalars['String'];
  /** Reads and enables pagination through a set of `Post`. */
  posts: PostsConnection;
  updatedAt: Scalars['Datetime'];
  year: Scalars['Int'];
};


export type GameChallengesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengeCondition>;
  filter?: InputMaybe<ChallengeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesOrderBy>>;
};


export type GameFavoriteGamesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FavoriteGameCondition>;
  filter?: InputMaybe<FavoriteGameFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteGamesOrderBy>>;
};


export type GamePostsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostCondition>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};

/** A condition to be used against `Game` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type GameCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `photo` field. */
  photo?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `year` field. */
  year?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Game` object types. All fields are combined with a logical ‘and.’ */
export type GameFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GameFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GameFilter>;
  /** Filter by the object’s `objectId` field. */
  objectId?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GameFilter>>;
  /** Filter by the object’s `photo` field. */
  photo?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `year` field. */
  year?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Game` */
export type GameInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  objectId: Scalars['String'];
  photo: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  year: Scalars['Int'];
};

/** Represents an update to a `Game`. Fields that are set will be updated. */
export type GamePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  objectId?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Game` values. */
export type GamesConnection = {
  __typename?: 'GamesConnection';
  /** A list of edges which contains the `Game` and cursor to aid in pagination. */
  edges: Array<GamesEdge>;
  /** A list of `Game` objects. */
  nodes: Array<Game>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Game` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Game` edge in the connection. */
export type GamesEdge = {
  __typename?: 'GamesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Game` at the end of the edge. */
  node: Game;
};

/** Methods to use when ordering `Game`. */
export enum GamesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  ObjectIdAsc = 'OBJECT_ID_ASC',
  ObjectIdDesc = 'OBJECT_ID_DESC',
  PhotoAsc = 'PHOTO_ASC',
  PhotoDesc = 'PHOTO_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  YearAsc = 'YEAR_ASC',
  YearDesc = 'YEAR_DESC'
}

export type Gender = Node & {
  __typename?: 'Gender';
  /** Reads and enables pagination through a set of `AudienceMeta`. */
  audienceMetas: AudienceMetasConnection;
  createdAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Gender`. */
  createdBy?: Maybe<User>;
  createdById: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `User`. */
  users: UsersConnection;
};


export type GenderAudienceMetasArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AudienceMetaCondition>;
  filter?: InputMaybe<AudienceMetaFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AudienceMetasOrderBy>>;
};


export type GenderUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A condition to be used against `Gender` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type GenderCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdById` field. */
  createdById?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Gender` object types. All fields are combined with a logical ‘and.’ */
export type GenderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GenderFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdById` field. */
  createdById?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GenderFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GenderFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Gender` */
export type GenderInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `Gender`. Fields that are set will be updated. */
export type GenderPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  createdById?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `Gender` values. */
export type GendersConnection = {
  __typename?: 'GendersConnection';
  /** A list of edges which contains the `Gender` and cursor to aid in pagination. */
  edges: Array<GendersEdge>;
  /** A list of `Gender` objects. */
  nodes: Array<Gender>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Gender` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Gender` edge in the connection. */
export type GendersEdge = {
  __typename?: 'GendersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Gender` at the end of the edge. */
  node: Gender;
};

/** Methods to use when ordering `Gender`. */
export enum GendersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByIdAsc = 'CREATED_BY_ID_ASC',
  CreatedByIdDesc = 'CREATED_BY_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Genre = Node & {
  __typename?: 'Genre';
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** Reads and enables pagination through a set of `MusicGenre`. */
  musicGenres: MusicGenresConnection;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectId: Scalars['String'];
  updatedAt: Scalars['Datetime'];
};


export type GenreMusicGenresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MusicGenreCondition>;
  filter?: InputMaybe<MusicGenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MusicGenresOrderBy>>;
};

/** A condition to be used against `Genre` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type GenreCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Genre` object types. All fields are combined with a logical ‘and.’ */
export type GenreFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GenreFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GenreFilter>;
  /** Filter by the object’s `objectId` field. */
  objectId?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GenreFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Genre` */
export type GenreInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  name: Scalars['String'];
  objectId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `Genre`. Fields that are set will be updated. */
export type GenrePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  name?: InputMaybe<Scalars['String']>;
  objectId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `Genre` values. */
export type GenresConnection = {
  __typename?: 'GenresConnection';
  /** A list of edges which contains the `Genre` and cursor to aid in pagination. */
  edges: Array<GenresEdge>;
  /** A list of `Genre` objects. */
  nodes: Array<Genre>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Genre` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Genre` edge in the connection. */
export type GenresEdge = {
  __typename?: 'GenresEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Genre` at the end of the edge. */
  node: Genre;
};

/** Methods to use when ordering `Genre`. */
export enum GenresOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  ObjectIdAsc = 'OBJECT_ID_ASC',
  ObjectIdDesc = 'OBJECT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** All input for the `getAvailableRoomsUsers` mutation. */
export type GetAvailableRoomsUsersInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `getAvailableRoomsUsers` mutation. */
export type GetAvailableRoomsUsersPayload = {
  __typename?: 'GetAvailableRoomsUsersPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  integers?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars['JSON']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['JSON']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['JSON']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['JSON']>>;
};

export type Like = Node & {
  __typename?: 'Like';
  createdAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Post` that is related to this `Like`. */
  post?: Maybe<Post>;
  postId: Scalars['Int'];
  /** Reads a single `User` that is related to this `Like`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/** A condition to be used against `Like` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LikeCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `postId` field. */
  postId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Like` object types. All fields are combined with a logical ‘and.’ */
export type LikeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LikeFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LikeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LikeFilter>>;
  /** Filter by the object’s `postId` field. */
  postId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Like` */
export type LikeInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** Represents an update to a `Like`. Fields that are set will be updated. */
export type LikePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  postId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Like` values. */
export type LikesConnection = {
  __typename?: 'LikesConnection';
  /** A list of edges which contains the `Like` and cursor to aid in pagination. */
  edges: Array<LikesEdge>;
  /** A list of `Like` objects. */
  nodes: Array<Like>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Like` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Like` edge in the connection. */
export type LikesEdge = {
  __typename?: 'LikesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Like` at the end of the edge. */
  node: Like;
};

/** Methods to use when ordering `Like`. */
export enum LikesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  Natural = 'NATURAL',
  PostIdAsc = 'POST_ID_ASC',
  PostIdDesc = 'POST_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Media = Node & {
  __typename?: 'Media';
  /** Reads and enables pagination through a set of `Activity`. */
  activities: ActivitiesConnection;
  /** Reads and enables pagination through a set of `ChallengesProof`. */
  challengesProofs: ChallengesProofsConnection;
  /** Reads and enables pagination through a set of `Cloud`. */
  clouds: CloudsConnection;
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Post`. */
  posts: PostsConnection;
  ratio?: Maybe<Scalars['String']>;
  recordedAt?: Maybe<Scalars['Datetime']>;
  thumbUrl: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  url: Scalars['String'];
  /** Reads a single `User` that is related to this `Media`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};


export type MediaActivitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ActivityCondition>;
  filter?: InputMaybe<ActivityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};


export type MediaChallengesProofsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengesProofCondition>;
  filter?: InputMaybe<ChallengesProofFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesProofsOrderBy>>;
};


export type MediaCloudsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CloudCondition>;
  filter?: InputMaybe<CloudFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CloudsOrderBy>>;
};


export type MediaPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostCondition>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};

/** A condition to be used against `Media` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MediaCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `ratio` field. */
  ratio?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `recordedAt` field. */
  recordedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `thumbUrl` field. */
  thumbUrl?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Media` values. */
export type MediaConnection = {
  __typename?: 'MediaConnection';
  /** A list of edges which contains the `Media` and cursor to aid in pagination. */
  edges: Array<MediaEdge>;
  /** A list of `Media` objects. */
  nodes: Array<Media>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Media` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Media` edge in the connection. */
export type MediaEdge = {
  __typename?: 'MediaEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Media` at the end of the edge. */
  node: Media;
};

/** A filter to be used against `Media` object types. All fields are combined with a logical ‘and.’ */
export type MediaFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MediaFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MediaFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MediaFilter>>;
  /** Filter by the object’s `ratio` field. */
  ratio?: InputMaybe<StringFilter>;
  /** Filter by the object’s `recordedAt` field. */
  recordedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `thumbUrl` field. */
  thumbUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Media` */
export type MediaInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  ratio?: InputMaybe<Scalars['String']>;
  recordedAt?: InputMaybe<Scalars['Datetime']>;
  thumbUrl: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  url: Scalars['String'];
  userId: Scalars['Int'];
};

/** Methods to use when ordering `Media`. */
export enum MediaOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RatioAsc = 'RATIO_ASC',
  RatioDesc = 'RATIO_DESC',
  RecordedAtAsc = 'RECORDED_AT_ASC',
  RecordedAtDesc = 'RECORDED_AT_DESC',
  ThumbUrlAsc = 'THUMB_URL_ASC',
  ThumbUrlDesc = 'THUMB_URL_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** Represents an update to a `Media`. Fields that are set will be updated. */
export type MediaPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  ratio?: InputMaybe<Scalars['String']>;
  recordedAt?: InputMaybe<Scalars['Datetime']>;
  thumbUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type Message = Node & {
  __typename?: 'Message';
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Post` that is related to this `Message`. */
  post?: Maybe<Post>;
  postId?: Maybe<Scalars['Int']>;
  /** Reads a single `Room` that is related to this `Message`. */
  room?: Maybe<Room>;
  roomId: Scalars['Int'];
  /** Reads a single `User` that is related to this `Message`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
  /** Reads and enables pagination through a set of `UsersReceivedMessage`. */
  usersReceivedMessages: UsersReceivedMessagesConnection;
};


export type MessageUsersReceivedMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersReceivedMessageCondition>;
  filter?: InputMaybe<UsersReceivedMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersReceivedMessagesOrderBy>>;
};

/** A condition to be used against `Message` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MessageCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `message` field. */
  message?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `postId` field. */
  postId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `roomId` field. */
  roomId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Message` object types. All fields are combined with a logical ‘and.’ */
export type MessageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MessageFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `message` field. */
  message?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MessageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MessageFilter>>;
  /** Filter by the object’s `postId` field. */
  postId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `roomId` field. */
  roomId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Message` */
export type MessageInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['Int']>;
  roomId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** Represents an update to a `Message`. Fields that are set will be updated. */
export type MessagePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['Int']>;
  roomId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Message` values. */
export type MessagesConnection = {
  __typename?: 'MessagesConnection';
  /** A list of edges which contains the `Message` and cursor to aid in pagination. */
  edges: Array<MessagesEdge>;
  /** A list of `Message` objects. */
  nodes: Array<Message>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Message` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Message` edge in the connection. */
export type MessagesEdge = {
  __typename?: 'MessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Message` at the end of the edge. */
  node: Message;
};

/** Methods to use when ordering `Message`. */
export enum MessagesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MessageAsc = 'MESSAGE_ASC',
  MessageDesc = 'MESSAGE_DESC',
  Natural = 'NATURAL',
  PostIdAsc = 'POST_ID_ASC',
  PostIdDesc = 'POST_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoomIdAsc = 'ROOM_ID_ASC',
  RoomIdDesc = 'ROOM_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Music = Node & {
  __typename?: 'Music';
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** Reads and enables pagination through a set of `MusicGenre`. */
  musicGenres: MusicGenresConnection;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectId?: Maybe<Scalars['String']>;
  photo: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  url: Scalars['String'];
};


export type MusicMusicGenresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MusicGenreCondition>;
  filter?: InputMaybe<MusicGenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MusicGenresOrderBy>>;
};

/** A condition to be used against `Music` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MusicCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `photo` field. */
  photo?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `Music` values. */
export type MusicConnection = {
  __typename?: 'MusicConnection';
  /** A list of edges which contains the `Music` and cursor to aid in pagination. */
  edges: Array<MusicEdge>;
  /** A list of `Music` objects. */
  nodes: Array<Music>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Music` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Music` edge in the connection. */
export type MusicEdge = {
  __typename?: 'MusicEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Music` at the end of the edge. */
  node: Music;
};

/** A filter to be used against `Music` object types. All fields are combined with a logical ‘and.’ */
export type MusicFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MusicFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MusicFilter>;
  /** Filter by the object’s `objectId` field. */
  objectId?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MusicFilter>>;
  /** Filter by the object’s `photo` field. */
  photo?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
};

export type MusicGenre = Node & {
  __typename?: 'MusicGenre';
  createdAt: Scalars['Datetime'];
  /** Reads a single `Genre` that is related to this `MusicGenre`. */
  genre?: Maybe<Genre>;
  genreId: Scalars['Int'];
  id: Scalars['Int'];
  /** Reads a single `Music` that is related to this `MusicGenre`. */
  music?: Maybe<Music>;
  musicId: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `MusicGenre` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MusicGenreCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `genreId` field. */
  genreId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `musicId` field. */
  musicId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `MusicGenre` object types. All fields are combined with a logical ‘and.’ */
export type MusicGenreFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MusicGenreFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `genreId` field. */
  genreId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `musicId` field. */
  musicId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MusicGenreFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MusicGenreFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `MusicGenre` */
export type MusicGenreInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  genreId: Scalars['Int'];
  musicId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `MusicGenre`. Fields that are set will be updated. */
export type MusicGenrePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  genreId?: InputMaybe<Scalars['Int']>;
  musicId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `MusicGenre` values. */
export type MusicGenresConnection = {
  __typename?: 'MusicGenresConnection';
  /** A list of edges which contains the `MusicGenre` and cursor to aid in pagination. */
  edges: Array<MusicGenresEdge>;
  /** A list of `MusicGenre` objects. */
  nodes: Array<MusicGenre>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MusicGenre` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `MusicGenre` edge in the connection. */
export type MusicGenresEdge = {
  __typename?: 'MusicGenresEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MusicGenre` at the end of the edge. */
  node: MusicGenre;
};

/** Methods to use when ordering `MusicGenre`. */
export enum MusicGenresOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  GenreIdAsc = 'GENRE_ID_ASC',
  GenreIdDesc = 'GENRE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MusicIdAsc = 'MUSIC_ID_ASC',
  MusicIdDesc = 'MUSIC_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** An input for mutations affecting `Music` */
export type MusicInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  objectId?: InputMaybe<Scalars['String']>;
  photo: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  url: Scalars['String'];
};

/** Methods to use when ordering `Music`. */
export enum MusicOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  ObjectIdAsc = 'OBJECT_ID_ASC',
  ObjectIdDesc = 'OBJECT_ID_DESC',
  PhotoAsc = 'PHOTO_ASC',
  PhotoDesc = 'PHOTO_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC'
}

/** Represents an update to a `Music`. Fields that are set will be updated. */
export type MusicPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  objectId?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  url?: InputMaybe<Scalars['String']>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  checkPrivateProfile?: Maybe<CheckPrivateProfilePayload>;
  /** Creates a single `Activity`. */
  createActivity?: Maybe<CreateActivityPayload>;
  /** Creates a single `Ad`. */
  createAd?: Maybe<CreateAdPayload>;
  /** Creates a single `Archive`. */
  createArchive?: Maybe<CreateArchivePayload>;
  /** Creates a single `Audience`. */
  createAudience?: Maybe<CreateAudiencePayload>;
  /** Creates a single `AudienceMeta`. */
  createAudienceMeta?: Maybe<CreateAudienceMetaPayload>;
  /** Creates a single `Beast`. */
  createBeast?: Maybe<CreateBeastPayload>;
  /** Creates a single `BlockedUser`. */
  createBlockedUser?: Maybe<CreateBlockedUserPayload>;
  /** Creates a single `Budget`. */
  createBudget?: Maybe<CreateBudgetPayload>;
  /** Creates a single `Campaign`. */
  createCampaign?: Maybe<CreateCampaignPayload>;
  /** Creates a single `CampaignsAction`. */
  createCampaignsAction?: Maybe<CreateCampaignsActionPayload>;
  /** Creates a single `Challenge`. */
  createChallenge?: Maybe<CreateChallengePayload>;
  /** Creates a single `ChallengesProof`. */
  createChallengesProof?: Maybe<CreateChallengesProofPayload>;
  /** Creates a single `ChallengesWitness`. */
  createChallengesWitness?: Maybe<CreateChallengesWitnessPayload>;
  /** Creates a single `Cloud`. */
  createCloud?: Maybe<CreateCloudPayload>;
  /** Creates a single `FavoriteGame`. */
  createFavoriteGame?: Maybe<CreateFavoriteGamePayload>;
  /** Creates a single `Fee`. */
  createFee?: Maybe<CreateFeePayload>;
  /** Creates a single `Feedback`. */
  createFeedback?: Maybe<CreateFeedbackPayload>;
  /** Creates a single `Following`. */
  createFollowing?: Maybe<CreateFollowingPayload>;
  /** Creates a single `Funny`. */
  createFunny?: Maybe<CreateFunnyPayload>;
  /** Creates a single `Game`. */
  createGame?: Maybe<CreateGamePayload>;
  /** Creates a single `Gender`. */
  createGender?: Maybe<CreateGenderPayload>;
  /** Creates a single `Genre`. */
  createGenre?: Maybe<CreateGenrePayload>;
  /** Creates a single `Like`. */
  createLike?: Maybe<CreateLikePayload>;
  /** Creates a single `Media`. */
  createMedia?: Maybe<CreateMediaPayload>;
  /** Creates a single `Message`. */
  createMessage?: Maybe<CreateMessagePayload>;
  /** Creates a single `Music`. */
  createMusic?: Maybe<CreateMusicPayload>;
  /** Creates a single `MusicGenre`. */
  createMusicGenre?: Maybe<CreateMusicGenrePayload>;
  /** Creates a single `Noob`. */
  createNoob?: Maybe<CreateNoobPayload>;
  /** Creates a single `NotificationLog`. */
  createNotificationLog?: Maybe<CreateNotificationLogPayload>;
  /** Creates a single `OzSubscription`. */
  createOzSubscription?: Maybe<CreateOzSubscriptionPayload>;
  /** Creates a single `PaymentMethod`. */
  createPaymentMethod?: Maybe<CreatePaymentMethodPayload>;
  /** Creates a single `Post`. */
  createPost?: Maybe<CreatePostPayload>;
  /** Creates a single `PostsReport`. */
  createPostsReport?: Maybe<CreatePostsReportPayload>;
  /** Creates a single `PostsView`. */
  createPostsView?: Maybe<CreatePostsViewPayload>;
  /** Creates a single `PrivateUser`. */
  createPrivateUser?: Maybe<CreatePrivateUserPayload>;
  /** Creates a single `ReportMessage`. */
  createReportMessage?: Maybe<CreateReportMessagePayload>;
  /** Creates a single `Role`. */
  createRole?: Maybe<CreateRolePayload>;
  /** Creates a single `Room`. */
  createRoom?: Maybe<CreateRoomPayload>;
  /** Creates a single `Sticker`. */
  createSticker?: Maybe<CreateStickerPayload>;
  /** Creates a single `StickersTag`. */
  createStickersTag?: Maybe<CreateStickersTagPayload>;
  /** Creates a single `Tag`. */
  createTag?: Maybe<CreateTagPayload>;
  /** Creates a single `Team`. */
  createTeam?: Maybe<CreateTeamPayload>;
  /** Creates a single `TeamsMember`. */
  createTeamsMember?: Maybe<CreateTeamsMemberPayload>;
  /** Creates a single `Transaction`. */
  createTransaction?: Maybe<CreateTransactionPayload>;
  /** Creates a single `User`. */
  createUser?: Maybe<CreateUserPayload>;
  /** Creates a single `UserAccess`. */
  createUserAccess?: Maybe<CreateUserAccessPayload>;
  /** Creates a single `UserEmail`. */
  createUserEmail?: Maybe<CreateUserEmailPayload>;
  /** Creates a single `UserRole`. */
  createUserRole?: Maybe<CreateUserRolePayload>;
  /** Creates a single `UsernamesCheck`. */
  createUsernamesCheck?: Maybe<CreateUsernamesCheckPayload>;
  /** Creates a single `UsersAvailableRoom`. */
  createUsersAvailableRoom?: Maybe<CreateUsersAvailableRoomPayload>;
  /** Creates a single `UsersReceivedMessage`. */
  createUsersReceivedMessage?: Maybe<CreateUsersReceivedMessagePayload>;
  /** Creates a single `Witness`. */
  createWitness?: Maybe<CreateWitnessPayload>;
  /** Deletes a single `Activity` using a unique key. */
  deleteActivity?: Maybe<DeleteActivityPayload>;
  /** Deletes a single `Activity` using its globally unique id. */
  deleteActivityByNodeId?: Maybe<DeleteActivityPayload>;
  /** Deletes a single `Ad` using a unique key. */
  deleteAd?: Maybe<DeleteAdPayload>;
  /** Deletes a single `Ad` using its globally unique id. */
  deleteAdByNodeId?: Maybe<DeleteAdPayload>;
  /** Deletes a single `Archive` using a unique key. */
  deleteArchive?: Maybe<DeleteArchivePayload>;
  /** Deletes a single `Archive` using its globally unique id. */
  deleteArchiveByNodeId?: Maybe<DeleteArchivePayload>;
  /** Deletes a single `Audience` using a unique key. */
  deleteAudience?: Maybe<DeleteAudiencePayload>;
  /** Deletes a single `Audience` using its globally unique id. */
  deleteAudienceByNodeId?: Maybe<DeleteAudiencePayload>;
  /** Deletes a single `AudienceMeta` using a unique key. */
  deleteAudienceMeta?: Maybe<DeleteAudienceMetaPayload>;
  /** Deletes a single `AudienceMeta` using its globally unique id. */
  deleteAudienceMetaByNodeId?: Maybe<DeleteAudienceMetaPayload>;
  /** Deletes a single `Beast` using a unique key. */
  deleteBeast?: Maybe<DeleteBeastPayload>;
  /** Deletes a single `Beast` using its globally unique id. */
  deleteBeastByNodeId?: Maybe<DeleteBeastPayload>;
  /** Deletes a single `BlockedUser` using a unique key. */
  deleteBlockedUser?: Maybe<DeleteBlockedUserPayload>;
  /** Deletes a single `BlockedUser` using its globally unique id. */
  deleteBlockedUserByNodeId?: Maybe<DeleteBlockedUserPayload>;
  /** Deletes a single `Budget` using a unique key. */
  deleteBudget?: Maybe<DeleteBudgetPayload>;
  /** Deletes a single `Budget` using its globally unique id. */
  deleteBudgetByNodeId?: Maybe<DeleteBudgetPayload>;
  /** Deletes a single `Campaign` using a unique key. */
  deleteCampaign?: Maybe<DeleteCampaignPayload>;
  /** Deletes a single `Campaign` using its globally unique id. */
  deleteCampaignByNodeId?: Maybe<DeleteCampaignPayload>;
  /** Deletes a single `CampaignsAction` using a unique key. */
  deleteCampaignsAction?: Maybe<DeleteCampaignsActionPayload>;
  /** Deletes a single `CampaignsAction` using its globally unique id. */
  deleteCampaignsActionByNodeId?: Maybe<DeleteCampaignsActionPayload>;
  /** Deletes a single `Challenge` using a unique key. */
  deleteChallenge?: Maybe<DeleteChallengePayload>;
  /** Deletes a single `Challenge` using its globally unique id. */
  deleteChallengeByNodeId?: Maybe<DeleteChallengePayload>;
  /** Deletes a single `ChallengesProof` using a unique key. */
  deleteChallengesProof?: Maybe<DeleteChallengesProofPayload>;
  /** Deletes a single `ChallengesProof` using its globally unique id. */
  deleteChallengesProofByNodeId?: Maybe<DeleteChallengesProofPayload>;
  /** Deletes a single `ChallengesWitness` using a unique key. */
  deleteChallengesWitness?: Maybe<DeleteChallengesWitnessPayload>;
  /** Deletes a single `ChallengesWitness` using its globally unique id. */
  deleteChallengesWitnessByNodeId?: Maybe<DeleteChallengesWitnessPayload>;
  /** Deletes a single `Cloud` using a unique key. */
  deleteCloud?: Maybe<DeleteCloudPayload>;
  /** Deletes a single `Cloud` using its globally unique id. */
  deleteCloudByNodeId?: Maybe<DeleteCloudPayload>;
  /** Deletes a single `FavoriteGame` using a unique key. */
  deleteFavoriteGame?: Maybe<DeleteFavoriteGamePayload>;
  /** Deletes a single `FavoriteGame` using its globally unique id. */
  deleteFavoriteGameByNodeId?: Maybe<DeleteFavoriteGamePayload>;
  /** Deletes a single `Fee` using a unique key. */
  deleteFee?: Maybe<DeleteFeePayload>;
  /** Deletes a single `Fee` using its globally unique id. */
  deleteFeeByNodeId?: Maybe<DeleteFeePayload>;
  /** Deletes a single `Feedback` using a unique key. */
  deleteFeedback?: Maybe<DeleteFeedbackPayload>;
  /** Deletes a single `Feedback` using its globally unique id. */
  deleteFeedbackByNodeId?: Maybe<DeleteFeedbackPayload>;
  /** Deletes a single `Following` using a unique key. */
  deleteFollowing?: Maybe<DeleteFollowingPayload>;
  /** Deletes a single `Following` using its globally unique id. */
  deleteFollowingByNodeId?: Maybe<DeleteFollowingPayload>;
  /** Deletes a single `Funny` using a unique key. */
  deleteFunny?: Maybe<DeleteFunnyPayload>;
  /** Deletes a single `Funny` using its globally unique id. */
  deleteFunnyByNodeId?: Maybe<DeleteFunnyPayload>;
  /** Deletes a single `Game` using a unique key. */
  deleteGame?: Maybe<DeleteGamePayload>;
  /** Deletes a single `Game` using its globally unique id. */
  deleteGameByNodeId?: Maybe<DeleteGamePayload>;
  /** Deletes a single `Gender` using a unique key. */
  deleteGender?: Maybe<DeleteGenderPayload>;
  /** Deletes a single `Gender` using its globally unique id. */
  deleteGenderByNodeId?: Maybe<DeleteGenderPayload>;
  /** Deletes a single `Genre` using a unique key. */
  deleteGenre?: Maybe<DeleteGenrePayload>;
  /** Deletes a single `Genre` using its globally unique id. */
  deleteGenreByNodeId?: Maybe<DeleteGenrePayload>;
  /** Deletes a single `Like` using a unique key. */
  deleteLike?: Maybe<DeleteLikePayload>;
  /** Deletes a single `Like` using its globally unique id. */
  deleteLikeByNodeId?: Maybe<DeleteLikePayload>;
  /** Deletes a single `Media` using a unique key. */
  deleteMedia?: Maybe<DeleteMediaPayload>;
  /** Deletes a single `Media` using its globally unique id. */
  deleteMediaByNodeId?: Maybe<DeleteMediaPayload>;
  /** Deletes a single `Message` using a unique key. */
  deleteMessage?: Maybe<DeleteMessagePayload>;
  /** Deletes a single `Message` using its globally unique id. */
  deleteMessageByNodeId?: Maybe<DeleteMessagePayload>;
  /** Deletes a single `Music` using a unique key. */
  deleteMusic?: Maybe<DeleteMusicPayload>;
  /** Deletes a single `Music` using its globally unique id. */
  deleteMusicByNodeId?: Maybe<DeleteMusicPayload>;
  /** Deletes a single `MusicGenre` using a unique key. */
  deleteMusicGenre?: Maybe<DeleteMusicGenrePayload>;
  /** Deletes a single `MusicGenre` using its globally unique id. */
  deleteMusicGenreByNodeId?: Maybe<DeleteMusicGenrePayload>;
  /** Deletes a single `Noob` using a unique key. */
  deleteNoob?: Maybe<DeleteNoobPayload>;
  /** Deletes a single `Noob` using its globally unique id. */
  deleteNoobByNodeId?: Maybe<DeleteNoobPayload>;
  /** Deletes a single `NotificationLog` using a unique key. */
  deleteNotificationLog?: Maybe<DeleteNotificationLogPayload>;
  /** Deletes a single `NotificationLog` using its globally unique id. */
  deleteNotificationLogByNodeId?: Maybe<DeleteNotificationLogPayload>;
  /** Deletes a single `OzSubscription` using a unique key. */
  deleteOzSubscription?: Maybe<DeleteOzSubscriptionPayload>;
  /** Deletes a single `OzSubscription` using its globally unique id. */
  deleteOzSubscriptionByNodeId?: Maybe<DeleteOzSubscriptionPayload>;
  /** Deletes a single `PaymentMethod` using a unique key. */
  deletePaymentMethod?: Maybe<DeletePaymentMethodPayload>;
  /** Deletes a single `PaymentMethod` using its globally unique id. */
  deletePaymentMethodByNodeId?: Maybe<DeletePaymentMethodPayload>;
  /** Deletes a single `Post` using a unique key. */
  deletePost?: Maybe<DeletePostPayload>;
  /** Deletes a single `Post` using its globally unique id. */
  deletePostByNodeId?: Maybe<DeletePostPayload>;
  /** Deletes a single `PostsReport` using a unique key. */
  deletePostsReport?: Maybe<DeletePostsReportPayload>;
  /** Deletes a single `PostsReport` using its globally unique id. */
  deletePostsReportByNodeId?: Maybe<DeletePostsReportPayload>;
  /** Deletes a single `PostsView` using a unique key. */
  deletePostsView?: Maybe<DeletePostsViewPayload>;
  /** Deletes a single `PostsView` using its globally unique id. */
  deletePostsViewByNodeId?: Maybe<DeletePostsViewPayload>;
  /** Deletes a single `PostsView` using a unique key. */
  deletePostsViewByPostIdAndUserId?: Maybe<DeletePostsViewPayload>;
  /** Deletes a single `PrivateUser` using a unique key. */
  deletePrivateUser?: Maybe<DeletePrivateUserPayload>;
  /** Deletes a single `PrivateUser` using its globally unique id. */
  deletePrivateUserByNodeId?: Maybe<DeletePrivateUserPayload>;
  /** Deletes a single `ReportMessage` using a unique key. */
  deleteReportMessage?: Maybe<DeleteReportMessagePayload>;
  /** Deletes a single `ReportMessage` using its globally unique id. */
  deleteReportMessageByNodeId?: Maybe<DeleteReportMessagePayload>;
  /** Deletes a single `Role` using a unique key. */
  deleteRole?: Maybe<DeleteRolePayload>;
  /** Deletes a single `Role` using its globally unique id. */
  deleteRoleByNodeId?: Maybe<DeleteRolePayload>;
  /** Deletes a single `Room` using a unique key. */
  deleteRoom?: Maybe<DeleteRoomPayload>;
  /** Deletes a single `Room` using its globally unique id. */
  deleteRoomByNodeId?: Maybe<DeleteRoomPayload>;
  /** Deletes a single `Sticker` using a unique key. */
  deleteSticker?: Maybe<DeleteStickerPayload>;
  /** Deletes a single `Sticker` using its globally unique id. */
  deleteStickerByNodeId?: Maybe<DeleteStickerPayload>;
  /** Deletes a single `StickersTag` using a unique key. */
  deleteStickersTag?: Maybe<DeleteStickersTagPayload>;
  /** Deletes a single `StickersTag` using its globally unique id. */
  deleteStickersTagByNodeId?: Maybe<DeleteStickersTagPayload>;
  /** Deletes a single `Tag` using a unique key. */
  deleteTag?: Maybe<DeleteTagPayload>;
  /** Deletes a single `Tag` using its globally unique id. */
  deleteTagByNodeId?: Maybe<DeleteTagPayload>;
  /** Deletes a single `Team` using a unique key. */
  deleteTeam?: Maybe<DeleteTeamPayload>;
  /** Deletes a single `Team` using a unique key. */
  deleteTeamByName?: Maybe<DeleteTeamPayload>;
  /** Deletes a single `Team` using its globally unique id. */
  deleteTeamByNodeId?: Maybe<DeleteTeamPayload>;
  /** Deletes a single `TeamsMember` using a unique key. */
  deleteTeamsMember?: Maybe<DeleteTeamsMemberPayload>;
  /** Deletes a single `TeamsMember` using its globally unique id. */
  deleteTeamsMemberByNodeId?: Maybe<DeleteTeamsMemberPayload>;
  /** Deletes a single `Transaction` using a unique key. */
  deleteTransaction?: Maybe<DeleteTransactionPayload>;
  /** Deletes a single `Transaction` using its globally unique id. */
  deleteTransactionByNodeId?: Maybe<DeleteTransactionPayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Deletes a single `UserAccess` using a unique key. */
  deleteUserAccess?: Maybe<DeleteUserAccessPayload>;
  /** Deletes a single `UserAccess` using its globally unique id. */
  deleteUserAccessByNodeId?: Maybe<DeleteUserAccessPayload>;
  /** Deletes a single `User` using its globally unique id. */
  deleteUserByNodeId?: Maybe<DeleteUserPayload>;
  /** Deletes a single `UserEmail` using a unique key. */
  deleteUserEmail?: Maybe<DeleteUserEmailPayload>;
  /** Deletes a single `UserEmail` using its globally unique id. */
  deleteUserEmailByNodeId?: Maybe<DeleteUserEmailPayload>;
  /** Deletes a single `UserEmail` using a unique key. */
  deleteUserEmailByUserId?: Maybe<DeleteUserEmailPayload>;
  /** Deletes a single `UserRole` using a unique key. */
  deleteUserRole?: Maybe<DeleteUserRolePayload>;
  /** Deletes a single `UserRole` using its globally unique id. */
  deleteUserRoleByNodeId?: Maybe<DeleteUserRolePayload>;
  /** Deletes a single `UsernamesCheck` using a unique key. */
  deleteUsernamesCheck?: Maybe<DeleteUsernamesCheckPayload>;
  /** Deletes a single `UsernamesCheck` using its globally unique id. */
  deleteUsernamesCheckByNodeId?: Maybe<DeleteUsernamesCheckPayload>;
  /** Deletes a single `UsersAvailableRoom` using a unique key. */
  deleteUsersAvailableRoom?: Maybe<DeleteUsersAvailableRoomPayload>;
  /** Deletes a single `UsersAvailableRoom` using its globally unique id. */
  deleteUsersAvailableRoomByNodeId?: Maybe<DeleteUsersAvailableRoomPayload>;
  /** Deletes a single `UsersReceivedMessage` using a unique key. */
  deleteUsersReceivedMessage?: Maybe<DeleteUsersReceivedMessagePayload>;
  /** Deletes a single `UsersReceivedMessage` using its globally unique id. */
  deleteUsersReceivedMessageByNodeId?: Maybe<DeleteUsersReceivedMessagePayload>;
  /** Deletes a single `Witness` using a unique key. */
  deleteWitness?: Maybe<DeleteWitnessPayload>;
  /** Deletes a single `Witness` using its globally unique id. */
  deleteWitnessByNodeId?: Maybe<DeleteWitnessPayload>;
  getAvailableRoomsUsers?: Maybe<GetAvailableRoomsUsersPayload>;
  /** Updates a single `Activity` using a unique key and a patch. */
  updateActivity?: Maybe<UpdateActivityPayload>;
  /** Updates a single `Activity` using its globally unique id and a patch. */
  updateActivityByNodeId?: Maybe<UpdateActivityPayload>;
  /** Updates a single `Ad` using a unique key and a patch. */
  updateAd?: Maybe<UpdateAdPayload>;
  /** Updates a single `Ad` using its globally unique id and a patch. */
  updateAdByNodeId?: Maybe<UpdateAdPayload>;
  /** Updates a single `Archive` using a unique key and a patch. */
  updateArchive?: Maybe<UpdateArchivePayload>;
  /** Updates a single `Archive` using its globally unique id and a patch. */
  updateArchiveByNodeId?: Maybe<UpdateArchivePayload>;
  /** Updates a single `Audience` using a unique key and a patch. */
  updateAudience?: Maybe<UpdateAudiencePayload>;
  /** Updates a single `Audience` using its globally unique id and a patch. */
  updateAudienceByNodeId?: Maybe<UpdateAudiencePayload>;
  /** Updates a single `AudienceMeta` using a unique key and a patch. */
  updateAudienceMeta?: Maybe<UpdateAudienceMetaPayload>;
  /** Updates a single `AudienceMeta` using its globally unique id and a patch. */
  updateAudienceMetaByNodeId?: Maybe<UpdateAudienceMetaPayload>;
  /** Updates a single `Beast` using a unique key and a patch. */
  updateBeast?: Maybe<UpdateBeastPayload>;
  /** Updates a single `Beast` using its globally unique id and a patch. */
  updateBeastByNodeId?: Maybe<UpdateBeastPayload>;
  /** Updates a single `BlockedUser` using a unique key and a patch. */
  updateBlockedUser?: Maybe<UpdateBlockedUserPayload>;
  /** Updates a single `BlockedUser` using its globally unique id and a patch. */
  updateBlockedUserByNodeId?: Maybe<UpdateBlockedUserPayload>;
  /** Updates a single `Budget` using a unique key and a patch. */
  updateBudget?: Maybe<UpdateBudgetPayload>;
  /** Updates a single `Budget` using its globally unique id and a patch. */
  updateBudgetByNodeId?: Maybe<UpdateBudgetPayload>;
  /** Updates a single `Campaign` using a unique key and a patch. */
  updateCampaign?: Maybe<UpdateCampaignPayload>;
  /** Updates a single `Campaign` using its globally unique id and a patch. */
  updateCampaignByNodeId?: Maybe<UpdateCampaignPayload>;
  /** Updates a single `CampaignsAction` using a unique key and a patch. */
  updateCampaignsAction?: Maybe<UpdateCampaignsActionPayload>;
  /** Updates a single `CampaignsAction` using its globally unique id and a patch. */
  updateCampaignsActionByNodeId?: Maybe<UpdateCampaignsActionPayload>;
  /** Updates a single `Challenge` using a unique key and a patch. */
  updateChallenge?: Maybe<UpdateChallengePayload>;
  /** Updates a single `Challenge` using its globally unique id and a patch. */
  updateChallengeByNodeId?: Maybe<UpdateChallengePayload>;
  /** Updates a single `ChallengesProof` using a unique key and a patch. */
  updateChallengesProof?: Maybe<UpdateChallengesProofPayload>;
  /** Updates a single `ChallengesProof` using its globally unique id and a patch. */
  updateChallengesProofByNodeId?: Maybe<UpdateChallengesProofPayload>;
  /** Updates a single `ChallengesWitness` using a unique key and a patch. */
  updateChallengesWitness?: Maybe<UpdateChallengesWitnessPayload>;
  /** Updates a single `ChallengesWitness` using its globally unique id and a patch. */
  updateChallengesWitnessByNodeId?: Maybe<UpdateChallengesWitnessPayload>;
  /** Updates a single `Cloud` using a unique key and a patch. */
  updateCloud?: Maybe<UpdateCloudPayload>;
  /** Updates a single `Cloud` using its globally unique id and a patch. */
  updateCloudByNodeId?: Maybe<UpdateCloudPayload>;
  /** Updates a single `FavoriteGame` using a unique key and a patch. */
  updateFavoriteGame?: Maybe<UpdateFavoriteGamePayload>;
  /** Updates a single `FavoriteGame` using its globally unique id and a patch. */
  updateFavoriteGameByNodeId?: Maybe<UpdateFavoriteGamePayload>;
  /** Updates a single `Fee` using a unique key and a patch. */
  updateFee?: Maybe<UpdateFeePayload>;
  /** Updates a single `Fee` using its globally unique id and a patch. */
  updateFeeByNodeId?: Maybe<UpdateFeePayload>;
  /** Updates a single `Feedback` using a unique key and a patch. */
  updateFeedback?: Maybe<UpdateFeedbackPayload>;
  /** Updates a single `Feedback` using its globally unique id and a patch. */
  updateFeedbackByNodeId?: Maybe<UpdateFeedbackPayload>;
  /** Updates a single `Following` using a unique key and a patch. */
  updateFollowing?: Maybe<UpdateFollowingPayload>;
  /** Updates a single `Following` using its globally unique id and a patch. */
  updateFollowingByNodeId?: Maybe<UpdateFollowingPayload>;
  /** Updates a single `Funny` using a unique key and a patch. */
  updateFunny?: Maybe<UpdateFunnyPayload>;
  /** Updates a single `Funny` using its globally unique id and a patch. */
  updateFunnyByNodeId?: Maybe<UpdateFunnyPayload>;
  /** Updates a single `Game` using a unique key and a patch. */
  updateGame?: Maybe<UpdateGamePayload>;
  /** Updates a single `Game` using its globally unique id and a patch. */
  updateGameByNodeId?: Maybe<UpdateGamePayload>;
  /** Updates a single `Gender` using a unique key and a patch. */
  updateGender?: Maybe<UpdateGenderPayload>;
  /** Updates a single `Gender` using its globally unique id and a patch. */
  updateGenderByNodeId?: Maybe<UpdateGenderPayload>;
  /** Updates a single `Genre` using a unique key and a patch. */
  updateGenre?: Maybe<UpdateGenrePayload>;
  /** Updates a single `Genre` using its globally unique id and a patch. */
  updateGenreByNodeId?: Maybe<UpdateGenrePayload>;
  /** Updates a single `Like` using a unique key and a patch. */
  updateLike?: Maybe<UpdateLikePayload>;
  /** Updates a single `Like` using its globally unique id and a patch. */
  updateLikeByNodeId?: Maybe<UpdateLikePayload>;
  /** Updates a single `Media` using a unique key and a patch. */
  updateMedia?: Maybe<UpdateMediaPayload>;
  /** Updates a single `Media` using its globally unique id and a patch. */
  updateMediaByNodeId?: Maybe<UpdateMediaPayload>;
  /** Updates a single `Message` using a unique key and a patch. */
  updateMessage?: Maybe<UpdateMessagePayload>;
  /** Updates a single `Message` using its globally unique id and a patch. */
  updateMessageByNodeId?: Maybe<UpdateMessagePayload>;
  /** Updates a single `Music` using a unique key and a patch. */
  updateMusic?: Maybe<UpdateMusicPayload>;
  /** Updates a single `Music` using its globally unique id and a patch. */
  updateMusicByNodeId?: Maybe<UpdateMusicPayload>;
  /** Updates a single `MusicGenre` using a unique key and a patch. */
  updateMusicGenre?: Maybe<UpdateMusicGenrePayload>;
  /** Updates a single `MusicGenre` using its globally unique id and a patch. */
  updateMusicGenreByNodeId?: Maybe<UpdateMusicGenrePayload>;
  /** Updates a single `Noob` using a unique key and a patch. */
  updateNoob?: Maybe<UpdateNoobPayload>;
  /** Updates a single `Noob` using its globally unique id and a patch. */
  updateNoobByNodeId?: Maybe<UpdateNoobPayload>;
  /** Updates a single `NotificationLog` using a unique key and a patch. */
  updateNotificationLog?: Maybe<UpdateNotificationLogPayload>;
  /** Updates a single `NotificationLog` using its globally unique id and a patch. */
  updateNotificationLogByNodeId?: Maybe<UpdateNotificationLogPayload>;
  /** Updates a single `OzSubscription` using a unique key and a patch. */
  updateOzSubscription?: Maybe<UpdateOzSubscriptionPayload>;
  /** Updates a single `OzSubscription` using its globally unique id and a patch. */
  updateOzSubscriptionByNodeId?: Maybe<UpdateOzSubscriptionPayload>;
  /** Updates a single `PaymentMethod` using a unique key and a patch. */
  updatePaymentMethod?: Maybe<UpdatePaymentMethodPayload>;
  /** Updates a single `PaymentMethod` using its globally unique id and a patch. */
  updatePaymentMethodByNodeId?: Maybe<UpdatePaymentMethodPayload>;
  /** Updates a single `Post` using a unique key and a patch. */
  updatePost?: Maybe<UpdatePostPayload>;
  /** Updates a single `Post` using its globally unique id and a patch. */
  updatePostByNodeId?: Maybe<UpdatePostPayload>;
  /** Updates a single `PostsReport` using a unique key and a patch. */
  updatePostsReport?: Maybe<UpdatePostsReportPayload>;
  /** Updates a single `PostsReport` using its globally unique id and a patch. */
  updatePostsReportByNodeId?: Maybe<UpdatePostsReportPayload>;
  /** Updates a single `PostsView` using a unique key and a patch. */
  updatePostsView?: Maybe<UpdatePostsViewPayload>;
  /** Updates a single `PostsView` using its globally unique id and a patch. */
  updatePostsViewByNodeId?: Maybe<UpdatePostsViewPayload>;
  /** Updates a single `PostsView` using a unique key and a patch. */
  updatePostsViewByPostIdAndUserId?: Maybe<UpdatePostsViewPayload>;
  /** Updates a single `PrivateUser` using a unique key and a patch. */
  updatePrivateUser?: Maybe<UpdatePrivateUserPayload>;
  /** Updates a single `PrivateUser` using its globally unique id and a patch. */
  updatePrivateUserByNodeId?: Maybe<UpdatePrivateUserPayload>;
  /** Updates a single `ReportMessage` using a unique key and a patch. */
  updateReportMessage?: Maybe<UpdateReportMessagePayload>;
  /** Updates a single `ReportMessage` using its globally unique id and a patch. */
  updateReportMessageByNodeId?: Maybe<UpdateReportMessagePayload>;
  updateReportedPost?: Maybe<UpdateReportedPostPayload>;
  /** Updates a single `Role` using a unique key and a patch. */
  updateRole?: Maybe<UpdateRolePayload>;
  /** Updates a single `Role` using its globally unique id and a patch. */
  updateRoleByNodeId?: Maybe<UpdateRolePayload>;
  /** Updates a single `Room` using a unique key and a patch. */
  updateRoom?: Maybe<UpdateRoomPayload>;
  /** Updates a single `Room` using its globally unique id and a patch. */
  updateRoomByNodeId?: Maybe<UpdateRoomPayload>;
  /** Updates a single `Sticker` using a unique key and a patch. */
  updateSticker?: Maybe<UpdateStickerPayload>;
  /** Updates a single `Sticker` using its globally unique id and a patch. */
  updateStickerByNodeId?: Maybe<UpdateStickerPayload>;
  /** Updates a single `StickersTag` using a unique key and a patch. */
  updateStickersTag?: Maybe<UpdateStickersTagPayload>;
  /** Updates a single `StickersTag` using its globally unique id and a patch. */
  updateStickersTagByNodeId?: Maybe<UpdateStickersTagPayload>;
  /** Updates a single `Tag` using a unique key and a patch. */
  updateTag?: Maybe<UpdateTagPayload>;
  /** Updates a single `Tag` using its globally unique id and a patch. */
  updateTagByNodeId?: Maybe<UpdateTagPayload>;
  /** Updates a single `Team` using a unique key and a patch. */
  updateTeam?: Maybe<UpdateTeamPayload>;
  /** Updates a single `Team` using a unique key and a patch. */
  updateTeamByName?: Maybe<UpdateTeamPayload>;
  /** Updates a single `Team` using its globally unique id and a patch. */
  updateTeamByNodeId?: Maybe<UpdateTeamPayload>;
  /** Updates a single `TeamsMember` using a unique key and a patch. */
  updateTeamsMember?: Maybe<UpdateTeamsMemberPayload>;
  /** Updates a single `TeamsMember` using its globally unique id and a patch. */
  updateTeamsMemberByNodeId?: Maybe<UpdateTeamsMemberPayload>;
  /** Updates a single `Transaction` using a unique key and a patch. */
  updateTransaction?: Maybe<UpdateTransactionPayload>;
  /** Updates a single `Transaction` using its globally unique id and a patch. */
  updateTransactionByNodeId?: Maybe<UpdateTransactionPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a single `UserAccess` using a unique key and a patch. */
  updateUserAccess?: Maybe<UpdateUserAccessPayload>;
  /** Updates a single `UserAccess` using its globally unique id and a patch. */
  updateUserAccessByNodeId?: Maybe<UpdateUserAccessPayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUserByNodeId?: Maybe<UpdateUserPayload>;
  /** Updates a single `UserEmail` using a unique key and a patch. */
  updateUserEmail?: Maybe<UpdateUserEmailPayload>;
  /** Updates a single `UserEmail` using its globally unique id and a patch. */
  updateUserEmailByNodeId?: Maybe<UpdateUserEmailPayload>;
  /** Updates a single `UserEmail` using a unique key and a patch. */
  updateUserEmailByUserId?: Maybe<UpdateUserEmailPayload>;
  /** Updates a single `UserRole` using a unique key and a patch. */
  updateUserRole?: Maybe<UpdateUserRolePayload>;
  /** Updates a single `UserRole` using its globally unique id and a patch. */
  updateUserRoleByNodeId?: Maybe<UpdateUserRolePayload>;
  /** Updates a single `UsernamesCheck` using a unique key and a patch. */
  updateUsernamesCheck?: Maybe<UpdateUsernamesCheckPayload>;
  /** Updates a single `UsernamesCheck` using its globally unique id and a patch. */
  updateUsernamesCheckByNodeId?: Maybe<UpdateUsernamesCheckPayload>;
  /** Updates a single `UsersAvailableRoom` using a unique key and a patch. */
  updateUsersAvailableRoom?: Maybe<UpdateUsersAvailableRoomPayload>;
  /** Updates a single `UsersAvailableRoom` using its globally unique id and a patch. */
  updateUsersAvailableRoomByNodeId?: Maybe<UpdateUsersAvailableRoomPayload>;
  /** Updates a single `UsersReceivedMessage` using a unique key and a patch. */
  updateUsersReceivedMessage?: Maybe<UpdateUsersReceivedMessagePayload>;
  /** Updates a single `UsersReceivedMessage` using its globally unique id and a patch. */
  updateUsersReceivedMessageByNodeId?: Maybe<UpdateUsersReceivedMessagePayload>;
  /** Updates a single `Witness` using a unique key and a patch. */
  updateWitness?: Maybe<UpdateWitnessPayload>;
  /** Updates a single `Witness` using its globally unique id and a patch. */
  updateWitnessByNodeId?: Maybe<UpdateWitnessPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckPrivateProfileArgs = {
  input: CheckPrivateProfileInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateActivityArgs = {
  input: CreateActivityInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAdArgs = {
  input: CreateAdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateArchiveArgs = {
  input: CreateArchiveInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAudienceArgs = {
  input: CreateAudienceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAudienceMetaArgs = {
  input: CreateAudienceMetaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBeastArgs = {
  input: CreateBeastInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBlockedUserArgs = {
  input: CreateBlockedUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBudgetArgs = {
  input: CreateBudgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCampaignsActionArgs = {
  input: CreateCampaignsActionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateChallengeArgs = {
  input: CreateChallengeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateChallengesProofArgs = {
  input: CreateChallengesProofInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateChallengesWitnessArgs = {
  input: CreateChallengesWitnessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCloudArgs = {
  input: CreateCloudInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFavoriteGameArgs = {
  input: CreateFavoriteGameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFeeArgs = {
  input: CreateFeeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFollowingArgs = {
  input: CreateFollowingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFunnyArgs = {
  input: CreateFunnyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGameArgs = {
  input: CreateGameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGenderArgs = {
  input: CreateGenderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGenreArgs = {
  input: CreateGenreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLikeArgs = {
  input: CreateLikeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMediaArgs = {
  input: CreateMediaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMusicArgs = {
  input: CreateMusicInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMusicGenreArgs = {
  input: CreateMusicGenreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNoobArgs = {
  input: CreateNoobInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateNotificationLogArgs = {
  input: CreateNotificationLogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOzSubscriptionArgs = {
  input: CreateOzSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePostArgs = {
  input: CreatePostInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePostsReportArgs = {
  input: CreatePostsReportInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePostsViewArgs = {
  input: CreatePostsViewInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePrivateUserArgs = {
  input: CreatePrivateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReportMessageArgs = {
  input: CreateReportMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRoomArgs = {
  input: CreateRoomInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateStickerArgs = {
  input: CreateStickerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateStickersTagArgs = {
  input: CreateStickersTagInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTeamsMemberArgs = {
  input: CreateTeamsMemberInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTransactionArgs = {
  input: CreateTransactionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserAccessArgs = {
  input: CreateUserAccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserEmailArgs = {
  input: CreateUserEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserRoleArgs = {
  input: CreateUserRoleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUsernamesCheckArgs = {
  input: CreateUsernamesCheckInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUsersAvailableRoomArgs = {
  input: CreateUsersAvailableRoomInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUsersReceivedMessageArgs = {
  input: CreateUsersReceivedMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWitnessArgs = {
  input: CreateWitnessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteActivityArgs = {
  input: DeleteActivityInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteActivityByNodeIdArgs = {
  input: DeleteActivityByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAdArgs = {
  input: DeleteAdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAdByNodeIdArgs = {
  input: DeleteAdByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteArchiveArgs = {
  input: DeleteArchiveInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteArchiveByNodeIdArgs = {
  input: DeleteArchiveByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAudienceArgs = {
  input: DeleteAudienceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAudienceByNodeIdArgs = {
  input: DeleteAudienceByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAudienceMetaArgs = {
  input: DeleteAudienceMetaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAudienceMetaByNodeIdArgs = {
  input: DeleteAudienceMetaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBeastArgs = {
  input: DeleteBeastInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBeastByNodeIdArgs = {
  input: DeleteBeastByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBlockedUserArgs = {
  input: DeleteBlockedUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBlockedUserByNodeIdArgs = {
  input: DeleteBlockedUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBudgetArgs = {
  input: DeleteBudgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBudgetByNodeIdArgs = {
  input: DeleteBudgetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCampaignArgs = {
  input: DeleteCampaignInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCampaignByNodeIdArgs = {
  input: DeleteCampaignByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCampaignsActionArgs = {
  input: DeleteCampaignsActionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCampaignsActionByNodeIdArgs = {
  input: DeleteCampaignsActionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChallengeArgs = {
  input: DeleteChallengeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChallengeByNodeIdArgs = {
  input: DeleteChallengeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChallengesProofArgs = {
  input: DeleteChallengesProofInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChallengesProofByNodeIdArgs = {
  input: DeleteChallengesProofByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChallengesWitnessArgs = {
  input: DeleteChallengesWitnessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChallengesWitnessByNodeIdArgs = {
  input: DeleteChallengesWitnessByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCloudArgs = {
  input: DeleteCloudInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCloudByNodeIdArgs = {
  input: DeleteCloudByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFavoriteGameArgs = {
  input: DeleteFavoriteGameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFavoriteGameByNodeIdArgs = {
  input: DeleteFavoriteGameByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeeArgs = {
  input: DeleteFeeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeeByNodeIdArgs = {
  input: DeleteFeeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedbackArgs = {
  input: DeleteFeedbackInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedbackByNodeIdArgs = {
  input: DeleteFeedbackByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFollowingArgs = {
  input: DeleteFollowingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFollowingByNodeIdArgs = {
  input: DeleteFollowingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFunnyArgs = {
  input: DeleteFunnyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFunnyByNodeIdArgs = {
  input: DeleteFunnyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGameArgs = {
  input: DeleteGameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGameByNodeIdArgs = {
  input: DeleteGameByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGenderArgs = {
  input: DeleteGenderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGenderByNodeIdArgs = {
  input: DeleteGenderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGenreArgs = {
  input: DeleteGenreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGenreByNodeIdArgs = {
  input: DeleteGenreByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLikeArgs = {
  input: DeleteLikeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLikeByNodeIdArgs = {
  input: DeleteLikeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMediaArgs = {
  input: DeleteMediaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMediaByNodeIdArgs = {
  input: DeleteMediaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMessageArgs = {
  input: DeleteMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMessageByNodeIdArgs = {
  input: DeleteMessageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMusicArgs = {
  input: DeleteMusicInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMusicByNodeIdArgs = {
  input: DeleteMusicByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMusicGenreArgs = {
  input: DeleteMusicGenreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMusicGenreByNodeIdArgs = {
  input: DeleteMusicGenreByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNoobArgs = {
  input: DeleteNoobInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNoobByNodeIdArgs = {
  input: DeleteNoobByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationLogArgs = {
  input: DeleteNotificationLogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteNotificationLogByNodeIdArgs = {
  input: DeleteNotificationLogByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOzSubscriptionArgs = {
  input: DeleteOzSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOzSubscriptionByNodeIdArgs = {
  input: DeleteOzSubscriptionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePaymentMethodByNodeIdArgs = {
  input: DeletePaymentMethodByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostArgs = {
  input: DeletePostInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostByNodeIdArgs = {
  input: DeletePostByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostsReportArgs = {
  input: DeletePostsReportInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostsReportByNodeIdArgs = {
  input: DeletePostsReportByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostsViewArgs = {
  input: DeletePostsViewInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostsViewByNodeIdArgs = {
  input: DeletePostsViewByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostsViewByPostIdAndUserIdArgs = {
  input: DeletePostsViewByPostIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePrivateUserArgs = {
  input: DeletePrivateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePrivateUserByNodeIdArgs = {
  input: DeletePrivateUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReportMessageArgs = {
  input: DeleteReportMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReportMessageByNodeIdArgs = {
  input: DeleteReportMessageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRoleByNodeIdArgs = {
  input: DeleteRoleByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRoomArgs = {
  input: DeleteRoomInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRoomByNodeIdArgs = {
  input: DeleteRoomByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStickerArgs = {
  input: DeleteStickerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStickerByNodeIdArgs = {
  input: DeleteStickerByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStickersTagArgs = {
  input: DeleteStickersTagInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStickersTagByNodeIdArgs = {
  input: DeleteStickersTagByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTagArgs = {
  input: DeleteTagInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTagByNodeIdArgs = {
  input: DeleteTagByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTeamArgs = {
  input: DeleteTeamInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTeamByNameArgs = {
  input: DeleteTeamByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTeamByNodeIdArgs = {
  input: DeleteTeamByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTeamsMemberArgs = {
  input: DeleteTeamsMemberInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTeamsMemberByNodeIdArgs = {
  input: DeleteTeamsMemberByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionArgs = {
  input: DeleteTransactionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTransactionByNodeIdArgs = {
  input: DeleteTransactionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserAccessArgs = {
  input: DeleteUserAccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserAccessByNodeIdArgs = {
  input: DeleteUserAccessByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByNodeIdArgs = {
  input: DeleteUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserEmailArgs = {
  input: DeleteUserEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserEmailByNodeIdArgs = {
  input: DeleteUserEmailByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserEmailByUserIdArgs = {
  input: DeleteUserEmailByUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserRoleArgs = {
  input: DeleteUserRoleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserRoleByNodeIdArgs = {
  input: DeleteUserRoleByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUsernamesCheckArgs = {
  input: DeleteUsernamesCheckInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUsernamesCheckByNodeIdArgs = {
  input: DeleteUsernamesCheckByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUsersAvailableRoomArgs = {
  input: DeleteUsersAvailableRoomInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUsersAvailableRoomByNodeIdArgs = {
  input: DeleteUsersAvailableRoomByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUsersReceivedMessageArgs = {
  input: DeleteUsersReceivedMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUsersReceivedMessageByNodeIdArgs = {
  input: DeleteUsersReceivedMessageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWitnessArgs = {
  input: DeleteWitnessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWitnessByNodeIdArgs = {
  input: DeleteWitnessByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetAvailableRoomsUsersArgs = {
  input: GetAvailableRoomsUsersInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateActivityArgs = {
  input: UpdateActivityInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateActivityByNodeIdArgs = {
  input: UpdateActivityByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAdArgs = {
  input: UpdateAdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAdByNodeIdArgs = {
  input: UpdateAdByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateArchiveArgs = {
  input: UpdateArchiveInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateArchiveByNodeIdArgs = {
  input: UpdateArchiveByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAudienceArgs = {
  input: UpdateAudienceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAudienceByNodeIdArgs = {
  input: UpdateAudienceByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAudienceMetaArgs = {
  input: UpdateAudienceMetaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAudienceMetaByNodeIdArgs = {
  input: UpdateAudienceMetaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBeastArgs = {
  input: UpdateBeastInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBeastByNodeIdArgs = {
  input: UpdateBeastByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBlockedUserArgs = {
  input: UpdateBlockedUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBlockedUserByNodeIdArgs = {
  input: UpdateBlockedUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBudgetArgs = {
  input: UpdateBudgetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBudgetByNodeIdArgs = {
  input: UpdateBudgetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCampaignByNodeIdArgs = {
  input: UpdateCampaignByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCampaignsActionArgs = {
  input: UpdateCampaignsActionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCampaignsActionByNodeIdArgs = {
  input: UpdateCampaignsActionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChallengeArgs = {
  input: UpdateChallengeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChallengeByNodeIdArgs = {
  input: UpdateChallengeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChallengesProofArgs = {
  input: UpdateChallengesProofInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChallengesProofByNodeIdArgs = {
  input: UpdateChallengesProofByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChallengesWitnessArgs = {
  input: UpdateChallengesWitnessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChallengesWitnessByNodeIdArgs = {
  input: UpdateChallengesWitnessByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCloudArgs = {
  input: UpdateCloudInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCloudByNodeIdArgs = {
  input: UpdateCloudByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFavoriteGameArgs = {
  input: UpdateFavoriteGameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFavoriteGameByNodeIdArgs = {
  input: UpdateFavoriteGameByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeeArgs = {
  input: UpdateFeeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeeByNodeIdArgs = {
  input: UpdateFeeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedbackArgs = {
  input: UpdateFeedbackInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedbackByNodeIdArgs = {
  input: UpdateFeedbackByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFollowingArgs = {
  input: UpdateFollowingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFollowingByNodeIdArgs = {
  input: UpdateFollowingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFunnyArgs = {
  input: UpdateFunnyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFunnyByNodeIdArgs = {
  input: UpdateFunnyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGameArgs = {
  input: UpdateGameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGameByNodeIdArgs = {
  input: UpdateGameByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGenderArgs = {
  input: UpdateGenderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGenderByNodeIdArgs = {
  input: UpdateGenderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGenreArgs = {
  input: UpdateGenreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGenreByNodeIdArgs = {
  input: UpdateGenreByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLikeArgs = {
  input: UpdateLikeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLikeByNodeIdArgs = {
  input: UpdateLikeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMediaArgs = {
  input: UpdateMediaInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMediaByNodeIdArgs = {
  input: UpdateMediaByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMessageArgs = {
  input: UpdateMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMessageByNodeIdArgs = {
  input: UpdateMessageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMusicArgs = {
  input: UpdateMusicInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMusicByNodeIdArgs = {
  input: UpdateMusicByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMusicGenreArgs = {
  input: UpdateMusicGenreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMusicGenreByNodeIdArgs = {
  input: UpdateMusicGenreByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNoobArgs = {
  input: UpdateNoobInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNoobByNodeIdArgs = {
  input: UpdateNoobByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationLogArgs = {
  input: UpdateNotificationLogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateNotificationLogByNodeIdArgs = {
  input: UpdateNotificationLogByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOzSubscriptionArgs = {
  input: UpdateOzSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOzSubscriptionByNodeIdArgs = {
  input: UpdateOzSubscriptionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePaymentMethodArgs = {
  input: UpdatePaymentMethodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePaymentMethodByNodeIdArgs = {
  input: UpdatePaymentMethodByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostArgs = {
  input: UpdatePostInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostByNodeIdArgs = {
  input: UpdatePostByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostsReportArgs = {
  input: UpdatePostsReportInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostsReportByNodeIdArgs = {
  input: UpdatePostsReportByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostsViewArgs = {
  input: UpdatePostsViewInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostsViewByNodeIdArgs = {
  input: UpdatePostsViewByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostsViewByPostIdAndUserIdArgs = {
  input: UpdatePostsViewByPostIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePrivateUserArgs = {
  input: UpdatePrivateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePrivateUserByNodeIdArgs = {
  input: UpdatePrivateUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReportMessageArgs = {
  input: UpdateReportMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReportMessageByNodeIdArgs = {
  input: UpdateReportMessageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReportedPostArgs = {
  input: UpdateReportedPostInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRoleByNodeIdArgs = {
  input: UpdateRoleByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRoomArgs = {
  input: UpdateRoomInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRoomByNodeIdArgs = {
  input: UpdateRoomByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStickerArgs = {
  input: UpdateStickerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStickerByNodeIdArgs = {
  input: UpdateStickerByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStickersTagArgs = {
  input: UpdateStickersTagInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStickersTagByNodeIdArgs = {
  input: UpdateStickersTagByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTagByNodeIdArgs = {
  input: UpdateTagByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTeamByNameArgs = {
  input: UpdateTeamByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTeamByNodeIdArgs = {
  input: UpdateTeamByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTeamsMemberArgs = {
  input: UpdateTeamsMemberInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTeamsMemberByNodeIdArgs = {
  input: UpdateTeamsMemberByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionArgs = {
  input: UpdateTransactionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTransactionByNodeIdArgs = {
  input: UpdateTransactionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserAccessArgs = {
  input: UpdateUserAccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserAccessByNodeIdArgs = {
  input: UpdateUserAccessByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByNodeIdArgs = {
  input: UpdateUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserEmailArgs = {
  input: UpdateUserEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserEmailByNodeIdArgs = {
  input: UpdateUserEmailByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserEmailByUserIdArgs = {
  input: UpdateUserEmailByUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRoleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserRoleByNodeIdArgs = {
  input: UpdateUserRoleByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUsernamesCheckArgs = {
  input: UpdateUsernamesCheckInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUsernamesCheckByNodeIdArgs = {
  input: UpdateUsernamesCheckByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUsersAvailableRoomArgs = {
  input: UpdateUsersAvailableRoomInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUsersAvailableRoomByNodeIdArgs = {
  input: UpdateUsersAvailableRoomByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUsersReceivedMessageArgs = {
  input: UpdateUsersReceivedMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUsersReceivedMessageByNodeIdArgs = {
  input: UpdateUsersReceivedMessageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWitnessArgs = {
  input: UpdateWitnessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWitnessByNodeIdArgs = {
  input: UpdateWitnessByNodeIdInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export type Noob = Node & {
  __typename?: 'Noob';
  createdAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Post` that is related to this `Noob`. */
  post?: Maybe<Post>;
  postId: Scalars['Int'];
  /** Reads a single `User` that is related to this `Noob`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/** A condition to be used against `Noob` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type NoobCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `postId` field. */
  postId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Noob` object types. All fields are combined with a logical ‘and.’ */
export type NoobFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NoobFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NoobFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NoobFilter>>;
  /** Filter by the object’s `postId` field. */
  postId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Noob` */
export type NoobInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** Represents an update to a `Noob`. Fields that are set will be updated. */
export type NoobPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  postId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Noob` values. */
export type NoobsConnection = {
  __typename?: 'NoobsConnection';
  /** A list of edges which contains the `Noob` and cursor to aid in pagination. */
  edges: Array<NoobsEdge>;
  /** A list of `Noob` objects. */
  nodes: Array<Noob>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Noob` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Noob` edge in the connection. */
export type NoobsEdge = {
  __typename?: 'NoobsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Noob` at the end of the edge. */
  node: Noob;
};

/** Methods to use when ordering `Noob`. */
export enum NoobsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  Natural = 'NATURAL',
  PostIdAsc = 'POST_ID_ASC',
  PostIdDesc = 'POST_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type NotificationLog = Node & {
  __typename?: 'NotificationLog';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  nbTokensFailure: Scalars['Int'];
  nbTokensSuccess: Scalars['Int'];
  nbTokensTotal: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  payload: Scalars['JSON'];
  source: Scalars['String'];
  users: Scalars['String'];
};

/**
 * A condition to be used against `NotificationLog` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NotificationLogCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `nbTokensFailure` field. */
  nbTokensFailure?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `nbTokensSuccess` field. */
  nbTokensSuccess?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `nbTokensTotal` field. */
  nbTokensTotal?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `source` field. */
  source?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `users` field. */
  users?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `NotificationLog` object types. All fields are combined with a logical ‘and.’ */
export type NotificationLogFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NotificationLogFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `nbTokensFailure` field. */
  nbTokensFailure?: InputMaybe<IntFilter>;
  /** Filter by the object’s `nbTokensSuccess` field. */
  nbTokensSuccess?: InputMaybe<IntFilter>;
  /** Filter by the object’s `nbTokensTotal` field. */
  nbTokensTotal?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NotificationLogFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NotificationLogFilter>>;
  /** Filter by the object’s `source` field. */
  source?: InputMaybe<StringFilter>;
  /** Filter by the object’s `users` field. */
  users?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `NotificationLog` */
export type NotificationLogInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  nbTokensFailure: Scalars['Int'];
  nbTokensSuccess: Scalars['Int'];
  nbTokensTotal: Scalars['Int'];
  payload: Scalars['JSON'];
  source: Scalars['String'];
  users: Scalars['String'];
};

/** Represents an update to a `NotificationLog`. Fields that are set will be updated. */
export type NotificationLogPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  nbTokensFailure?: InputMaybe<Scalars['Int']>;
  nbTokensSuccess?: InputMaybe<Scalars['Int']>;
  nbTokensTotal?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['JSON']>;
  source?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `NotificationLog` values. */
export type NotificationLogsConnection = {
  __typename?: 'NotificationLogsConnection';
  /** A list of edges which contains the `NotificationLog` and cursor to aid in pagination. */
  edges: Array<NotificationLogsEdge>;
  /** A list of `NotificationLog` objects. */
  nodes: Array<NotificationLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NotificationLog` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `NotificationLog` edge in the connection. */
export type NotificationLogsEdge = {
  __typename?: 'NotificationLogsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `NotificationLog` at the end of the edge. */
  node: NotificationLog;
};

/** Methods to use when ordering `NotificationLog`. */
export enum NotificationLogsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  NbTokensFailureAsc = 'NB_TOKENS_FAILURE_ASC',
  NbTokensFailureDesc = 'NB_TOKENS_FAILURE_DESC',
  NbTokensSuccessAsc = 'NB_TOKENS_SUCCESS_ASC',
  NbTokensSuccessDesc = 'NB_TOKENS_SUCCESS_DESC',
  NbTokensTotalAsc = 'NB_TOKENS_TOTAL_ASC',
  NbTokensTotalDesc = 'NB_TOKENS_TOTAL_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  UsersAsc = 'USERS_ASC',
  UsersDesc = 'USERS_DESC'
}

export enum NotificationType {
  ChallengeWitnessInvitationSent = 'CHALLENGE_WITNESS_INVITATION_SENT',
  MediaUploaded = 'MEDIA_UPLOADED',
  TeamInvitationAccepted = 'TEAM_INVITATION_ACCEPTED',
  TeamInvitationSent = 'TEAM_INVITATION_SENT',
  UserAcceptedFollow = 'USER_ACCEPTED_FOLLOW',
  UserActionedPost = 'USER_ACTIONED_POST',
  UserAddedComment = 'USER_ADDED_COMMENT',
  UserInvitedToChallenge = 'USER_INVITED_TO_CHALLENGE',
  UserLikedComment = 'USER_LIKED_COMMENT',
  UserNowFollowing = 'USER_NOW_FOLLOWING',
  UserRepliedToComment = 'USER_REPLIED_TO_COMMENT',
  UserRequestedFollow = 'USER_REQUESTED_FOLLOW',
  UserSentMessage = 'USER_SENT_MESSAGE',
  UserSentPost = 'USER_SENT_POST'
}

/** A filter to be used against NotificationType fields. All fields are combined with a logical ‘and.’ */
export type NotificationTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<NotificationType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<NotificationType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<NotificationType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<NotificationType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<NotificationType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<NotificationType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<NotificationType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<NotificationType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<NotificationType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type OzSubscription = Node & {
  __typename?: 'OzSubscription';
  cancelledAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  /** Reads a single `Fee` that is related to this `OzSubscription`. */
  fee?: Maybe<Fee>;
  feeId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  processedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Transaction` that is related to this `OzSubscription`. */
  transaction?: Maybe<Transaction>;
  transactionId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `OzSubscription`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `OzSubscription` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OzSubscriptionCondition = {
  /** Checks for equality with the object’s `cancelledAt` field. */
  cancelledAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `feeId` field. */
  feeId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `processedAt` field. */
  processedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `transactionId` field. */
  transactionId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `OzSubscription` object types. All fields are combined with a logical ‘and.’ */
export type OzSubscriptionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OzSubscriptionFilter>>;
  /** Filter by the object’s `cancelledAt` field. */
  cancelledAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `feeId` field. */
  feeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OzSubscriptionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OzSubscriptionFilter>>;
  /** Filter by the object’s `processedAt` field. */
  processedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `transactionId` field. */
  transactionId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `OzSubscription` */
export type OzSubscriptionInput = {
  cancelledAt?: InputMaybe<Scalars['Datetime']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  feeId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  processedAt?: InputMaybe<Scalars['Datetime']>;
  transactionId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `OzSubscription`. Fields that are set will be updated. */
export type OzSubscriptionPatch = {
  cancelledAt?: InputMaybe<Scalars['Datetime']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  feeId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  processedAt?: InputMaybe<Scalars['Datetime']>;
  transactionId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `OzSubscription` values. */
export type OzSubscriptionsConnection = {
  __typename?: 'OzSubscriptionsConnection';
  /** A list of edges which contains the `OzSubscription` and cursor to aid in pagination. */
  edges: Array<OzSubscriptionsEdge>;
  /** A list of `OzSubscription` objects. */
  nodes: Array<OzSubscription>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OzSubscription` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `OzSubscription` edge in the connection. */
export type OzSubscriptionsEdge = {
  __typename?: 'OzSubscriptionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `OzSubscription` at the end of the edge. */
  node: OzSubscription;
};

/** Methods to use when ordering `OzSubscription`. */
export enum OzSubscriptionsOrderBy {
  CancelledAtAsc = 'CANCELLED_AT_ASC',
  CancelledAtDesc = 'CANCELLED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FeeIdAsc = 'FEE_ID_ASC',
  FeeIdDesc = 'FEE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProcessedAtAsc = 'PROCESSED_AT_ASC',
  ProcessedAtDesc = 'PROCESSED_AT_DESC',
  TransactionIdAsc = 'TRANSACTION_ID_ASC',
  TransactionIdDesc = 'TRANSACTION_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type PaymentMethod = Node & {
  __typename?: 'PaymentMethod';
  brand: Scalars['String'];
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  id: Scalars['Int'];
  last4: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  preferred: Scalars['Boolean'];
  stripePaymentMethodId: Scalars['String'];
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions: TransactionsConnection;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `PaymentMethod`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};


export type PaymentMethodTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/**
 * A condition to be used against `PaymentMethod` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PaymentMethodCondition = {
  /** Checks for equality with the object’s `brand` field. */
  brand?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `expMonth` field. */
  expMonth?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `expYear` field. */
  expYear?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `last4` field. */
  last4?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `preferred` field. */
  preferred?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `stripePaymentMethodId` field. */
  stripePaymentMethodId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `PaymentMethod` object types. All fields are combined with a logical ‘and.’ */
export type PaymentMethodFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PaymentMethodFilter>>;
  /** Filter by the object’s `brand` field. */
  brand?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `expMonth` field. */
  expMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `expYear` field. */
  expYear?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `last4` field. */
  last4?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PaymentMethodFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PaymentMethodFilter>>;
  /** Filter by the object’s `preferred` field. */
  preferred?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `stripePaymentMethodId` field. */
  stripePaymentMethodId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `PaymentMethod` */
export type PaymentMethodInput = {
  brand: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  last4: Scalars['Int'];
  preferred?: InputMaybe<Scalars['Boolean']>;
  stripePaymentMethodId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `PaymentMethod`. Fields that are set will be updated. */
export type PaymentMethodPatch = {
  brand?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  expMonth?: InputMaybe<Scalars['Int']>;
  expYear?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  last4?: InputMaybe<Scalars['Int']>;
  preferred?: InputMaybe<Scalars['Boolean']>;
  stripePaymentMethodId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `PaymentMethod` values. */
export type PaymentMethodsConnection = {
  __typename?: 'PaymentMethodsConnection';
  /** A list of edges which contains the `PaymentMethod` and cursor to aid in pagination. */
  edges: Array<PaymentMethodsEdge>;
  /** A list of `PaymentMethod` objects. */
  nodes: Array<PaymentMethod>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PaymentMethod` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PaymentMethod` edge in the connection. */
export type PaymentMethodsEdge = {
  __typename?: 'PaymentMethodsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PaymentMethod` at the end of the edge. */
  node: PaymentMethod;
};

/** Methods to use when ordering `PaymentMethod`. */
export enum PaymentMethodsOrderBy {
  BrandAsc = 'BRAND_ASC',
  BrandDesc = 'BRAND_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  ExpMonthAsc = 'EXP_MONTH_ASC',
  ExpMonthDesc = 'EXP_MONTH_DESC',
  ExpYearAsc = 'EXP_YEAR_ASC',
  ExpYearDesc = 'EXP_YEAR_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Last4Asc = 'LAST4_ASC',
  Last4Desc = 'LAST4_DESC',
  Natural = 'NATURAL',
  PreferredAsc = 'PREFERRED_ASC',
  PreferredDesc = 'PREFERRED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StripePaymentMethodIdAsc = 'STRIPE_PAYMENT_METHOD_ID_ASC',
  StripePaymentMethodIdDesc = 'STRIPE_PAYMENT_METHOD_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Post = Node & {
  __typename?: 'Post';
  /** Reads and enables pagination through a set of `Activity`. */
  activities: ActivitiesConnection;
  archivedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `Archive`. */
  archives: ArchivesConnection;
  /** Reads and enables pagination through a set of `Beast`. */
  beasts: BeastsConnection;
  caption?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Cloud`. */
  clouds: CloudsConnection;
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `Funny`. */
  funnies: FunniesConnection;
  /** Reads a single `Game` that is related to this `Post`. */
  game?: Maybe<Game>;
  gameId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** Reads and enables pagination through a set of `Like`. */
  likes: LikesConnection;
  /** Reads a single `Media` that is related to this `Post`. */
  media?: Maybe<Media>;
  mediaId: Scalars['Int'];
  /** Reads and enables pagination through a set of `Message`. */
  messages: MessagesConnection;
  nbComments: Scalars['Int'];
  nbShares: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Noob`. */
  noobs: NoobsConnection;
  objectId?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `PostsReport`. */
  postsReports: PostsReportsConnection;
  /** Reads and enables pagination through a set of `PostsView`. */
  postsViews: PostsViewsConnection;
  removedAt?: Maybe<Scalars['Datetime']>;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Post`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};


export type PostActivitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ActivityCondition>;
  filter?: InputMaybe<ActivityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};


export type PostArchivesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArchiveCondition>;
  filter?: InputMaybe<ArchiveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArchivesOrderBy>>;
};


export type PostBeastsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BeastCondition>;
  filter?: InputMaybe<BeastFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BeastsOrderBy>>;
};


export type PostCloudsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CloudCondition>;
  filter?: InputMaybe<CloudFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CloudsOrderBy>>;
};


export type PostFunniesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FunnyCondition>;
  filter?: InputMaybe<FunnyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FunniesOrderBy>>;
};


export type PostLikesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};


export type PostMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};


export type PostNoobsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoobCondition>;
  filter?: InputMaybe<NoobFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NoobsOrderBy>>;
};


export type PostPostsReportsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostsReportCondition>;
  filter?: InputMaybe<PostsReportFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsReportsOrderBy>>;
};


export type PostPostsViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostsViewCondition>;
  filter?: InputMaybe<PostsViewFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsViewsOrderBy>>;
};

/** A condition to be used against `Post` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PostCondition = {
  /** Checks for equality with the object’s `archivedAt` field. */
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `caption` field. */
  caption?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `gameId` field. */
  gameId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `mediaId` field. */
  mediaId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `nbComments` field. */
  nbComments?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `nbShares` field. */
  nbShares?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `removedAt` field. */
  removedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Post` object types. All fields are combined with a logical ‘and.’ */
export type PostFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostFilter>>;
  /** Filter by the object’s `archivedAt` field. */
  archivedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `caption` field. */
  caption?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `gameId` field. */
  gameId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `mediaId` field. */
  mediaId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `nbComments` field. */
  nbComments?: InputMaybe<IntFilter>;
  /** Filter by the object’s `nbShares` field. */
  nbShares?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostFilter>;
  /** Filter by the object’s `objectId` field. */
  objectId?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostFilter>>;
  /** Filter by the object’s `removedAt` field. */
  removedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Post` */
export type PostInput = {
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  caption?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  gameId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  mediaId: Scalars['Int'];
  nbComments?: InputMaybe<Scalars['Int']>;
  nbShares?: InputMaybe<Scalars['Int']>;
  objectId?: InputMaybe<Scalars['String']>;
  removedAt?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `Post`. Fields that are set will be updated. */
export type PostPatch = {
  archivedAt?: InputMaybe<Scalars['Datetime']>;
  caption?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  gameId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  mediaId?: InputMaybe<Scalars['Int']>;
  nbComments?: InputMaybe<Scalars['Int']>;
  nbShares?: InputMaybe<Scalars['Int']>;
  objectId?: InputMaybe<Scalars['String']>;
  removedAt?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Post` values. */
export type PostsConnection = {
  __typename?: 'PostsConnection';
  /** A list of edges which contains the `Post` and cursor to aid in pagination. */
  edges: Array<PostsEdge>;
  /** A list of `Post` objects. */
  nodes: Array<Post>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Post` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Post` edge in the connection. */
export type PostsEdge = {
  __typename?: 'PostsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Post` at the end of the edge. */
  node: Post;
};

/** Methods to use when ordering `Post`. */
export enum PostsOrderBy {
  ArchivedAtAsc = 'ARCHIVED_AT_ASC',
  ArchivedAtDesc = 'ARCHIVED_AT_DESC',
  CaptionAsc = 'CAPTION_ASC',
  CaptionDesc = 'CAPTION_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  GameIdAsc = 'GAME_ID_ASC',
  GameIdDesc = 'GAME_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MediaIdAsc = 'MEDIA_ID_ASC',
  MediaIdDesc = 'MEDIA_ID_DESC',
  Natural = 'NATURAL',
  NbCommentsAsc = 'NB_COMMENTS_ASC',
  NbCommentsDesc = 'NB_COMMENTS_DESC',
  NbSharesAsc = 'NB_SHARES_ASC',
  NbSharesDesc = 'NB_SHARES_DESC',
  ObjectIdAsc = 'OBJECT_ID_ASC',
  ObjectIdDesc = 'OBJECT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RemovedAtAsc = 'REMOVED_AT_ASC',
  RemovedAtDesc = 'REMOVED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type PostsReport = Node & {
  __typename?: 'PostsReport';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Post` that is related to this `PostsReport`. */
  post?: Maybe<Post>;
  postId?: Maybe<Scalars['Int']>;
  /** Reads a single `ReportMessage` that is related to this `PostsReport`. */
  reportMessage?: Maybe<ReportMessage>;
  reportMessageId?: Maybe<Scalars['Int']>;
  status?: Maybe<PostsReportsStatus>;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `PostsReport`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  validatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `PostsReport`. */
  validatingUser?: Maybe<User>;
  validatingUserId?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `PostsReport` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PostsReportCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `postId` field. */
  postId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `reportMessageId` field. */
  reportMessageId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<PostsReportsStatus>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `validatedAt` field. */
  validatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `validatingUserId` field. */
  validatingUserId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `PostsReport` object types. All fields are combined with a logical ‘and.’ */
export type PostsReportFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostsReportFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostsReportFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostsReportFilter>>;
  /** Filter by the object’s `postId` field. */
  postId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `reportMessageId` field. */
  reportMessageId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<PostsReportsStatusFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `validatedAt` field. */
  validatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `validatingUserId` field. */
  validatingUserId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `PostsReport` */
export type PostsReportInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['Int']>;
  reportMessageId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<PostsReportsStatus>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  validatedAt?: InputMaybe<Scalars['Datetime']>;
  validatingUserId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `PostsReport`. Fields that are set will be updated. */
export type PostsReportPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['Int']>;
  reportMessageId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<PostsReportsStatus>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
  validatedAt?: InputMaybe<Scalars['Datetime']>;
  validatingUserId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `PostsReport` values. */
export type PostsReportsConnection = {
  __typename?: 'PostsReportsConnection';
  /** A list of edges which contains the `PostsReport` and cursor to aid in pagination. */
  edges: Array<PostsReportsEdge>;
  /** A list of `PostsReport` objects. */
  nodes: Array<PostsReport>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PostsReport` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PostsReport` edge in the connection. */
export type PostsReportsEdge = {
  __typename?: 'PostsReportsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PostsReport` at the end of the edge. */
  node: PostsReport;
};

/** Methods to use when ordering `PostsReport`. */
export enum PostsReportsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PostIdAsc = 'POST_ID_ASC',
  PostIdDesc = 'POST_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReportMessageIdAsc = 'REPORT_MESSAGE_ID_ASC',
  ReportMessageIdDesc = 'REPORT_MESSAGE_ID_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  ValidatedAtAsc = 'VALIDATED_AT_ASC',
  ValidatedAtDesc = 'VALIDATED_AT_DESC',
  ValidatingUserIdAsc = 'VALIDATING_USER_ID_ASC',
  ValidatingUserIdDesc = 'VALIDATING_USER_ID_DESC'
}

export enum PostsReportsStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED'
}

/** A filter to be used against PostsReportsStatus fields. All fields are combined with a logical ‘and.’ */
export type PostsReportsStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<PostsReportsStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<PostsReportsStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<PostsReportsStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<PostsReportsStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<PostsReportsStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<PostsReportsStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<PostsReportsStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<PostsReportsStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<PostsReportsStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<PostsReportsStatus>>;
};

export type PostsView = Node & {
  __typename?: 'PostsView';
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Post` that is related to this `PostsView`. */
  post?: Maybe<Post>;
  postId: Scalars['Int'];
  /** Reads a single `User` that is related to this `PostsView`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `PostsView` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PostsViewCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `postId` field. */
  postId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `PostsView` object types. All fields are combined with a logical ‘and.’ */
export type PostsViewFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostsViewFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostsViewFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostsViewFilter>>;
  /** Filter by the object’s `postId` field. */
  postId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `PostsView` */
export type PostsViewInput = {
  id?: InputMaybe<Scalars['Int']>;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** Represents an update to a `PostsView`. Fields that are set will be updated. */
export type PostsViewPatch = {
  id?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `PostsView` values. */
export type PostsViewsConnection = {
  __typename?: 'PostsViewsConnection';
  /** A list of edges which contains the `PostsView` and cursor to aid in pagination. */
  edges: Array<PostsViewsEdge>;
  /** A list of `PostsView` objects. */
  nodes: Array<PostsView>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PostsView` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PostsView` edge in the connection. */
export type PostsViewsEdge = {
  __typename?: 'PostsViewsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PostsView` at the end of the edge. */
  node: PostsView;
};

/** Methods to use when ordering `PostsView`. */
export enum PostsViewsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PostIdAsc = 'POST_ID_ASC',
  PostIdDesc = 'POST_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type PrivateUser = Node & {
  __typename?: 'PrivateUser';
  createdAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  private: Scalars['Boolean'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `PrivateUser`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `PrivateUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PrivateUserCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `private` field. */
  private?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `PrivateUser` object types. All fields are combined with a logical ‘and.’ */
export type PrivateUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PrivateUserFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PrivateUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PrivateUserFilter>>;
  /** Filter by the object’s `private` field. */
  private?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `PrivateUser` */
export type PrivateUserInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  private?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `PrivateUser`. Fields that are set will be updated. */
export type PrivateUserPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  private?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `PrivateUser` values. */
export type PrivateUsersConnection = {
  __typename?: 'PrivateUsersConnection';
  /** A list of edges which contains the `PrivateUser` and cursor to aid in pagination. */
  edges: Array<PrivateUsersEdge>;
  /** A list of `PrivateUser` objects. */
  nodes: Array<PrivateUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PrivateUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PrivateUser` edge in the connection. */
export type PrivateUsersEdge = {
  __typename?: 'PrivateUsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PrivateUser` at the end of the edge. */
  node: PrivateUser;
};

/** Methods to use when ordering `PrivateUser`. */
export enum PrivateUsersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivateAsc = 'PRIVATE_ASC',
  PrivateDesc = 'PRIVATE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  /** Reads and enables pagination through a set of `Activity`. */
  activities?: Maybe<ActivitiesConnection>;
  activity?: Maybe<Activity>;
  /** Reads a single `Activity` using its globally unique `ID`. */
  activityByNodeId?: Maybe<Activity>;
  ad?: Maybe<Ad>;
  /** Reads a single `Ad` using its globally unique `ID`. */
  adByNodeId?: Maybe<Ad>;
  /** Reads and enables pagination through a set of `Ad`. */
  ads?: Maybe<AdsConnection>;
  archive?: Maybe<Archive>;
  /** Reads a single `Archive` using its globally unique `ID`. */
  archiveByNodeId?: Maybe<Archive>;
  /** Reads and enables pagination through a set of `Archive`. */
  archives?: Maybe<ArchivesConnection>;
  audience?: Maybe<Audience>;
  /** Reads a single `Audience` using its globally unique `ID`. */
  audienceByNodeId?: Maybe<Audience>;
  audienceMeta?: Maybe<AudienceMeta>;
  /** Reads a single `AudienceMeta` using its globally unique `ID`. */
  audienceMetaByNodeId?: Maybe<AudienceMeta>;
  /** Reads and enables pagination through a set of `AudienceMeta`. */
  audienceMetas?: Maybe<AudienceMetasConnection>;
  /** Reads and enables pagination through a set of `Audience`. */
  audiences?: Maybe<AudiencesConnection>;
  beast?: Maybe<Beast>;
  /** Reads a single `Beast` using its globally unique `ID`. */
  beastByNodeId?: Maybe<Beast>;
  /** Reads and enables pagination through a set of `Beast`. */
  beasts?: Maybe<BeastsConnection>;
  blockedUser?: Maybe<BlockedUser>;
  /** Reads a single `BlockedUser` using its globally unique `ID`. */
  blockedUserByNodeId?: Maybe<BlockedUser>;
  /** Reads and enables pagination through a set of `BlockedUser`. */
  blockedUsers?: Maybe<BlockedUsersConnection>;
  budget?: Maybe<Budget>;
  /** Reads a single `Budget` using its globally unique `ID`. */
  budgetByNodeId?: Maybe<Budget>;
  /** Reads and enables pagination through a set of `Budget`. */
  budgets?: Maybe<BudgetsConnection>;
  campaign?: Maybe<Campaign>;
  /** Reads a single `Campaign` using its globally unique `ID`. */
  campaignByNodeId?: Maybe<Campaign>;
  /** Reads and enables pagination through a set of `Campaign`. */
  campaigns?: Maybe<CampaignsConnection>;
  campaignsAction?: Maybe<CampaignsAction>;
  /** Reads a single `CampaignsAction` using its globally unique `ID`. */
  campaignsActionByNodeId?: Maybe<CampaignsAction>;
  /** Reads and enables pagination through a set of `CampaignsAction`. */
  campaignsActions?: Maybe<CampaignsActionsConnection>;
  challenge?: Maybe<Challenge>;
  /** Reads a single `Challenge` using its globally unique `ID`. */
  challengeByNodeId?: Maybe<Challenge>;
  /** Reads and enables pagination through a set of `Challenge`. */
  challenges?: Maybe<ChallengesConnection>;
  challengesProof?: Maybe<ChallengesProof>;
  /** Reads a single `ChallengesProof` using its globally unique `ID`. */
  challengesProofByNodeId?: Maybe<ChallengesProof>;
  /** Reads and enables pagination through a set of `ChallengesProof`. */
  challengesProofs?: Maybe<ChallengesProofsConnection>;
  challengesWitness?: Maybe<ChallengesWitness>;
  /** Reads a single `ChallengesWitness` using its globally unique `ID`. */
  challengesWitnessByNodeId?: Maybe<ChallengesWitness>;
  /** Reads and enables pagination through a set of `ChallengesWitness`. */
  challengesWitnesses?: Maybe<ChallengesWitnessesConnection>;
  cloud?: Maybe<Cloud>;
  /** Reads a single `Cloud` using its globally unique `ID`. */
  cloudByNodeId?: Maybe<Cloud>;
  /** Reads and enables pagination through a set of `Cloud`. */
  clouds?: Maybe<CloudsConnection>;
  currentUserId?: Maybe<Scalars['Int']>;
  favoriteGame?: Maybe<FavoriteGame>;
  /** Reads a single `FavoriteGame` using its globally unique `ID`. */
  favoriteGameByNodeId?: Maybe<FavoriteGame>;
  /** Reads and enables pagination through a set of `FavoriteGame`. */
  favoriteGames?: Maybe<FavoriteGamesConnection>;
  fee?: Maybe<Fee>;
  /** Reads a single `Fee` using its globally unique `ID`. */
  feeByNodeId?: Maybe<Fee>;
  feedback?: Maybe<Feedback>;
  /** Reads a single `Feedback` using its globally unique `ID`. */
  feedbackByNodeId?: Maybe<Feedback>;
  /** Reads and enables pagination through a set of `Feedback`. */
  feedbacks?: Maybe<FeedbacksConnection>;
  /** Reads and enables pagination through a set of `Fee`. */
  fees?: Maybe<FeesConnection>;
  following?: Maybe<Following>;
  /** Reads a single `Following` using its globally unique `ID`. */
  followingByNodeId?: Maybe<Following>;
  /** Reads and enables pagination through a set of `Following`. */
  followings?: Maybe<FollowingsConnection>;
  /** Reads and enables pagination through a set of `Funny`. */
  funnies?: Maybe<FunniesConnection>;
  funny?: Maybe<Funny>;
  /** Reads a single `Funny` using its globally unique `ID`. */
  funnyByNodeId?: Maybe<Funny>;
  game?: Maybe<Game>;
  /** Reads a single `Game` using its globally unique `ID`. */
  gameByNodeId?: Maybe<Game>;
  /** Reads and enables pagination through a set of `Game`. */
  games?: Maybe<GamesConnection>;
  gender?: Maybe<Gender>;
  /** Reads a single `Gender` using its globally unique `ID`. */
  genderByNodeId?: Maybe<Gender>;
  /** Reads and enables pagination through a set of `Gender`. */
  genders?: Maybe<GendersConnection>;
  genre?: Maybe<Genre>;
  /** Reads a single `Genre` using its globally unique `ID`. */
  genreByNodeId?: Maybe<Genre>;
  /** Reads and enables pagination through a set of `Genre`. */
  genres?: Maybe<GenresConnection>;
  like?: Maybe<Like>;
  /** Reads a single `Like` using its globally unique `ID`. */
  likeByNodeId?: Maybe<Like>;
  /** Reads and enables pagination through a set of `Like`. */
  likes?: Maybe<LikesConnection>;
  media?: Maybe<Media>;
  /** Reads a single `Media` using its globally unique `ID`. */
  mediaByNodeId?: Maybe<Media>;
  mediaIsPost?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Media`. */
  medias?: Maybe<MediaConnection>;
  message?: Maybe<Message>;
  /** Reads a single `Message` using its globally unique `ID`. */
  messageByNodeId?: Maybe<Message>;
  /** Reads and enables pagination through a set of `Message`. */
  messages?: Maybe<MessagesConnection>;
  music?: Maybe<Music>;
  /** Reads a single `Music` using its globally unique `ID`. */
  musicByNodeId?: Maybe<Music>;
  musicGenre?: Maybe<MusicGenre>;
  /** Reads a single `MusicGenre` using its globally unique `ID`. */
  musicGenreByNodeId?: Maybe<MusicGenre>;
  /** Reads and enables pagination through a set of `MusicGenre`. */
  musicGenres?: Maybe<MusicGenresConnection>;
  /** Reads and enables pagination through a set of `Music`. */
  musics?: Maybe<MusicConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  noob?: Maybe<Noob>;
  /** Reads a single `Noob` using its globally unique `ID`. */
  noobByNodeId?: Maybe<Noob>;
  /** Reads and enables pagination through a set of `Noob`. */
  noobs?: Maybe<NoobsConnection>;
  notificationLog?: Maybe<NotificationLog>;
  /** Reads a single `NotificationLog` using its globally unique `ID`. */
  notificationLogByNodeId?: Maybe<NotificationLog>;
  /** Reads and enables pagination through a set of `NotificationLog`. */
  notificationLogs?: Maybe<NotificationLogsConnection>;
  ozSubscription?: Maybe<OzSubscription>;
  /** Reads a single `OzSubscription` using its globally unique `ID`. */
  ozSubscriptionByNodeId?: Maybe<OzSubscription>;
  /** Reads and enables pagination through a set of `OzSubscription`. */
  ozSubscriptions?: Maybe<OzSubscriptionsConnection>;
  paymentMethod?: Maybe<PaymentMethod>;
  /** Reads a single `PaymentMethod` using its globally unique `ID`. */
  paymentMethodByNodeId?: Maybe<PaymentMethod>;
  /** Reads and enables pagination through a set of `PaymentMethod`. */
  paymentMethods?: Maybe<PaymentMethodsConnection>;
  post?: Maybe<Post>;
  /** Reads a single `Post` using its globally unique `ID`. */
  postByNodeId?: Maybe<Post>;
  /** Reads and enables pagination through a set of `Post`. */
  posts?: Maybe<PostsConnection>;
  postsReport?: Maybe<PostsReport>;
  /** Reads a single `PostsReport` using its globally unique `ID`. */
  postsReportByNodeId?: Maybe<PostsReport>;
  /** Reads and enables pagination through a set of `PostsReport`. */
  postsReports?: Maybe<PostsReportsConnection>;
  postsView?: Maybe<PostsView>;
  /** Reads a single `PostsView` using its globally unique `ID`. */
  postsViewByNodeId?: Maybe<PostsView>;
  postsViewByPostIdAndUserId?: Maybe<PostsView>;
  /** Reads and enables pagination through a set of `PostsView`. */
  postsViews?: Maybe<PostsViewsConnection>;
  privateUser?: Maybe<PrivateUser>;
  /** Reads a single `PrivateUser` using its globally unique `ID`. */
  privateUserByNodeId?: Maybe<PrivateUser>;
  /** Reads and enables pagination through a set of `PrivateUser`. */
  privateUsers?: Maybe<PrivateUsersConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  reportMessage?: Maybe<ReportMessage>;
  /** Reads a single `ReportMessage` using its globally unique `ID`. */
  reportMessageByNodeId?: Maybe<ReportMessage>;
  /** Reads and enables pagination through a set of `ReportMessage`. */
  reportMessages?: Maybe<ReportMessagesConnection>;
  role?: Maybe<Role>;
  /** Reads a single `Role` using its globally unique `ID`. */
  roleByNodeId?: Maybe<Role>;
  /** Reads and enables pagination through a set of `Role`. */
  roles?: Maybe<RolesConnection>;
  room?: Maybe<Room>;
  /** Reads a single `Room` using its globally unique `ID`. */
  roomByNodeId?: Maybe<Room>;
  /** Reads and enables pagination through a set of `Room`. */
  rooms?: Maybe<RoomsConnection>;
  sticker?: Maybe<Sticker>;
  /** Reads a single `Sticker` using its globally unique `ID`. */
  stickerByNodeId?: Maybe<Sticker>;
  /** Reads and enables pagination through a set of `Sticker`. */
  stickers?: Maybe<StickersConnection>;
  stickersTag?: Maybe<StickersTag>;
  /** Reads a single `StickersTag` using its globally unique `ID`. */
  stickersTagByNodeId?: Maybe<StickersTag>;
  /** Reads and enables pagination through a set of `StickersTag`. */
  stickersTags?: Maybe<StickersTagsConnection>;
  tag?: Maybe<Tag>;
  /** Reads a single `Tag` using its globally unique `ID`. */
  tagByNodeId?: Maybe<Tag>;
  /** Reads and enables pagination through a set of `Tag`. */
  tags?: Maybe<TagsConnection>;
  team?: Maybe<Team>;
  teamByName?: Maybe<Team>;
  /** Reads a single `Team` using its globally unique `ID`. */
  teamByNodeId?: Maybe<Team>;
  /** Reads and enables pagination through a set of `Team`. */
  teams?: Maybe<TeamsConnection>;
  teamsMember?: Maybe<TeamsMember>;
  /** Reads a single `TeamsMember` using its globally unique `ID`. */
  teamsMemberByNodeId?: Maybe<TeamsMember>;
  /** Reads and enables pagination through a set of `TeamsMember`. */
  teamsMembers?: Maybe<TeamsMembersConnection>;
  transaction?: Maybe<Transaction>;
  /** Reads a single `Transaction` using its globally unique `ID`. */
  transactionByNodeId?: Maybe<Transaction>;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions?: Maybe<TransactionsConnection>;
  user?: Maybe<User>;
  user1IsFollowingUser2?: Maybe<Scalars['Boolean']>;
  userAccess?: Maybe<UserAccess>;
  /** Reads a single `UserAccess` using its globally unique `ID`. */
  userAccessByNodeId?: Maybe<UserAccess>;
  /** Reads and enables pagination through a set of `UserAccess`. */
  userAccesses?: Maybe<UserAccessesConnection>;
  /** Reads a single `User` using its globally unique `ID`. */
  userByNodeId?: Maybe<User>;
  userEmail?: Maybe<UserEmail>;
  /** Reads a single `UserEmail` using its globally unique `ID`. */
  userEmailByNodeId?: Maybe<UserEmail>;
  userEmailByUserId?: Maybe<UserEmail>;
  /** Reads and enables pagination through a set of `UserEmail`. */
  userEmails?: Maybe<UserEmailsConnection>;
  userIsAdmin?: Maybe<Scalars['Boolean']>;
  userIsPlus?: Maybe<Scalars['Boolean']>;
  userIsPrivate?: Maybe<Scalars['Boolean']>;
  userRole?: Maybe<UserRole>;
  /** Reads a single `UserRole` using its globally unique `ID`. */
  userRoleByNodeId?: Maybe<UserRole>;
  /** Reads and enables pagination through a set of `UserRole`. */
  userRoles?: Maybe<UserRolesConnection>;
  usernamesCheck?: Maybe<UsernamesCheck>;
  /** Reads a single `UsernamesCheck` using its globally unique `ID`. */
  usernamesCheckByNodeId?: Maybe<UsernamesCheck>;
  /** Reads and enables pagination through a set of `UsernamesCheck`. */
  usernamesChecks?: Maybe<UsernamesChecksConnection>;
  /** Reads and enables pagination through a set of `User`. */
  users?: Maybe<UsersConnection>;
  usersAvailableRoom?: Maybe<UsersAvailableRoom>;
  /** Reads a single `UsersAvailableRoom` using its globally unique `ID`. */
  usersAvailableRoomByNodeId?: Maybe<UsersAvailableRoom>;
  /** Reads and enables pagination through a set of `UsersAvailableRoom`. */
  usersAvailableRooms?: Maybe<UsersAvailableRoomsConnection>;
  usersReceivedMessage?: Maybe<UsersReceivedMessage>;
  /** Reads a single `UsersReceivedMessage` using its globally unique `ID`. */
  usersReceivedMessageByNodeId?: Maybe<UsersReceivedMessage>;
  /** Reads and enables pagination through a set of `UsersReceivedMessage`. */
  usersReceivedMessages?: Maybe<UsersReceivedMessagesConnection>;
  witness?: Maybe<Witness>;
  /** Reads a single `Witness` using its globally unique `ID`. */
  witnessByNodeId?: Maybe<Witness>;
  /** Reads and enables pagination through a set of `Witness`. */
  witnesses?: Maybe<WitnessesConnection>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActivitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ActivityCondition>;
  filter?: InputMaybe<ActivityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActivityArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryActivityByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAdByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAdsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AdCondition>;
  filter?: InputMaybe<AdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AdsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryArchiveArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryArchiveByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryArchivesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArchiveCondition>;
  filter?: InputMaybe<ArchiveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArchivesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAudienceArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAudienceByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAudienceMetaArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAudienceMetaByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAudienceMetasArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AudienceMetaCondition>;
  filter?: InputMaybe<AudienceMetaFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AudienceMetasOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAudiencesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AudienceCondition>;
  filter?: InputMaybe<AudienceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AudiencesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBeastArgs = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBeastByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBeastsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BeastCondition>;
  filter?: InputMaybe<BeastFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BeastsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBlockedUserArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBlockedUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBlockedUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BlockedUserCondition>;
  filter?: InputMaybe<BlockedUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BlockedUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBudgetArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBudgetByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBudgetsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BudgetCondition>;
  filter?: InputMaybe<BudgetFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BudgetsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignsActionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignsActionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignsActionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CampaignsActionCondition>;
  filter?: InputMaybe<CampaignsActionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CampaignsActionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryChallengeArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChallengeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChallengesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengeCondition>;
  filter?: InputMaybe<ChallengeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryChallengesProofArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChallengesProofByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChallengesProofsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengesProofCondition>;
  filter?: InputMaybe<ChallengesProofFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesProofsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryChallengesWitnessArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChallengesWitnessByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChallengesWitnessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengesWitnessCondition>;
  filter?: InputMaybe<ChallengesWitnessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesWitnessesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCloudArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCloudByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCloudsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CloudCondition>;
  filter?: InputMaybe<CloudFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CloudsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFavoriteGameArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFavoriteGameByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFavoriteGamesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FavoriteGameCondition>;
  filter?: InputMaybe<FavoriteGameFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteGamesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeeArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbacksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FeedbackCondition>;
  filter?: InputMaybe<FeedbackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeedbacksOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FeeCondition>;
  filter?: InputMaybe<FeeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFollowingArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFollowingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFollowingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FollowingCondition>;
  filter?: InputMaybe<FollowingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FollowingsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFunniesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FunnyCondition>;
  filter?: InputMaybe<FunnyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FunniesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFunnyArgs = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFunnyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGameArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGameByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGamesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GameCondition>;
  filter?: InputMaybe<GameFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GamesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGenderArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGenderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGendersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenderCondition>;
  filter?: InputMaybe<GenderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GendersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGenreArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGenreByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGenresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenreCondition>;
  filter?: InputMaybe<GenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLikeArgs = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLikeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLikesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMediaArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMediaByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMediaIsPostArgs = {
  mediaId?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMediasArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MediaCondition>;
  filter?: InputMaybe<MediaFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MediaOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMessageArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMessageByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMusicArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMusicByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMusicGenreArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMusicGenreByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMusicGenresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MusicGenreCondition>;
  filter?: InputMaybe<MusicGenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MusicGenresOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMusicsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MusicCondition>;
  filter?: InputMaybe<MusicFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MusicOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNoobArgs = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNoobByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNoobsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoobCondition>;
  filter?: InputMaybe<NoobFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NoobsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationLogArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationLogByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNotificationLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NotificationLogCondition>;
  filter?: InputMaybe<NotificationLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NotificationLogsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOzSubscriptionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOzSubscriptionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOzSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OzSubscriptionCondition>;
  filter?: InputMaybe<OzSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OzSubscriptionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentMethodArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentMethodByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentMethodCondition>;
  filter?: InputMaybe<PaymentMethodFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPostArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostCondition>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPostsReportArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostsReportByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostsReportsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostsReportCondition>;
  filter?: InputMaybe<PostsReportFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsReportsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPostsViewArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostsViewByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostsViewByPostIdAndUserIdArgs = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostsViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostsViewCondition>;
  filter?: InputMaybe<PostsViewFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsViewsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPrivateUserArgs = {
  userId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPrivateUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPrivateUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PrivateUserCondition>;
  filter?: InputMaybe<PrivateUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PrivateUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReportMessageArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReportMessageByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReportMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ReportMessageCondition>;
  filter?: InputMaybe<ReportMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportMessagesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRoleArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRoleByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RoleCondition>;
  filter?: InputMaybe<RoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RolesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRoomArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRoomByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRoomsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RoomCondition>;
  filter?: InputMaybe<RoomFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RoomsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryStickerArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryStickerByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryStickersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StickerCondition>;
  filter?: InputMaybe<StickerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StickersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryStickersTagArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryStickersTagByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryStickersTagsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StickersTagCondition>;
  filter?: InputMaybe<StickersTagFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StickersTagsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTagArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTagByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTagsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TagCondition>;
  filter?: InputMaybe<TagFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TeamCondition>;
  filter?: InputMaybe<TeamFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamsMemberArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamsMemberByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTeamsMembersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TeamsMemberCondition>;
  filter?: InputMaybe<TeamsMemberFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsMembersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUser1IsFollowingUser2Args = {
  user1?: InputMaybe<Scalars['Int']>;
  user2?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserAccessArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserAccessByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserAccessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserAccessCondition>;
  filter?: InputMaybe<UserAccessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserAccessesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserEmailArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserEmailByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserEmailByUserIdArgs = {
  userId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserEmailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserEmailCondition>;
  filter?: InputMaybe<UserEmailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserEmailsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserIsPrivateArgs = {
  userId?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserRoleArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserRoleByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserRoleCondition>;
  filter?: InputMaybe<UserRoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserRolesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsernamesCheckArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUsernamesCheckByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUsernamesChecksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsernamesCheckCondition>;
  filter?: InputMaybe<UsernamesCheckFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsernamesChecksOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersAvailableRoomArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersAvailableRoomByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersAvailableRoomsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersAvailableRoomCondition>;
  filter?: InputMaybe<UsersAvailableRoomFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersAvailableRoomsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersReceivedMessageArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersReceivedMessageByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersReceivedMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersReceivedMessageCondition>;
  filter?: InputMaybe<UsersReceivedMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersReceivedMessagesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWitnessArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWitnessByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWitnessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<WitnessCondition>;
  filter?: InputMaybe<WitnessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WitnessesOrderBy>>;
};

export enum RecurringPayments {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

/** A filter to be used against RecurringPayments fields. All fields are combined with a logical ‘and.’ */
export type RecurringPaymentsFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<RecurringPayments>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<RecurringPayments>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<RecurringPayments>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<RecurringPayments>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<RecurringPayments>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<RecurringPayments>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<RecurringPayments>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<RecurringPayments>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<RecurringPayments>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<RecurringPayments>>;
};

export type ReportMessage = Node & {
  __typename?: 'ReportMessage';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  message?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `PostsReport`. */
  postsReports: PostsReportsConnection;
  severity?: Maybe<Severity>;
  updatedAt: Scalars['Datetime'];
};


export type ReportMessagePostsReportsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostsReportCondition>;
  filter?: InputMaybe<PostsReportFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsReportsOrderBy>>;
};

/**
 * A condition to be used against `ReportMessage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ReportMessageCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `message` field. */
  message?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `severity` field. */
  severity?: InputMaybe<Severity>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `ReportMessage` object types. All fields are combined with a logical ‘and.’ */
export type ReportMessageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ReportMessageFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `message` field. */
  message?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ReportMessageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ReportMessageFilter>>;
  /** Filter by the object’s `severity` field. */
  severity?: InputMaybe<SeverityFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ReportMessage` */
export type ReportMessageInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Severity>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `ReportMessage`. Fields that are set will be updated. */
export type ReportMessagePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Severity>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `ReportMessage` values. */
export type ReportMessagesConnection = {
  __typename?: 'ReportMessagesConnection';
  /** A list of edges which contains the `ReportMessage` and cursor to aid in pagination. */
  edges: Array<ReportMessagesEdge>;
  /** A list of `ReportMessage` objects. */
  nodes: Array<ReportMessage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReportMessage` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReportMessage` edge in the connection. */
export type ReportMessagesEdge = {
  __typename?: 'ReportMessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReportMessage` at the end of the edge. */
  node: ReportMessage;
};

/** Methods to use when ordering `ReportMessage`. */
export enum ReportMessagesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MessageAsc = 'MESSAGE_ASC',
  MessageDesc = 'MESSAGE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SeverityAsc = 'SEVERITY_ASC',
  SeverityDesc = 'SEVERITY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Role = Node & {
  __typename?: 'Role';
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `UserRole`. */
  userRoles: UserRolesConnection;
};


export type RoleUserRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserRoleCondition>;
  filter?: InputMaybe<UserRoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserRolesOrderBy>>;
};

/** A condition to be used against `Role` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type RoleCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Role` object types. All fields are combined with a logical ‘and.’ */
export type RoleFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RoleFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RoleFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RoleFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Role` */
export type RoleInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `Role`. Fields that are set will be updated. */
export type RolePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `Role` values. */
export type RolesConnection = {
  __typename?: 'RolesConnection';
  /** A list of edges which contains the `Role` and cursor to aid in pagination. */
  edges: Array<RolesEdge>;
  /** A list of `Role` objects. */
  nodes: Array<Role>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Role` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Role` edge in the connection. */
export type RolesEdge = {
  __typename?: 'RolesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Role` at the end of the edge. */
  node: Role;
};

/** Methods to use when ordering `Role`. */
export enum RolesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Room = Node & {
  __typename?: 'Room';
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  isGroup: Scalars['Boolean'];
  lastMessageSentAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `Message`. */
  messages: MessagesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Room`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
  /** Reads and enables pagination through a set of `UsersAvailableRoom`. */
  usersAvailableRooms: UsersAvailableRoomsConnection;
};


export type RoomMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};


export type RoomUsersAvailableRoomsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersAvailableRoomCondition>;
  filter?: InputMaybe<UsersAvailableRoomFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersAvailableRoomsOrderBy>>;
};

/** A condition to be used against `Room` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type RoomCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isGroup` field. */
  isGroup?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `lastMessageSentAt` field. */
  lastMessageSentAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Room` object types. All fields are combined with a logical ‘and.’ */
export type RoomFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RoomFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isGroup` field. */
  isGroup?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastMessageSentAt` field. */
  lastMessageSentAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RoomFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RoomFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Room` */
export type RoomInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isGroup?: InputMaybe<Scalars['Boolean']>;
  lastMessageSentAt?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `Room`. Fields that are set will be updated. */
export type RoomPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isGroup?: InputMaybe<Scalars['Boolean']>;
  lastMessageSentAt?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Room` values. */
export type RoomsConnection = {
  __typename?: 'RoomsConnection';
  /** A list of edges which contains the `Room` and cursor to aid in pagination. */
  edges: Array<RoomsEdge>;
  /** A list of `Room` objects. */
  nodes: Array<Room>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Room` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Room` edge in the connection. */
export type RoomsEdge = {
  __typename?: 'RoomsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Room` at the end of the edge. */
  node: Room;
};

/** Methods to use when ordering `Room`. */
export enum RoomsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsGroupAsc = 'IS_GROUP_ASC',
  IsGroupDesc = 'IS_GROUP_DESC',
  LastMessageSentAtAsc = 'LAST_MESSAGE_SENT_AT_ASC',
  LastMessageSentAtDesc = 'LAST_MESSAGE_SENT_AT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export enum Severity {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  None = 'NONE',
  VeryLow = 'VERY_LOW'
}

/** A filter to be used against Severity fields. All fields are combined with a logical ‘and.’ */
export type SeverityFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Severity>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Severity>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Severity>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Severity>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Severity>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Severity>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Severity>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Severity>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Severity>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Severity>>;
};

export type Sticker = Node & {
  __typename?: 'Sticker';
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectId?: Maybe<Scalars['String']>;
  photo: Scalars['String'];
  /** Reads and enables pagination through a set of `StickersTag`. */
  stickersTags: StickersTagsConnection;
  updatedAt: Scalars['Datetime'];
};


export type StickerStickersTagsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StickersTagCondition>;
  filter?: InputMaybe<StickersTagFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StickersTagsOrderBy>>;
};

/** A condition to be used against `Sticker` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type StickerCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `photo` field. */
  photo?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Sticker` object types. All fields are combined with a logical ‘and.’ */
export type StickerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StickerFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StickerFilter>;
  /** Filter by the object’s `objectId` field. */
  objectId?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StickerFilter>>;
  /** Filter by the object’s `photo` field. */
  photo?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Sticker` */
export type StickerInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  objectId?: InputMaybe<Scalars['String']>;
  photo: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `Sticker`. Fields that are set will be updated. */
export type StickerPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  objectId?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `Sticker` values. */
export type StickersConnection = {
  __typename?: 'StickersConnection';
  /** A list of edges which contains the `Sticker` and cursor to aid in pagination. */
  edges: Array<StickersEdge>;
  /** A list of `Sticker` objects. */
  nodes: Array<Sticker>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Sticker` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Sticker` edge in the connection. */
export type StickersEdge = {
  __typename?: 'StickersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Sticker` at the end of the edge. */
  node: Sticker;
};

/** Methods to use when ordering `Sticker`. */
export enum StickersOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  ObjectIdAsc = 'OBJECT_ID_ASC',
  ObjectIdDesc = 'OBJECT_ID_DESC',
  PhotoAsc = 'PHOTO_ASC',
  PhotoDesc = 'PHOTO_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type StickersTag = Node & {
  __typename?: 'StickersTag';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Sticker` that is related to this `StickersTag`. */
  sticker?: Maybe<Sticker>;
  stickerId: Scalars['Int'];
  /** Reads a single `Tag` that is related to this `StickersTag`. */
  tag?: Maybe<Tag>;
  tagId: Scalars['Int'];
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `StickersTag` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type StickersTagCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `stickerId` field. */
  stickerId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `tagId` field. */
  tagId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `StickersTag` object types. All fields are combined with a logical ‘and.’ */
export type StickersTagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StickersTagFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StickersTagFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StickersTagFilter>>;
  /** Filter by the object’s `stickerId` field. */
  stickerId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tagId` field. */
  tagId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `StickersTag` */
export type StickersTagInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  stickerId: Scalars['Int'];
  tagId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `StickersTag`. Fields that are set will be updated. */
export type StickersTagPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  stickerId?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `StickersTag` values. */
export type StickersTagsConnection = {
  __typename?: 'StickersTagsConnection';
  /** A list of edges which contains the `StickersTag` and cursor to aid in pagination. */
  edges: Array<StickersTagsEdge>;
  /** A list of `StickersTag` objects. */
  nodes: Array<StickersTag>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `StickersTag` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `StickersTag` edge in the connection. */
export type StickersTagsEdge = {
  __typename?: 'StickersTagsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `StickersTag` at the end of the edge. */
  node: StickersTag;
};

/** Methods to use when ordering `StickersTag`. */
export enum StickersTagsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StickerIdAsc = 'STICKER_ID_ASC',
  StickerIdDesc = 'STICKER_ID_DESC',
  TagIdAsc = 'TAG_ID_ASC',
  TagIdDesc = 'TAG_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']>;
};

/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type Subscription = {
  __typename?: 'Subscription';
  /** Reads and enables pagination through a set of `Activity`. (live) */
  activities?: Maybe<ActivitiesConnection>;
  /**  (live) */
  activity?: Maybe<Activity>;
  /** Reads a single `Activity` using its globally unique `ID`. (live) */
  activityByNodeId?: Maybe<Activity>;
  /**  (live) */
  ad?: Maybe<Ad>;
  /** Reads a single `Ad` using its globally unique `ID`. (live) */
  adByNodeId?: Maybe<Ad>;
  /** Reads and enables pagination through a set of `Ad`. (live) */
  ads?: Maybe<AdsConnection>;
  /**  (live) */
  archive?: Maybe<Archive>;
  /** Reads a single `Archive` using its globally unique `ID`. (live) */
  archiveByNodeId?: Maybe<Archive>;
  /** Reads and enables pagination through a set of `Archive`. (live) */
  archives?: Maybe<ArchivesConnection>;
  /**  (live) */
  audience?: Maybe<Audience>;
  /** Reads a single `Audience` using its globally unique `ID`. (live) */
  audienceByNodeId?: Maybe<Audience>;
  /**  (live) */
  audienceMeta?: Maybe<AudienceMeta>;
  /** Reads a single `AudienceMeta` using its globally unique `ID`. (live) */
  audienceMetaByNodeId?: Maybe<AudienceMeta>;
  /** Reads and enables pagination through a set of `AudienceMeta`. (live) */
  audienceMetas?: Maybe<AudienceMetasConnection>;
  /** Reads and enables pagination through a set of `Audience`. (live) */
  audiences?: Maybe<AudiencesConnection>;
  /**  (live) */
  beast?: Maybe<Beast>;
  /** Reads a single `Beast` using its globally unique `ID`. (live) */
  beastByNodeId?: Maybe<Beast>;
  /** Reads and enables pagination through a set of `Beast`. (live) */
  beasts?: Maybe<BeastsConnection>;
  /**  (live) */
  blockedUser?: Maybe<BlockedUser>;
  /** Reads a single `BlockedUser` using its globally unique `ID`. (live) */
  blockedUserByNodeId?: Maybe<BlockedUser>;
  /** Reads and enables pagination through a set of `BlockedUser`. (live) */
  blockedUsers?: Maybe<BlockedUsersConnection>;
  /**  (live) */
  budget?: Maybe<Budget>;
  /** Reads a single `Budget` using its globally unique `ID`. (live) */
  budgetByNodeId?: Maybe<Budget>;
  /** Reads and enables pagination through a set of `Budget`. (live) */
  budgets?: Maybe<BudgetsConnection>;
  /**  (live) */
  campaign?: Maybe<Campaign>;
  /** Reads a single `Campaign` using its globally unique `ID`. (live) */
  campaignByNodeId?: Maybe<Campaign>;
  /** Reads and enables pagination through a set of `Campaign`. (live) */
  campaigns?: Maybe<CampaignsConnection>;
  /**  (live) */
  campaignsAction?: Maybe<CampaignsAction>;
  /** Reads a single `CampaignsAction` using its globally unique `ID`. (live) */
  campaignsActionByNodeId?: Maybe<CampaignsAction>;
  /** Reads and enables pagination through a set of `CampaignsAction`. (live) */
  campaignsActions?: Maybe<CampaignsActionsConnection>;
  /**  (live) */
  challenge?: Maybe<Challenge>;
  /** Reads a single `Challenge` using its globally unique `ID`. (live) */
  challengeByNodeId?: Maybe<Challenge>;
  /** Reads and enables pagination through a set of `Challenge`. (live) */
  challenges?: Maybe<ChallengesConnection>;
  /**  (live) */
  challengesProof?: Maybe<ChallengesProof>;
  /** Reads a single `ChallengesProof` using its globally unique `ID`. (live) */
  challengesProofByNodeId?: Maybe<ChallengesProof>;
  /** Reads and enables pagination through a set of `ChallengesProof`. (live) */
  challengesProofs?: Maybe<ChallengesProofsConnection>;
  /**  (live) */
  challengesWitness?: Maybe<ChallengesWitness>;
  /** Reads a single `ChallengesWitness` using its globally unique `ID`. (live) */
  challengesWitnessByNodeId?: Maybe<ChallengesWitness>;
  /** Reads and enables pagination through a set of `ChallengesWitness`. (live) */
  challengesWitnesses?: Maybe<ChallengesWitnessesConnection>;
  /**  (live) */
  cloud?: Maybe<Cloud>;
  /** Reads a single `Cloud` using its globally unique `ID`. (live) */
  cloudByNodeId?: Maybe<Cloud>;
  /** Reads and enables pagination through a set of `Cloud`. (live) */
  clouds?: Maybe<CloudsConnection>;
  /**  (live) */
  currentUserId?: Maybe<Scalars['Int']>;
  /**  (live) */
  favoriteGame?: Maybe<FavoriteGame>;
  /** Reads a single `FavoriteGame` using its globally unique `ID`. (live) */
  favoriteGameByNodeId?: Maybe<FavoriteGame>;
  /** Reads and enables pagination through a set of `FavoriteGame`. (live) */
  favoriteGames?: Maybe<FavoriteGamesConnection>;
  /**  (live) */
  fee?: Maybe<Fee>;
  /** Reads a single `Fee` using its globally unique `ID`. (live) */
  feeByNodeId?: Maybe<Fee>;
  /**  (live) */
  feedback?: Maybe<Feedback>;
  /** Reads a single `Feedback` using its globally unique `ID`. (live) */
  feedbackByNodeId?: Maybe<Feedback>;
  /** Reads and enables pagination through a set of `Feedback`. (live) */
  feedbacks?: Maybe<FeedbacksConnection>;
  /** Reads and enables pagination through a set of `Fee`. (live) */
  fees?: Maybe<FeesConnection>;
  /**  (live) */
  following?: Maybe<Following>;
  /** Reads a single `Following` using its globally unique `ID`. (live) */
  followingByNodeId?: Maybe<Following>;
  /** Reads and enables pagination through a set of `Following`. (live) */
  followings?: Maybe<FollowingsConnection>;
  /** Reads and enables pagination through a set of `Funny`. (live) */
  funnies?: Maybe<FunniesConnection>;
  /**  (live) */
  funny?: Maybe<Funny>;
  /** Reads a single `Funny` using its globally unique `ID`. (live) */
  funnyByNodeId?: Maybe<Funny>;
  /**  (live) */
  game?: Maybe<Game>;
  /** Reads a single `Game` using its globally unique `ID`. (live) */
  gameByNodeId?: Maybe<Game>;
  /** Reads and enables pagination through a set of `Game`. (live) */
  games?: Maybe<GamesConnection>;
  /**  (live) */
  gender?: Maybe<Gender>;
  /** Reads a single `Gender` using its globally unique `ID`. (live) */
  genderByNodeId?: Maybe<Gender>;
  /** Reads and enables pagination through a set of `Gender`. (live) */
  genders?: Maybe<GendersConnection>;
  /**  (live) */
  genre?: Maybe<Genre>;
  /** Reads a single `Genre` using its globally unique `ID`. (live) */
  genreByNodeId?: Maybe<Genre>;
  /** Reads and enables pagination through a set of `Genre`. (live) */
  genres?: Maybe<GenresConnection>;
  /**  (live) */
  like?: Maybe<Like>;
  /** Reads a single `Like` using its globally unique `ID`. (live) */
  likeByNodeId?: Maybe<Like>;
  /** Reads and enables pagination through a set of `Like`. (live) */
  likes?: Maybe<LikesConnection>;
  /**  (live) */
  media?: Maybe<Media>;
  /** Reads a single `Media` using its globally unique `ID`. (live) */
  mediaByNodeId?: Maybe<Media>;
  /**  (live) */
  mediaIsPost?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Media`. (live) */
  medias?: Maybe<MediaConnection>;
  /**  (live) */
  message?: Maybe<Message>;
  /** Reads a single `Message` using its globally unique `ID`. (live) */
  messageByNodeId?: Maybe<Message>;
  /** Reads and enables pagination through a set of `Message`. (live) */
  messages?: Maybe<MessagesConnection>;
  /**  (live) */
  music?: Maybe<Music>;
  /** Reads a single `Music` using its globally unique `ID`. (live) */
  musicByNodeId?: Maybe<Music>;
  /**  (live) */
  musicGenre?: Maybe<MusicGenre>;
  /** Reads a single `MusicGenre` using its globally unique `ID`. (live) */
  musicGenreByNodeId?: Maybe<MusicGenre>;
  /** Reads and enables pagination through a set of `MusicGenre`. (live) */
  musicGenres?: Maybe<MusicGenresConnection>;
  /** Reads and enables pagination through a set of `Music`. (live) */
  musics?: Maybe<MusicConnection>;
  /** Fetches an object given its globally unique `ID`. (live) */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. (live) */
  nodeId: Scalars['ID'];
  /**  (live) */
  noob?: Maybe<Noob>;
  /** Reads a single `Noob` using its globally unique `ID`. (live) */
  noobByNodeId?: Maybe<Noob>;
  /** Reads and enables pagination through a set of `Noob`. (live) */
  noobs?: Maybe<NoobsConnection>;
  /**  (live) */
  notificationLog?: Maybe<NotificationLog>;
  /** Reads a single `NotificationLog` using its globally unique `ID`. (live) */
  notificationLogByNodeId?: Maybe<NotificationLog>;
  /** Reads and enables pagination through a set of `NotificationLog`. (live) */
  notificationLogs?: Maybe<NotificationLogsConnection>;
  /**  (live) */
  ozSubscription?: Maybe<OzSubscription>;
  /** Reads a single `OzSubscription` using its globally unique `ID`. (live) */
  ozSubscriptionByNodeId?: Maybe<OzSubscription>;
  /** Reads and enables pagination through a set of `OzSubscription`. (live) */
  ozSubscriptions?: Maybe<OzSubscriptionsConnection>;
  /**  (live) */
  paymentMethod?: Maybe<PaymentMethod>;
  /** Reads a single `PaymentMethod` using its globally unique `ID`. (live) */
  paymentMethodByNodeId?: Maybe<PaymentMethod>;
  /** Reads and enables pagination through a set of `PaymentMethod`. (live) */
  paymentMethods?: Maybe<PaymentMethodsConnection>;
  /**  (live) */
  post?: Maybe<Post>;
  /** Reads a single `Post` using its globally unique `ID`. (live) */
  postByNodeId?: Maybe<Post>;
  /** Reads and enables pagination through a set of `Post`. (live) */
  posts?: Maybe<PostsConnection>;
  /**  (live) */
  postsReport?: Maybe<PostsReport>;
  /** Reads a single `PostsReport` using its globally unique `ID`. (live) */
  postsReportByNodeId?: Maybe<PostsReport>;
  /** Reads and enables pagination through a set of `PostsReport`. (live) */
  postsReports?: Maybe<PostsReportsConnection>;
  /**  (live) */
  postsView?: Maybe<PostsView>;
  /** Reads a single `PostsView` using its globally unique `ID`. (live) */
  postsViewByNodeId?: Maybe<PostsView>;
  /**  (live) */
  postsViewByPostIdAndUserId?: Maybe<PostsView>;
  /** Reads and enables pagination through a set of `PostsView`. (live) */
  postsViews?: Maybe<PostsViewsConnection>;
  /**  (live) */
  privateUser?: Maybe<PrivateUser>;
  /** Reads a single `PrivateUser` using its globally unique `ID`. (live) */
  privateUserByNodeId?: Maybe<PrivateUser>;
  /** Reads and enables pagination through a set of `PrivateUser`. (live) */
  privateUsers?: Maybe<PrivateUsersConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form. (live)
   */
  query: Query;
  /**  (live) */
  reportMessage?: Maybe<ReportMessage>;
  /** Reads a single `ReportMessage` using its globally unique `ID`. (live) */
  reportMessageByNodeId?: Maybe<ReportMessage>;
  /** Reads and enables pagination through a set of `ReportMessage`. (live) */
  reportMessages?: Maybe<ReportMessagesConnection>;
  /**  (live) */
  role?: Maybe<Role>;
  /** Reads a single `Role` using its globally unique `ID`. (live) */
  roleByNodeId?: Maybe<Role>;
  /** Reads and enables pagination through a set of `Role`. (live) */
  roles?: Maybe<RolesConnection>;
  /**  (live) */
  room?: Maybe<Room>;
  /** Reads a single `Room` using its globally unique `ID`. (live) */
  roomByNodeId?: Maybe<Room>;
  /** Reads and enables pagination through a set of `Room`. (live) */
  rooms?: Maybe<RoomsConnection>;
  /**  (live) */
  sticker?: Maybe<Sticker>;
  /** Reads a single `Sticker` using its globally unique `ID`. (live) */
  stickerByNodeId?: Maybe<Sticker>;
  /** Reads and enables pagination through a set of `Sticker`. (live) */
  stickers?: Maybe<StickersConnection>;
  /**  (live) */
  stickersTag?: Maybe<StickersTag>;
  /** Reads a single `StickersTag` using its globally unique `ID`. (live) */
  stickersTagByNodeId?: Maybe<StickersTag>;
  /** Reads and enables pagination through a set of `StickersTag`. (live) */
  stickersTags?: Maybe<StickersTagsConnection>;
  /**  (live) */
  tag?: Maybe<Tag>;
  /** Reads a single `Tag` using its globally unique `ID`. (live) */
  tagByNodeId?: Maybe<Tag>;
  /** Reads and enables pagination through a set of `Tag`. (live) */
  tags?: Maybe<TagsConnection>;
  /**  (live) */
  team?: Maybe<Team>;
  /**  (live) */
  teamByName?: Maybe<Team>;
  /** Reads a single `Team` using its globally unique `ID`. (live) */
  teamByNodeId?: Maybe<Team>;
  /** Reads and enables pagination through a set of `Team`. (live) */
  teams?: Maybe<TeamsConnection>;
  /**  (live) */
  teamsMember?: Maybe<TeamsMember>;
  /** Reads a single `TeamsMember` using its globally unique `ID`. (live) */
  teamsMemberByNodeId?: Maybe<TeamsMember>;
  /** Reads and enables pagination through a set of `TeamsMember`. (live) */
  teamsMembers?: Maybe<TeamsMembersConnection>;
  /**  (live) */
  transaction?: Maybe<Transaction>;
  /** Reads a single `Transaction` using its globally unique `ID`. (live) */
  transactionByNodeId?: Maybe<Transaction>;
  /** Reads and enables pagination through a set of `Transaction`. (live) */
  transactions?: Maybe<TransactionsConnection>;
  /**  (live) */
  user?: Maybe<User>;
  /**  (live) */
  user1IsFollowingUser2?: Maybe<Scalars['Boolean']>;
  /**  (live) */
  userAccess?: Maybe<UserAccess>;
  /** Reads a single `UserAccess` using its globally unique `ID`. (live) */
  userAccessByNodeId?: Maybe<UserAccess>;
  /** Reads and enables pagination through a set of `UserAccess`. (live) */
  userAccesses?: Maybe<UserAccessesConnection>;
  /** Reads a single `User` using its globally unique `ID`. (live) */
  userByNodeId?: Maybe<User>;
  /**  (live) */
  userEmail?: Maybe<UserEmail>;
  /** Reads a single `UserEmail` using its globally unique `ID`. (live) */
  userEmailByNodeId?: Maybe<UserEmail>;
  /**  (live) */
  userEmailByUserId?: Maybe<UserEmail>;
  /** Reads and enables pagination through a set of `UserEmail`. (live) */
  userEmails?: Maybe<UserEmailsConnection>;
  /**  (live) */
  userIsAdmin?: Maybe<Scalars['Boolean']>;
  /**  (live) */
  userIsPlus?: Maybe<Scalars['Boolean']>;
  /**  (live) */
  userIsPrivate?: Maybe<Scalars['Boolean']>;
  /**  (live) */
  userRole?: Maybe<UserRole>;
  /** Reads a single `UserRole` using its globally unique `ID`. (live) */
  userRoleByNodeId?: Maybe<UserRole>;
  /** Reads and enables pagination through a set of `UserRole`. (live) */
  userRoles?: Maybe<UserRolesConnection>;
  /**  (live) */
  usernamesCheck?: Maybe<UsernamesCheck>;
  /** Reads a single `UsernamesCheck` using its globally unique `ID`. (live) */
  usernamesCheckByNodeId?: Maybe<UsernamesCheck>;
  /** Reads and enables pagination through a set of `UsernamesCheck`. (live) */
  usernamesChecks?: Maybe<UsernamesChecksConnection>;
  /** Reads and enables pagination through a set of `User`. (live) */
  users?: Maybe<UsersConnection>;
  /**  (live) */
  usersAvailableRoom?: Maybe<UsersAvailableRoom>;
  /** Reads a single `UsersAvailableRoom` using its globally unique `ID`. (live) */
  usersAvailableRoomByNodeId?: Maybe<UsersAvailableRoom>;
  /** Reads and enables pagination through a set of `UsersAvailableRoom`. (live) */
  usersAvailableRooms?: Maybe<UsersAvailableRoomsConnection>;
  /**  (live) */
  usersReceivedMessage?: Maybe<UsersReceivedMessage>;
  /** Reads a single `UsersReceivedMessage` using its globally unique `ID`. (live) */
  usersReceivedMessageByNodeId?: Maybe<UsersReceivedMessage>;
  /** Reads and enables pagination through a set of `UsersReceivedMessage`. (live) */
  usersReceivedMessages?: Maybe<UsersReceivedMessagesConnection>;
  /**  (live) */
  witness?: Maybe<Witness>;
  /** Reads a single `Witness` using its globally unique `ID`. (live) */
  witnessByNodeId?: Maybe<Witness>;
  /** Reads and enables pagination through a set of `Witness`. (live) */
  witnesses?: Maybe<WitnessesConnection>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionActivitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ActivityCondition>;
  filter?: InputMaybe<ActivityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionActivityArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionActivityByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAdArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAdByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAdsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AdCondition>;
  filter?: InputMaybe<AdFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AdsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionArchiveArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionArchiveByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionArchivesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArchiveCondition>;
  filter?: InputMaybe<ArchiveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArchivesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAudienceArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAudienceByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAudienceMetaArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAudienceMetaByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAudienceMetasArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AudienceMetaCondition>;
  filter?: InputMaybe<AudienceMetaFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AudienceMetasOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionAudiencesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AudienceCondition>;
  filter?: InputMaybe<AudienceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AudiencesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionBeastArgs = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionBeastByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionBeastsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BeastCondition>;
  filter?: InputMaybe<BeastFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BeastsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionBlockedUserArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionBlockedUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionBlockedUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BlockedUserCondition>;
  filter?: InputMaybe<BlockedUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BlockedUsersOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionBudgetArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionBudgetByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionBudgetsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BudgetCondition>;
  filter?: InputMaybe<BudgetFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BudgetsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCampaignArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCampaignByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCampaignsActionArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCampaignsActionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCampaignsActionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CampaignsActionCondition>;
  filter?: InputMaybe<CampaignsActionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CampaignsActionsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionChallengeArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionChallengeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionChallengesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengeCondition>;
  filter?: InputMaybe<ChallengeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionChallengesProofArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionChallengesProofByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionChallengesProofsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengesProofCondition>;
  filter?: InputMaybe<ChallengesProofFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesProofsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionChallengesWitnessArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionChallengesWitnessByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionChallengesWitnessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengesWitnessCondition>;
  filter?: InputMaybe<ChallengesWitnessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesWitnessesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCloudArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCloudByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionCloudsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CloudCondition>;
  filter?: InputMaybe<CloudFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CloudsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFavoriteGameArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFavoriteGameByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFavoriteGamesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FavoriteGameCondition>;
  filter?: InputMaybe<FavoriteGameFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteGamesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeeArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedbackArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedbackByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeedbacksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FeedbackCondition>;
  filter?: InputMaybe<FeedbackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeedbacksOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFeesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FeeCondition>;
  filter?: InputMaybe<FeeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFollowingArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFollowingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFollowingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FollowingCondition>;
  filter?: InputMaybe<FollowingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FollowingsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFunniesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FunnyCondition>;
  filter?: InputMaybe<FunnyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FunniesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFunnyArgs = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionFunnyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGameArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGameByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGamesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GameCondition>;
  filter?: InputMaybe<GameFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GamesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGenderArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGenderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGendersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenderCondition>;
  filter?: InputMaybe<GenderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GendersOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGenreArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGenreByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionGenresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenreCondition>;
  filter?: InputMaybe<GenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLikeArgs = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLikeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionLikesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMediaArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMediaByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMediaIsPostArgs = {
  mediaId?: InputMaybe<Scalars['Int']>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMediasArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MediaCondition>;
  filter?: InputMaybe<MediaFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MediaOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMessageArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMessageByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMusicArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMusicByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMusicGenreArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMusicGenreByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMusicGenresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MusicGenreCondition>;
  filter?: InputMaybe<MusicGenreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MusicGenresOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionMusicsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MusicCondition>;
  filter?: InputMaybe<MusicFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MusicOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionNodeArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionNoobArgs = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionNoobByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionNoobsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoobCondition>;
  filter?: InputMaybe<NoobFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NoobsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionNotificationLogArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionNotificationLogByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionNotificationLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NotificationLogCondition>;
  filter?: InputMaybe<NotificationLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NotificationLogsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionOzSubscriptionArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionOzSubscriptionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionOzSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OzSubscriptionCondition>;
  filter?: InputMaybe<OzSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OzSubscriptionsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPaymentMethodArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPaymentMethodByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentMethodCondition>;
  filter?: InputMaybe<PaymentMethodFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPostArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPostByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostCondition>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPostsReportArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPostsReportByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPostsReportsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostsReportCondition>;
  filter?: InputMaybe<PostsReportFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsReportsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPostsViewArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPostsViewByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPostsViewByPostIdAndUserIdArgs = {
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPostsViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostsViewCondition>;
  filter?: InputMaybe<PostsViewFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsViewsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPrivateUserArgs = {
  userId: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPrivateUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionPrivateUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PrivateUserCondition>;
  filter?: InputMaybe<PrivateUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PrivateUsersOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionReportMessageArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionReportMessageByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionReportMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ReportMessageCondition>;
  filter?: InputMaybe<ReportMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportMessagesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionRoleArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionRoleByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RoleCondition>;
  filter?: InputMaybe<RoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RolesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionRoomArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionRoomByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionRoomsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RoomCondition>;
  filter?: InputMaybe<RoomFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RoomsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionStickerArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionStickerByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionStickersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StickerCondition>;
  filter?: InputMaybe<StickerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StickersOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionStickersTagArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionStickersTagByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionStickersTagsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StickersTagCondition>;
  filter?: InputMaybe<StickersTagFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StickersTagsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTagArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTagByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTagsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TagCondition>;
  filter?: InputMaybe<TagFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTeamArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTeamByNameArgs = {
  name: Scalars['String'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTeamByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTeamsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TeamCondition>;
  filter?: InputMaybe<TeamFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTeamsMemberArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTeamsMemberByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTeamsMembersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TeamsMemberCondition>;
  filter?: InputMaybe<TeamsMemberFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsMembersOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTransactionArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTransactionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUser1IsFollowingUser2Args = {
  user1?: InputMaybe<Scalars['Int']>;
  user2?: InputMaybe<Scalars['Int']>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserAccessArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserAccessByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserAccessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserAccessCondition>;
  filter?: InputMaybe<UserAccessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserAccessesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserEmailArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserEmailByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserEmailByUserIdArgs = {
  userId: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserEmailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserEmailCondition>;
  filter?: InputMaybe<UserEmailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserEmailsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserIsPrivateArgs = {
  userId?: InputMaybe<Scalars['Int']>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserRoleArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserRoleByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUserRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserRoleCondition>;
  filter?: InputMaybe<UserRoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserRolesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUsernamesCheckArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUsernamesCheckByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUsernamesChecksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsernamesCheckCondition>;
  filter?: InputMaybe<UsernamesCheckFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsernamesChecksOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUsersAvailableRoomArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUsersAvailableRoomByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUsersAvailableRoomsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersAvailableRoomCondition>;
  filter?: InputMaybe<UsersAvailableRoomFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersAvailableRoomsOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUsersReceivedMessageArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUsersReceivedMessageByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionUsersReceivedMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersReceivedMessageCondition>;
  filter?: InputMaybe<UsersReceivedMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersReceivedMessagesOrderBy>>;
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWitnessArgs = {
  id: Scalars['Int'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWitnessByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/**
 * The root subscription type: contains events and live queries you can subscribe to with the `subscription` operation.
 *
 * #### Live Queries
 *
 * Live query fields are differentiated by containing `(live)` at the end of their
 * description, they are added for each field in the `Query` type. When you
 * subscribe to a live query field, the selection set will be evaluated and sent to
 * the client, and then most things\* that would cause the output of the selection
 * set to change will trigger the selection set to be re-evaluated and the results
 * to be re-sent to the client.
 *
 * _(\* Not everything: typically only changes to persisted data referenced by the query are detected, not computed fields.)_
 *
 * Live queries can be very expensive, so try and keep them small and focussed.
 *
 * #### Events
 *
 * Event fields will run their selection set when, and only when, the specified
 * server-side event occurs. This makes them a lot more efficient than Live
 * Queries, but it is still recommended that you keep payloads fairly small.
 */
export type SubscriptionWitnessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<WitnessCondition>;
  filter?: InputMaybe<WitnessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WitnessesOrderBy>>;
};

export type Tag = Node & {
  __typename?: 'Tag';
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectId: Scalars['String'];
  /** Reads and enables pagination through a set of `StickersTag`. */
  stickersTags: StickersTagsConnection;
  updatedAt: Scalars['Datetime'];
};


export type TagStickersTagsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<StickersTagCondition>;
  filter?: InputMaybe<StickersTagFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<StickersTagsOrderBy>>;
};

/** A condition to be used against `Tag` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TagCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Tag` object types. All fields are combined with a logical ‘and.’ */
export type TagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TagFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TagFilter>;
  /** Filter by the object’s `objectId` field. */
  objectId?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TagFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Tag` */
export type TagInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  objectId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `Tag`. Fields that are set will be updated. */
export type TagPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  objectId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `Tag` values. */
export type TagsConnection = {
  __typename?: 'TagsConnection';
  /** A list of edges which contains the `Tag` and cursor to aid in pagination. */
  edges: Array<TagsEdge>;
  /** A list of `Tag` objects. */
  nodes: Array<Tag>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Tag` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Tag` edge in the connection. */
export type TagsEdge = {
  __typename?: 'TagsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Tag` at the end of the edge. */
  node: Tag;
};

/** Methods to use when ordering `Tag`. */
export enum TagsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  ObjectIdAsc = 'OBJECT_ID_ASC',
  ObjectIdDesc = 'OBJECT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Team = Node & {
  __typename?: 'Team';
  /** Reads and enables pagination through a set of `Activity`. */
  activities: ActivitiesConnection;
  /** Reads and enables pagination through a set of `Challenge`. */
  challengesByChallengedTeamId: ChallengesConnection;
  /** Reads and enables pagination through a set of `Challenge`. */
  challengesByInitiatorTeamId: ChallengesConnection;
  createdAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Team`. */
  createdBy?: Maybe<User>;
  createdById: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  nbChallengesWon?: Maybe<Scalars['Int']>;
  nbMembers: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  objectId?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `TeamsMember`. */
  teamsMembers: TeamsMembersConnection;
  updatedAt: Scalars['Datetime'];
};


export type TeamActivitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ActivityCondition>;
  filter?: InputMaybe<ActivityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};


export type TeamChallengesByChallengedTeamIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengeCondition>;
  filter?: InputMaybe<ChallengeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesOrderBy>>;
};


export type TeamChallengesByInitiatorTeamIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengeCondition>;
  filter?: InputMaybe<ChallengeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesOrderBy>>;
};


export type TeamTeamsMembersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TeamsMemberCondition>;
  filter?: InputMaybe<TeamsMemberFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsMembersOrderBy>>;
};

/** A condition to be used against `Team` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TeamCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdById` field. */
  createdById?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `nbChallengesWon` field. */
  nbChallengesWon?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `nbMembers` field. */
  nbMembers?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `photo` field. */
  photo?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `Team` object types. All fields are combined with a logical ‘and.’ */
export type TeamFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TeamFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdById` field. */
  createdById?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Filter by the object’s `nbChallengesWon` field. */
  nbChallengesWon?: InputMaybe<IntFilter>;
  /** Filter by the object’s `nbMembers` field. */
  nbMembers?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TeamFilter>;
  /** Filter by the object’s `objectId` field. */
  objectId?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TeamFilter>>;
  /** Filter by the object’s `photo` field. */
  photo?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Team` */
export type TeamInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  createdById: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  nbChallengesWon?: InputMaybe<Scalars['Int']>;
  nbMembers?: InputMaybe<Scalars['Int']>;
  objectId?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `Team`. Fields that are set will be updated. */
export type TeamPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  createdById?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nbChallengesWon?: InputMaybe<Scalars['Int']>;
  nbMembers?: InputMaybe<Scalars['Int']>;
  objectId?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `Team` values. */
export type TeamsConnection = {
  __typename?: 'TeamsConnection';
  /** A list of edges which contains the `Team` and cursor to aid in pagination. */
  edges: Array<TeamsEdge>;
  /** A list of `Team` objects. */
  nodes: Array<Team>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Team` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Team` edge in the connection. */
export type TeamsEdge = {
  __typename?: 'TeamsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Team` at the end of the edge. */
  node: Team;
};

export type TeamsMember = Node & {
  __typename?: 'TeamsMember';
  accepted: Scalars['Boolean'];
  acceptedAt?: Maybe<Scalars['Datetime']>;
  bannedAt?: Maybe<Scalars['Datetime']>;
  bannedBy?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  isTeamLead: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  removedAt?: Maybe<Scalars['Datetime']>;
  removedBy?: Maybe<Scalars['Int']>;
  /** Reads a single `Team` that is related to this `TeamsMember`. */
  team?: Maybe<Team>;
  teamId: Scalars['Int'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `TeamsMember`. */
  user?: Maybe<User>;
  /** Reads a single `User` that is related to this `TeamsMember`. */
  userByBannedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `TeamsMember`. */
  userByRemovedBy?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `TeamsMember` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TeamsMemberCondition = {
  /** Checks for equality with the object’s `accepted` field. */
  accepted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `acceptedAt` field. */
  acceptedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `bannedAt` field. */
  bannedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `bannedBy` field. */
  bannedBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isTeamLead` field. */
  isTeamLead?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `removedAt` field. */
  removedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `removedBy` field. */
  removedBy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `teamId` field. */
  teamId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `TeamsMember` object types. All fields are combined with a logical ‘and.’ */
export type TeamsMemberFilter = {
  /** Filter by the object’s `accepted` field. */
  accepted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `acceptedAt` field. */
  acceptedAt?: InputMaybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TeamsMemberFilter>>;
  /** Filter by the object’s `bannedAt` field. */
  bannedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `bannedBy` field. */
  bannedBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isTeamLead` field. */
  isTeamLead?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TeamsMemberFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TeamsMemberFilter>>;
  /** Filter by the object’s `removedAt` field. */
  removedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `removedBy` field. */
  removedBy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `teamId` field. */
  teamId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `TeamsMember` */
export type TeamsMemberInput = {
  accepted?: InputMaybe<Scalars['Boolean']>;
  acceptedAt?: InputMaybe<Scalars['Datetime']>;
  bannedAt?: InputMaybe<Scalars['Datetime']>;
  bannedBy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isTeamLead?: InputMaybe<Scalars['Boolean']>;
  removedAt?: InputMaybe<Scalars['Datetime']>;
  removedBy?: InputMaybe<Scalars['Int']>;
  teamId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `TeamsMember`. Fields that are set will be updated. */
export type TeamsMemberPatch = {
  accepted?: InputMaybe<Scalars['Boolean']>;
  acceptedAt?: InputMaybe<Scalars['Datetime']>;
  bannedAt?: InputMaybe<Scalars['Datetime']>;
  bannedBy?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  isTeamLead?: InputMaybe<Scalars['Boolean']>;
  removedAt?: InputMaybe<Scalars['Datetime']>;
  removedBy?: InputMaybe<Scalars['Int']>;
  teamId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `TeamsMember` values. */
export type TeamsMembersConnection = {
  __typename?: 'TeamsMembersConnection';
  /** A list of edges which contains the `TeamsMember` and cursor to aid in pagination. */
  edges: Array<TeamsMembersEdge>;
  /** A list of `TeamsMember` objects. */
  nodes: Array<TeamsMember>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TeamsMember` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TeamsMember` edge in the connection. */
export type TeamsMembersEdge = {
  __typename?: 'TeamsMembersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TeamsMember` at the end of the edge. */
  node: TeamsMember;
};

/** Methods to use when ordering `TeamsMember`. */
export enum TeamsMembersOrderBy {
  AcceptedAsc = 'ACCEPTED_ASC',
  AcceptedAtAsc = 'ACCEPTED_AT_ASC',
  AcceptedAtDesc = 'ACCEPTED_AT_DESC',
  AcceptedDesc = 'ACCEPTED_DESC',
  BannedAtAsc = 'BANNED_AT_ASC',
  BannedAtDesc = 'BANNED_AT_DESC',
  BannedByAsc = 'BANNED_BY_ASC',
  BannedByDesc = 'BANNED_BY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsTeamLeadAsc = 'IS_TEAM_LEAD_ASC',
  IsTeamLeadDesc = 'IS_TEAM_LEAD_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RemovedAtAsc = 'REMOVED_AT_ASC',
  RemovedAtDesc = 'REMOVED_AT_DESC',
  RemovedByAsc = 'REMOVED_BY_ASC',
  RemovedByDesc = 'REMOVED_BY_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** Methods to use when ordering `Team`. */
export enum TeamsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByIdAsc = 'CREATED_BY_ID_ASC',
  CreatedByIdDesc = 'CREATED_BY_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NbChallengesWonAsc = 'NB_CHALLENGES_WON_ASC',
  NbChallengesWonDesc = 'NB_CHALLENGES_WON_DESC',
  NbMembersAsc = 'NB_MEMBERS_ASC',
  NbMembersDesc = 'NB_MEMBERS_DESC',
  ObjectIdAsc = 'OBJECT_ID_ASC',
  ObjectIdDesc = 'OBJECT_ID_DESC',
  PhotoAsc = 'PHOTO_ASC',
  PhotoDesc = 'PHOTO_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Transaction = Node & {
  __typename?: 'Transaction';
  amount: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  currency: Currencies;
  /** Reads a single `Fee` that is related to this `Transaction`. */
  fee?: Maybe<Fee>;
  feeId: Scalars['Int'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `OzSubscription`. */
  ozSubscriptions: OzSubscriptionsConnection;
  /** Reads a single `PaymentMethod` that is related to this `Transaction`. */
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId: Scalars['Int'];
  stripeError?: Maybe<Scalars['JSON']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  stripeStatus: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Transaction`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};


export type TransactionOzSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OzSubscriptionCondition>;
  filter?: InputMaybe<OzSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OzSubscriptionsOrderBy>>;
};

/**
 * A condition to be used against `Transaction` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TransactionCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currency` field. */
  currency?: InputMaybe<Currencies>;
  /** Checks for equality with the object’s `feeId` field. */
  feeId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paymentMethodId` field. */
  paymentMethodId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `stripeError` field. */
  stripeError?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `stripePaymentId` field. */
  stripePaymentId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `stripeStatus` field. */
  stripeStatus?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type TransactionFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TransactionFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currency` field. */
  currency?: InputMaybe<CurrenciesFilter>;
  /** Filter by the object’s `feeId` field. */
  feeId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TransactionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TransactionFilter>>;
  /** Filter by the object’s `paymentMethodId` field. */
  paymentMethodId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `stripePaymentId` field. */
  stripePaymentId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stripeStatus` field. */
  stripeStatus?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Transaction` */
export type TransactionInput = {
  amount: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currency: Currencies;
  feeId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  paymentMethodId: Scalars['Int'];
  stripeError?: InputMaybe<Scalars['JSON']>;
  stripePaymentId?: InputMaybe<Scalars['String']>;
  stripeStatus: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `Transaction`. Fields that are set will be updated. */
export type TransactionPatch = {
  amount?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currency?: InputMaybe<Currencies>;
  feeId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  paymentMethodId?: InputMaybe<Scalars['Int']>;
  stripeError?: InputMaybe<Scalars['JSON']>;
  stripePaymentId?: InputMaybe<Scalars['String']>;
  stripeStatus?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Transaction` values. */
export type TransactionsConnection = {
  __typename?: 'TransactionsConnection';
  /** A list of edges which contains the `Transaction` and cursor to aid in pagination. */
  edges: Array<TransactionsEdge>;
  /** A list of `Transaction` objects. */
  nodes: Array<Transaction>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Transaction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Transaction` edge in the connection. */
export type TransactionsEdge = {
  __typename?: 'TransactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Transaction` at the end of the edge. */
  node: Transaction;
};

/** Methods to use when ordering `Transaction`. */
export enum TransactionsOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CurrencyAsc = 'CURRENCY_ASC',
  CurrencyDesc = 'CURRENCY_DESC',
  FeeIdAsc = 'FEE_ID_ASC',
  FeeIdDesc = 'FEE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PaymentMethodIdAsc = 'PAYMENT_METHOD_ID_ASC',
  PaymentMethodIdDesc = 'PAYMENT_METHOD_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StripeErrorAsc = 'STRIPE_ERROR_ASC',
  StripeErrorDesc = 'STRIPE_ERROR_DESC',
  StripePaymentIdAsc = 'STRIPE_PAYMENT_ID_ASC',
  StripePaymentIdDesc = 'STRIPE_PAYMENT_ID_DESC',
  StripeStatusAsc = 'STRIPE_STATUS_ASC',
  StripeStatusDesc = 'STRIPE_STATUS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** All input for the `updateActivityByNodeId` mutation. */
export type UpdateActivityByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Activity` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Activity` being updated. */
  patch: ActivityPatch;
};

/** All input for the `updateActivity` mutation. */
export type UpdateActivityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Activity` being updated. */
  patch: ActivityPatch;
};

/** The output of our update `Activity` mutation. */
export type UpdateActivityPayload = {
  __typename?: 'UpdateActivityPayload';
  /** The `Activity` that was updated by this mutation. */
  activity?: Maybe<Activity>;
  /** An edge for our `Activity`. May be used by Relay 1. */
  activityEdge?: Maybe<ActivitiesEdge>;
  /** Reads a single `User` that is related to this `Activity`. */
  affectedUser?: Maybe<User>;
  /** Reads a single `Challenge` that is related to this `Activity`. */
  challenge?: Maybe<Challenge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Media` that is related to this `Activity`. */
  media?: Maybe<Media>;
  /** Reads a single `Post` that is related to this `Activity`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `Activity`. */
  team?: Maybe<Team>;
  /** Reads a single `User` that is related to this `Activity`. */
  user?: Maybe<User>;
};


/** The output of our update `Activity` mutation. */
export type UpdateActivityPayloadActivityEdgeArgs = {
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};

/** All input for the `updateAdByNodeId` mutation. */
export type UpdateAdByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Ad` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Ad` being updated. */
  patch: AdPatch;
};

/** All input for the `updateAd` mutation. */
export type UpdateAdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Ad` being updated. */
  patch: AdPatch;
};

/** The output of our update `Ad` mutation. */
export type UpdateAdPayload = {
  __typename?: 'UpdateAdPayload';
  /** The `Ad` that was updated by this mutation. */
  ad?: Maybe<Ad>;
  /** An edge for our `Ad`. May be used by Relay 1. */
  adEdge?: Maybe<AdsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Ad` mutation. */
export type UpdateAdPayloadAdEdgeArgs = {
  orderBy?: InputMaybe<Array<AdsOrderBy>>;
};

/** All input for the `updateArchiveByNodeId` mutation. */
export type UpdateArchiveByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Archive` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Archive` being updated. */
  patch: ArchivePatch;
};

/** All input for the `updateArchive` mutation. */
export type UpdateArchiveInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Archive` being updated. */
  patch: ArchivePatch;
};

/** The output of our update `Archive` mutation. */
export type UpdateArchivePayload = {
  __typename?: 'UpdateArchivePayload';
  /** The `Archive` that was updated by this mutation. */
  archive?: Maybe<Archive>;
  /** An edge for our `Archive`. May be used by Relay 1. */
  archiveEdge?: Maybe<ArchivesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Cloud` that is related to this `Archive`. */
  cloud?: Maybe<Cloud>;
  /** Reads a single `Post` that is related to this `Archive`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Archive`. */
  user?: Maybe<User>;
};


/** The output of our update `Archive` mutation. */
export type UpdateArchivePayloadArchiveEdgeArgs = {
  orderBy?: InputMaybe<Array<ArchivesOrderBy>>;
};

/** All input for the `updateAudienceByNodeId` mutation. */
export type UpdateAudienceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Audience` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Audience` being updated. */
  patch: AudiencePatch;
};

/** All input for the `updateAudience` mutation. */
export type UpdateAudienceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Audience` being updated. */
  patch: AudiencePatch;
};

/** All input for the `updateAudienceMetaByNodeId` mutation. */
export type UpdateAudienceMetaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AudienceMeta` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `AudienceMeta` being updated. */
  patch: AudienceMetaPatch;
};

/** All input for the `updateAudienceMeta` mutation. */
export type UpdateAudienceMetaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `AudienceMeta` being updated. */
  patch: AudienceMetaPatch;
};

/** The output of our update `AudienceMeta` mutation. */
export type UpdateAudienceMetaPayload = {
  __typename?: 'UpdateAudienceMetaPayload';
  /** Reads a single `Audience` that is related to this `AudienceMeta`. */
  audience?: Maybe<Audience>;
  /** The `AudienceMeta` that was updated by this mutation. */
  audienceMeta?: Maybe<AudienceMeta>;
  /** An edge for our `AudienceMeta`. May be used by Relay 1. */
  audienceMetaEdge?: Maybe<AudienceMetasEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Gender` that is related to this `AudienceMeta`. */
  gender?: Maybe<Gender>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `AudienceMeta` mutation. */
export type UpdateAudienceMetaPayloadAudienceMetaEdgeArgs = {
  orderBy?: InputMaybe<Array<AudienceMetasOrderBy>>;
};

/** The output of our update `Audience` mutation. */
export type UpdateAudiencePayload = {
  __typename?: 'UpdateAudiencePayload';
  /** The `Audience` that was updated by this mutation. */
  audience?: Maybe<Audience>;
  /** An edge for our `Audience`. May be used by Relay 1. */
  audienceEdge?: Maybe<AudiencesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Audience`. */
  user?: Maybe<User>;
};


/** The output of our update `Audience` mutation. */
export type UpdateAudiencePayloadAudienceEdgeArgs = {
  orderBy?: InputMaybe<Array<AudiencesOrderBy>>;
};

/** All input for the `updateBeastByNodeId` mutation. */
export type UpdateBeastByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Beast` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Beast` being updated. */
  patch: BeastPatch;
};

/** All input for the `updateBeast` mutation. */
export type UpdateBeastInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Beast` being updated. */
  patch: BeastPatch;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** The output of our update `Beast` mutation. */
export type UpdateBeastPayload = {
  __typename?: 'UpdateBeastPayload';
  /** The `Beast` that was updated by this mutation. */
  beast?: Maybe<Beast>;
  /** An edge for our `Beast`. May be used by Relay 1. */
  beastEdge?: Maybe<BeastsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Post` that is related to this `Beast`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Beast`. */
  user?: Maybe<User>;
};


/** The output of our update `Beast` mutation. */
export type UpdateBeastPayloadBeastEdgeArgs = {
  orderBy?: InputMaybe<Array<BeastsOrderBy>>;
};

/** All input for the `updateBlockedUserByNodeId` mutation. */
export type UpdateBlockedUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BlockedUser` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BlockedUser` being updated. */
  patch: BlockedUserPatch;
};

/** All input for the `updateBlockedUser` mutation. */
export type UpdateBlockedUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `BlockedUser` being updated. */
  patch: BlockedUserPatch;
};

/** The output of our update `BlockedUser` mutation. */
export type UpdateBlockedUserPayload = {
  __typename?: 'UpdateBlockedUserPayload';
  /** The `BlockedUser` that was updated by this mutation. */
  blockedUser?: Maybe<BlockedUser>;
  /** An edge for our `BlockedUser`. May be used by Relay 1. */
  blockedUserEdge?: Maybe<BlockedUsersEdge>;
  /** Reads a single `User` that is related to this `BlockedUser`. */
  blocked_users_buid?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `BlockedUser`. */
  user?: Maybe<User>;
};


/** The output of our update `BlockedUser` mutation. */
export type UpdateBlockedUserPayloadBlockedUserEdgeArgs = {
  orderBy?: InputMaybe<Array<BlockedUsersOrderBy>>;
};

/** All input for the `updateBudgetByNodeId` mutation. */
export type UpdateBudgetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Budget` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Budget` being updated. */
  patch: BudgetPatch;
};

/** All input for the `updateBudget` mutation. */
export type UpdateBudgetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Budget` being updated. */
  patch: BudgetPatch;
};

/** The output of our update `Budget` mutation. */
export type UpdateBudgetPayload = {
  __typename?: 'UpdateBudgetPayload';
  /** The `Budget` that was updated by this mutation. */
  budget?: Maybe<Budget>;
  /** An edge for our `Budget`. May be used by Relay 1. */
  budgetEdge?: Maybe<BudgetsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Budget`. */
  user?: Maybe<User>;
};


/** The output of our update `Budget` mutation. */
export type UpdateBudgetPayloadBudgetEdgeArgs = {
  orderBy?: InputMaybe<Array<BudgetsOrderBy>>;
};

/** All input for the `updateCampaignByNodeId` mutation. */
export type UpdateCampaignByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Campaign` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Campaign` being updated. */
  patch: CampaignPatch;
};

/** All input for the `updateCampaign` mutation. */
export type UpdateCampaignInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Campaign` being updated. */
  patch: CampaignPatch;
};

/** The output of our update `Campaign` mutation. */
export type UpdateCampaignPayload = {
  __typename?: 'UpdateCampaignPayload';
  /** Reads a single `CampaignsAction` that is related to this `Campaign`. */
  action?: Maybe<CampaignsAction>;
  /** Reads a single `Ad` that is related to this `Campaign`. */
  ad?: Maybe<Ad>;
  /** Reads a single `User` that is related to this `Campaign`. */
  approvedBy?: Maybe<User>;
  /** Reads a single `Audience` that is related to this `Campaign`. */
  audience?: Maybe<Audience>;
  /** Reads a single `Budget` that is related to this `Campaign`. */
  budget?: Maybe<Budget>;
  /** The `Campaign` that was updated by this mutation. */
  campaign?: Maybe<Campaign>;
  /** An edge for our `Campaign`. May be used by Relay 1. */
  campaignEdge?: Maybe<CampaignsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Campaign`. */
  user?: Maybe<User>;
};


/** The output of our update `Campaign` mutation. */
export type UpdateCampaignPayloadCampaignEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};

/** All input for the `updateCampaignsActionByNodeId` mutation. */
export type UpdateCampaignsActionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CampaignsAction` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CampaignsAction` being updated. */
  patch: CampaignsActionPatch;
};

/** All input for the `updateCampaignsAction` mutation. */
export type UpdateCampaignsActionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `CampaignsAction` being updated. */
  patch: CampaignsActionPatch;
};

/** The output of our update `CampaignsAction` mutation. */
export type UpdateCampaignsActionPayload = {
  __typename?: 'UpdateCampaignsActionPayload';
  /** The `CampaignsAction` that was updated by this mutation. */
  campaignsAction?: Maybe<CampaignsAction>;
  /** An edge for our `CampaignsAction`. May be used by Relay 1. */
  campaignsActionEdge?: Maybe<CampaignsActionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `CampaignsAction`. */
  userTo?: Maybe<User>;
};


/** The output of our update `CampaignsAction` mutation. */
export type UpdateCampaignsActionPayloadCampaignsActionEdgeArgs = {
  orderBy?: InputMaybe<Array<CampaignsActionsOrderBy>>;
};

/** All input for the `updateChallengeByNodeId` mutation. */
export type UpdateChallengeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Challenge` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Challenge` being updated. */
  patch: ChallengePatch;
};

/** All input for the `updateChallenge` mutation. */
export type UpdateChallengeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Challenge` being updated. */
  patch: ChallengePatch;
};

/** The output of our update `Challenge` mutation. */
export type UpdateChallengePayload = {
  __typename?: 'UpdateChallengePayload';
  /** The `Challenge` that was updated by this mutation. */
  challenge?: Maybe<Challenge>;
  /** An edge for our `Challenge`. May be used by Relay 1. */
  challengeEdge?: Maybe<ChallengesEdge>;
  /** Reads a single `Team` that is related to this `Challenge`. */
  challengedTeam?: Maybe<Team>;
  /** Reads a single `User` that is related to this `Challenge`. */
  challengedUser?: Maybe<User>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Game` that is related to this `Challenge`. */
  game?: Maybe<Game>;
  /** Reads a single `Team` that is related to this `Challenge`. */
  initiatorTeam?: Maybe<Team>;
  /** Reads a single `User` that is related to this `Challenge`. */
  initiatorUser?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Challenge`. */
  user?: Maybe<User>;
};


/** The output of our update `Challenge` mutation. */
export type UpdateChallengePayloadChallengeEdgeArgs = {
  orderBy?: InputMaybe<Array<ChallengesOrderBy>>;
};

/** All input for the `updateChallengesProofByNodeId` mutation. */
export type UpdateChallengesProofByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ChallengesProof` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ChallengesProof` being updated. */
  patch: ChallengesProofPatch;
};

/** All input for the `updateChallengesProof` mutation. */
export type UpdateChallengesProofInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ChallengesProof` being updated. */
  patch: ChallengesProofPatch;
};

/** The output of our update `ChallengesProof` mutation. */
export type UpdateChallengesProofPayload = {
  __typename?: 'UpdateChallengesProofPayload';
  /** Reads a single `Challenge` that is related to this `ChallengesProof`. */
  challenge?: Maybe<Challenge>;
  /** The `ChallengesProof` that was updated by this mutation. */
  challengesProof?: Maybe<ChallengesProof>;
  /** An edge for our `ChallengesProof`. May be used by Relay 1. */
  challengesProofEdge?: Maybe<ChallengesProofsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Media` that is related to this `ChallengesProof`. */
  media?: Maybe<Media>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ChallengesProof`. */
  user?: Maybe<User>;
};


/** The output of our update `ChallengesProof` mutation. */
export type UpdateChallengesProofPayloadChallengesProofEdgeArgs = {
  orderBy?: InputMaybe<Array<ChallengesProofsOrderBy>>;
};

/** All input for the `updateChallengesWitnessByNodeId` mutation. */
export type UpdateChallengesWitnessByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ChallengesWitness` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ChallengesWitness` being updated. */
  patch: ChallengesWitnessPatch;
};

/** All input for the `updateChallengesWitness` mutation. */
export type UpdateChallengesWitnessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ChallengesWitness` being updated. */
  patch: ChallengesWitnessPatch;
};

/** The output of our update `ChallengesWitness` mutation. */
export type UpdateChallengesWitnessPayload = {
  __typename?: 'UpdateChallengesWitnessPayload';
  /** Reads a single `Challenge` that is related to this `ChallengesWitness`. */
  challenge?: Maybe<Challenge>;
  /** The `ChallengesWitness` that was updated by this mutation. */
  challengesWitness?: Maybe<ChallengesWitness>;
  /** An edge for our `ChallengesWitness`. May be used by Relay 1. */
  challengesWitnessEdge?: Maybe<ChallengesWitnessesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `ChallengesWitness`. */
  user?: Maybe<User>;
};


/** The output of our update `ChallengesWitness` mutation. */
export type UpdateChallengesWitnessPayloadChallengesWitnessEdgeArgs = {
  orderBy?: InputMaybe<Array<ChallengesWitnessesOrderBy>>;
};

/** All input for the `updateCloudByNodeId` mutation. */
export type UpdateCloudByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Cloud` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Cloud` being updated. */
  patch: CloudPatch;
};

/** All input for the `updateCloud` mutation. */
export type UpdateCloudInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Cloud` being updated. */
  patch: CloudPatch;
};

/** The output of our update `Cloud` mutation. */
export type UpdateCloudPayload = {
  __typename?: 'UpdateCloudPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Cloud` that was updated by this mutation. */
  cloud?: Maybe<Cloud>;
  /** An edge for our `Cloud`. May be used by Relay 1. */
  cloudEdge?: Maybe<CloudsEdge>;
  /** Reads a single `Media` that is related to this `Cloud`. */
  media?: Maybe<Media>;
  /** Reads a single `Post` that is related to this `Cloud`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Cloud`. */
  user?: Maybe<User>;
};


/** The output of our update `Cloud` mutation. */
export type UpdateCloudPayloadCloudEdgeArgs = {
  orderBy?: InputMaybe<Array<CloudsOrderBy>>;
};

/** All input for the `updateFavoriteGameByNodeId` mutation. */
export type UpdateFavoriteGameByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FavoriteGame` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `FavoriteGame` being updated. */
  patch: FavoriteGamePatch;
};

/** All input for the `updateFavoriteGame` mutation. */
export type UpdateFavoriteGameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `FavoriteGame` being updated. */
  patch: FavoriteGamePatch;
};

/** The output of our update `FavoriteGame` mutation. */
export type UpdateFavoriteGamePayload = {
  __typename?: 'UpdateFavoriteGamePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FavoriteGame` that was updated by this mutation. */
  favoriteGame?: Maybe<FavoriteGame>;
  /** An edge for our `FavoriteGame`. May be used by Relay 1. */
  favoriteGameEdge?: Maybe<FavoriteGamesEdge>;
  /** Reads a single `Game` that is related to this `FavoriteGame`. */
  game?: Maybe<Game>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `FavoriteGame`. */
  user?: Maybe<User>;
};


/** The output of our update `FavoriteGame` mutation. */
export type UpdateFavoriteGamePayloadFavoriteGameEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoriteGamesOrderBy>>;
};

/** All input for the `updateFeeByNodeId` mutation. */
export type UpdateFeeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Fee` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Fee` being updated. */
  patch: FeePatch;
};

/** All input for the `updateFee` mutation. */
export type UpdateFeeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Fee` being updated. */
  patch: FeePatch;
};

/** The output of our update `Fee` mutation. */
export type UpdateFeePayload = {
  __typename?: 'UpdateFeePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Fee` that was updated by this mutation. */
  fee?: Maybe<Fee>;
  /** An edge for our `Fee`. May be used by Relay 1. */
  feeEdge?: Maybe<FeesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Fee` mutation. */
export type UpdateFeePayloadFeeEdgeArgs = {
  orderBy?: InputMaybe<Array<FeesOrderBy>>;
};

/** All input for the `updateFeedbackByNodeId` mutation. */
export type UpdateFeedbackByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Feedback` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Feedback` being updated. */
  patch: FeedbackPatch;
};

/** All input for the `updateFeedback` mutation. */
export type UpdateFeedbackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Feedback` being updated. */
  patch: FeedbackPatch;
};

/** The output of our update `Feedback` mutation. */
export type UpdateFeedbackPayload = {
  __typename?: 'UpdateFeedbackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Feedback` that was updated by this mutation. */
  feedback?: Maybe<Feedback>;
  /** An edge for our `Feedback`. May be used by Relay 1. */
  feedbackEdge?: Maybe<FeedbacksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Feedback`. */
  user?: Maybe<User>;
};


/** The output of our update `Feedback` mutation. */
export type UpdateFeedbackPayloadFeedbackEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedbacksOrderBy>>;
};

/** All input for the `updateFollowingByNodeId` mutation. */
export type UpdateFollowingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Following` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Following` being updated. */
  patch: FollowingPatch;
};

/** All input for the `updateFollowing` mutation. */
export type UpdateFollowingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Following` being updated. */
  patch: FollowingPatch;
};

/** The output of our update `Following` mutation. */
export type UpdateFollowingPayload = {
  __typename?: 'UpdateFollowingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Following` that was updated by this mutation. */
  following?: Maybe<Following>;
  /** An edge for our `Following`. May be used by Relay 1. */
  followingEdge?: Maybe<FollowingsEdge>;
  /** Reads a single `User` that is related to this `Following`. */
  followings_fid?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Following`. */
  user?: Maybe<User>;
};


/** The output of our update `Following` mutation. */
export type UpdateFollowingPayloadFollowingEdgeArgs = {
  orderBy?: InputMaybe<Array<FollowingsOrderBy>>;
};

/** All input for the `updateFunnyByNodeId` mutation. */
export type UpdateFunnyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Funny` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Funny` being updated. */
  patch: FunnyPatch;
};

/** All input for the `updateFunny` mutation. */
export type UpdateFunnyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Funny` being updated. */
  patch: FunnyPatch;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** The output of our update `Funny` mutation. */
export type UpdateFunnyPayload = {
  __typename?: 'UpdateFunnyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Funny` that was updated by this mutation. */
  funny?: Maybe<Funny>;
  /** An edge for our `Funny`. May be used by Relay 1. */
  funnyEdge?: Maybe<FunniesEdge>;
  /** Reads a single `Post` that is related to this `Funny`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Funny`. */
  user?: Maybe<User>;
};


/** The output of our update `Funny` mutation. */
export type UpdateFunnyPayloadFunnyEdgeArgs = {
  orderBy?: InputMaybe<Array<FunniesOrderBy>>;
};

/** All input for the `updateGameByNodeId` mutation. */
export type UpdateGameByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Game` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Game` being updated. */
  patch: GamePatch;
};

/** All input for the `updateGame` mutation. */
export type UpdateGameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Game` being updated. */
  patch: GamePatch;
};

/** The output of our update `Game` mutation. */
export type UpdateGamePayload = {
  __typename?: 'UpdateGamePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Game` that was updated by this mutation. */
  game?: Maybe<Game>;
  /** An edge for our `Game`. May be used by Relay 1. */
  gameEdge?: Maybe<GamesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Game` mutation. */
export type UpdateGamePayloadGameEdgeArgs = {
  orderBy?: InputMaybe<Array<GamesOrderBy>>;
};

/** All input for the `updateGenderByNodeId` mutation. */
export type UpdateGenderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Gender` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Gender` being updated. */
  patch: GenderPatch;
};

/** All input for the `updateGender` mutation. */
export type UpdateGenderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Gender` being updated. */
  patch: GenderPatch;
};

/** The output of our update `Gender` mutation. */
export type UpdateGenderPayload = {
  __typename?: 'UpdateGenderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Gender`. */
  createdBy?: Maybe<User>;
  /** The `Gender` that was updated by this mutation. */
  gender?: Maybe<Gender>;
  /** An edge for our `Gender`. May be used by Relay 1. */
  genderEdge?: Maybe<GendersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Gender` mutation. */
export type UpdateGenderPayloadGenderEdgeArgs = {
  orderBy?: InputMaybe<Array<GendersOrderBy>>;
};

/** All input for the `updateGenreByNodeId` mutation. */
export type UpdateGenreByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Genre` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Genre` being updated. */
  patch: GenrePatch;
};

/** All input for the `updateGenre` mutation. */
export type UpdateGenreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Genre` being updated. */
  patch: GenrePatch;
};

/** The output of our update `Genre` mutation. */
export type UpdateGenrePayload = {
  __typename?: 'UpdateGenrePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Genre` that was updated by this mutation. */
  genre?: Maybe<Genre>;
  /** An edge for our `Genre`. May be used by Relay 1. */
  genreEdge?: Maybe<GenresEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Genre` mutation. */
export type UpdateGenrePayloadGenreEdgeArgs = {
  orderBy?: InputMaybe<Array<GenresOrderBy>>;
};

/** All input for the `updateLikeByNodeId` mutation. */
export type UpdateLikeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Like` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Like` being updated. */
  patch: LikePatch;
};

/** All input for the `updateLike` mutation. */
export type UpdateLikeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Like` being updated. */
  patch: LikePatch;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** The output of our update `Like` mutation. */
export type UpdateLikePayload = {
  __typename?: 'UpdateLikePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Like` that was updated by this mutation. */
  like?: Maybe<Like>;
  /** An edge for our `Like`. May be used by Relay 1. */
  likeEdge?: Maybe<LikesEdge>;
  /** Reads a single `Post` that is related to this `Like`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Like`. */
  user?: Maybe<User>;
};


/** The output of our update `Like` mutation. */
export type UpdateLikePayloadLikeEdgeArgs = {
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};

/** All input for the `updateMediaByNodeId` mutation. */
export type UpdateMediaByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Media` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Media` being updated. */
  patch: MediaPatch;
};

/** All input for the `updateMedia` mutation. */
export type UpdateMediaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Media` being updated. */
  patch: MediaPatch;
};

/** The output of our update `Media` mutation. */
export type UpdateMediaPayload = {
  __typename?: 'UpdateMediaPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Media` that was updated by this mutation. */
  media?: Maybe<Media>;
  /** An edge for our `Media`. May be used by Relay 1. */
  mediaEdge?: Maybe<MediaEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Media`. */
  user?: Maybe<User>;
};


/** The output of our update `Media` mutation. */
export type UpdateMediaPayloadMediaEdgeArgs = {
  orderBy?: InputMaybe<Array<MediaOrderBy>>;
};

/** All input for the `updateMessageByNodeId` mutation. */
export type UpdateMessageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Message` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Message` being updated. */
  patch: MessagePatch;
};

/** All input for the `updateMessage` mutation. */
export type UpdateMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Message` being updated. */
  patch: MessagePatch;
};

/** The output of our update `Message` mutation. */
export type UpdateMessagePayload = {
  __typename?: 'UpdateMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Message` that was updated by this mutation. */
  message?: Maybe<Message>;
  /** An edge for our `Message`. May be used by Relay 1. */
  messageEdge?: Maybe<MessagesEdge>;
  /** Reads a single `Post` that is related to this `Message`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Room` that is related to this `Message`. */
  room?: Maybe<Room>;
  /** Reads a single `User` that is related to this `Message`. */
  user?: Maybe<User>;
};


/** The output of our update `Message` mutation. */
export type UpdateMessagePayloadMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};

/** All input for the `updateMusicByNodeId` mutation. */
export type UpdateMusicByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Music` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Music` being updated. */
  patch: MusicPatch;
};

/** All input for the `updateMusicGenreByNodeId` mutation. */
export type UpdateMusicGenreByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MusicGenre` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `MusicGenre` being updated. */
  patch: MusicGenrePatch;
};

/** All input for the `updateMusicGenre` mutation. */
export type UpdateMusicGenreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `MusicGenre` being updated. */
  patch: MusicGenrePatch;
};

/** The output of our update `MusicGenre` mutation. */
export type UpdateMusicGenrePayload = {
  __typename?: 'UpdateMusicGenrePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Genre` that is related to this `MusicGenre`. */
  genre?: Maybe<Genre>;
  /** Reads a single `Music` that is related to this `MusicGenre`. */
  music?: Maybe<Music>;
  /** The `MusicGenre` that was updated by this mutation. */
  musicGenre?: Maybe<MusicGenre>;
  /** An edge for our `MusicGenre`. May be used by Relay 1. */
  musicGenreEdge?: Maybe<MusicGenresEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `MusicGenre` mutation. */
export type UpdateMusicGenrePayloadMusicGenreEdgeArgs = {
  orderBy?: InputMaybe<Array<MusicGenresOrderBy>>;
};

/** All input for the `updateMusic` mutation. */
export type UpdateMusicInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Music` being updated. */
  patch: MusicPatch;
};

/** The output of our update `Music` mutation. */
export type UpdateMusicPayload = {
  __typename?: 'UpdateMusicPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Music` that was updated by this mutation. */
  music?: Maybe<Music>;
  /** An edge for our `Music`. May be used by Relay 1. */
  musicEdge?: Maybe<MusicEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Music` mutation. */
export type UpdateMusicPayloadMusicEdgeArgs = {
  orderBy?: InputMaybe<Array<MusicOrderBy>>;
};

/** All input for the `updateNoobByNodeId` mutation. */
export type UpdateNoobByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Noob` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Noob` being updated. */
  patch: NoobPatch;
};

/** All input for the `updateNoob` mutation. */
export type UpdateNoobInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Noob` being updated. */
  patch: NoobPatch;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** The output of our update `Noob` mutation. */
export type UpdateNoobPayload = {
  __typename?: 'UpdateNoobPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Noob` that was updated by this mutation. */
  noob?: Maybe<Noob>;
  /** An edge for our `Noob`. May be used by Relay 1. */
  noobEdge?: Maybe<NoobsEdge>;
  /** Reads a single `Post` that is related to this `Noob`. */
  post?: Maybe<Post>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Noob`. */
  user?: Maybe<User>;
};


/** The output of our update `Noob` mutation. */
export type UpdateNoobPayloadNoobEdgeArgs = {
  orderBy?: InputMaybe<Array<NoobsOrderBy>>;
};

/** All input for the `updateNotificationLogByNodeId` mutation. */
export type UpdateNotificationLogByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `NotificationLog` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `NotificationLog` being updated. */
  patch: NotificationLogPatch;
};

/** All input for the `updateNotificationLog` mutation. */
export type UpdateNotificationLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `NotificationLog` being updated. */
  patch: NotificationLogPatch;
};

/** The output of our update `NotificationLog` mutation. */
export type UpdateNotificationLogPayload = {
  __typename?: 'UpdateNotificationLogPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `NotificationLog` that was updated by this mutation. */
  notificationLog?: Maybe<NotificationLog>;
  /** An edge for our `NotificationLog`. May be used by Relay 1. */
  notificationLogEdge?: Maybe<NotificationLogsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `NotificationLog` mutation. */
export type UpdateNotificationLogPayloadNotificationLogEdgeArgs = {
  orderBy?: InputMaybe<Array<NotificationLogsOrderBy>>;
};

/** All input for the `updateOzSubscriptionByNodeId` mutation. */
export type UpdateOzSubscriptionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `OzSubscription` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `OzSubscription` being updated. */
  patch: OzSubscriptionPatch;
};

/** All input for the `updateOzSubscription` mutation. */
export type UpdateOzSubscriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `OzSubscription` being updated. */
  patch: OzSubscriptionPatch;
};

/** The output of our update `OzSubscription` mutation. */
export type UpdateOzSubscriptionPayload = {
  __typename?: 'UpdateOzSubscriptionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Fee` that is related to this `OzSubscription`. */
  fee?: Maybe<Fee>;
  /** The `OzSubscription` that was updated by this mutation. */
  ozSubscription?: Maybe<OzSubscription>;
  /** An edge for our `OzSubscription`. May be used by Relay 1. */
  ozSubscriptionEdge?: Maybe<OzSubscriptionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Transaction` that is related to this `OzSubscription`. */
  transaction?: Maybe<Transaction>;
  /** Reads a single `User` that is related to this `OzSubscription`. */
  user?: Maybe<User>;
};


/** The output of our update `OzSubscription` mutation. */
export type UpdateOzSubscriptionPayloadOzSubscriptionEdgeArgs = {
  orderBy?: InputMaybe<Array<OzSubscriptionsOrderBy>>;
};

/** All input for the `updatePaymentMethodByNodeId` mutation. */
export type UpdatePaymentMethodByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PaymentMethod` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PaymentMethod` being updated. */
  patch: PaymentMethodPatch;
};

/** All input for the `updatePaymentMethod` mutation. */
export type UpdatePaymentMethodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `PaymentMethod` being updated. */
  patch: PaymentMethodPatch;
};

/** The output of our update `PaymentMethod` mutation. */
export type UpdatePaymentMethodPayload = {
  __typename?: 'UpdatePaymentMethodPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PaymentMethod` that was updated by this mutation. */
  paymentMethod?: Maybe<PaymentMethod>;
  /** An edge for our `PaymentMethod`. May be used by Relay 1. */
  paymentMethodEdge?: Maybe<PaymentMethodsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PaymentMethod`. */
  user?: Maybe<User>;
};


/** The output of our update `PaymentMethod` mutation. */
export type UpdatePaymentMethodPayloadPaymentMethodEdgeArgs = {
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
};

/** All input for the `updatePostByNodeId` mutation. */
export type UpdatePostByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Post` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Post` being updated. */
  patch: PostPatch;
};

/** All input for the `updatePost` mutation. */
export type UpdatePostInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Post` being updated. */
  patch: PostPatch;
};

/** The output of our update `Post` mutation. */
export type UpdatePostPayload = {
  __typename?: 'UpdatePostPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Game` that is related to this `Post`. */
  game?: Maybe<Game>;
  /** Reads a single `Media` that is related to this `Post`. */
  media?: Maybe<Media>;
  /** The `Post` that was updated by this mutation. */
  post?: Maybe<Post>;
  /** An edge for our `Post`. May be used by Relay 1. */
  postEdge?: Maybe<PostsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Post`. */
  user?: Maybe<User>;
};


/** The output of our update `Post` mutation. */
export type UpdatePostPayloadPostEdgeArgs = {
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};

/** All input for the `updatePostsReportByNodeId` mutation. */
export type UpdatePostsReportByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PostsReport` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PostsReport` being updated. */
  patch: PostsReportPatch;
};

/** All input for the `updatePostsReport` mutation. */
export type UpdatePostsReportInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `PostsReport` being updated. */
  patch: PostsReportPatch;
};

/** The output of our update `PostsReport` mutation. */
export type UpdatePostsReportPayload = {
  __typename?: 'UpdatePostsReportPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Post` that is related to this `PostsReport`. */
  post?: Maybe<Post>;
  /** The `PostsReport` that was updated by this mutation. */
  postsReport?: Maybe<PostsReport>;
  /** An edge for our `PostsReport`. May be used by Relay 1. */
  postsReportEdge?: Maybe<PostsReportsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ReportMessage` that is related to this `PostsReport`. */
  reportMessage?: Maybe<ReportMessage>;
  /** Reads a single `User` that is related to this `PostsReport`. */
  user?: Maybe<User>;
  /** Reads a single `User` that is related to this `PostsReport`. */
  validatingUser?: Maybe<User>;
};


/** The output of our update `PostsReport` mutation. */
export type UpdatePostsReportPayloadPostsReportEdgeArgs = {
  orderBy?: InputMaybe<Array<PostsReportsOrderBy>>;
};

/** All input for the `updatePostsViewByNodeId` mutation. */
export type UpdatePostsViewByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PostsView` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PostsView` being updated. */
  patch: PostsViewPatch;
};

/** All input for the `updatePostsViewByPostIdAndUserId` mutation. */
export type UpdatePostsViewByPostIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PostsView` being updated. */
  patch: PostsViewPatch;
  postId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** All input for the `updatePostsView` mutation. */
export type UpdatePostsViewInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `PostsView` being updated. */
  patch: PostsViewPatch;
};

/** The output of our update `PostsView` mutation. */
export type UpdatePostsViewPayload = {
  __typename?: 'UpdatePostsViewPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Post` that is related to this `PostsView`. */
  post?: Maybe<Post>;
  /** The `PostsView` that was updated by this mutation. */
  postsView?: Maybe<PostsView>;
  /** An edge for our `PostsView`. May be used by Relay 1. */
  postsViewEdge?: Maybe<PostsViewsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PostsView`. */
  user?: Maybe<User>;
};


/** The output of our update `PostsView` mutation. */
export type UpdatePostsViewPayloadPostsViewEdgeArgs = {
  orderBy?: InputMaybe<Array<PostsViewsOrderBy>>;
};

/** All input for the `updatePrivateUserByNodeId` mutation. */
export type UpdatePrivateUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PrivateUser` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PrivateUser` being updated. */
  patch: PrivateUserPatch;
};

/** All input for the `updatePrivateUser` mutation. */
export type UpdatePrivateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PrivateUser` being updated. */
  patch: PrivateUserPatch;
  userId: Scalars['Int'];
};

/** The output of our update `PrivateUser` mutation. */
export type UpdatePrivateUserPayload = {
  __typename?: 'UpdatePrivateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PrivateUser` that was updated by this mutation. */
  privateUser?: Maybe<PrivateUser>;
  /** An edge for our `PrivateUser`. May be used by Relay 1. */
  privateUserEdge?: Maybe<PrivateUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PrivateUser`. */
  user?: Maybe<User>;
};


/** The output of our update `PrivateUser` mutation. */
export type UpdatePrivateUserPayloadPrivateUserEdgeArgs = {
  orderBy?: InputMaybe<Array<PrivateUsersOrderBy>>;
};

/** All input for the `updateReportMessageByNodeId` mutation. */
export type UpdateReportMessageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ReportMessage` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ReportMessage` being updated. */
  patch: ReportMessagePatch;
};

/** All input for the `updateReportMessage` mutation. */
export type UpdateReportMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `ReportMessage` being updated. */
  patch: ReportMessagePatch;
};

/** The output of our update `ReportMessage` mutation. */
export type UpdateReportMessagePayload = {
  __typename?: 'UpdateReportMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ReportMessage` that was updated by this mutation. */
  reportMessage?: Maybe<ReportMessage>;
  /** An edge for our `ReportMessage`. May be used by Relay 1. */
  reportMessageEdge?: Maybe<ReportMessagesEdge>;
};


/** The output of our update `ReportMessage` mutation. */
export type UpdateReportMessagePayloadReportMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<ReportMessagesOrderBy>>;
};

/** All input for the `updateReportedPost` mutation. */
export type UpdateReportedPostInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  reportid?: InputMaybe<Scalars['Int']>;
};

/** The output of our `updateReportedPost` mutation. */
export type UpdateReportedPostPayload = {
  __typename?: 'UpdateReportedPostPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  results?: Maybe<Array<Maybe<UpdateReportedPostRecord>>>;
};

/** The return type of our `updateReportedPost` mutation. */
export type UpdateReportedPostRecord = {
  __typename?: 'UpdateReportedPostRecord';
  postMediaId?: Maybe<Scalars['Int']>;
  postPostId?: Maybe<Scalars['Int']>;
  postReportMessageId?: Maybe<Scalars['Int']>;
  postUserId?: Maybe<Scalars['Int']>;
};

/** All input for the `updateRoleByNodeId` mutation. */
export type UpdateRoleByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Role` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Role` being updated. */
  patch: RolePatch;
};

/** All input for the `updateRole` mutation. */
export type UpdateRoleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Role` being updated. */
  patch: RolePatch;
};

/** The output of our update `Role` mutation. */
export type UpdateRolePayload = {
  __typename?: 'UpdateRolePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Role` that was updated by this mutation. */
  role?: Maybe<Role>;
  /** An edge for our `Role`. May be used by Relay 1. */
  roleEdge?: Maybe<RolesEdge>;
};


/** The output of our update `Role` mutation. */
export type UpdateRolePayloadRoleEdgeArgs = {
  orderBy?: InputMaybe<Array<RolesOrderBy>>;
};

/** All input for the `updateRoomByNodeId` mutation. */
export type UpdateRoomByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Room` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Room` being updated. */
  patch: RoomPatch;
};

/** All input for the `updateRoom` mutation. */
export type UpdateRoomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Room` being updated. */
  patch: RoomPatch;
};

/** The output of our update `Room` mutation. */
export type UpdateRoomPayload = {
  __typename?: 'UpdateRoomPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Room` that was updated by this mutation. */
  room?: Maybe<Room>;
  /** An edge for our `Room`. May be used by Relay 1. */
  roomEdge?: Maybe<RoomsEdge>;
  /** Reads a single `User` that is related to this `Room`. */
  user?: Maybe<User>;
};


/** The output of our update `Room` mutation. */
export type UpdateRoomPayloadRoomEdgeArgs = {
  orderBy?: InputMaybe<Array<RoomsOrderBy>>;
};

/** All input for the `updateStickerByNodeId` mutation. */
export type UpdateStickerByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Sticker` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Sticker` being updated. */
  patch: StickerPatch;
};

/** All input for the `updateSticker` mutation. */
export type UpdateStickerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Sticker` being updated. */
  patch: StickerPatch;
};

/** The output of our update `Sticker` mutation. */
export type UpdateStickerPayload = {
  __typename?: 'UpdateStickerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Sticker` that was updated by this mutation. */
  sticker?: Maybe<Sticker>;
  /** An edge for our `Sticker`. May be used by Relay 1. */
  stickerEdge?: Maybe<StickersEdge>;
};


/** The output of our update `Sticker` mutation. */
export type UpdateStickerPayloadStickerEdgeArgs = {
  orderBy?: InputMaybe<Array<StickersOrderBy>>;
};

/** All input for the `updateStickersTagByNodeId` mutation. */
export type UpdateStickersTagByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `StickersTag` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `StickersTag` being updated. */
  patch: StickersTagPatch;
};

/** All input for the `updateStickersTag` mutation. */
export type UpdateStickersTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `StickersTag` being updated. */
  patch: StickersTagPatch;
};

/** The output of our update `StickersTag` mutation. */
export type UpdateStickersTagPayload = {
  __typename?: 'UpdateStickersTagPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Sticker` that is related to this `StickersTag`. */
  sticker?: Maybe<Sticker>;
  /** The `StickersTag` that was updated by this mutation. */
  stickersTag?: Maybe<StickersTag>;
  /** An edge for our `StickersTag`. May be used by Relay 1. */
  stickersTagEdge?: Maybe<StickersTagsEdge>;
  /** Reads a single `Tag` that is related to this `StickersTag`. */
  tag?: Maybe<Tag>;
};


/** The output of our update `StickersTag` mutation. */
export type UpdateStickersTagPayloadStickersTagEdgeArgs = {
  orderBy?: InputMaybe<Array<StickersTagsOrderBy>>;
};

/** All input for the `updateTagByNodeId` mutation. */
export type UpdateTagByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Tag` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Tag` being updated. */
  patch: TagPatch;
};

/** All input for the `updateTag` mutation. */
export type UpdateTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Tag` being updated. */
  patch: TagPatch;
};

/** The output of our update `Tag` mutation. */
export type UpdateTagPayload = {
  __typename?: 'UpdateTagPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Tag` that was updated by this mutation. */
  tag?: Maybe<Tag>;
  /** An edge for our `Tag`. May be used by Relay 1. */
  tagEdge?: Maybe<TagsEdge>;
};


/** The output of our update `Tag` mutation. */
export type UpdateTagPayloadTagEdgeArgs = {
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

/** All input for the `updateTeamByName` mutation. */
export type UpdateTeamByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `Team` being updated. */
  patch: TeamPatch;
};

/** All input for the `updateTeamByNodeId` mutation. */
export type UpdateTeamByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Team` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Team` being updated. */
  patch: TeamPatch;
};

/** All input for the `updateTeam` mutation. */
export type UpdateTeamInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Team` being updated. */
  patch: TeamPatch;
};

/** The output of our update `Team` mutation. */
export type UpdateTeamPayload = {
  __typename?: 'UpdateTeamPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Team`. */
  createdBy?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Team` that was updated by this mutation. */
  team?: Maybe<Team>;
  /** An edge for our `Team`. May be used by Relay 1. */
  teamEdge?: Maybe<TeamsEdge>;
};


/** The output of our update `Team` mutation. */
export type UpdateTeamPayloadTeamEdgeArgs = {
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};

/** All input for the `updateTeamsMemberByNodeId` mutation. */
export type UpdateTeamsMemberByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `TeamsMember` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `TeamsMember` being updated. */
  patch: TeamsMemberPatch;
};

/** All input for the `updateTeamsMember` mutation. */
export type UpdateTeamsMemberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `TeamsMember` being updated. */
  patch: TeamsMemberPatch;
};

/** The output of our update `TeamsMember` mutation. */
export type UpdateTeamsMemberPayload = {
  __typename?: 'UpdateTeamsMemberPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Team` that is related to this `TeamsMember`. */
  team?: Maybe<Team>;
  /** The `TeamsMember` that was updated by this mutation. */
  teamsMember?: Maybe<TeamsMember>;
  /** An edge for our `TeamsMember`. May be used by Relay 1. */
  teamsMemberEdge?: Maybe<TeamsMembersEdge>;
  /** Reads a single `User` that is related to this `TeamsMember`. */
  user?: Maybe<User>;
  /** Reads a single `User` that is related to this `TeamsMember`. */
  userByBannedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `TeamsMember`. */
  userByRemovedBy?: Maybe<User>;
};


/** The output of our update `TeamsMember` mutation. */
export type UpdateTeamsMemberPayloadTeamsMemberEdgeArgs = {
  orderBy?: InputMaybe<Array<TeamsMembersOrderBy>>;
};

/** All input for the `updateTransactionByNodeId` mutation. */
export type UpdateTransactionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Transaction` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Transaction` being updated. */
  patch: TransactionPatch;
};

/** All input for the `updateTransaction` mutation. */
export type UpdateTransactionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Transaction` being updated. */
  patch: TransactionPatch;
};

/** The output of our update `Transaction` mutation. */
export type UpdateTransactionPayload = {
  __typename?: 'UpdateTransactionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Fee` that is related to this `Transaction`. */
  fee?: Maybe<Fee>;
  /** Reads a single `PaymentMethod` that is related to this `Transaction`. */
  paymentMethod?: Maybe<PaymentMethod>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Transaction` that was updated by this mutation. */
  transaction?: Maybe<Transaction>;
  /** An edge for our `Transaction`. May be used by Relay 1. */
  transactionEdge?: Maybe<TransactionsEdge>;
  /** Reads a single `User` that is related to this `Transaction`. */
  user?: Maybe<User>;
};


/** The output of our update `Transaction` mutation. */
export type UpdateTransactionPayloadTransactionEdgeArgs = {
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};

/** All input for the `updateUserAccessByNodeId` mutation. */
export type UpdateUserAccessByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserAccess` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserAccess` being updated. */
  patch: UserAccessPatch;
};

/** All input for the `updateUserAccess` mutation. */
export type UpdateUserAccessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `UserAccess` being updated. */
  patch: UserAccessPatch;
};

/** The output of our update `UserAccess` mutation. */
export type UpdateUserAccessPayload = {
  __typename?: 'UpdateUserAccessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserAccess`. */
  user?: Maybe<User>;
  /** The `UserAccess` that was updated by this mutation. */
  userAccess?: Maybe<UserAccess>;
  /** An edge for our `UserAccess`. May be used by Relay 1. */
  userAccessEdge?: Maybe<UserAccessesEdge>;
};


/** The output of our update `UserAccess` mutation. */
export type UpdateUserAccessPayloadUserAccessEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAccessesOrderBy>>;
};

/** All input for the `updateUserByNodeId` mutation. */
export type UpdateUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUserEmailByNodeId` mutation. */
export type UpdateUserEmailByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserEmail` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserEmail` being updated. */
  patch: UserEmailPatch;
};

/** All input for the `updateUserEmailByUserId` mutation. */
export type UpdateUserEmailByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `UserEmail` being updated. */
  patch: UserEmailPatch;
  userId: Scalars['Int'];
};

/** All input for the `updateUserEmail` mutation. */
export type UpdateUserEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `UserEmail` being updated. */
  patch: UserEmailPatch;
};

/** The output of our update `UserEmail` mutation. */
export type UpdateUserEmailPayload = {
  __typename?: 'UpdateUserEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserEmail`. */
  user?: Maybe<User>;
  /** The `UserEmail` that was updated by this mutation. */
  userEmail?: Maybe<UserEmail>;
  /** An edge for our `UserEmail`. May be used by Relay 1. */
  userEmailEdge?: Maybe<UserEmailsEdge>;
};


/** The output of our update `UserEmail` mutation. */
export type UpdateUserEmailPayloadUserEmailEdgeArgs = {
  orderBy?: InputMaybe<Array<UserEmailsOrderBy>>;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Gender` that is related to this `User`. */
  gender?: Maybe<Gender>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was updated by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `updateUserRoleByNodeId` mutation. */
export type UpdateUserRoleByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserRole` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserRole` being updated. */
  patch: UserRolePatch;
};

/** All input for the `updateUserRole` mutation. */
export type UpdateUserRoleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `UserRole` being updated. */
  patch: UserRolePatch;
};

/** The output of our update `UserRole` mutation. */
export type UpdateUserRolePayload = {
  __typename?: 'UpdateUserRolePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Role` that is related to this `UserRole`. */
  role?: Maybe<Role>;
  /** Reads a single `User` that is related to this `UserRole`. */
  user?: Maybe<User>;
  /** The `UserRole` that was updated by this mutation. */
  userRole?: Maybe<UserRole>;
  /** An edge for our `UserRole`. May be used by Relay 1. */
  userRoleEdge?: Maybe<UserRolesEdge>;
};


/** The output of our update `UserRole` mutation. */
export type UpdateUserRolePayloadUserRoleEdgeArgs = {
  orderBy?: InputMaybe<Array<UserRolesOrderBy>>;
};

/** All input for the `updateUsernamesCheckByNodeId` mutation. */
export type UpdateUsernamesCheckByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UsernamesCheck` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UsernamesCheck` being updated. */
  patch: UsernamesCheckPatch;
};

/** All input for the `updateUsernamesCheck` mutation. */
export type UpdateUsernamesCheckInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `UsernamesCheck` being updated. */
  patch: UsernamesCheckPatch;
};

/** The output of our update `UsernamesCheck` mutation. */
export type UpdateUsernamesCheckPayload = {
  __typename?: 'UpdateUsernamesCheckPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UsernamesCheck` that was updated by this mutation. */
  usernamesCheck?: Maybe<UsernamesCheck>;
  /** An edge for our `UsernamesCheck`. May be used by Relay 1. */
  usernamesCheckEdge?: Maybe<UsernamesChecksEdge>;
};


/** The output of our update `UsernamesCheck` mutation. */
export type UpdateUsernamesCheckPayloadUsernamesCheckEdgeArgs = {
  orderBy?: InputMaybe<Array<UsernamesChecksOrderBy>>;
};

/** All input for the `updateUsersAvailableRoomByNodeId` mutation. */
export type UpdateUsersAvailableRoomByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UsersAvailableRoom` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UsersAvailableRoom` being updated. */
  patch: UsersAvailableRoomPatch;
};

/** All input for the `updateUsersAvailableRoom` mutation. */
export type UpdateUsersAvailableRoomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `UsersAvailableRoom` being updated. */
  patch: UsersAvailableRoomPatch;
};

/** The output of our update `UsersAvailableRoom` mutation. */
export type UpdateUsersAvailableRoomPayload = {
  __typename?: 'UpdateUsersAvailableRoomPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Room` that is related to this `UsersAvailableRoom`. */
  room?: Maybe<Room>;
  /** Reads a single `User` that is related to this `UsersAvailableRoom`. */
  user?: Maybe<User>;
  /** The `UsersAvailableRoom` that was updated by this mutation. */
  usersAvailableRoom?: Maybe<UsersAvailableRoom>;
  /** An edge for our `UsersAvailableRoom`. May be used by Relay 1. */
  usersAvailableRoomEdge?: Maybe<UsersAvailableRoomsEdge>;
};


/** The output of our update `UsersAvailableRoom` mutation. */
export type UpdateUsersAvailableRoomPayloadUsersAvailableRoomEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersAvailableRoomsOrderBy>>;
};

/** All input for the `updateUsersReceivedMessageByNodeId` mutation. */
export type UpdateUsersReceivedMessageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UsersReceivedMessage` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UsersReceivedMessage` being updated. */
  patch: UsersReceivedMessagePatch;
};

/** All input for the `updateUsersReceivedMessage` mutation. */
export type UpdateUsersReceivedMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `UsersReceivedMessage` being updated. */
  patch: UsersReceivedMessagePatch;
};

/** The output of our update `UsersReceivedMessage` mutation. */
export type UpdateUsersReceivedMessagePayload = {
  __typename?: 'UpdateUsersReceivedMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Message` that is related to this `UsersReceivedMessage`. */
  message?: Maybe<Message>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UsersReceivedMessage`. */
  user?: Maybe<User>;
  /** The `UsersReceivedMessage` that was updated by this mutation. */
  usersReceivedMessage?: Maybe<UsersReceivedMessage>;
  /** An edge for our `UsersReceivedMessage`. May be used by Relay 1. */
  usersReceivedMessageEdge?: Maybe<UsersReceivedMessagesEdge>;
};


/** The output of our update `UsersReceivedMessage` mutation. */
export type UpdateUsersReceivedMessagePayloadUsersReceivedMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersReceivedMessagesOrderBy>>;
};

/** All input for the `updateWitnessByNodeId` mutation. */
export type UpdateWitnessByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Witness` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Witness` being updated. */
  patch: WitnessPatch;
};

/** All input for the `updateWitness` mutation. */
export type UpdateWitnessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Witness` being updated. */
  patch: WitnessPatch;
};

/** The output of our update `Witness` mutation. */
export type UpdateWitnessPayload = {
  __typename?: 'UpdateWitnessPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Witness`. */
  user?: Maybe<User>;
  /** The `Witness` that was updated by this mutation. */
  witness?: Maybe<Witness>;
  /** An edge for our `Witness`. May be used by Relay 1. */
  witnessEdge?: Maybe<WitnessesEdge>;
};


/** The output of our update `Witness` mutation. */
export type UpdateWitnessPayloadWitnessEdgeArgs = {
  orderBy?: InputMaybe<Array<WitnessesOrderBy>>;
};

export type User = Node & {
  __typename?: 'User';
  active: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `Activity`. */
  activities: ActivitiesConnection;
  /** Reads and enables pagination through a set of `Activity`. */
  activitiesByAffectedUserId: ActivitiesConnection;
  appleId?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Archive`. */
  archives: ArchivesConnection;
  /** Reads and enables pagination through a set of `Audience`. */
  audiences: AudiencesConnection;
  /** Reads and enables pagination through a set of `Beast`. */
  beasts: BeastsConnection;
  bio?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `BlockedUser`. */
  blockedUsers: BlockedUsersConnection;
  /** Reads and enables pagination through a set of `BlockedUser`. */
  blockedUsersByBlockedUserId: BlockedUsersConnection;
  /** Reads and enables pagination through a set of `Budget`. */
  budgets: BudgetsConnection;
  /** Reads and enables pagination through a set of `Campaign`. */
  campaigns: CampaignsConnection;
  /** Reads and enables pagination through a set of `CampaignsAction`. */
  campaignsActionsByUserToId: CampaignsActionsConnection;
  /** Reads and enables pagination through a set of `Campaign`. */
  campaignsByApprovedById: CampaignsConnection;
  /** Reads and enables pagination through a set of `Challenge`. */
  challenges: ChallengesConnection;
  /** Reads and enables pagination through a set of `Challenge`. */
  challengesByChallengedUserId: ChallengesConnection;
  /** Reads and enables pagination through a set of `Challenge`. */
  challengesByInitiatorUserId: ChallengesConnection;
  /** Reads and enables pagination through a set of `ChallengesProof`. */
  challengesProofs: ChallengesProofsConnection;
  /** Reads and enables pagination through a set of `ChallengesWitness`. */
  challengesWitnesses: ChallengesWitnessesConnection;
  /** Reads and enables pagination through a set of `Cloud`. */
  clouds: CloudsConnection;
  coverPhoto?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  dateOfBirth?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Datetime']>;
  email?: Maybe<Scalars['String']>;
  facebookId?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `FavoriteGame`. */
  favoriteGames: FavoriteGamesConnection;
  /** Reads and enables pagination through a set of `Feedback`. */
  feedbacks: FeedbacksConnection;
  firstName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Following`. */
  followings: FollowingsConnection;
  /** Reads and enables pagination through a set of `Following`. */
  followingsByFollowingId: FollowingsConnection;
  /** Reads and enables pagination through a set of `Funny`. */
  funnies: FunniesConnection;
  /** Reads a single `Gender` that is related to this `User`. */
  gender?: Maybe<Gender>;
  genderId?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `Gender`. */
  gendersByCreatedById: GendersConnection;
  googleId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Like`. */
  likes: LikesConnection;
  /** Reads and enables pagination through a set of `Media`. */
  medias: MediaConnection;
  /** Reads and enables pagination through a set of `Message`. */
  messages: MessagesConnection;
  mfa?: Maybe<Scalars['JSON']>;
  microsoftData?: Maybe<Scalars['JSON']>;
  nbChallengesWon: Scalars['Int'];
  nbFollowers?: Maybe<Scalars['Int']>;
  nbFollowing?: Maybe<Scalars['Int']>;
  nbPosts?: Maybe<Scalars['Int']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Noob`. */
  noobs: NoobsConnection;
  notificationsPreferences?: Maybe<Scalars['JSON']>;
  objectId?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `OzSubscription`. */
  ozSubscriptions: OzSubscriptionsConnection;
  password?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `PaymentMethod`. */
  paymentMethods: PaymentMethodsConnection;
  phone?: Maybe<Scalars['BigInt']>;
  photo?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Post`. */
  posts: PostsConnection;
  /** Reads and enables pagination through a set of `PostsReport`. */
  postsReports: PostsReportsConnection;
  /** Reads and enables pagination through a set of `PostsReport`. */
  postsReportsByValidatingUserId: PostsReportsConnection;
  /** Reads and enables pagination through a set of `PostsView`. */
  postsViews: PostsViewsConnection;
  /** Reads a single `PrivateUser` that is related to this `User`. */
  privateUser?: Maybe<PrivateUser>;
  /**
   * Reads and enables pagination through a set of `PrivateUser`.
   * @deprecated Please use privateUser instead
   */
  privateUsers: PrivateUsersConnection;
  /** Reads and enables pagination through a set of `Room`. */
  rooms: RoomsConnection;
  salt?: Maybe<Scalars['String']>;
  showOnboarding?: Maybe<Scalars['Boolean']>;
  stripeId?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Team`. */
  teamsByCreatedById: TeamsConnection;
  /** Reads and enables pagination through a set of `TeamsMember`. */
  teamsMembers: TeamsMembersConnection;
  /** Reads and enables pagination through a set of `TeamsMember`. */
  teamsMembersByBannedBy: TeamsMembersConnection;
  /** Reads and enables pagination through a set of `TeamsMember`. */
  teamsMembersByRemovedBy: TeamsMembersConnection;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactions: TransactionsConnection;
  twitchId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `UserAccess`. */
  userAccesses: UserAccessesConnection;
  /** Reads a single `UserEmail` that is related to this `User`. */
  userEmail?: Maybe<UserEmail>;
  /**
   * Reads and enables pagination through a set of `UserEmail`.
   * @deprecated Please use userEmail instead
   */
  userEmails: UserEmailsConnection;
  /** Reads and enables pagination through a set of `UserRole`. */
  userRoles: UserRolesConnection;
  username: Scalars['String'];
  /** Reads and enables pagination through a set of `UsersAvailableRoom`. */
  usersAvailableRooms: UsersAvailableRoomsConnection;
  /** Reads and enables pagination through a set of `UsersReceivedMessage`. */
  usersReceivedMessages: UsersReceivedMessagesConnection;
  verificationEmailSent: Scalars['Boolean'];
  verifiedEmail: Scalars['Boolean'];
  verifiedPhone: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `Witness`. */
  witnesses: WitnessesConnection;
};


export type UserActivitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ActivityCondition>;
  filter?: InputMaybe<ActivityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};


export type UserActivitiesByAffectedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ActivityCondition>;
  filter?: InputMaybe<ActivityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};


export type UserArchivesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ArchiveCondition>;
  filter?: InputMaybe<ArchiveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ArchivesOrderBy>>;
};


export type UserAudiencesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AudienceCondition>;
  filter?: InputMaybe<AudienceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AudiencesOrderBy>>;
};


export type UserBeastsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BeastCondition>;
  filter?: InputMaybe<BeastFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BeastsOrderBy>>;
};


export type UserBlockedUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BlockedUserCondition>;
  filter?: InputMaybe<BlockedUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BlockedUsersOrderBy>>;
};


export type UserBlockedUsersByBlockedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BlockedUserCondition>;
  filter?: InputMaybe<BlockedUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BlockedUsersOrderBy>>;
};


export type UserBudgetsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BudgetCondition>;
  filter?: InputMaybe<BudgetFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BudgetsOrderBy>>;
};


export type UserCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};


export type UserCampaignsActionsByUserToIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CampaignsActionCondition>;
  filter?: InputMaybe<CampaignsActionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CampaignsActionsOrderBy>>;
};


export type UserCampaignsByApprovedByIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
};


export type UserChallengesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengeCondition>;
  filter?: InputMaybe<ChallengeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesOrderBy>>;
};


export type UserChallengesByChallengedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengeCondition>;
  filter?: InputMaybe<ChallengeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesOrderBy>>;
};


export type UserChallengesByInitiatorUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengeCondition>;
  filter?: InputMaybe<ChallengeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesOrderBy>>;
};


export type UserChallengesProofsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengesProofCondition>;
  filter?: InputMaybe<ChallengesProofFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesProofsOrderBy>>;
};


export type UserChallengesWitnessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ChallengesWitnessCondition>;
  filter?: InputMaybe<ChallengesWitnessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ChallengesWitnessesOrderBy>>;
};


export type UserCloudsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CloudCondition>;
  filter?: InputMaybe<CloudFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CloudsOrderBy>>;
};


export type UserFavoriteGamesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FavoriteGameCondition>;
  filter?: InputMaybe<FavoriteGameFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoriteGamesOrderBy>>;
};


export type UserFeedbacksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FeedbackCondition>;
  filter?: InputMaybe<FeedbackFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeedbacksOrderBy>>;
};


export type UserFollowingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FollowingCondition>;
  filter?: InputMaybe<FollowingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FollowingsOrderBy>>;
};


export type UserFollowingsByFollowingIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FollowingCondition>;
  filter?: InputMaybe<FollowingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FollowingsOrderBy>>;
};


export type UserFunniesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FunnyCondition>;
  filter?: InputMaybe<FunnyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FunniesOrderBy>>;
};


export type UserGendersByCreatedByIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenderCondition>;
  filter?: InputMaybe<GenderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GendersOrderBy>>;
};


export type UserLikesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LikeCondition>;
  filter?: InputMaybe<LikeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LikesOrderBy>>;
};


export type UserMediasArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MediaCondition>;
  filter?: InputMaybe<MediaFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MediaOrderBy>>;
};


export type UserMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MessageCondition>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessagesOrderBy>>;
};


export type UserNoobsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NoobCondition>;
  filter?: InputMaybe<NoobFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NoobsOrderBy>>;
};


export type UserOzSubscriptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OzSubscriptionCondition>;
  filter?: InputMaybe<OzSubscriptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OzSubscriptionsOrderBy>>;
};


export type UserPaymentMethodsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentMethodCondition>;
  filter?: InputMaybe<PaymentMethodFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentMethodsOrderBy>>;
};


export type UserPostsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostCondition>;
  filter?: InputMaybe<PostFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsOrderBy>>;
};


export type UserPostsReportsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostsReportCondition>;
  filter?: InputMaybe<PostsReportFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsReportsOrderBy>>;
};


export type UserPostsReportsByValidatingUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostsReportCondition>;
  filter?: InputMaybe<PostsReportFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsReportsOrderBy>>;
};


export type UserPostsViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostsViewCondition>;
  filter?: InputMaybe<PostsViewFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostsViewsOrderBy>>;
};


export type UserPrivateUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PrivateUserCondition>;
  filter?: InputMaybe<PrivateUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PrivateUsersOrderBy>>;
};


export type UserRoomsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RoomCondition>;
  filter?: InputMaybe<RoomFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RoomsOrderBy>>;
};


export type UserTeamsByCreatedByIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TeamCondition>;
  filter?: InputMaybe<TeamFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsOrderBy>>;
};


export type UserTeamsMembersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TeamsMemberCondition>;
  filter?: InputMaybe<TeamsMemberFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsMembersOrderBy>>;
};


export type UserTeamsMembersByBannedByArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TeamsMemberCondition>;
  filter?: InputMaybe<TeamsMemberFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsMembersOrderBy>>;
};


export type UserTeamsMembersByRemovedByArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TeamsMemberCondition>;
  filter?: InputMaybe<TeamsMemberFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TeamsMembersOrderBy>>;
};


export type UserTransactionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TransactionCondition>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TransactionsOrderBy>>;
};


export type UserUserAccessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserAccessCondition>;
  filter?: InputMaybe<UserAccessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserAccessesOrderBy>>;
};


export type UserUserEmailsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserEmailCondition>;
  filter?: InputMaybe<UserEmailFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserEmailsOrderBy>>;
};


export type UserUserRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserRoleCondition>;
  filter?: InputMaybe<UserRoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserRolesOrderBy>>;
};


export type UserUsersAvailableRoomsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersAvailableRoomCondition>;
  filter?: InputMaybe<UsersAvailableRoomFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersAvailableRoomsOrderBy>>;
};


export type UserUsersReceivedMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UsersReceivedMessageCondition>;
  filter?: InputMaybe<UsersReceivedMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersReceivedMessagesOrderBy>>;
};


export type UserWitnessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<WitnessCondition>;
  filter?: InputMaybe<WitnessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WitnessesOrderBy>>;
};

export type UserAccess = Node & {
  __typename?: 'UserAccess';
  bannedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  suspendedAt?: Maybe<Scalars['Datetime']>;
  suspendedFor?: Maybe<Duration>;
  /** Reads a single `User` that is related to this `UserAccess`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `UserAccess` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserAccessCondition = {
  /** Checks for equality with the object’s `bannedAt` field. */
  bannedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `suspendedAt` field. */
  suspendedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `suspendedFor` field. */
  suspendedFor?: InputMaybe<Duration>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `UserAccess` object types. All fields are combined with a logical ‘and.’ */
export type UserAccessFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserAccessFilter>>;
  /** Filter by the object’s `bannedAt` field. */
  bannedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserAccessFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserAccessFilter>>;
  /** Filter by the object’s `suspendedAt` field. */
  suspendedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `suspendedFor` field. */
  suspendedFor?: InputMaybe<DurationFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `UserAccess` */
export type UserAccessInput = {
  bannedAt?: InputMaybe<Scalars['Datetime']>;
  suspendedAt?: InputMaybe<Scalars['Datetime']>;
  suspendedFor?: InputMaybe<Duration>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `UserAccess`. Fields that are set will be updated. */
export type UserAccessPatch = {
  bannedAt?: InputMaybe<Scalars['Datetime']>;
  suspendedAt?: InputMaybe<Scalars['Datetime']>;
  suspendedFor?: InputMaybe<Duration>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `UserAccess` values. */
export type UserAccessesConnection = {
  __typename?: 'UserAccessesConnection';
  /** A list of edges which contains the `UserAccess` and cursor to aid in pagination. */
  edges: Array<UserAccessesEdge>;
  /** A list of `UserAccess` objects. */
  nodes: Array<UserAccess>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserAccess` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserAccess` edge in the connection. */
export type UserAccessesEdge = {
  __typename?: 'UserAccessesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserAccess` at the end of the edge. */
  node: UserAccess;
};

/** Methods to use when ordering `UserAccess`. */
export enum UserAccessesOrderBy {
  BannedAtAsc = 'BANNED_AT_ASC',
  BannedAtDesc = 'BANNED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SuspendedAtAsc = 'SUSPENDED_AT_ASC',
  SuspendedAtDesc = 'SUSPENDED_AT_DESC',
  SuspendedForAsc = 'SUSPENDED_FOR_ASC',
  SuspendedForDesc = 'SUSPENDED_FOR_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `appleId` field. */
  appleId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `bio` field. */
  bio?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `coverPhoto` field. */
  coverPhoto?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `dateOfBirth` field. */
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `facebookId` field. */
  facebookId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `genderId` field. */
  genderId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `googleId` field. */
  googleId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `mfa` field. */
  mfa?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `microsoftData` field. */
  microsoftData?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `nbChallengesWon` field. */
  nbChallengesWon?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `nbFollowers` field. */
  nbFollowers?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `nbFollowing` field. */
  nbFollowing?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `nbPosts` field. */
  nbPosts?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `notificationsPreferences` field. */
  notificationsPreferences?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `password` field. */
  password?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `phone` field. */
  phone?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `photo` field. */
  photo?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `salt` field. */
  salt?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `showOnboarding` field. */
  showOnboarding?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `stripeId` field. */
  stripeId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `twitchId` field. */
  twitchId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `verificationEmailSent` field. */
  verificationEmailSent?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `verifiedEmail` field. */
  verifiedEmail?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `verifiedPhone` field. */
  verifiedPhone?: InputMaybe<Scalars['Boolean']>;
};

export type UserEmail = Node & {
  __typename?: 'UserEmail';
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `User` that is related to this `UserEmail`. */
  user?: Maybe<User>;
  userActivateEmailSentAt?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `UserEmail` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserEmailCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userActivateEmailSentAt` field. */
  userActivateEmailSentAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `UserEmail` object types. All fields are combined with a logical ‘and.’ */
export type UserEmailFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserEmailFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserEmailFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserEmailFilter>>;
  /** Filter by the object’s `userActivateEmailSentAt` field. */
  userActivateEmailSentAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `UserEmail` */
export type UserEmailInput = {
  userActivateEmailSentAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `UserEmail`. Fields that are set will be updated. */
export type UserEmailPatch = {
  userActivateEmailSentAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `UserEmail` values. */
export type UserEmailsConnection = {
  __typename?: 'UserEmailsConnection';
  /** A list of edges which contains the `UserEmail` and cursor to aid in pagination. */
  edges: Array<UserEmailsEdge>;
  /** A list of `UserEmail` objects. */
  nodes: Array<UserEmail>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserEmail` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserEmail` edge in the connection. */
export type UserEmailsEdge = {
  __typename?: 'UserEmailsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserEmail` at the end of the edge. */
  node: UserEmail;
};

/** Methods to use when ordering `UserEmail`. */
export enum UserEmailsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserActivateEmailSentAtAsc = 'USER_ACTIVATE_EMAIL_SENT_AT_ASC',
  UserActivateEmailSentAtDesc = 'USER_ACTIVATE_EMAIL_SENT_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Filter by the object’s `active` field. */
  active?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `appleId` field. */
  appleId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bio` field. */
  bio?: InputMaybe<StringFilter>;
  /** Filter by the object’s `coverPhoto` field. */
  coverPhoto?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateOfBirth` field. */
  dateOfBirth?: InputMaybe<DateFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `facebookId` field. */
  facebookId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `genderId` field. */
  genderId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `googleId` field. */
  googleId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `nbChallengesWon` field. */
  nbChallengesWon?: InputMaybe<IntFilter>;
  /** Filter by the object’s `nbFollowers` field. */
  nbFollowers?: InputMaybe<IntFilter>;
  /** Filter by the object’s `nbFollowing` field. */
  nbFollowing?: InputMaybe<IntFilter>;
  /** Filter by the object’s `nbPosts` field. */
  nbPosts?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserFilter>;
  /** Filter by the object’s `objectId` field. */
  objectId?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `password` field. */
  password?: InputMaybe<StringFilter>;
  /** Filter by the object’s `phone` field. */
  phone?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `photo` field. */
  photo?: InputMaybe<StringFilter>;
  /** Filter by the object’s `salt` field. */
  salt?: InputMaybe<StringFilter>;
  /** Filter by the object’s `showOnboarding` field. */
  showOnboarding?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `stripeId` field. */
  stripeId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `twitchId` field. */
  twitchId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
  /** Filter by the object’s `verificationEmailSent` field. */
  verificationEmailSent?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `verifiedEmail` field. */
  verifiedEmail?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `verifiedPhone` field. */
  verifiedPhone?: InputMaybe<BooleanFilter>;
};

/** An input for mutations affecting `User` */
export type UserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  appleId?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  coverPhoto?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  email?: InputMaybe<Scalars['String']>;
  facebookId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  genderId?: InputMaybe<Scalars['Int']>;
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  mfa?: InputMaybe<Scalars['JSON']>;
  microsoftData?: InputMaybe<Scalars['JSON']>;
  nbChallengesWon?: InputMaybe<Scalars['Int']>;
  nbFollowers?: InputMaybe<Scalars['Int']>;
  nbFollowing?: InputMaybe<Scalars['Int']>;
  nbPosts?: InputMaybe<Scalars['Int']>;
  notificationsPreferences?: InputMaybe<Scalars['JSON']>;
  objectId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['BigInt']>;
  photo?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  showOnboarding?: InputMaybe<Scalars['Boolean']>;
  stripeId?: InputMaybe<Scalars['String']>;
  twitchId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  username: Scalars['String'];
  verificationEmailSent?: InputMaybe<Scalars['Boolean']>;
  verifiedEmail?: InputMaybe<Scalars['Boolean']>;
  verifiedPhone?: InputMaybe<Scalars['Boolean']>;
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  active?: InputMaybe<Scalars['Boolean']>;
  appleId?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  coverPhoto?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  email?: InputMaybe<Scalars['String']>;
  facebookId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  genderId?: InputMaybe<Scalars['Int']>;
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  mfa?: InputMaybe<Scalars['JSON']>;
  microsoftData?: InputMaybe<Scalars['JSON']>;
  nbChallengesWon?: InputMaybe<Scalars['Int']>;
  nbFollowers?: InputMaybe<Scalars['Int']>;
  nbFollowing?: InputMaybe<Scalars['Int']>;
  nbPosts?: InputMaybe<Scalars['Int']>;
  notificationsPreferences?: InputMaybe<Scalars['JSON']>;
  objectId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['BigInt']>;
  photo?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  showOnboarding?: InputMaybe<Scalars['Boolean']>;
  stripeId?: InputMaybe<Scalars['String']>;
  twitchId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  username?: InputMaybe<Scalars['String']>;
  verificationEmailSent?: InputMaybe<Scalars['Boolean']>;
  verifiedEmail?: InputMaybe<Scalars['Boolean']>;
  verifiedPhone?: InputMaybe<Scalars['Boolean']>;
};

export type UserRole = Node & {
  __typename?: 'UserRole';
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Role` that is related to this `UserRole`. */
  role?: Maybe<Role>;
  roleId: Scalars['Int'];
  /** Reads a single `User` that is related to this `UserRole`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `UserRole` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserRoleCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `roleId` field. */
  roleId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `UserRole` object types. All fields are combined with a logical ‘and.’ */
export type UserRoleFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserRoleFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserRoleFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserRoleFilter>>;
  /** Filter by the object’s `roleId` field. */
  roleId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `UserRole` */
export type UserRoleInput = {
  roleId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** Represents an update to a `UserRole`. Fields that are set will be updated. */
export type UserRolePatch = {
  roleId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `UserRole` values. */
export type UserRolesConnection = {
  __typename?: 'UserRolesConnection';
  /** A list of edges which contains the `UserRole` and cursor to aid in pagination. */
  edges: Array<UserRolesEdge>;
  /** A list of `UserRole` objects. */
  nodes: Array<UserRole>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserRole` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserRole` edge in the connection. */
export type UserRolesEdge = {
  __typename?: 'UserRolesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserRole` at the end of the edge. */
  node: UserRole;
};

/** Methods to use when ordering `UserRole`. */
export enum UserRolesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoleIdAsc = 'ROLE_ID_ASC',
  RoleIdDesc = 'ROLE_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type UsernamesCheck = Node & {
  __typename?: 'UsernamesCheck';
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt: Scalars['Datetime'];
  word: Scalars['String'];
};

/**
 * A condition to be used against `UsernamesCheck` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UsernamesCheckCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `word` field. */
  word?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `UsernamesCheck` object types. All fields are combined with a logical ‘and.’ */
export type UsernamesCheckFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UsernamesCheckFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UsernamesCheckFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UsernamesCheckFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `word` field. */
  word?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `UsernamesCheck` */
export type UsernamesCheckInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  word: Scalars['String'];
};

/** Represents an update to a `UsernamesCheck`. Fields that are set will be updated. */
export type UsernamesCheckPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  word?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `UsernamesCheck` values. */
export type UsernamesChecksConnection = {
  __typename?: 'UsernamesChecksConnection';
  /** A list of edges which contains the `UsernamesCheck` and cursor to aid in pagination. */
  edges: Array<UsernamesChecksEdge>;
  /** A list of `UsernamesCheck` objects. */
  nodes: Array<UsernamesCheck>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UsernamesCheck` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UsernamesCheck` edge in the connection. */
export type UsernamesChecksEdge = {
  __typename?: 'UsernamesChecksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UsernamesCheck` at the end of the edge. */
  node: UsernamesCheck;
};

/** Methods to use when ordering `UsernamesCheck`. */
export enum UsernamesChecksOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  WordAsc = 'WORD_ASC',
  WordDesc = 'WORD_DESC'
}

export type UsersAvailableRoom = Node & {
  __typename?: 'UsersAvailableRoom';
  createdAt: Scalars['Datetime'];
  deletedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Room` that is related to this `UsersAvailableRoom`. */
  room?: Maybe<Room>;
  roomId: Scalars['Int'];
  /** Reads a single `User` that is related to this `UsersAvailableRoom`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `UsersAvailableRoom` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UsersAvailableRoomCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `roomId` field. */
  roomId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `UsersAvailableRoom` object types. All fields are combined with a logical ‘and.’ */
export type UsersAvailableRoomFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UsersAvailableRoomFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deletedAt` field. */
  deletedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UsersAvailableRoomFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UsersAvailableRoomFilter>>;
  /** Filter by the object’s `roomId` field. */
  roomId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `UsersAvailableRoom` */
export type UsersAvailableRoomInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  roomId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** Represents an update to a `UsersAvailableRoom`. Fields that are set will be updated. */
export type UsersAvailableRoomPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deletedAt?: InputMaybe<Scalars['Datetime']>;
  roomId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `UsersAvailableRoom` values. */
export type UsersAvailableRoomsConnection = {
  __typename?: 'UsersAvailableRoomsConnection';
  /** A list of edges which contains the `UsersAvailableRoom` and cursor to aid in pagination. */
  edges: Array<UsersAvailableRoomsEdge>;
  /** A list of `UsersAvailableRoom` objects. */
  nodes: Array<UsersAvailableRoom>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UsersAvailableRoom` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UsersAvailableRoom` edge in the connection. */
export type UsersAvailableRoomsEdge = {
  __typename?: 'UsersAvailableRoomsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UsersAvailableRoom` at the end of the edge. */
  node: UsersAvailableRoom;
};

/** Methods to use when ordering `UsersAvailableRoom`. */
export enum UsersAvailableRoomsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoomIdAsc = 'ROOM_ID_ASC',
  RoomIdDesc = 'ROOM_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  AppleIdAsc = 'APPLE_ID_ASC',
  AppleIdDesc = 'APPLE_ID_DESC',
  BioAsc = 'BIO_ASC',
  BioDesc = 'BIO_DESC',
  CoverPhotoAsc = 'COVER_PHOTO_ASC',
  CoverPhotoDesc = 'COVER_PHOTO_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DateOfBirthAsc = 'DATE_OF_BIRTH_ASC',
  DateOfBirthDesc = 'DATE_OF_BIRTH_DESC',
  DeletedAtAsc = 'DELETED_AT_ASC',
  DeletedAtDesc = 'DELETED_AT_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FacebookIdAsc = 'FACEBOOK_ID_ASC',
  FacebookIdDesc = 'FACEBOOK_ID_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  GenderIdAsc = 'GENDER_ID_ASC',
  GenderIdDesc = 'GENDER_ID_DESC',
  GoogleIdAsc = 'GOOGLE_ID_ASC',
  GoogleIdDesc = 'GOOGLE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  MfaAsc = 'MFA_ASC',
  MfaDesc = 'MFA_DESC',
  MicrosoftDataAsc = 'MICROSOFT_DATA_ASC',
  MicrosoftDataDesc = 'MICROSOFT_DATA_DESC',
  Natural = 'NATURAL',
  NbChallengesWonAsc = 'NB_CHALLENGES_WON_ASC',
  NbChallengesWonDesc = 'NB_CHALLENGES_WON_DESC',
  NbFollowersAsc = 'NB_FOLLOWERS_ASC',
  NbFollowersDesc = 'NB_FOLLOWERS_DESC',
  NbFollowingAsc = 'NB_FOLLOWING_ASC',
  NbFollowingDesc = 'NB_FOLLOWING_DESC',
  NbPostsAsc = 'NB_POSTS_ASC',
  NbPostsDesc = 'NB_POSTS_DESC',
  NotificationsPreferencesAsc = 'NOTIFICATIONS_PREFERENCES_ASC',
  NotificationsPreferencesDesc = 'NOTIFICATIONS_PREFERENCES_DESC',
  ObjectIdAsc = 'OBJECT_ID_ASC',
  ObjectIdDesc = 'OBJECT_ID_DESC',
  PasswordAsc = 'PASSWORD_ASC',
  PasswordDesc = 'PASSWORD_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  PhotoAsc = 'PHOTO_ASC',
  PhotoDesc = 'PHOTO_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SaltAsc = 'SALT_ASC',
  SaltDesc = 'SALT_DESC',
  ShowOnboardingAsc = 'SHOW_ONBOARDING_ASC',
  ShowOnboardingDesc = 'SHOW_ONBOARDING_DESC',
  StripeIdAsc = 'STRIPE_ID_ASC',
  StripeIdDesc = 'STRIPE_ID_DESC',
  TwitchIdAsc = 'TWITCH_ID_ASC',
  TwitchIdDesc = 'TWITCH_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  VerificationEmailSentAsc = 'VERIFICATION_EMAIL_SENT_ASC',
  VerificationEmailSentDesc = 'VERIFICATION_EMAIL_SENT_DESC',
  VerifiedEmailAsc = 'VERIFIED_EMAIL_ASC',
  VerifiedEmailDesc = 'VERIFIED_EMAIL_DESC',
  VerifiedPhoneAsc = 'VERIFIED_PHONE_ASC',
  VerifiedPhoneDesc = 'VERIFIED_PHONE_DESC'
}

export type UsersReceivedMessage = Node & {
  __typename?: 'UsersReceivedMessage';
  createdAt: Scalars['Datetime'];
  id: Scalars['Int'];
  /** Reads a single `Message` that is related to this `UsersReceivedMessage`. */
  message?: Maybe<Message>;
  messageId: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `User` that is related to this `UsersReceivedMessage`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/**
 * A condition to be used against `UsersReceivedMessage` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type UsersReceivedMessageCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `messageId` field. */
  messageId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `UsersReceivedMessage` object types. All fields are combined with a logical ‘and.’ */
export type UsersReceivedMessageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UsersReceivedMessageFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `messageId` field. */
  messageId?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UsersReceivedMessageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UsersReceivedMessageFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `UsersReceivedMessage` */
export type UsersReceivedMessageInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  messageId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** Represents an update to a `UsersReceivedMessage`. Fields that are set will be updated. */
export type UsersReceivedMessagePatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  messageId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `UsersReceivedMessage` values. */
export type UsersReceivedMessagesConnection = {
  __typename?: 'UsersReceivedMessagesConnection';
  /** A list of edges which contains the `UsersReceivedMessage` and cursor to aid in pagination. */
  edges: Array<UsersReceivedMessagesEdge>;
  /** A list of `UsersReceivedMessage` objects. */
  nodes: Array<UsersReceivedMessage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UsersReceivedMessage` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UsersReceivedMessage` edge in the connection. */
export type UsersReceivedMessagesEdge = {
  __typename?: 'UsersReceivedMessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UsersReceivedMessage` at the end of the edge. */
  node: UsersReceivedMessage;
};

/** Methods to use when ordering `UsersReceivedMessage`. */
export enum UsersReceivedMessagesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MessageIdAsc = 'MESSAGE_ID_ASC',
  MessageIdDesc = 'MESSAGE_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type Witness = Node & {
  __typename?: 'Witness';
  challengesModerated: Scalars['Int'];
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  rank: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `Witness`. */
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

/** A condition to be used against `Witness` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type WitnessCondition = {
  /** Checks for equality with the object’s `challengesModerated` field. */
  challengesModerated?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rank` field. */
  rank?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Witness` object types. All fields are combined with a logical ‘and.’ */
export type WitnessFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WitnessFilter>>;
  /** Filter by the object’s `challengesModerated` field. */
  challengesModerated?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WitnessFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WitnessFilter>>;
  /** Filter by the object’s `rank` field. */
  rank?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Witness` */
export type WitnessInput = {
  challengesModerated?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId: Scalars['Int'];
};

/** Represents an update to a `Witness`. Fields that are set will be updated. */
export type WitnessPatch = {
  challengesModerated?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['Int']>;
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Witness` values. */
export type WitnessesConnection = {
  __typename?: 'WitnessesConnection';
  /** A list of edges which contains the `Witness` and cursor to aid in pagination. */
  edges: Array<WitnessesEdge>;
  /** A list of `Witness` objects. */
  nodes: Array<Witness>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Witness` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Witness` edge in the connection. */
export type WitnessesEdge = {
  __typename?: 'WitnessesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Witness` at the end of the edge. */
  node: Witness;
};

/** Methods to use when ordering `Witness`. */
export enum WitnessesOrderBy {
  ChallengesModeratedAsc = 'CHALLENGES_MODERATED_ASC',
  ChallengesModeratedDesc = 'CHALLENGES_MODERATED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RankAsc = 'RANK_ASC',
  RankDesc = 'RANK_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type GetPostQueryVariables = Exact<{
  postId?: InputMaybe<Scalars['Int']>;
}>;


export type GetPostQuery = { __typename?: 'Query', posts?: { __typename?: 'PostsConnection', nodes: Array<{ __typename?: 'Post', caption?: string | null, createdAt: any, deletedAt?: any | null, user?: { __typename?: 'User', id: number, photo?: string | null, username: string, privateUser?: { __typename?: 'PrivateUser', private: boolean } | null } | null, media?: { __typename?: 'Media', thumbUrl: string, url: string } | null }> } | null };


export const GetPostDocument = gql`
    query GetPost($postId: Int) {
  posts(condition: {id: $postId}) {
    nodes {
      caption
      createdAt
      user {
        id
        photo
        username
        privateUser {
          private
        }
      }
      media {
        thumbUrl
        url
      }
      deletedAt
    }
  }
}
    `;

/**
 * __useGetPostQuery__
 *
 * To run a query within a React component, call `useGetPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetPostQuery(baseOptions?: Apollo.QueryHookOptions<GetPostQuery, GetPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostQuery, GetPostQueryVariables>(GetPostDocument, options);
      }
export function useGetPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostQuery, GetPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostQuery, GetPostQueryVariables>(GetPostDocument, options);
        }
export type GetPostQueryHookResult = ReturnType<typeof useGetPostQuery>;
export type GetPostLazyQueryHookResult = ReturnType<typeof useGetPostLazyQuery>;
export type GetPostQueryResult = Apollo.QueryResult<GetPostQuery, GetPostQueryVariables>;
import { useAuth0 } from '@auth0/auth0-react';
import { FC, ReactNode } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import routeNames from 'src/utils/routeNames';

interface GuestProps {
    children: ReactNode;
}

const Guest: FC<GuestProps> = ({ children }) => {
    const [searchParams] = useSearchParams();
    const { isAuthenticated, isLoading } = useAuth0();

    if (!isLoading && isAuthenticated) {
        if (searchParams.get('next')) {
            return <Navigate to={decodeURI(searchParams.get('next'))} />;
        }

        return <Navigate to={routeNames.home} />;
    }

    return <>{children}</>;
};

export default Guest;

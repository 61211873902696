import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import config from 'src/utils/config';

export const createApolloClient = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    const Authorization = accessToken ? { Authorization: `Bearer ${accessToken}` } : null;
    return new ApolloClient({
        link: new HttpLink({
            uri: config.graphqlApi,
            headers: Authorization,
        }),
        cache: new InMemoryCache(),
    });
};

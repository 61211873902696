import './index.css';
import './assets/css/icomoon.min.css';

import { ApolloProvider } from '@apollo/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { render } from 'react-dom';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { createApolloClient } from 'src/apollo/client';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import ScrollTop from 'src/hooks/useScrollTop';
import * as serviceWorker from 'src/serviceWorker';
import { persistor, store } from 'src/store';

import config from './utils/config';

const client = createApolloClient();

ReactGA.initialize('G-D0N7DZG64L');

render(
    <HelmetProvider>
        <Provider store={store}>
            <Auth0Provider
                domain={config.domain}
                clientId={config.client_id}
                authorizationParams={{
                    redirect_uri: window.location.origin,
                }}
            >
                <ApolloProvider client={client}>
                    <PersistGate loading={<>Loading ...</>} persistor={persistor}>
                        <SidebarProvider>
                            <BrowserRouter>
                                <ScrollTop />
                                <App />
                            </BrowserRouter>
                        </SidebarProvider>
                    </PersistGate>
                </ApolloProvider>
            </Auth0Provider>
        </Provider>
    </HelmetProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();

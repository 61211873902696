import { lazy, Suspense } from 'react';
import Guest from 'src/components/Guest';
import SuspenseLoader from 'src/components/SuspenseLoader';
import routeNames from 'src/utils/routeNames';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

// Auth
const Login = Loader(lazy(() => import('src/pages/Auth/Login')));

const authRoutes = [
    {
        path: routeNames.auth.login,
        element: (
            <Guest>
                <Login />
            </Guest>
        ),
    },
];

export default authRoutes;

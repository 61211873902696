const logout = () => {
    setSession(null, null, null);
};

export const setSession = (accessToken: string | null, idToken: string | null, refreshToken: string | null): void => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
    } else {
        localStorage.removeItem('accessToken');
    }
    if (idToken) {
        localStorage.setItem('idToken', idToken);
    } else {
        localStorage.removeItem('idToken');
    }
    if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
    } else {
        localStorage.removeItem('refreshToken');
    }
};

export { logout };

import { Box, List, ListItem, styled } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import { TMenuItem } from 'src/models/types';
import { logout } from 'src/services/authentication';
import { logout as logoutAction } from 'src/store/actions/authenticationActions';
import { v4 as uuidv4 } from 'uuid';

import NavigationMenuItem from './item';
import menuItems, { MenuItem } from './items';

const MenuWrapper = styled(Box)(
    () => `
  .MuiList-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    & > .MuiList-root {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
    }
  }
`
);

const SubMenuWrapper = styled(Box)(
    ({ theme }) => `
    width: 100%;
    .MuiList-root {
      padding: 0;
      display: flex;
      flex-direction: row;

      .MuiList-root .MuiList-root .MuiListItem-root .MuiIconButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 0 2px;
        justify-content: center;
        width: auto;

        &:last-child {
          margin-left: auto;
        }

        &:first-child {
          margin-right: auto;
        }

        .MuiIconButton-root {
          display: flex;
          background-color: transparent !important;
          justify-content: center;
          font-size: ${theme.typography.pxToRem(14)};
          padding: ${theme.spacing(1.4, 2)};
          position: relative;
          font-weight: bold;
          color: ${theme.colors.alpha.trueWhite[100]};

          .name-wrapper {
            transition: ${theme.transitions.create(['color'])};
          }

          .MuiBadge-root {
            position: absolute;
            right: 16px;
            top: 12px;

            .MuiBadge-badge {
              background: ${theme.colors.alpha.white[70]};
              color: ${theme.colors.alpha.black[100]};
              font-size: ${theme.typography.pxToRem(11)};
              font-weight: bold;
              text-transform: uppercase;
            }
          }

          .MuiSvgIcon-root {
            transition: ${theme.transitions.create(['color'])};
            font-size: ${theme.typography.pxToRem(24)};
            margin-right: ${theme.spacing(1)};
            color: ${theme.colors.alpha.trueWhite[50]};
          }

          @media only screen and (max-width: 1350px){
            .MuiSvgIcon-root {
              margin: 0;
            }
          }

          &.active,
          &:hover {
            background-color: ${theme.colors.alpha.white[10]};

            .MuiSvgIcon-root {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }
      }
    }
`
);

const renderNavigationMenuItems = ({
    items,
    type,
    path,
}: {
    items: MenuItem[];
    path: string;
    type?: string;
}): JSX.Element => (
    <SubMenuWrapper>
        <List component="div" sx={{ display: 'flex' }}>
            {type === TMenuItem.LOGO ? (
                <ListItem
                    component="div"
                    className="Mui-children"
                    key="top-menu-logo"
                    style={{ marginTop: '-26px', marginBottom: '26px' }}
                >
                    <Logo withText />
                </ListItem>
            ) : (
                items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])
            )}
        </List>
    </SubMenuWrapper>
);

const reduceChildRoutes = ({
    ev,
    path,
    item,
}: {
    ev: JSX.Element[];
    path: string;
    item: MenuItem;
}): Array<JSX.Element> => {
    const key = uuidv4();

    const exactMatch = item.link
        ? !!matchPath(
              {
                  path: item.link,
                  end: true,
              },
              path
          )
        : false;

    if (item.items) {
        const partialMatch = item.link
            ? !!matchPath(
                  {
                      path: item.link,
                      end: false,
                  },
                  path
              )
            : false;

        ev.push(
            <NavigationMenuItem
                key={key}
                active={partialMatch}
                open={partialMatch}
                name={item.name}
                icon={item.icon}
                link={item.link}
                badge={item.badge}
                action={item.action}
            >
                {renderNavigationMenuItems({
                    path,
                    items: item.items,
                })}
            </NavigationMenuItem>
        );
    } else {
        ev.push(
            <NavigationMenuItem
                key={key}
                active={exactMatch}
                name={item.name}
                link={item.link}
                badge={item.badge}
                icon={item.icon}
                action={item.action}
            />
        );
    }

    return ev;
};

const NavigationMenu = () => {
    const location = useLocation();
    const { user } = useAuth();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logoutAction());
        logout();
    };

    return (
        <>
            {menuItems({ user, logoutAction: handleLogout }).map((section) => (
                <MenuWrapper key={uuidv4()}>
                    <List component="div">
                        {renderNavigationMenuItems({
                            items: section.items,
                            path: location.pathname,
                            type: section.type,
                        })}
                    </List>
                </MenuWrapper>
            ))}
        </>
    );
};

export default NavigationMenu;

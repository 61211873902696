import { buildApiRoute, restoreLoginApi, subscribeToOzPlusApi } from 'src/utils/api';

import {
    CLEAR_AUTHENTICATION,
    LOGIN,
    LOGOUT,
    RESTORE,
    SAVE_AUTHENTICATION,
    SUBSCRIBE_TO_OZ_PLUS,
} from '../constants/authentication.constants';

export const logout = () => ({
    type: LOGOUT,
});

export const login = (payload) => ({
    type: LOGIN,
    payload,
});

export const saveAuthentication = (payload) => ({
    type: SAVE_AUTHENTICATION,
    payload,
});

export const clearAuthentication = () => ({
    type: CLEAR_AUTHENTICATION,
    payload: null,
});

export const subscribeToOzPlus = () => ({
    type: SUBSCRIBE_TO_OZ_PLUS,
    payload: {
        request: {
            url: subscribeToOzPlusApi,
            method: 'post',
        },
    },
});

export const restore = () => {
    return {
        type: RESTORE,
        payload: {
            request: {
                url: buildApiRoute(restoreLoginApi, { isIamApi: true }),
                method: 'post',
                withCredentials: true,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                },
            },
        },
    };
};

import { Avatar, Box, Container, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LogoLight from 'src/assets/images/logo-light.png';
import { useGetPostQuery } from 'src/generated/graphql';
import useAuth from 'src/hooks/useAuth';
import routeNames from 'src/utils/routeNames';

const SinglePost = () => {
    const { postId } = useParams();
    const [postData, setData] = useState(null);
    const [error] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();

    const { data, loading } = useGetPostQuery({
        variables: { postId: parseInt(postId) },
        fetchPolicy: 'cache-and-network',
    });
    useEffect(() => {
        if (!loading) {
            if (!isAuthenticated) {
                if (
                    data.posts.nodes[0]?.deletedAt === null ||
                    data.posts.nodes[0]?.user?.privateUser?.private === null
                ) {
                    setData(data);
                } else {
                    navigate(`${routeNames.auth.login}?next=${encodeURI(location.pathname)}`);
                }
            } else {
                if (!data.posts.nodes[0]) {
                    setData(null);
                    navigate(`${routeNames.auth.login}`);
                } else {
                    setData(data);
                }
            }
        }
    }, [isAuthenticated, loading, data, navigate, location.pathname]);

    return (
        <div
            style={{
                background: 'linear-gradient(to bottom right, #c17dff, #5e43ac, #3c2b8d)',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
            }}
        >
            <div>
                <a href="index.html" className="pt-2.5 pl-2.5 pb-1">
                    <img className="w-[50%] sm:w-[11rem] md:w-[13rem] lg:w-[10rem]" src={LogoLight} alt="Logo" />
                </a>
            </div>
            <Container maxWidth="sm">
                <Box textAlign="center" paddingTop="50px">
                    {postData && (
                        <>
                            <Box display="flex" flexDirection="column" alignItems="center" marginBottom="20px">
                                <Avatar src={postData.posts.nodes[0].user.photo} sx={{ width: 80, height: 80 }} />
                                <Typography variant="h5" fontWeight="bold" marginTop="10px" color="white">
                                    {postData.posts.nodes[0].user.username}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" marginBottom="20px">
                                <ReactPlayer
                                    controls
                                    url={postData.posts.nodes[0].media.url}
                                    width="100%"
                                    height="100%"
                                />
                            </Box>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="subtitle1" color="white">
                                        {postData.posts.nodes[0].caption}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="caption" color="white">
                                        {format(new Date(postData.posts.nodes[0].createdAt), 'hh:mm:ss yyyy/MM/dd')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {error && <Typography>{error}</Typography>}
                </Box>
            </Container>
        </div>
    );
};

export default SinglePost;

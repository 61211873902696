import { lazy } from 'react';
import { RouteObject } from 'react-router';
import Authenticated from 'src/components/Authenticated';
import AuthLayout from 'src/layouts/AuthLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import TopNavigationLayout from 'src/layouts/TopNavigationLayout';
import SinglePost from 'src/pages/Posts/Single';
import TermsAndConditions from 'src/pages/Terms-conditions';
import routeNames from 'src/utils/routeNames';

import authRoutes from './auth';
import baseRoutes from './base';
import { Loader } from './Loader';

const Home = Loader(lazy(() => import('src/pages/Home')));
const PrivacyPolicy = Loader(lazy(() => import('src/pages/Privacy-policy')));

const router: RouteObject[] = [
    // Auth Routes
    {
        path: 'auth',
        element: <AuthLayout />,
        children: authRoutes,
    },

    // Main Routes
    {
        path: '',
        element: <BaseLayout />,
        children: baseRoutes,
    },

    // Post is not private
    {
        path: '',
        element: <BaseLayout />,
        children: [
            {
                path: routeNames.posts.single,
                element: <SinglePost />,
            },
            { path: routeNames.termsAndConditions, element: <TermsAndConditions /> },
            { path: routeNames.termsConditions, element: <TermsAndConditions /> },
        ],
    },

    // Privacy policy Routes
    { path: routeNames.privacyPolicy, element: <PrivacyPolicy /> },
    // Authenticated Routes
    {
        path: '',
        element: (
            <Authenticated>
                <TopNavigationLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '',
                element: <Home />,
            },
            {
                path: routeNames.posts.single,
                element: <SinglePost />,
            },
        ],
    },
];

export default router;

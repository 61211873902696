import { Container, Typography } from '@mui/material';
import LogoLight from 'src/assets/images/logo-light.png';

const TermsAndConditions = () => {
    return (
        <div
            style={{
                background: 'linear-gradient(to bottom right, #c17dff, #5e43ac, #3c2b8d)',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
            }}
        >
            <div>
                <a href="" className="pt-2.5 pl-2.5 pb-1">
                    <img className="w-[50%] sm:w-[11rem] md:w-[13rem] lg:w-[10rem]" src={LogoLight} alt="Logo" />
                </a>
            </div>

            <Container style={{ height: '100%', marginTop: '150px', color: 'white' }}>
                <div>
                    <Typography
                        variant="h1"
                        color="white"
                        align="center"
                        paddingBottom="20px"
                        style={{ textDecoration: 'underline' }}
                    >
                        Terms and Conditions
                    </Typography>
                </div>
                Ozlynk provides an online Platform that enables our Users to record and share gaming clips and videos,
                and access the best-shared content by the gaming community within a safe and friendly environment, via
                our services (together: the Services). We enable users to interact with each other through clips,
                engagement and messages. We offer our Services via our Desktop app, mobile applications, and our
                Website: Ozlynk.com. These Terms of Service (the Terms) apply to the relationship between Ozlynk and the
                Users of the Services. Use of the Services is conditional upon acceptance of and compliance with these
                Terms. Ozlynk may amend these Terms from time to time. The latest version of the Terms applies to the
                relationship between Ozlynk and the User. Please read these Terms carefully, and contact us if you have
                any questions.
                <Typography variant="h4" color={'white'}>
                    1. WHO CAN USE Ozlynk
                </Typography>
                1.1 You may use our Services only if you can form a binding contract with Ozlynk, and only in compliance
                with these Terms and all applicable laws. 1.2 When you create your Ozlynk account, you must provide us
                with accurate and complete information. Any use or access by anyone under the age of 13 (thirteen) is
                prohibited.
                <Typography variant="h4" color={'white'}>
                    2. REGISTRATION AND ACCOUNT
                </Typography>
                2.1 You can use our Services with or without an account. If you sign up for an Account, you can do so
                via your e-mail address or log in via your account on a connected third-party platform, such as Discord,
                Steam or Facebook. Please note that an Account gives you access to more features on the Platform. 2.2 We
                require you to choose a password that is at least 8 (eight) characters long and we recommend you to
                include letters, symbols and numbers and not use it for other services. You are fully responsible for
                not disclosing your login credentials and the use of your Account to anyone. 2.3 When you create your
                Account, you agree to only provide correct and complete information. You are responsible for the
                accuracy of the data in your Account and Ozlynk cannot be held liable in that respect. 2.4 If, for
                whatsoever reason, your Account is blocked or deleted, you are no longer entitled to use the Platform in
                any way (whether through that Account, another Account, or without an Account). 2.5You may not allow use
                of your Account by any unauthorised third party nor others that do not have the authority to represent
                you. You guarantee that any third party that uses your Account is authorised to represent you. 2.6If you
                use our Services on behalf of a company, organization, or other entity, then "you" includes you and that
                entity, and you represent and warrant that you are authorized to grant all permissions and licenses
                provided in these Terms and bind the entity to these Terms, and that you agree to these Terms on the
                entity's behalf. 2.7The Services, Platform and our Apps are continuously updated. You agree that these
                Terms will apply to all (future) versions thereof, unless we have stated otherwise. 2.8We reserve the
                right to refuse Services to anyone. We are entitled to suspend, block or delete your Account, including
                but not limited to cases where Ozlynk receives a legitimate notice-and-take-down or DMCA request, in
                case of violation of one or more of the applicable Terms, any form of fraud, intentional abuse or any
                willful misconduct (or attempt thereto) in using any of our Services or disruption of a proper
                functioning of the Platform.
                <Typography variant="h4" color={'white'}>
                    3. (PROHIBITED) USE OF THE PLATFORM
                </Typography>
                3.1 You must ensure that your use of the Services is in accordance with these Terms and for the purpose
                for which Ozlynk intended the Platform/Services. You may not use the Services in any way that impairs
                the integrity or continuity of Ozlynks' systems and/or the Services. 3.2 It is your responsibility to
                use the Services in compliance with applicable law and with any third party rights. More specifically
                (without limitation), you are not allowed to use the Services: (a) in a way that infringes on a third
                party's intellectual property rights; (b) in a way that violates a person's privacy; (c) to send
                unsolicited messages to any other User or third party recipient; or (d) to conduct any illegal activity
                or solicit the performance of any illegal activity or other activity that infringes the rights of third
                parties. 3.3 If you act contrary to this provision, we reserve the right to block and/or terminate your
                Account and/or use of the Services at any time and to remove all information relating to your
                misconduct. 3.4 You are not permitted to disclose, reproduce or otherwise exploit (parts of) the
                Services, including the content related therein, such as, but not limited to, music or other auditory
                functions made available through the Platform. The Services are for personal use only. Unless otherwise
                expressly authorized herein or by us, you agree not to display, modify, sell, resell, exploit, transfer
                or upload for any commercial purposes, any portion of the Services.
                <Typography variant="h4" color={'white'}>
                    4. INTELLECTUAL PROPERTY AND USER GENERATED CONTENT
                </Typography>
                4.1 Ozlynk is the exclusive owner of all intellectual property rights vested in and/or arising from the
                Platform (including the underlying source and object code) and Services, including, but not limited to,
                patents, patent applications, trademarks, trademark applications, service marks, trade names,
                copyrights, trade secrets, licenses, domain names, know how, property rights and processes (the
                Intellectual Property Rights). 4.2 As long as you comply with these Terms and our policies, we grant you
                a limited, non-exclusive, non-transferable, non-sublicensable and revocable license to use our Platform
                and Services. 4.3 Ozlynk allows you to post content, including video (clips), comments, links, and other
                materials on the Platform. Anything that you post or otherwise make available, in the broadest sense, on
                the Platform is referred to as User Generated Content. You retain all (Intellectual Property) rights in,
                and are solely responsible for, the User Generated Content you post on the Platform. By uploading any
                User Generated Content, you represent and warrant that: i. doing so does not violate or infringe any
                rights of a third-party; and ii. you created the User Generated Content you are uploading, or have a
                sufficient license, or are otherwise authorized to upload the User Generated Content consistent with
                these Terms. 4.4 You grant Ozlynk, and its sub-licensees, to the furthest extent and for the maximum
                duration permitted by applicable law (perpetually, if permitted under applicable law), a non-exclusive,
                unrestricted, irrevocable, royalty-free, transferable, fully sublicensable, worldwide license to use,
                store, display, reproduce, save, modify, adapt, publish, translate, create derivative works from,
                perform distribute and/or redistribute, all or a part of your User Generated Content, in any form,
                format (digitally or otherwise), media, or media channels now known or later created, for the purposes
                of, including but not limited to, operating, developing, providing, using, promoting, and marketing the
                Platform, the Products, the Services, the App and/or the Website. Nothing in these Terms shall restrict
                other legal rights Ozlynk may have to User Generated Content, for example under other licenses. 4.5The
                license granted in section 2.4 includes but is not limited to the (re)distribution of a part or all of
                the User Generated Content, to and/or through third parties. 4.6Unless otherwise required by applicable
                law, Ozlynk will not monitor the User Generated Content uploaded or shared via the Platform. However, we
                reserve the right to remove or modify any User Generated Content if we are notified that the content or
                information violates these Terms or our policies, or otherwise is unlawful or inappropriate. 4.7Ozlynk
                takes no responsibility and assumes no liability for any User Content that you or any other user or
                third party posts or transmits using our Products. You understand and agree that you may be exposed to
                User Content that is inaccurate, objectionable, inappropriate for users, or otherwise unsuited to your
                purpose. 4.8Following termination or deactivation of your account, or if you remove any User Generated
                Content from Ozlynk, we may retain your User Generated Content for a commercially reasonable period of
                time for backup, archival, or audit purposes. Furthermore, copies of content shared with others may
                remain outside the Platform, even after you delete the content from your account. Ozlynk and its Users
                may retain and continue to use, store, display, reproduce, re-pin, modify, create derivative works,
                perform, and distribute any of your User Generated Content that other users have stored or shared
                through Ozlynk. 4.9We value hearing from our users, and are always interested in learning about ways we
                can make Ozlynk more awesome. If you choose to submit comments, ideas or feedback (example via
                feedback.Ozlynk.tv), you agree that we are free to use them without any restriction or compensation to
                you. By accepting your submission, Ozlynk does not waive any rights to use similar or related Feedback
                previously known to Ozlynk, or developed by its employees, or obtained from sources other than you.
                <Typography variant="h4" color={'white'}>
                    5. USE OF API
                </Typography>
                5.1 As part of our Services, we provide a tool known as Application Programming Interfaces (Ozlynk API).
                The Ozlynk API enables our Users to display (selected) User Generated Content shared publicly on the
                Platform via their own services, such as their websites and/or applications. 5.2 We grant the User of
                the Ozlynk API a limited, non-exclusive, non-sublicensable and revocable license to display the User
                Generated Content shared publicly on the Platform via their own services, such as their websites and/or
                applications (the API License), provided the User complies with the following conditions: 5.2 .1 The
                User uses the Ozlynk API in accordance with these Terms,all applicable laws and regulations that apply
                to such use. 5.2.2 the User does not infringe any rights of third parties. 5.2 .3 the User provides
                Ozlynk all assistance requested in complying with, and concluding all agreements required by the
                applicable laws and regulations. 5.3 User acknowledges it qualifies as separate Controller, as defined
                in the General Data Protection Regulation (EU) 2016/679 (the “GDPR”), regarding the processing of
                personal data in the User Generated Content as displayed through the use of the Ozlynk API. 5.4 Each
                User using the Ozlynk API shall be liable for all Ozlynk's and other Users' damages arising out of any
                breach of article 3.2 by the User using the Ozlynk API services. 5.5Each User using the Ozlynk API shall
                indemnify Ozlynk and keep Ozlynk indemnified for all claims, proceedings or actions brought by another
                User or any other third party, including governmental authorities.
                <Typography variant="h4" color={'white'}>
                    6. PRIVACY
                </Typography>
                6.1 When you use our Services, we will process certain personal data about you. We will process your
                personal data. 6.2 By publicly posting content on the Platform, you are directing Ozlynk to disclose
                that information as broadly as possible, including through our APIs and via its partners (such as
                Microsoft).
                <Typography variant="h4" color={'white'}>
                    7. AVAILABILITY OF THE SERVICES, DISCLAIMER OF WARRANTIES
                </Typography>
                7.1 The Platform is available through the Website and via our Apps. The Apps are available among others
                on devices running Windows, Android and Apple's iOS. 7.2 The Services and all included content are
                provided on an "as is" basis without warranty of any kind, whether express or implied. 7.3 We will make
                reasonable efforts to ensure the availability of the Services at all times. However, we do not make any
                commitments with regard to the availability, continuity, functionality or usability of the Services, nor
                do we make any other commitments other than those expressly set out in these Terms. You acknowledge that
                the Services are provided over the internet and mobile networks and thus the quality and availability of
                the Services may be affected by factors outside our reasonable control. 7.4 We have the right to
                partially or entirely terminate, suspend or alter the provision of the Services at any time, without
                prior notification, and for any reason at its sole discretion, for example (without limitation) if we
                have reason to believe that you act in violation of these Terms, applicable law or the rights of a third
                party, or we decide to cease or alter one or more of our services in general. 7.5 We care about the
                security of our users. While we work to protect the security of your content and account, Ozlynk cannot
                guarantee that unauthorized third parties will not be able to defeat our security measures. Please
                notify us immediately of any compromise or unauthorized use of your account. 7.6 We reserve the right to
                modify the Services at any time. 7.7 We reserve the right to wholly or partially use third parties to
                supply the Platform at any time. 7.8 Ozlynk SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS
                OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING
                OUT OF COURSE OF DEALING OR USAGE OF TRADE.
                <Typography variant="h4" color={'white'}>
                    8. TERM AND TERMINATION
                </Typography>
                8.1 The agreement between you and Ozlynk reflected by these Terms is effective when you access the
                Platform or use other Services, for example by creating an Account, and remains in effect for as long as
                you use our Services (and are allowed to do so). 8.2 You may terminate this agreement at any time by
                deleting your Account and stopping to use our Services. We may terminate this agreement immediately and
                without notice and stop providing access to the Services if you breach these Terms, you violate
                applicable laws, or we reasonably believe termination is necessary to protect us, other Users or other
                third parties. In such case, the license provided by Ozlynk is also terminated. 8.3 If your Account has
                been inactive for more than two (2) years, we may terminate your Account after we have sent you a
                reminder and you have not taken any action within the time frame specified by us.
                <Typography variant="h4" color={'white'}>
                    9. LIMITATION OF LIABILITY
                </Typography>
                9.1 TO THE MAXIMUM EXTENT PERMITTED BY LAW, Ozlynk SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED
                DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM:
                A. YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; B. ANY DAMAGE OR ALTERATION TO
                YOUR EQUIPMENT INCLUDING, BUT NOT LIMITED TO, COMPUTER EQUIPMENT AND DEVICES BECAUSE OF YOUR USE OF THE
                PLATFORM AND/OR SERVICES; C. ANY ACTIONS, INACTIONS, CONDUCT OR (USER GENERATED) CONTENT OF ANY USER OR
                THIRD PARTY ON THE PLATFORM, INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT
                OF OTHER USERS OR THIRD PARTIES; OR D. UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR ACCOUNT,
                TRANSMISSIONS OR (USER GENERATED) CONTENT; E. FAILURE TO MEET ANY OF OUR OBLIGATIONS UNDER THESE TERMS
                WHERE SUCH FAILURE IS DUE TO EVENTS BEYOND OUR CONTROL (FOR EXAMPLE A NETWORK FAILURE). 9.2 IF Ozlynk IS
                LIABLE FOR ANY REASON, THIS LIABILITY SHALL BE LIMITED TO AN AMOUNT OF ONE HUNDRED UNITED STATES DOLLARS
                ($ 100). 9.3 Nothing in these Terms will exclude or limit our liability if this cannot be excluded or
                limited under the applicable law.
                <Typography variant="h4" color={'white'}>
                    10. INDEMNITY
                </Typography>
                10.1 To the maximum extent permitted by applicable law, you agree to indemnify, defend and hold harmless
                Ozlynk and its respective officers, directors, employees and agents, from and against any claims, suits,
                proceedings, disputes, demands, liabilities, damages, losses, costs and expenses, including, without
                limitation, reasonable legal and accounting fees (including, but not limited to, costs of defense),
                arising out or in any way connected with: a) a breach by you or any other user of your Account of these
                Terms or our Community Standards; b) your (improper) use of the Platform and Services; or c) a breach by
                you or any other user of your Account of any laws and regulations or third party rights such as
                intellectual property or privacy rights.
                <Typography variant="h4" color={'white'}>
                    11. THIRD-PARTY LINKS, SITES, AND SERVICES
                </Typography>
                Our Platform and Website may contain (back)links to third-party websites, advertisers, services, special
                offers, or other events or activities that are not owned or controlled by Ozlynk. We do not endorse or
                assume any responsibility for any such third-party sites, information, materials, products, or services.
                If you access any third party website, service, or content from Ozlynk, you do so at your own risk and
                you agree that Ozlynk will have no liability arising from your use of or access to any third-party
                website, service, or content.
                <Typography variant="h4" color={'white'}>
                    12. MISCELLANEOUS
                </Typography>
                12.1 Ozlynk reserves the right to determine the form and means of providing notifications to you, and
                you agree to receive legal notices electronically if we so choose. 12.2 We may revise these Terms from
                time to time and the most current version will always be posted on our Website. If a revision, in our
                sole discretion, is material we will notify you. By continuing to access or use the Services after
                revisions become effective, you agree to be bound by the revised Terms. If you do not agree to the new
                terms, please stop using the Services. 12.3 These Terms, and any rights and licenses granted hereunder,
                may not be transferred or assigned by you, but may be assigned by Ozlynk without restriction. Any
                attempted transfer or assignment in violation hereof shall be null and void. 12.4 These Terms, together
                with any amendments and any additional agreements you may enter into with Ozlynk in connection with the
                products, shall constitute the entire agreement between you and Ozlynk concerning the Services. 12.5If
                any provision of these Terms is deemed unlawful, void, voidable or otherwise unenforceable, this does
                not affect the validity and enforceability of the remaining provisions of these Terms. The unlawful,
                void, voidable or otherwise unenforceable part shall be deemed replaced by a valid and enforceable
                provision that achieves the aim and scope of the replaced provision closely. 12.6No waiver of any term
                of these Terms shall be deemed a further or continuing waiver of such term or any other term, and
                Ozlynk's failure to assert any right or provision under these Terms shall not constitute a waiver of
                such right or provision.
                <Typography variant="h4" color={'white'}>
                    13. DISPUTE RESOLUTION, LAW AND JURISDICTION
                </Typography>
                13.1 These Terms shall be governed by the laws of Canada. 13.2 For any dispute you have with Ozlynk, you
                agree to first contact us and attempt to resolve the dispute with us informally. If Ozlynk has not been
                able to resolve the dispute with you informally, we each agree to resolve any claim, dispute, or
                controversy (excluding claims for injunctive or other equitable relief) arising out of or in connection
                with or relating to these Terms under the jurisdiction of the Courts in Canada, except as otherwise
                provided by mandatory law. 13.3 Nothing in this Section shall prevent either party from seeking
                injunctive or other equitable relief from the courts for matters related to data security, intellectual
                property or unauthorized access to the Service.
                <Typography variant="h4" color={'white'}>
                    14. DATA DELETION REQUESTS AND CONTACT INFORMATION
                </Typography>
                14.1 Data Deletion Requests: 14.1 .1 You have the right to request the deletion of your personal data
                collected by Ozlynk. If you wish to exercise this right, please send a written request to our designated
                email address for data deletion at privacy@ozlynk.com. 14.1 .2 Upon receiving your request, we will
                review it and take appropriate actions to delete your personal data, provided that such deletion is in
                accordance with applicable laws and regulations. Please note that certain data may be retained for legal
                or legitimate business purposes. 14.1 .3 We will respond to your data deletion request within a
                reasonable timeframe and, if necessary, may request additional information to verify your identity. 14.2
                Contact Us: 14.2 .1 If you have any questions, concerns, or feedback regarding these Terms, our
                Services, or the handling of your personal data, please contact us through our designated email address
                at contact@ozlynk.com. 14.2 .2 We are committed to addressing your inquiries promptly and efficiently.
                When contacting us, please provide detailed information to help us understand and respond to your query
                effectively. 14.3 Changes to Contact Information: We reserve the right to update our contact information
                for data deletion requests and general inquiries. Any changes will be communicated through the latest
                version of these Terms and on our Website. 14.4 Language of Communication: All communication related to
                data deletion requests and general inquiries will be conducted in English, and any responses or notices
                will be provided in English. By using our Services, you acknowledge and agree to the procedures outlined
                in this section regarding data deletion requests and contacting us.
            </Container>
        </div>
    );
};

export default TermsAndConditions;

import { setSession } from 'src/services/authentication';

import {
    CLEAR_AUTHENTICATION,
    LOGIN,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    RESTORE,
    RESTORE_FAIL,
    RESTORE_SUCCESS,
    SAVE_AUTHENTICATION,
    SUBSCRIBE_TO_OZ_PLUS,
    SUBSCRIBE_TO_OZ_PLUS_FAIL,
    SUBSCRIBE_TO_OZ_PLUS_SUCCESS,
} from '../constants/authentication.constants';

const initialState = {
    access_token: undefined,
    refresh_token: undefined,
    token_type: undefined,
    expires_in: undefined,
    isAuthenticated: false,
    user: {
        id: undefined,
        isPlusUser: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phone: undefined,
        photo: undefined,
    },
    subscription: {
        loading: false,
        error: null,
        result: null,
    },
    logout: {
        loading: false,
        error: null,
        result: null,
    },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const authUserReducer = (state = initialState, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case SAVE_AUTHENTICATION:
            return {
                ...state,
                isAuthenticated: true,
                ...payload,
            };
        case CLEAR_AUTHENTICATION: {
            return { ...initialState, logout: { ...state.logout } };
        }
        case LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                ...payload,
                error: null,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                ...payload,
                error: null,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                ...payload,
                error,
            };
        case LOGOUT:
            return { ...initialState, logout: { ...state.logout } };

        case SUBSCRIBE_TO_OZ_PLUS:
            return {
                ...state,
                subscription: { loading: true, error: null, result: null },
            };
        case SUBSCRIBE_TO_OZ_PLUS_SUCCESS:
            return {
                ...state,
                subscription: { loading: false, error: null, result: { isPlus: true } },
                user: { ...state.user, isPlusUser: true },
            };
        case SUBSCRIBE_TO_OZ_PLUS_FAIL:
            return {
                ...state,
                subscription: { loading: false, error: payload.error, result: null },
            };

        case RESTORE:
            return {
                ...state,
                isAuthenticated: false,
                loading: true,
                error: null,
            };
        case RESTORE_SUCCESS:
            setSession(payload.data.accessToken, payload.data.refreshToken, payload.data.idToken);
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                ...payload.data,
                error: null,
            };
        case RESTORE_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                error,
            };

        default:
            return state;
    }
};

export default authUserReducer;

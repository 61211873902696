import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import AuthenticationReducer from './authentication.reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authentication'],
};

const reducers = combineReducers({
    authentication: AuthenticationReducer,
});

const appReducer = persistReducer(persistConfig, reducers);

export default appReducer;

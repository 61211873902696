import { lazy } from 'react';

import { Loader } from './Loader';

// Status
const Status404 = Loader(lazy(() => import('src/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/pages/Status/Status500')));
const Home = Loader(lazy(() => import('src/pages/Home')));

const baseRoutes = [
    {
        path: '',
        // element: <TopNavigationLayout />,
        children: [{ path: '', element: <Home /> }],
    },
    {
        path: 'status',
        children: [
            {
                path: '500',
                element: <Status500 />,
            },
        ],
    },
    {
        path: '*',
        element: <Status404 />,
    },
];

export default baseRoutes;

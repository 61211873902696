import type { ReactNode } from 'react';
import { TMenuItem } from 'src/models/types';

export interface MenuItem {
    action?: Function;
    link?: string;
    icon?: ReactNode;
    badge?: string;
    badgeTooltip?: string;

    items?: MenuItem[];
    name: string;
}
export interface MenuItems {
    items: MenuItem[];
    heading: string;
    type?: string;
}

const menuItems = ({ user, logoutAction }: { user: any; logoutAction: Function }): MenuItems[] => {
    return [
        { type: TMenuItem.LOGO, items: [], heading: '' },
        {
            heading: '',
            items: [
                // {
                //     name: 'Download for iOS',
                //     icon: AppleIcon,
                //     link: '',
                // },
                // {
                //     name: 'Download for Android',
                //     icon: AndroidIcon,
                //     link: '',
                // },
                // {
                //     name: 'Download for Windows',
                //     icon: WindowIcon,
                //     link: '',
                // },
                // {
                //     name: 'Download for Mac',
                //     icon: LaptopMacIcon,
                //     link: '',
                // },
                {
                    name: 'Home',
                    link: '',
                },
                {
                    name: 'Features',
                    link: '',
                },
                {
                    name: 'Screens',
                    link: '',
                },
                {
                    name: 'Download',
                    link: '',
                },
                {
                    name: 'Team',
                    link: '',
                },
                {
                    name: 'Pricing',
                    link: '',
                },
                {
                    name: 'Sub Menu',
                    link: '',
                },
                user?.id
                    ? {
                          name: user.username,
                          items: [
                              {
                                  name: 'Logout',
                                  action: logoutAction,
                                  link: '/',
                              },
                          ],
                      }
                    : {
                          name: 'Login',
                          link: '/auth/login',
                      },
            ],
        },
    ];
};
export default menuItems;

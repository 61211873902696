const authBase = '/auth';

const routeNames = {
    auth: {
        login: authBase + '/login',
        register: authBase + '/register',
        recoverPassword: authBase + '/recover-password',
    },
    home: '/',
    posts: {
        single: '/posts/:postId',
    },
    privacyPolicy: '/privacy-policy',
    termsAndConditions: '/terms-and-conditions',
    termsConditions: '/terms-conditions',
};

export default routeNames;

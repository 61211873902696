import config from './config';

// file to hold api routes as constants
const signupApi = '/auth/register';
const loginApi = '/auth/login';
const refreshTokenApi = '/token/refresh';
const logoutApi = '';

const getPublicPostApi = '/media/:userId/public/posts/:postId';
const getPrivatePostApi = '/media/posts/:postId';

const subscribeToOzPlusApi = '/users/plus/subscribe';

const restoreLoginApi = '/auth/restore';

export const buildApiRoute = (route: string, args: any) => {
    const keys = Object.keys(args);
    const { isIamApi } = args;
    let newRoute = route;
    keys.forEach((key) => {
        newRoute = newRoute.replace(`:${key}`, args[key]);
    });

    return isIamApi ? `${config.iamApiUrl}${newRoute}` : newRoute;
};

export {
    getPrivatePostApi,
    getPublicPostApi,
    loginApi,
    logoutApi,
    refreshTokenApi,
    restoreLoginApi,
    signupApi,
    subscribeToOzPlusApi,
};
